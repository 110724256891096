import {v4 as uuidv4} from "uuid"
import MaterialIcon from "material-icons-react"

const Button = (props) => {
    if (props.icon){
        if (props?.iconType === "google"){
            if (props?.iconPosition === "end"){
                return(<button type={props.type} name={props.name} style={props.style} disabled={props.disabled} className={props.class} value={props.value} onClick={props.onClick} id={props.id}>
                 {props.text}<MaterialIcon size={props?.iconSize ? props.iconSize : "small"} className={props?.iconClass} color={props?.iconColor} icon={props?.iconName}/>
                </button>);
            }else{
                return(<button type={props.type} name={props.name} style={props.style} disabled={props.disabled} className={props.class} value={props.value} onClick={props.onClick} id={props.id}>
                 <MaterialIcon size={props?.iconSize ? props.iconSize : "small"} className={props?.iconClass} color={props?.iconColor} icon={props?.iconName}/>{props.text}
                </button>);
            }
        }else{
            if (props?.iconPosition === "end"){
                return(<button type={props.type} name={props.name} style={props.style} disabled={props.disabled} className={props.class} value={props.value} onClick={props.onClick} id={props.id}>
                 {props.text}<i className={props.iconClass}/>
                </button>);
            }else{
                return(<button type={props.type} name={props.name} style={props.style} disabled={props.disabled} className={props.class} value={props.value} onClick={props.onClick} id={props.id}>
                 <i className={props.iconClass}/>{props.text}
                </button>);
            }
        }
    }
    else if (props.spinner){
        return(<button type={props.type} style={props.style} name={props.name} className={props.class} value={props.value} onClick={props.onClick} id={props.id} disabled={true}>
         <span className={"spinner-border spinner-border-sm"} role={"status"} aria-hidden={true}/> {props.text}
        </button>);
    }
    else{
        return(<button style={props.style} type={props.type} name={props.name} className={props.class} id={props.id} title={props.title}
         value={props.value} onClick={props.onClick} disabled={props.disabled}>{props.text}</button>);
    }
};

Button.defaultProps = {
    type: "button",
    style: {},
    name: "",
    value: "",
    text: "Submit",
    onClick: null,
    class: "btn",
    icon: false,
    iconClass: "",
    spinner: false,
    id: "btn"+uuidv4(),
    disabled: false,
    title: ""
};

export default Button;