import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import {Suspense, useState} from "react";
import Button from "../component-assets/Button";
import ApiRequester from "../ApiRequester";
import { toast, ToastContainer } from "react-toastify";
import {campusToGuid, formFail, getUTCTimestamp, handleSubmitActions, formSuccess} from "../../libs";

const CustomSystemSettings = (props) => {

    let dData;
    if (ReactSession.get("PAL") != null) {
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const [saveBtn, setSaveBtn] = useState(false);
    const [check1, setCheck1] = useState(props?.UserEditDisplayName);
    const [check2, setCheck2] = useState(props?.CaregiverChgGroupEnabled);
    const [check3, setCheck3] = useState(props?.CaregiverChgToneEnabled);

    function performSave(){
        let payload, timestamp;
        handleSubmitActions("editSubmit", "loadEditSubmit");
        timestamp = getUTCTimestamp();
        payload = {UserEditDisplayName: check1, CaregiverChgGroupEnabled: check2, CaregiverChgToneEnabled: check3,
            CampusId: campusToGuid(dData, dData?.acnt), ModifiedBy: dData.username, DateModified: timestamp}
        ApiRequester({reqEndpoint: "save", Tbl: "CampusConfig", PKey: "CampusId", Payload: [payload],
                            CampusId: campusToGuid(dData, dData?.acnt)}).then(data => {
            if(data[0]){
                setSaveBtn(false);
                props?.setGlobalConfigData(prevVal => ({
                    ...prevVal, UserEditDisplayName: check1, CaregiverChgGroupEnabled: check2,
                    CaregiverChgToneEnabled: check3
                }))
                formSuccess("Saved customization settings", "editSubmit", "loadEditSubmit");
            }
            else{
                formFail("Error encountered, save failed", "editSubmit", "loadEditSubmit");
            }
        })
    }


    return (
        <>
            <div className={"card"}>
                <div className={"card-header"}>
                    <h4 className={"card-header-title"}>User Customizations</h4>
                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadEditSubmit"}/>
                    {saveBtn && <Button id={"editSubmit"} style={{width: "75px"}} text={"Save"} class={"btn btn-primary"} onClick={() => performSave()} />}
                </div>
                <div className={"card-body"}>
                    <div className={"list-group list-group-flush my-n3"}>
                        <div className={"list-group-item"}>
                            <div className={"row align-items-center"}>
                                <div className={"col"}>
                                    <h4 className={"font-weight-base mb-1"}>Display Name</h4>
                                    <p className={"text-muted"}>User can change their display name.</p>
                                </div>
                                <div className={"col-auto"}>
                                    <div className={"form-check form-switch"}>
                                        <input className={"form-check-input"} id={"check1"} type={"checkbox"}
                                               checked={check1}
                                               onChange={() => {
                                                   setSaveBtn(true);
                                                   setCheck1(!check1);
                                               }} />
                                        <label className={"form-check-label"} htmlFor={"check1"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"list-group-item"}>
                            <div className={"row align-items-center"}>
                                <div className={"col"}>
                                    <h4 className={"font-weight-base mb-1"}>Group</h4>
                                    <p className={"text-muted"}>User can change the group of alerts they receive</p>
                                </div>
                                <div className={"col-auto"}>
                                    <div className={"form-check form-switch"}>
                                        <input className={"form-check-input"} id={"check2"} type={"checkbox"}
                                               checked={check2}
                                               onChange={() => {
                                                   setSaveBtn(true);
                                                   setCheck2(!check2);
                                               }} />
                                        <label className={"form-check-label"} htmlFor={"check2"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"list-group-item"}>
                            <div className={"row align-items-center"}>
                                <div className={"col"}>
                                    <h4 className={"font-weight-base mb-1"}>Alert Tone</h4>
                                    <p className={"text-muted"}>User can set their own notification sound for alerts</p>
                                </div>
                                <div className={"col-auto"}>
                                    <div className={"form-check form-switch"}>
                                        <input className={"form-check-input"} id={"check3"} type={"checkbox"}
                                               checked={check3}
                                               onChange={() => {
                                                   setSaveBtn(true);
                                                   setCheck3(!check3);
                                               }} />
                                        <label className={"form-check-label"} htmlFor={"check3"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </>
    );
};

export default CustomSystemSettings;