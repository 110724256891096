import { Navigate } from 'react-router-dom';
import Navigation from "./Navigation";
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import { useParams } from "react-router";
import UserManagement from "./UserManagement";
import UserGroups from "./UserGroups";
import LegacyGroups from "./LegacyGroups";
import LegacyUserManagement from "./LegacyUserManagement";
import SessionReload from "./component-assets/SessionReload";
import HeaderBanner from "./component-assets/HeaderBanner";

const Users = (props) => {
    let pageContent, dData, paramVars, bData;
    paramVars = useParams();

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }
    if (paramVars?.dType === "groups" && dData?.access?.Users?.Groups?.Read){
        if (dData.migrated) {
            pageContent = <UserGroups />
        }else{
            pageContent = <LegacyGroups />;
        }
    }
    else{
        if (dData?.access?.Users?.UserManagement?.Read){
            if (dData.migrated){
                pageContent = <UserManagement />
            }else{
                pageContent = <LegacyUserManagement />
            }
        }else{
            pageContent = (
                <div>
                    <h3>Page not found. 404</h3>
                </div>
            );
        }
    }

    return (dData?.slauth === true && dData?.tKey != null && dData?.tKey !== "" && dData?.acnt != null && dData?.access?.Users) ? (
        <>
            <Navigation />
            {dData?.myVersion !== dData?.currentVersion && dData?.cloud ? <HeaderBanner /> : <></>}
            {dData?.migrated && <SessionReload />}
            {pageContent}
        </>
    ) : <Navigate to={"/"}/>;
};

export default Users;