import dklogo from "../../img/PCLC.svg";
import {Link} from 'react-router-dom';

const PasswordRecovery = (props) => {

    return (
        <div className={"row justify-content-center"}>
            <div className={"col-12 col-md-5 col-xl-4 my-5"}>
                <img src={dklogo} alt={"Palcare Logo Colored Varient"} className={"mb-3 w-100"} />
                <div>
                    <h3 className={"text-dark"}>Please contact your administrator for password recovery. For admin account recover contact support at 920-694-0085</h3>
                </div>
                <Link to={"/"}>Back to Login</Link>
            </div>
        </div>
    )
};

export default PasswordRecovery;