import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import TableHeaders from "../component-assets/TableHeaders";
import TableBodySimple from "../component-assets/TableBodySimple";

const DetailedEvents = (props) => {
    let dData, summaries, active, index2, tableBody, summaryClass, detailedClass, headerData;

    headerData = [
        {label: "Event", key: "Text"},
        {label: "Device", key: "Device"},
        {label: "Alert Type", key: "EventType", align: "center"},
        {label: "Occurred", key: "EventDateTime"},
        {label: "Responded", key: "AckDateTime"},
        {label: "Accepted/By", key: ["AcceptDateTime", "AcceptUsername"], type: "obj"},
        {label: "Finished", key: "FinishDateTime"},
        {label: "Reason", key: "Reason"},
        {label: "Response", key: "RawResponseTime", type: "switch", key2: "ResponseTime"}
    ]

    if (ReactSession.get("PAL") != null) {
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }
    summaries = [];

    if (props.repType === "Detailed"){
        summaryClass = "d-none";
        detailedClass = "";
    }else{
        summaryClass = "";
        detailedClass = "d-none";
    }

    if (props.reportData.reasons !== undefined){
        let index = 0;
        for (index = 0; index < props.reportData.reasons.length; index++){
            summaries.push(
                <tr key={"summaryRow-reasons-"+index.toString()}>
                    <td key={"reasonKey"+index.toString()} className={"ps-1"}>{props.reportData.reasons[index].key}</td>
                    <td key={"countKey"+index.toString()} className={"ps-1 text-center"}>{props.reportData.reasons[index].count}</td>
                    <td key={"percentKey"+index.toString()} className={"ps-1 text-center"}>{props.reportData.reasons[index].percent}</td>
                </tr>
            );
        }
    }
    active = [];
    if (props.reportData.mostActive !== undefined){
        for (index2 = 0; index2 < props.reportData.mostActive.length; index2++){
            active.push(
                <tr key={"summaryRow-active-"+index2.toString()}>
                    <td key={"sumActiveRow"+[index2.toString()]} className={"ps-1"}>{props.reportData.mostActive[index2]?.Resident}</td>
                    <td key={"activeLocRow"+[index2.toString()]} className={"ps-1 text-center"}>{props.reportData.mostActive[index2]?.Location}</td>
                    <td key={"activeCountRow"+[index2.toString()]} className={"ps-1 text-center"}>{props.reportData.mostActive[index2]?.Count}</td>
                    <td key={"activePercentRow"+[index2.toString()]} className={"ps-1 text-center"}>{props.reportData.mostActive[index2]?.Percent}</td>
                </tr>
            );

        }
    }

    const tableHeaders = <TableHeaders checkbox={false} headerData={headerData} sortOrder={props?.sorter?.sortOrder}
                                       sortColumn={props?.sorter?.sortColumn} sorter={props?.sorter} setSorter={props?.setSorter}/>
    if (props.tableRows !== undefined){
        tableBody = (<TableBodySimple tableData={props?.tableRows} checkbox={false} tableHeaders={headerData} />)
    }else{
        tableBody = ""
    }

    return (
        <>
            <div className={summaryClass}>
                <h6 className={"my-1 fs-3 px-4"}>
                    {props.repType}: <strong className={"fs-3 d-inline ms-2"} style={{color: "#0081c7"}}>{props.start}</strong> - <strong className={"fs-3 d-inline"} style={{color: "#0081c7"}}>{props.end}</strong>
                </h6>
                <div className={"d-flex justify-content-between px-4 pt-1 pb-4 my-2"}>
                    <div className={"text-center align-top w-50 me-6"}>
                        <table className={"table table-sm table-white table-hover"}>
                            <thead>
                                <tr>
                                    <th className={"table table-header w-auto py-1"} colSpan={"3"}>
                                        Event Summary Stats
                                    </th>
                                </tr>
                                <tr>
                                    <th className={"table table-header w-auto py-1"}>
                                        Reason
                                    </th>
                                    <th className={"table table-header w-auto py-1"}>
                                        Total
                                    </th>
                                    <th className={"table table-header w-auto py-1"}>
                                        % Total Volume
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className={"ps-1"}>Total Events</td>
                                    <td className={"ps-1 text-center"}>{props.reportData.count}</td>
                                    <td className={"ps-1 text-right"}> </td>
                                </tr>
                                {summaries}
                            </tbody>
                        </table>
                    </div>
                    <div className={"d-inline-block text-center align-top w-50 ms-6"}>
                        <table className={"table table-sm table-white table-hover"}>
                            <thead>
                                <tr>
                                    <th className={"table table-header w-auto py-1"} colSpan={"4"}>
                                        Most Active Residents/Locations
                                    </th>
                                </tr>
                                <tr>
                                    <th className={"table table-header w-auto py-1"}>
                                        Name
                                    </th>
                                    <th className={"table table-header w-auto py-1"}>
                                        Location
                                    </th>
                                    <th className={"table table-header w-auto py-1"}>
                                        Events
                                    </th>
                                    <th className={"table table-header w-auto py-1"}>
                                        % Volume
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {active}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className={detailedClass}>
                <h6 className={"my-1 fs-3 px-4"}>
                    {dData.campus} {props.repType} Report: <span className={"fs-3 d-inline ms-2"} style={{color: "#0081c7"}}>{props.start}</span> - <span className={"fs-3 d-inline"} style={{color: "#0081c7"}}>{props.end}</span>
                </h6>
                <div className={"pt-1 pb-4 px-4 my-2"}>
                    <table className={"table table-sm table-white table-hover"}>
                        <thead className={"sticky-table-head"}>
                            {tableHeaders}
                        </thead>
                        <tbody>
                            {tableBody}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default DetailedEvents;