import { ReactSession } from 'react-client-session';
import {Navigate} from "react-router-dom";
import {AES, enc} from "crypto-js";
import Navigation from "./Navigation";
import Header from "./Header";
import Button from "./component-assets/Button";
import {Suspense, useEffect, useState} from "react";
import { toast, ToastContainer } from "react-toastify";
import {v4 as uuidv4} from "uuid"
import {getUTCTimestamp, serverid_to_mac} from "../libs";
import ApiRequester from "./ApiRequester";
import { getTimeZone } from "../libs";
import Footer from "./Footer";
import CampusDropDown from "./component-assets/CampusDropDown";

function LegacyDemoPanel(props) {
    let dData, bData;
    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const [campusId, setCampusId] = useState(dData.acnt);
    const [server, setServer] = useState(dData.campusData[dData.acnt]?.Servers[0]?.ServerId);
    const [serverOptions, setServerOptions] = useState([]);
    const [pc, setPc] = useState({
        state: false,
        payload: {}
    });
    const [psc, setPsc] = useState({
        state: false,
        payload: {}
    });
    const [aio, setAio] = useState({
        state: false,
        payload: {}
    });
    const [wp, setWp] = useState({
        state: false,
        payload: {}
    });
    const [wr, setWr] = useState({
        state: false,
        payload: {}
    });
    const [fl, setFl] = useState({
        state: false,
        payload: {}
    })

    // Alert Sending Functions

    function sendAlert(deviceName){
        let resList, resUL, li, i, payload;

        resUL = document.getElementById("resList");
        li = resUL.querySelectorAll("li");
        resList = [];

        for (i=0; i < li.length; i++){
            let nxtBox = document.getElementById("res"+i);
            if (nxtBox.checked){
                resList.push(nxtBox.value);
            }
        }

        if (deviceName === "Pull Cord"){
            if (pc.state){
                payload = pc.payload;
                document.getElementById("s1").classList.remove("d-none");
                document.getElementById("b1").classList.add("d-none");
                payload.ackDateTime = getUTCTimestamp();
                payload.stage = "DONE";
                ApiRequester({reqEndpoint: "v2Send", Method: "PATCH", Payload: payload}).then(data => {
                    if (data[0]){
                        setPc({state: false, payload: {}});
                        toast.success("'"+deviceName+"' cleared");
                    }
                    else{
                        toast.error("Failed to clear '"+deviceName+" Pendant'");
                    }
                    document.getElementById("b1").classList.remove("d-none");
                    document.getElementById("s1").classList.add("d-none");
                });
            }
            else{
                if (server) {
                    if (resList.length > 0) {
                        payload = getV2(resList, deviceName, "00000001", server);
                        document.getElementById("s1").classList.remove("d-none");
                        document.getElementById("b1").classList.add("d-none");
                        ApiRequester({reqEndpoint: "v2Send", Method: "POST", Payload: payload}).then(data => {
                            if (data[0]) {
                                setPc({state: true, payload: payload});
                                toast.success("Alert created for '" + deviceName + " Cleared'");
                            } else {
                                toast.error("Failed to create '" + deviceName + " Pendant'");
                            }
                            document.getElementById("b1").classList.remove("d-none");
                            document.getElementById("s1").classList.add("d-none");
                        });
                    } else {
                        toast.error("No Resident Selected");
                    }
                }else {
                    toast.warning("No server to attach alarms to, connect a server to campus and re-login");
                }
            }
        }
        else if(deviceName === "Push Cord"){
            if (psc.state){
                payload = psc.payload;
                document.getElementById("s2").classList.remove("d-none");
                document.getElementById("b2").classList.add("d-none");
                payload.ackDateTime = getUTCTimestamp();
                payload.stage = "DONE";
                ApiRequester({reqEndpoint: "v2Send", Method: "PATCH", Payload: payload}).then(data => {
                    if (data[0]){
                        setPsc({state: false, payload: {}});
                        toast.success("'"+deviceName+"' cleared");
                    }
                    else{
                        toast.error("Failed to clear '"+deviceName+" Pendant'");
                    }
                    document.getElementById("b2").classList.remove("d-none");
                    document.getElementById("s2").classList.add("d-none");
                });
            }
            else {
                if (server) {
                    if (resList.length > 0) {
                        payload = getV2(resList, deviceName, "00000002", server, "Alert");
                        document.getElementById("s2").classList.remove("d-none");
                        document.getElementById("b2").classList.add("d-none");
                        ApiRequester({reqEndpoint: "v2Send", Method: "POST", Payload: payload}).then(data => {
                            if (data[0]) {
                                setPsc({state: true, payload: payload});
                                toast.success("Alert created for '" + deviceName + " Cleared'");
                            } else {
                                toast.error("Failed to create '" + deviceName + " Pendant'");
                            }
                            document.getElementById("b2").classList.remove("d-none");
                            document.getElementById("s2").classList.add("d-none");
                        });
                    } else {
                        toast.error("No Resident Selected");
                    }
                }else {
                    toast.warning("No server to attach alarms to, connect a server to campus and re-login");
                }
            }
        }
        else if(deviceName === "AIO Station"){
            if (aio.state){
                payload = aio.payload;
                document.getElementById("s3").classList.remove("d-none");
                document.getElementById("b3").classList.add("d-none");
                payload.ackDateTime = getUTCTimestamp();
                payload.stage = "DONE";
                ApiRequester({reqEndpoint: "v2Send", Method: "PATCH", Payload: payload}).then(data => {
                    if (data[0]){
                        setAio({state: false, payload: {}});
                        toast.success("'"+deviceName+"' cleared");
                    }
                    else{
                        toast.error("Failed to clear '"+deviceName+" Pendant'");
                    }
                    document.getElementById("b3").classList.remove("d-none");
                    document.getElementById("s3").classList.add("d-none");
                });
            }
            else{
                if (server) {
                    if (resList.length > 0) {
                        payload = getV2(resList, deviceName, "00000003", server, "Alert");
                        document.getElementById("s3").classList.remove("d-none");
                        document.getElementById("b3").classList.add("d-none");
                        ApiRequester({reqEndpoint: "v2Send", Method: "POST", Payload: payload}).then(data => {
                            if (data[0]) {
                                setAio({state: true, payload: payload});
                                toast.success("Alert created for '" + deviceName + " Cleared'");
                            } else {
                                toast.error("Failed to create '" + deviceName + "'");
                            }
                            document.getElementById("b3").classList.remove("d-none");
                            document.getElementById("s3").classList.add("d-none");
                        });
                    } else {
                        toast.error("No Resident Selected");
                    }
                }else {
                    toast.warning("No server to attach alarms to, connect a server to campus and re-login");
                }
            }
        }
        else if(deviceName === "Waterproof Pendant"){
            if (wp.state){
                payload = wp.payload;
                document.getElementById("s4").classList.remove("d-none");
                document.getElementById("b4").classList.add("d-none");
                payload.ackDateTime = getUTCTimestamp();
                payload.stage = "DONE";
                ApiRequester({reqEndpoint: "v2Send", Method: "PATCH", Payload: payload}).then(data => {
                    if (data[0]){
                        setWp({state: false, payload: {}});
                        toast.success("'"+deviceName+"' cleared");
                    }
                    else{
                        toast.error("Failed to clear '"+deviceName+" Pendant'");
                    }
                    document.getElementById("b4").classList.remove("d-none");
                    document.getElementById("s4").classList.add("d-none");
                });
            }
            else{
                if (server) {
                    if (resList.length > 0) {
                        payload = getV2(resList, deviceName, "00000004", server, "Alert");
                        document.getElementById("s4").classList.remove("d-none");
                        document.getElementById("b4").classList.add("d-none");
                        ApiRequester({reqEndpoint: "v2Send", Method: "POST", Payload: payload}).then(data => {
                            if (data[0]) {
                                setWp({state: true, payload: payload});
                                toast.success("Alert created for '" + deviceName + " Cleared'");
                            } else {
                                toast.error("Failed to create '" + deviceName + "'");
                            }
                            document.getElementById("b4").classList.remove("d-none");
                            document.getElementById("s4").classList.add("d-none");
                        });
                    } else {
                        toast.error("No Resident Selected");
                    }
                } else {
                    toast.warning("No server to attach alarms to, connect a server to campus and re-login");
                }
            }
        }
        else if(deviceName === "Water Resistant Pendant"){
            if (wr.state){
                payload = wr.payload;
                document.getElementById("s5").classList.remove("d-none");
                document.getElementById("b5").classList.add("d-none");
                payload.ackDateTime = getUTCTimestamp();
                payload.stage = "DONE";
                ApiRequester({reqEndpoint: "v2Send", Method: "PATCH", Payload: payload}).then(data => {
                    if (data[0]){
                        setWr({state: false, payload: {}});
                        toast.success("'"+deviceName+"' cleared");
                    }
                    else{
                        toast.error("Failed to clear '"+deviceName+" Pendant'");
                    }
                    document.getElementById("b5").classList.remove("d-none");
                    document.getElementById("s5").classList.add("d-none");
                });
            }
            else{
                if (server) {
                    if (resList.length > 0) {
                        payload = getV2(resList, deviceName, "00000005", server, "Alert");
                        document.getElementById("s5").classList.remove("d-none");
                        document.getElementById("b5").classList.add("d-none");
                        ApiRequester({reqEndpoint: "v2Send", Method: "POST", Payload: payload}).then(data => {
                            if (data[0]) {
                                setWr({state: true, payload: payload});
                                toast.success("Alert created for '" + deviceName + " Cleared'");
                            } else {
                                toast.error("Failed to create '" + deviceName + "'");
                            }
                            document.getElementById("b5").classList.remove("d-none");
                            document.getElementById("s5").classList.add("d-none");
                        });
                    } else {
                        toast.error("No Resident Selected");
                    }
                } else {
                    toast.warning("No server to attach alarms to, connect a server to campus and re-login");
                }
            }
        }
        else if(deviceName === "Fall Pendant"){
            if (fl.state){
                payload = fl.payload;
                document.getElementById("s6").classList.remove("d-none");
                document.getElementById("b6").classList.add("d-none");
                payload.ackDateTime = getUTCTimestamp();
                payload.stage = "DONE";
                ApiRequester({reqEndpoint: "v2Send", Method: "PATCH", Payload: payload}).then(data => {
                    if (data[0]){
                        setFl({state: false, payload: {}});
                        toast.success("'"+deviceName+"' cleared");
                    }
                    else{
                        toast.error("Failed to clear '"+deviceName+"'");
                    }
                    document.getElementById("b6").classList.remove("d-none");
                    document.getElementById("s6").classList.add("d-none");
                });
            }
            else{
                if (server) {
                    if (resList.length > 0) {
                        payload = getV2(resList, deviceName, "00000006", server, "Fall");
                        document.getElementById("s6").classList.remove("d-none");
                        document.getElementById("b6").classList.add("d-none");
                        ApiRequester({reqEndpoint: "v2Send", Method: "POST", Payload: payload}).then(data => {
                            if (data[0]) {
                                setFl({state: true, payload: payload});
                                toast.success("Alert created for '" + deviceName + " Cleared'");
                            } else {
                                toast.error("Failed to create '" + deviceName + "'");
                            }
                            document.getElementById("b6").classList.remove("d-none");
                            document.getElementById("s6").classList.add("d-none");
                        });
                    } else {
                        toast.error("No Resident Selected");
                    }
                } else {
                    toast.warning("No server to attach alarms to, connect a server to campus and re-login");
                }
            }
        }
    }

    function getV2(resList, device, deviceId, serverId, eType="Alert"){
        let payload, location, locationId, timestamp, residents;
        residents = [];
        if (resList[0].search("1") !== -1){
            location = "room 2";
            locationId = 2;
        }
        else if(resList[0].search("2") !== -1){
            location = "room 3";
            locationId = 3;
        }
        else{
            location = "room 1";
            locationId = 1;
        }
        timestamp = getUTCTimestamp();
        for (let i=0; i < resList.length; i++){
            residents.push({id: i, firstName: resList[i], lastName: ""});
        }
        payload = {id: uuidv4(), type: eType, stage: "CREATE",
            text: "Alarm "+device+" in "+ location +" "+ resList[0], capCodes: ["0220001"],
            location: {id: locationId, name: location, building: "Main", uid: ""},
            device: {id: deviceId, name: device, type: "", area: "", presses: 1, uid: deviceId},
            residents: residents, near: "", escalation: [0], capcodes: "0220001", vendor: "PalatiumCare",
            version: "1.0.0", eVersion: "2.51", ttl: 0, iTime: 0, eventDateTime: timestamp, ackDateTime: "",
            timeZone: getTimeZone(), actions: "", messageNumber: "", mac: serverid_to_mac(serverId)};
        return payload;
    }

    useEffect(() => {
        let tmp = [];
        for (let i=0; i < dData.campusData[campusId].Servers.length; i++){
            if (dData.campusData[campusId].Servers[i].GreenServer){
                tmp.push(
                    <option key={"server-option"+i} value={dData.campusData[campusId].Servers[i].ServerId}>{dData.campusData[campusId].Servers[i].ProductName} ({serverid_to_mac(dData.campusData[campusId].Servers[i].ServerId)})</option>
                );
            }
        }
        if (tmp.length <= 0){
            setServer(() => "");
            tmp.push(
                <option key={"server-option-null"} value={""}>No available servers</option>
            )
        }
        setServerOptions(tmp);
    }, [campusId])

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div>
            </div>
            <CampusDropDown campusId={campusId} setCampusId={(item) => setCampusId(item)} setCount={() => {}} pageAction={() => {}}/>
        </div>
    )

    return (dData?.slauth === true && dData?.tKey != null && dData?.tKey !== "" && dData?.acnt != null && ["palcare", "palcare-admin"].includes(dData?.userrole)) ? (
        <>
            <Navigation />
            <div className={"main-content"}>
                <Header preTitle={"Demo"} Title={"Demo Panel"} content={headerContent}/>
                <div className={"container-fluid"}>
                    <div className={"row justify-content-center"}>
                        <div className={"col-sm-3 mb-3"}>
                            <div className={"form-floating mb-3"}>
                                <select className={"form-select"} required={true} id={"serverId"} value={server} onChange={(e) => {setServer(e.target.value)}}>
                                    {serverOptions}
                                </select>
                                <label htmlFor={"serverId"}>Server</label>
                            </div>
                            <div className={"p-3 bg-auth flexWidth"} style={{maxWidth: "525px", minWidth: "175px", border: "rgb(227, 235, 246) 1px solid"}}>
                                <h3 className={"mb-4"}>Resident Selection</h3>
                                <ul id={"resList"} style={{listStyle: "none"}} className={"mb-5 ps-3"}>
                                    <li key={"dr1"} className={"mb-3"}>
                                        <div className={"form-check"}>
                                            <input className={"form-check-input table-checkbox me-3"} type={"checkbox"} value={"Resident 1"} name={"res0"} id={"res0"} />
                                                <label className={"form-check-label align-middle"} htmlFor={"res0"}>Resident 1</label>
                                        </div>
                                    </li>
                                    <li key={"dr2"} className={"mb-3"}>
                                        <div className={"form-check"}>
                                            <input className={"form-check-input table-checkbox me-3"} type={"checkbox"} value={"Resident 2"} name={"res1"} id={"res1"} />
                                                <label className={"form-check-label align-middle"} htmlFor={"res1"}>Resident 2</label>
                                        </div>
                                    </li>
                                    <li key={"dr3"} className={"mb-3"}>
                                        <div className={"form-check"}>
                                            <input className={"form-check-input table-checkbox me-3"} type={"checkbox"} value={"Resident 3"} name={"res2"} id={"res2"} />
                                                <label className={"form-check-label align-middle"} htmlFor={"res2"}>Resident 3</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={"col-sm-9"}>
                            <div className={"align-content-center p-3 bg-auth w-50 flexWidth"} style={{maxWidth: "525px", minWidth: "250px", border: "rgb(227, 235, 246) 1px solid"}}>
                                <h3 className={"mb-4"}>Device Panel</h3>
                                <ul id={"buttonList px-1"} style={{listStyle: "none", padding: 0}}>
                                    <li key={"dd1"} className={"mb-3"}>
                                        <Button style={{maxWidth: "500px"}} class={pc.state?"btn btn-success w-100 d-none":"btn btn-secondary w-100 d-none"}
                                                text={"Sending"} id={"s1"} spinner={true} />
                                        <Button style={{maxWidth: "500px"}} class={pc.state?"btn btn-success w-100":"btn btn-secondary w-100"}
                                                text={pc.state?"Clear Pull Cord":"Activate Pull Cord"} id={"b1"}
                                                onClick={() => sendAlert("Pull Cord")} />
                                    </li>
                                    <li key={"dd2"} className={"mb-3"}>
                                        <Button style={{maxWidth: "500px"}} class={psc.state?"btn btn-success w-100 d-none":"btn btn-secondary w-100 d-none"}
                                                text={"Sending"} id={"s2"} spinner={true} />
                                        <Button style={{maxWidth: "500px"}} class={psc.state?"btn btn-success w-100":"btn btn-secondary w-100"}
                                                text={psc.state?"Clear Push Cord":"Activate Push Cord"} id={"b2"}
                                                onClick={() => sendAlert("Push Cord")} />
                                    </li>
                                    <li key={"dd3"} className={"mb-3"}>
                                        <Button style={{maxWidth: "500px"}} class={aio.state?"btn btn-success w-100 d-none":"btn btn-secondary w-100 d-none"}
                                                text={"Sending"} id={"s3"} spinner={true} />
                                        <Button style={{maxWidth: "500px"}} class={aio.state?"btn btn-success w-100":"btn btn-secondary w-100"}
                                                text={aio.state?"Clear AIO Station":"Activate AIO Station"} id={"b3"}
                                                onClick={() => sendAlert("AIO Station")} />
                                    </li>
                                    <li key={"dd4"} className={"mb-3"}>
                                        <Button style={{maxWidth: "500px"}} class={wp.state?"btn btn-success w-100 d-none":"btn btn-secondary w-100 d-none"}
                                                text={"Sending"} id={"s4"} spinner={true} />
                                        <Button style={{maxWidth: "500px"}} class={wp.state?"btn btn-success w-100":"btn btn-secondary w-100"}
                                                text={wp.state?"Clear Waterproof Pendant":"Activate Waterproof Pendant"} id={"b4"}
                                                onClick={() => sendAlert("Waterproof Pendant")} />
                                    </li>
                                    <li key={"dd5"} className={"mb-3"}>
                                        <Button style={{maxWidth: "500px"}} class={wr.state?"btn btn-success w-100 d-none":"btn btn-secondary w-100 d-none"}
                                                text={"Sending"} id={"s5"} spinner={true} />
                                        <Button style={{maxWidth: "500px"}} class={wr.state?"btn btn-success w-100":"btn btn-secondary w-100"}
                                                text={wr.state?"Clear Water Resistant Pendant":"Activate Water Resistant Pendant"} id={"b5"}
                                                onClick={() => sendAlert("Water Resistant Pendant")} />
                                    </li>
                                    <li key={"dd6"} className={"mb-3"}>
                                        <Button style={{maxWidth: "500px"}} class={fl.state?"btn btn-success w-100 d-none":"btn btn-secondary w-100 d-none"}
                                                text={"Sending"} id={"s6"} spinner={true} />
                                        <Button style={{maxWidth: "500px"}} class={fl.state?"btn btn-success w-100":"btn btn-secondary w-100"}
                                                text={fl.state?"Clear Fall Alarm":"Activate Fall Alarm"} id={"b6"}
                                                onClick={() => sendAlert("Fall Pendant")} />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={"footer-spacer"}>
                    </div>
                </div>
                <Footer />
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </>
    ) : <Navigate to={"/"}/>;
}

export default LegacyDemoPanel;