import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import Header from "./Header";
import {Suspense, useEffect, useState} from "react";
import {
    paginateData,
    formatDate,
    sortFunc,
    handleSubmitActions,
    getCurrentYear,
    formSuccess,
    showModal,
    formatDateIso,
    formCheck,
    convertHHMMtoSec,
    swapModal,
    range,
    calculateSendTime,
    formFail,
    checkAll, getToday, getWeekRange, getMonthRange, getQuarterRange
} from "../libs";
import {toast, ToastContainer} from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import Footer from "./Footer";
import { CSVLink } from "react-csv"
import DatePicker from "react-datepicker";
import PaginationItems from "./component-assets/PaginationItems";
import {Pagination} from "react-bootstrap";
import PerPageModal from "./component-assets/PerPageModal";
import CampusDropDown from "./component-assets/CampusDropDown";
import Button from "./component-assets/Button";
import TableBodySimple from "./component-assets/TableBodySimple";
import TableHeaders from "./component-assets/TableHeaders";
import ApiRequester from "./ApiRequester";
import {v4 as uuidv4} from "uuid"
import TableBody from "./component-assets/TableBody";


const EnvironmentReports = (props) => {

    let dData, areaTypeHeaders, residentHeaders, eventTypeHeaders, userHeaders, reasonHeaders, areaHeaders;

    if (ReactSession.get("PAL") != null) {
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    // Filter Modal Table Variables
    areaHeaders = [
        {label: "Area Name", key: "area_name", align: "left",
            link: {enabled: true, type: "standard", linkto: "/profiles/area", linkkey: "area_id",
                inmodal: true, modalKey: "cancelFilterBtn2"}},
    ];
    areaTypeHeaders = [
        {label: "Area Type", key: "area_type_name", align: "left"},
        {label: "Description", key: "area_type_description", align: "left"},
    ];
    residentHeaders = [
        {label: "First Name", key: "resident_first_name", align: "left",
            link: {enabled: true, type: "standard", linkto: "/profiles/resident", linkkey: "resident_id",
                inmodal: true, modalKey: "cancelFilterBtn2"}},
        {label: "Last Name", key: "resident_last_name", align: "left",
            link: {enabled: true, type: "standard", linkto: "/profiles/resident", linkkey: "resident_id",
                inmodal: true, modalKey: "cancelFilterBtn2"}},
        {label: "Nickname", key: "resident_nickname", align: "left"},
        {label: "Status", key: "is_active", align: "center", type: "bool", f: "Moved Out", t: "Active"}
    ];
    userHeaders = [
        {label: "Username", key: "username", align: "left"},
        {label: "Display", key: "display_name", align: "left"},
        {label: "Role", key: "user_role", align: "left"}
    ];
    eventTypeHeaders = [
        {label: "Event Type", key: "event_type_name", align: "left"},
    ];
    reasonHeaders = [
        {label: "Reason", key: "reason_code_description", align: "left"},
    ];

    let daysago = new Date().setDate(new Date().getDate() - 1);

    // Data Variables
    const [detailedHeaderData, setDetailedHeaderData] = useState([]);
    const [summaryHeaderData, setSummaryHeaderData] = useState([]);
    const [csvHeaders, setCSVHeaders] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [activeData, setActiveData] = useState([]);
    const [summaryData, setSummaryData] = useState([]);
    const [summaryActive, setSummaryActive] = useState([]);
    const [freqData, setFreqData] = useState([]);
    const [csvUrl, setCSVUrl] = useState("");
    // Table Control Variables
    const [tableRows, setTableRows] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(dData.perPage);
    const [ppRules, setPRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    });
    const [sorter, setSorter] = useState({
        sortColumn: "description",
        sortOrder: "desc"
    });
    // Page Variables
    const [campusId, setCampusId] = useState(dData.acnt);
    const [reportDisplay, setReportDisplay] = useState("");
    const [count, setCount] = useState(0);
    const [filterCount, setFilterCount] = useState(0);
    const [footer, setFooter] = useState(<div></div>);
    const [repType, setRepType] = useState("Summary");
    const [reportType, setReportType] = useState("Temperature Reports")
    const [environTypeData, setEnvironTypeData] = useState([]);
    const [environTypeOptions, setEnvironTypeOptions] = useState([]);
    const [environTypeId, setEnvironTypeId] = useState(1);
    const [scheduleable, setScheduleable] = useState(true);
    const [csvLink, setLink] = useState({
        filename: `environment_report.csv`,
        headers: [],
        data: []
    });
    const [overLimit, setOverLimit] = useState(false);
    // Helper Control Variables
    const [status, setStatus] = useState("init");
    const [startDisplay, setStartDisplay] = useState("");
    const [endDisplay, setEndDisplay] = useState("");
    const [tabItem, setTabItem] = useState("area");
    const [tabCat, setTabCat] = useState("areas");
    const [btnAvail, setBtnAvail] = useState(false);
    const [tmpPerPage, setTmpPerPage] = useState(dData.perPage);
    const [freqOptions, setFreqOptions] = useState([]);
    const [rangeName, setRangeName] = useState("Yesterday");
    const [rangeId, setRangeId] = useState(2);
    // Header Variables
    const [csvButtonHTML, setCSVButtonHTML] = useState("");
    const [start, setStart] = useState(new Date(daysago));
    const [end, setEnd] = useState(new Date(daysago));
    // Area Filter Table Variables
    const [areaOriginData, setAreaOriginData] = useState([]);
    const [areaTableRows, setAreaTableRows] = useState([]);
    const [areaDataCnt, setAreaDataCnt] = useState(0);
    const [areaIdList, setAreaIdList] = useState([]);
    const [areaSearch, setAreaSearch] = useState("");
    const [areaSorter, setAreaSorter] = useState({
        sortColumn: "area_name",
        sortOrder: "desc"
    });
    // Area Type Filter Table Variables
    const [areaTypeOriginData, setAreaTypeOriginData] = useState([]);
    const [areaTypeTableRows, setAreaTypeTableRows] = useState([]);
    const [areaTypeDataCnt, setAreaTypeDataCnt] = useState(0);
    const [areaTypeIdList, setAreaTypeIdList] = useState([]);
    const [areaTypeSearch, setAreaTypeSearch] = useState("");
    const [areaTypeSorter, setAreaTypeSorter] = useState({
        sortColumn: "area_type_name",
        sortOrder: "desc"
    });
    // Resident Filter Table Variables
    const [resOriginData, setResOriginData] = useState([]);
    const [resTableRows, setResTableRows] = useState([]);
    const [resDataCnt, setResDataCnt] = useState(0);
    const [resIdList, setResIdList] = useState([]);
    const [resSearch, setResSearch] = useState("");
    const [resSorter, setResSorter] = useState({
        sortColumn: "resident_first_name",
        sortOrder: "desc"
    });
    // Event Type Filter Table Variables
    const [eventTypeOriginData, setEventTypeOriginData] = useState([]);
    const [eventTypeTableRows, setEventTypeTableRows] = useState([]);
    const [eventTypeDataCnt, setEventTypeDataCnt] = useState(0);
    const [eventTypeIdList, setEventTypeIdList] = useState([]);
    const [eventTypeSearch, setEventTypeSearch] = useState("");
    const [eventTypeSorter, setEventTypeSorter] = useState({
        sortColumn: "event_type_name",
        sortOrder: "desc"
    });
    // User Filter Table Variables
    const [userOriginData, setUserOriginData] = useState([]);
    const [userTableRows, setUserTableRows] = useState([]);
    const [userDataCnt, setUserDataCnt] = useState(0);
    const [userIdList, setUserIdList] = useState([]);
    const [userSearch, setUserSearch] = useState("");
    const [userSorter, setUserSorter] = useState({
        sortColumn: "username",
        sortOrder: "desc"
    });
    // Reason Filter Table Variables
    const [reasonOriginData, setReasonOriginData] = useState([]);
    const [reasonTableRows, setReasonTableRows] = useState([]);
    const [reasonDataCnt, setReasonDataCnt] = useState(0);
    const [reasonIdList, setReasonIdList] = useState([]);
    const [reasonSearch, setReasonSearch] = useState("");
    const [reasonSorter, setReasonSorter] = useState({
        sortColumn: "reason_code_description",
        sortOrder: "desc"
    });
    // Backend Filter Holders For Selected Filters IDs
    const [areaFilterList, setAreaFilterList] = useState(dData?.areaFilter ? dData.areaFilter : []);
    const [areaTypeFilterList, setAreaTypeFilterList] = useState(dData?.areaTypeFilter ? dData.areaTypeFilter : []);
    const [resFilterList, setResFilterList] = useState(dData?.resFilter ? dData.resFilter : []);
    const [typeFilterList, setTypeFilterList] = useState(dData?.typeFilter ? dData.typeFilter : []);
    const [userFilterList, setUserFilterList] = useState(dData?.userFilter ? dData.userFilter : []);
    const [reasonFilterList, setReasonFilterList] = useState(dData?.reasonFilter ? dData.reasonFilter : []);
    // Backend Filter Holders For Selected Filters Labels
    const [areaFilterLabels, setAreaFilterLabels] = useState(dData?.areaFilterLabel ? dData.areaFilterLabel : []);
    const [areaTypeFilterLabels, setAreaTypeFilterLabels] = useState(dData?.areaTypeFilterLabel ? dData.areaTypeFilterLabel : []);
    const [resFilterLabels, setResFilterLabels] = useState(dData?.resFilterLabel ? dData.resFilterLabel : []);
    const [typeFilterLabels, setTypeFilterLabels] = useState(dData?.typeFilterLabel ? dData.typeFilterLabel : []);
    const [userFilterLabels, setUserFilterLabels] = useState(dData?.userFilterLabel ? dData.userFilterLabel : []);
    const [reasonFilterLabels, setReasonFilterLabels] = useState(dData?.reasonFilterLabel ? dData.reasonFilterLabel : []);
    // Modal for emails on schedules
    const [addCurEmails, setAddCurEmails] = useState([]);
    const [addNewEmails, setAddNewEmails] = useState([]);
    const [emailEntities, setEmailEntities] = useState([]);
    // Overlimit send email report storage variables, updated on run report.
    const [reportTypeIdx, setReportTypeIdx] = useState(0);
    const [reportFilterTermsx, setReportFilterTermsx] = useState({});
    const [startDatex, setStartDatex] = useState("");
    const [endDatex, setEndDatex] = useState("");

    // Processes report origin data into active data, active data has filters applied removing data potentially.
    useEffect(() => {
        setActiveData(reportData);
        setSummaryActive(summaryData);
    }, [reportData, summaryData]);

    // This process applies sort and generates jsx for table body presentation, also handles pagination.
    useEffect(() => {
        if (repType.toLowerCase() === "summary"){
            if (summaryActive?.length > 0){
                let pRules = paginateData(summaryActive, page, perPage);
                setPRules(pRules);
                if (summaryActive.length > 0) {
                    summaryActive.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
                }
                setFooter(() => (
                    <div>
                        <Pagination>
                            <PaginationItems setPage={setPage} page={page} beginDot={pRules.beginDot}
                                             endDot={pRules.endDot} pageMin={pRules.pageMin} pageMax={pRules.pageMax}
                                             max={pRules.max}/>
                        </Pagination>
                        <Button class={"btn btn-secondary fs-5 ms-4"} text={perPage} onClick={() => showModal("filtermodal")}/>
                    </div>
                ));
                setTableRows(<TableBody start={pRules.start} end={pRules.end} tableData={summaryActive}
                                        checkbox={false} tableHeaders={summaryHeaderData} />);
                setBtnAvail(() => true);
            }else{
                setFooter(() => <div style={{minWidth: "166px"}}></div>);
            }
            setLink(() => {
                return {filename: `${reportType.toLowerCase().replaceAll(" ", "")}_${repType.toLowerCase()}_${start}_${end}.csv`,
                    headers: csvHeaders,
                    data: reportData}
            });
        }else{
            if (activeData?.length > 0){
                let pRules = paginateData(activeData, page, perPage);
                setPRules(pRules);
                if (activeData.length > 0) {
                    activeData.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
                }
                setFooter(() => (
                    <div>
                        <Pagination>
                            <PaginationItems setPage={setPage} page={page} beginDot={pRules.beginDot}
                                             endDot={pRules.endDot} pageMin={pRules.pageMin} pageMax={pRules.pageMax}
                                             max={pRules.max}/>
                        </Pagination>
                        <Button class={"btn btn-secondary fs-5 ms-4"} text={perPage} onClick={() => showModal("filtermodal")}/>
                    </div>
                ));
                setTableRows(<TableBodySimple start={pRules.start} end={pRules.end} tableData={activeData}
                                              checkbox={false} tableHeaders={detailedHeaderData} />);
                setBtnAvail(() => true);
            }else{
                setFooter(() => <div style={{minWidth: "166px"}}></div>);
            }
            setLink(() => {
                return {filename: `${reportType.toLowerCase().replaceAll(" ", "")}_${repType.toLowerCase()}_${start}_${end}.csv`,
                    headers: csvHeaders,
                    data: reportData}
            });
        }
    }, [activeData, summaryActive, sorter, page, perPage, reportData, repType]);

    useEffect(() => {
        if (environTypeId === 2){
            setReportType("Humidity Reports");
            setDetailedHeaderData(() => [
                {label: "Timestamp", key: "local_create_time", align: "center", type: "datetime"},
                {label: "Resident", key: "resident_name", align: "center",
                    link: {enabled: true, type: "standard", linkto: "/profiles/resident", linkkey: "resident_id"}},
                {label: "Area", key: "area_name", align: "center",
                    link: {enabled: true, type: "standard", linkto: "/profiles/area", linkkey: "area_id"}},
                {label: "Device", key: "device_name", align: "center",
                    link: {enabled: true, type: "standard", linkto: "/profiles/inovonics", linkkey: "device_id"}},
                {label: "Device Serial", key: "manufacture_uuid", align: "center"},
                {label: "Current Humidity", key: "current_humidity", align: "center"}
            ]);
            setSummaryHeaderData(() => [
                {label: "Device", key: "device_name", align: "center",
                    link: {enabled: true, type: "standard", linkto: "/profiles/inovonics", linkkey: "device_id"}},
                {label: "Device Serial", key: "manufacture_uuid", align: "center"},
                {label: "Area", key: "area_name", align: "center",
                    link: {enabled: true, type: "standard", linkto: "/profiles/area", linkkey: "area_id"}},
                {label: "Resident", key: "resident_name", align: "center",
                    link: {enabled: true, type: "standard", linkto: "/profiles/resident", linkkey: "resident_id"}},
                {label: "Avg Humidity", key: "avg_humidity", align: "center"},
                {label: "High Humidity", key: "max_humidity", align: "center"},
                {label: "Low Humidity", key: "min_humidity", align: "center"}
            ]);
            setCSVHeaders(() => [
                {label: "Timestamp", key: "local_create_time"},
                {label: "Resident", key: "resident_name"},
                {label: "Area", key: "area_name"},
                {label: "Area Type", key: "area_type_name"},
                {label: "Device", key: "device_name"},
                {label: "Device Serial", key: "manufacture_uuid"},
                {label: "Device Category", key: "device_category_short_name"},
                {label: "Current Humidity", key: "current_humidity"}
            ]);
        }else if (environTypeId === 3){
            setReportType("Battery Reports");
            setDetailedHeaderData(() => [
                {label: "Timestamp", key: "local_create_time", align: "center", type: "datetime"},
                {label: "Resident", key: "resident_name", align: "center",
                    link: {enabled: true, type: "standard", linkto: "/profiles/resident", linkkey: "resident_id"}},
                {label: "Area", key: "area_name", align: "center",
                    link: {enabled: true, type: "standard", linkto: "/profiles/area", linkkey: "area_id"}},
                {label: "Device", key: "device_name", align: "center",
                    link: {enabled: true, type: "standard", linkto: "/profiles/inovonics", linkkey: "device_id"}},
                {label: "Device Serial", key: "manufacture_uuid", align: "center"},
                {label: "Current Battery", key: "current_battery", align: "center"},
            ]);
            setSummaryHeaderData(() => [
                {label: "Device", key: "device_name", align: "center",
                    link: {enabled: true, type: "standard", linkto: "/profiles/inovonics", linkkey: "device_id"}},
                {label: "Device Serial", key: "manufacture_uuid", align: "center"},
                {label: "Area", key: "area_name", align: "center",
                    link: {enabled: true, type: "standard", linkto: "/profiles/area", linkkey: "area_id"}},
                {label: "Resident", key: "resident_name", align: "center",
                    link: {enabled: true, type: "standard", linkto: "/profiles/resident", linkkey: "resident_id"}},
                {label: "Avg Battery", key: "avg_battery", align: "center"},
                {label: "High Battery", key: "max_battery", align: "center"},
                {label: "Low Battery", key: "min_battery", align: "center"}
            ]);
            setCSVHeaders(() => [
                {label: "Timestamp", key: "local_create_time"},
                {label: "Resident", key: "resident_name"},
                {label: "Area", key: "area_name"},
                {label: "Area Type", key: "area_type_name"},
                {label: "Device", key: "device_name"},
                {label: "Device Serial", key: "manufacture_uuid"},
                {label: "Device Category", key: "device_category_short_name"},
                {label: "Current Battery", key: "current_battery"}
            ]);
        }else{
            setReportType("Temperature Reports");
            setDetailedHeaderData(() => [
                {label: "Timestamp", key: "local_create_time", align: "center", type: "datetime"},
                {label: "Area", key: "area_name", align: "center",
                    link: {enabled: true, type: "standard", linkto: "/profiles/area", linkkey: "area_id"}},
                {label: "Device", key: "device_name", align: "center",
                    link: {enabled: true, type: "standard", linkto: "/profiles/inovonics", linkkey: "device_id"}},
                {label: "Device Serial", key: "manufacture_uuid", align: "center"},
                {label: "Resident", key: "resident_name", align: "center",
                    link: {enabled: true, type: "standard", linkto: "/profiles/resident", linkkey: "resident_id"}},
                {label: "Current Temp", key: "current_temperature", align: "center"},
            ]);
            setSummaryHeaderData(() => [
                {label: "Device", key: "device_name", align: "center",
                    link: {enabled: true, type: "standard", linkto: "/profiles/inovonics", linkkey: "device_id"}},
                {label: "Device Serial", key: "manufacture_uuid", align: "center"},
                {label: "Area", key: "area_name", align: "center",
                    link: {enabled: true, type: "standard", linkto: "/profiles/area", linkkey: "area_id"}},
                {label: "Resident", key: "resident_name", align: "center",
                    link: {enabled: true, type: "standard", linkto: "/profiles/resident", linkkey: "resident_id"}},
                {label: "Avg Temp", key: "avg_temperature", align: "center"},
                {label: "High Temp", key: "max_temperature", align: "center"},
                {label: "Low Temp", key: "min_temperature", align: "center"}
            ]);
            setCSVHeaders(() => [
                {label: "Timestamp", key: "local_create_time"},
                {label: "Resident", key: "resident_name"},
                {label: "Area", key: "area_name"},
                {label: "Area Type", key: "area_type_name"},
                {label: "Device", key: "device_name"},
                {label: "Device Serial", key: "manufacture_uuid"},
                {label: "Device Category", key: "device_category_short_name"},
                {label: "Current Temp", key: "current_temperature"}
            ]);
        }
    }, [environTypeId]);

    // This makes button appear to download or not.
    useEffect(() => {
        if (reportData?.length > 0) {
            setCSVButtonHTML(() =>
                <div className={"d-inline-block"}>
                    <div className={"mx-2"}>
                        {btnAvail === true
                            ? <CSVLink {...csvLink} uFEFF={false}>
                                <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} style={{height: "46px"}}
                                        class={"btn px-4 pt-2 btn-primary fs-4 mb-2"}/></CSVLink>
                            : ""}
                    </div>
                </div>
            );

        }else{
            setCSVButtonHTML(() => "");
        }
    }, [csvLink]);

    // This effect monitors fetching data on count expire, implemented for campus drop down
    // change to rerun report using count to rerun.
    useEffect(() => {
        let interval;
        interval = setInterval(() => {
             setCount(count => count + 1);
        }, 1000);
        if (status !== "init" && count <= 0){
            getReportData();
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        let interval;
        interval = setInterval(() => {
             setFilterCount(filterCount => filterCount + 1);
        }, 1000);
        if (filterCount <= 0){
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "att_event_types",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setEventTypeOriginData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "core_reason_codes",
                                procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                setReasonOriginData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "att_window_frequency",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setFreqData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "att_environmental_type",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setEnvironTypeData(data);
            });
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "getportalfilters",
                                campus_id: campusId, device_category_filter: "1", area_type_filter: "1",
                                user_filter: "1", resident_group_filter: "0", area_filter: "1", area_group_filter: "0",
                                user_group_filter: "0", resident_filter: "1"}).then(data => {
                setAreaTypeOriginData(() => data?.area_types?.length > 0 ? data.area_types : []);
                setResOriginData(() => data?.residents?.length > 0 ? data.residents : []);
                setUserOriginData(() => data?.users?.length > 0 ? data.users : []);
                setAreaOriginData(() => data?.areas?.length > 0 ? data.areas : []);
            });
            setFilterCount(filterCount => 600);
        }
        return () => clearInterval(interval);
    }, [filterCount]);

    useEffect(() => {
        let tmp = [];
        for (let i=0; i < freqData?.length; i++){
            tmp.push(
                <option key={`freq-option-${i}`} value={freqData[i]?.window_frequency_id}>{freqData[i]?.frequency_name}</option>
            );
        }
        setFreqOptions(() => tmp);
    }, [freqData]);

    useEffect(() => {
        let tmp = [];
        for (let i=0; i < environTypeData?.length; i++){
            tmp.push(
                <option key={`environ-option-${i}`} value={environTypeData[i]?.environmental_type_id}>
                    {environTypeData[i]?.environmental_type_name}
                </option>
            );
        }
        setEnvironTypeOptions(() => tmp);
    }, [environTypeData]);

    useEffect(() => {
        let i, tmp = [], input;
        input = areaSearch?.toString()?.toLowerCase();
        for (i = 0; i < areaOriginData?.length; i++){
            if ((areaOriginData[i]?.area_name && areaOriginData[i]?.area_name?.toLowerCase()?.search(input) !== -1)){
                tmp.push(areaOriginData[i]);
            }
        }
        setAreaDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, areaSorter.sortColumn, areaSorter.sortOrder));
        setAreaTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                    actionAppearance={() => {}} tableHeaders={areaHeaders} checkboxId={"uCheckArea"}
                                    parentComponent={"EventReports"} dData={dData} checkboxlist={areaIdList}
                                    modal={false} editModal={() => {}} unk={"area-x-"}
                                    setCheckboxId={(value) => {setAreaIdList(value)}} checkidkey={"area_id"} />);
    }, [areaOriginData, areaSorter, areaIdList, areaSearch]);

    useEffect(() => {
        let i, tmp = [], input;
        input = areaTypeSearch?.toString()?.toLowerCase();
        for (i = 0; i < areaTypeOriginData?.length; i++){
            if ((areaTypeOriginData[i]?.area_type_name && areaTypeOriginData[i]?.area_type_name?.toLowerCase()?.search(input) !== -1) ||
                (areaTypeOriginData[i]?.area_type_description && areaTypeOriginData[i]?.area_type_description?.toLowerCase()?.search(input) !== -1)){
                tmp.push(areaTypeOriginData[i]);
            }
        }
        setAreaTypeDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, areaTypeSorter.sortColumn, areaTypeSorter.sortOrder));
        setAreaTypeTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                        actionAppearance={() => {}} tableHeaders={areaTypeHeaders} checkboxId={"uCheckAreaType"}
                                        parentComponent={"EventReports"} dData={dData} checkboxlist={areaTypeIdList}
                                        modal={false} editModal={() => {}} unk={"area-type-x-"}
                                        setCheckboxId={(value) => {setAreaTypeIdList(value)}} checkidkey={"area_type_id"} />);
    }, [areaTypeOriginData, areaTypeSorter, areaTypeIdList, areaTypeSearch]);

    useEffect(() => {
        let i, tmp = [], input;
        input = resSearch?.toString()?.toLowerCase();
        for (i = 0; i < resOriginData?.length; i++){
            if ((resOriginData[i]?.resident_first_name && resOriginData[i]?.resident_first_name?.toLowerCase()?.search(input) !== -1) ||
                (resOriginData[i]?.resident_last_name && resOriginData[i]?.resident_last_name?.toLowerCase()?.search(input) !== -1) ||
                (resOriginData[i]?.resident_nickname && resOriginData[i]?.resident_nickname?.toLowerCase()?.search(input) !== -1)){
                tmp.push(resOriginData[i]);
            }
        }
        setResDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, resSorter.sortColumn, resSorter.sortOrder));
        setResTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                   actionAppearance={() => {}} tableHeaders={residentHeaders} checkboxId={"uCheckRes"}
                                   parentComponent={"EventReports"} dData={dData} checkboxlist={resIdList}
                                   modal={false} editModal={() => {}} unk={"resident-x-"} addlEditCheck={true}
                                   addlEditKey={"is_active"}
                                   setCheckboxId={(value) => {setResIdList(value)}} checkidkey={"resident_id"} />);
    }, [resOriginData, resSorter, resIdList, resSearch]);

    useEffect(() => {
        let i, tmp = [], input;
        input = eventTypeSearch?.toString()?.toLowerCase();
        for (i = 0; i < eventTypeOriginData?.length; i++){
            if ((eventTypeOriginData[i]?.event_type_name && eventTypeOriginData[i]?.event_type_name?.toLowerCase()?.search(input) !== -1)){
                tmp.push(eventTypeOriginData[i]);
            }
        }
        setEventTypeDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, eventTypeSorter.sortColumn, eventTypeSorter.sortOrder));
        setEventTypeTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                   actionAppearance={() => {}} tableHeaders={eventTypeHeaders} checkboxId={"uCheckType"}
                                   parentComponent={"EventReports"} dData={dData} checkboxlist={eventTypeIdList}
                                   modal={false} editModal={() => {}} unk={"event-type-x-"}
                                   setCheckboxId={(value) => {setEventTypeIdList(value)}} checkidkey={"event_type_id"} />);
    }, [eventTypeOriginData, eventTypeSorter, eventTypeIdList, eventTypeSearch]);

    useEffect(() => {
        let i, tmp = [], input;
        input = userSearch?.toString()?.toLowerCase();
        for (i = 0; i < userOriginData?.length; i++){
            if ((userOriginData[i]?.username && userOriginData[i]?.username?.toLowerCase()?.search(input) !== -1) ||
                (userOriginData[i]?.display_name && userOriginData[i]?.display_name?.toLowerCase()?.search(input) !== -1) ||
                (userOriginData[i]?.user_role && userOriginData[i]?.user_role?.toLowerCase()?.search(input) !== -1)){
                tmp.push(userOriginData[i]);
            }
        }
        setUserDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, userSorter.sortColumn, userSorter.sortOrder));
        setUserTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                   actionAppearance={() => {}} tableHeaders={userHeaders} checkboxId={"uCheckUser"}
                                   parentComponent={"EventReports"} dData={dData} checkboxlist={userIdList}
                                   modal={false} editModal={() => {}} unk={"user-x-"}
                                   setCheckboxId={(value) => {setUserIdList(value)}} checkidkey={"user_id"} />);
    }, [userOriginData, userSorter, userIdList, userSearch]);

    useEffect(() => {
        let i, tmp = [], input;
        input = reasonSearch?.toString()?.toLowerCase();
        for (i = 0; i < reasonOriginData?.length; i++){
            if ((reasonOriginData[i]?.reason_code_description && reasonOriginData[i]?.reason_code_description?.toLowerCase()?.search(input) !== -1)){
                tmp.push(reasonOriginData[i]);
            }
        }
        setReasonDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, reasonSorter.sortColumn, reasonSorter.sortOrder));
        setReasonTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                   actionAppearance={() => {}} tableHeaders={reasonHeaders} checkboxId={"uCheckReason"}
                                   parentComponent={"EventReports"} dData={dData} checkboxlist={reasonIdList}
                                   modal={false} editModal={() => {}} unk={"reason-x-"}
                                   setCheckboxId={(value) => {setReasonIdList(value)}} checkidkey={"reason_code_id"} />)
    }, [reasonOriginData, reasonSorter, reasonIdList, reasonSearch]);

    useEffect(() => {
        if (status === "init") {
            setReportDisplay(
                <div>
                    <h4 className={"my-1 fs-3 px-4"}>No Report Loaded.</h4>
                </div>
            );
        }else if (status.toLowerCase() === "loading"){
            setReportDisplay(<div></div>);
        }else if (repType.toLowerCase() === "detailed"){
            if (!overLimit){
                if (activeData?.length <= 0){
                    setReportDisplay(
                        <div>
                            <h4 className={"my-1 fs-3 px-4"}>No data to show.</h4>
                        </div>
                    );
                }else{
                    setReportDisplay(
                        <>
                            <h6 className={"my-1 fs-3 px-4"}>
                                {repType}: <span className={"fs-3 d-inline ms-2"} style={{color: "#0081c7"}}>{startDisplay}</span> - <span className={"fs-3 d-inline"} style={{color: "#0081c7"}}>{endDisplay}</span>
                            </h6>
                            <div className={"pt-1 pb-4 px-4 my-2"}>
                                <table className={"table table-sm table-white table-hover"}>
                                    <thead className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString() ? "sticky-table-headX" : "sticky-table-head"}>
                                        {detailedHeaders}
                                    </thead>
                                    <tbody id={"tBody"}>
                                        {tableRows}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    );
                }
            }else{
                setReportDisplay(
                    <div style={{maxWidth: "500px"}}>
                        <h6 className={"my-1 fs-4 px-4"}>
                            Report data too large for local preview. Large report's details can be generated and emailed.
                        </h6>
                        <form id={"sendForm"} className={"was-validated pt-1 pb-4 px-4 my-2"}>
                            <div className={"form-floating mb-3"}>
                                <input type={"text"} className={"form-control"} id={"emailx"} required={true}
                                       onKeyUp={() => formCheck("sendForm", "downloadSubmit")}
                                       pattern={"[A-Za-z0-9._%+\\-]+@[A-Za-z0-9.\\-]+\\.[A-Za-z]{2,}$"}/>
                                <label htmlFor={"email"}>Email</label>
                                <div className={"invalid-feedback"}>
                                    Please enter a valid email!
                                </div>
                            </div>
                            <div>
                                <Button type={"button"} class={"btn btn-primary d-none"} disabled={true}
                                        spinner={true} text={"Generating"} id={"loadDownloadSubmit"}/>
                                <button type={"button"} className={"btn btn-primary"} id={"downloadSubmit"}
                                        onClick={() => sendReport()}>Email Report</button>
                            </div>
                            <h6 id={"sendMsg"} className={"d-none text-success fs-4 mt-3"}>Could take several minutes depending size of report.</h6>
                        </form>
                    </div>
                );
            }
        } else if (repType.toLowerCase() === "summary") {
            setReportDisplay(
                <>
                    <h6 className={"my-1 fs-3 px-4"}>
                        {repType}: <span className={"fs-3 d-inline ms-2"} style={{color: "#0081c7"}}>{startDisplay}</span>
                    </h6>
                    <div className={"pt-1 pb-4 px-4 my-2"}>
                        <div className={"d-flex justify-content-around"}>
                        </div>
                        <table className={"table table-sm table-white table-hover"}>
                            <thead className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString()
                                ? "sticky-table-headX" : "sticky-table-head"}>
                                {summaryHeaders}
                            </thead>
                            <tbody id={"tBody"}>
                                {tableRows}
                            </tbody>
                        </table>
                    </div>
                </>
            );
        } else {
            setReportDisplay(
                <div>
                    <h4 className={"my-1 fs-3 px-4"}>For report requested, please contact your customer success
                        manager.</h4>
                </div>
            );
        }
    }, [tableRows, status, repType, csvUrl]);

    useEffect(() => {
        formCheck("addEmailForm", "addEmailSubmit");
    }, [addNewEmails, addCurEmails]);

    useEffect(() => {
        scheduleFormCheck("scheduleForm", "scheduleSubmit");
    }, [emailEntities]);

    function getReportData() {
        let startDisplay, endDisplay, devCat;
        handleSubmitActions("submitBtn", "loadSubmitBtn");

        // Sets status to loading and clears initial text display.
        setStatus(() => "Loading");
        startDisplay = formatDate(start);
        endDisplay = formatDate(end);
        setStartDisplay(() => startDisplay);
        setEndDisplay(() => endDisplay);

        setStartDatex(formatDateIso(start));
        setEndDatex(formatDateIso(end));

        if (environTypeId === 1){
            devCat = "1,17"
        }else if (environTypeId === 2){
            devCat = "17"
        }else{
            devCat = "1"
        }

        // Conditional opportunity for different endpoints and reports.
        ApiRequester({reqEndpoint: "get-mysql-report",
            rep_type: "environment_report",
            campus_id: campusId, start_date: formatDateIso(start),
            end_date: formatDateIso(end),
            event_category_filter: "",
            device_category_filter: devCat,
            area_type_filter: areaTypeFilterList.join(","),
            reason_filter: reasonFilterList.join(","),
            user_filter: userFilterList.join(","),
            event_type_filter: typeFilterList.join(","),
            resident_filter: resFilterList.join(","),
            resident_group_filter: "",
            area_filter: areaFilterList.join(","),
            area_group_filter: "",
            user_group_filter: "",
            environment_type: environTypeId
        }).then(data => {
            handleSubmitActions("loadSubmitBtn", "submitBtn");
            if (data?.ErrorCode === 11){
                toast.error("Report timed out, report too big, contact your customer success manager for assistance with large reports.");
            }else if (data?.ErrorCode === 10){
                toast.error("An unknown error was encountered requesting report, please contact customer success if problem persists.");
            }else if (data?.ErrorCode === 12) {
                toast.error("Request unauthorized, service may be unavailable, please contact customer success if problem persists.");
            }else if (data?.overlimit){
                setOverLimit(true);
                setCSVUrl(data?.url ? data?.url : "");
                toast.warn(`Report requested over 5000 events, details will need to be downloaded.`);
            }else{
                setOverLimit(false);
                setCSVUrl(data?.url ? data?.url : "");
            }
            setPage(1);
            let pRules = paginateData(data?.records ? data.records : [], 1, perPage);
            setPRules(() => pRules);
            setReportData(() => data?.records ? data.records : []);
            setSummaryData(() => data?.summary?.records ? data.summary.records : []);
            setCount(() => 600);
            setStatus(() => "");
        });
    }

    function setListActive(ulItem, liItem){
        let ul, li, liItems;
        ul = document.getElementById(ulItem);
        liItems = ul.getElementsByTagName("li");
        for (let i=0; i < liItems?.length; i++){
            li = liItems[i];
            if (li.id === liItem){
                li.classList.add("active");
                li.setAttribute("aria-current", true);
            }else{
                li.setAttribute("aria-current", false);
                li.classList.remove("active");
            }
        }
    }

    function toggleDateWindow(toggle){
        let dateDiv;
        dateDiv = document.getElementById("dateWindow");
        if (toggle){
            dateDiv.classList.remove("d-none");
            dateDiv.classList.add("d-inline-block");
        }else{
            dateDiv.classList.add("d-none");
            dateDiv.classList.remove("d-inline-block");
        }
    }

    function filterSubmit(){
        // Submit function submitted by the pagination number of items per page modal.
        handleSubmitActions("filterSubmit", "loadFilterSubmit");
        setPerPage(tmpPerPage);
        dData.perPage = tmpPerPage;
        setPage(1);
        ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
        formSuccess("Page parameters stored to session.", "filterSubmit",
            "loadFilterSubmit", "cancelFilterBtn");
    }

    function clearFilters(){
        // Function used in filter modal to clear all toggled checkboxes across all tabs.
        setAreaIdList([]);
        setAreaTypeIdList([]);
        setResIdList([]);
        setEventTypeIdList([]);
        setUserIdList([]);
        setReasonIdList([]);
        document.getElementById("uCheckHead2").checked = false;
        document.getElementById("uCheckHead3").checked = false;
        document.getElementById("uCheckHead4").checked = false;
        document.getElementById("uCheckHead5").checked = false;
        document.getElementById("uCheckHead6").checked = false;
        document.getElementById("uCheckHead7").checked = false;
        document.getElementById("uCheckHead8").checked = false;
        document.getElementById("uCheckHead10").checked = false;
        document.getElementById("uCheckHead11").checked = false;
    }

    function showFilterModal(){
        // Function that preps and populates the filter modal with the checks when called based on stored session.
        setAreaIdList(areaFilterList);
        document.getElementById("uCheckHead8").checked = areaFilterList.length === areaOriginData.length;
        setAreaTypeIdList(areaTypeFilterList);
        document.getElementById("uCheckHead2").checked = areaTypeFilterList.length === areaTypeOriginData.length;
        setResIdList(resFilterList);
        document.getElementById("uCheckHead4").checked = resFilterList.length === resOriginData.length;
        setEventTypeIdList(typeFilterList);
        document.getElementById("uCheckHead5").checked = typeFilterList.length === eventTypeOriginData.length;
        setUserIdList(userFilterList);
        document.getElementById("uCheckHead6").checked = userFilterList.length === userOriginData.length;
        setReasonIdList(reasonFilterList);
        document.getElementById("uCheckHead7").checked = reasonFilterList.length === reasonOriginData.length;
        showModal("filtermodal2");
    }

    function filterSubmit2(){
        // Function used to set filters to vars on submit from filter modal.
        let areaTypeLabels = [], devLabels = [], resLabels = [], typeLabels = [], userLabels = [], reasonLabels = [],
            areaLabels = [], areaGroupLabels = [], residentGroupLabels = [], userGroupLabels = [];
        handleSubmitActions("filterSubmit2", "loadFilterSubmit2");
        for (let i=0; i < areaOriginData?.length; i++){
            if (areaIdList.includes(areaOriginData[i]?.area_id?.toString())){
                areaLabels.push(areaOriginData[i]?.area_name);
            }
        }
        for (let i=0; i < areaTypeOriginData?.length; i++){
            if (areaTypeIdList.includes(areaTypeOriginData[i]?.area_type_id?.toString())){
                areaTypeLabels.push(areaTypeOriginData[i]?.area_type_name);
            }
        }
        for (let i=0; i < resOriginData?.length; i++){
            if (resIdList.includes(resOriginData[i]?.resident_id?.toString())){
                resLabels.push(`${resOriginData[i]?.resident_first_name} ${resOriginData[i]?.resident_last_name}`);
            }
        }
        for (let i=0; i < eventTypeOriginData?.length; i++){
            if (eventTypeIdList.includes(eventTypeOriginData[i]?.event_type_id?.toString())){
                typeLabels.push(eventTypeOriginData[i]?.event_type_name);
            }
        }
        for (let i=0; i < userOriginData?.length; i++){
            if (userIdList.includes(userOriginData[i]?.user_id?.toString())){
                userLabels.push(userOriginData[i]?.username);
            }
        }
        for (let i=0; i < reasonOriginData?.length; i++){
            if (reasonIdList.includes(reasonOriginData[i]?.reason_code_id?.toString())){
                reasonLabels.push(reasonOriginData[i]?.reason_code_description);
            }
        }
        setAreaFilterList(areaIdList);
        setAreaFilterLabels(areaLabels);
        setAreaTypeFilterList(areaTypeIdList);
        setAreaTypeFilterLabels(areaTypeLabels);
        setResFilterList(resIdList);
        setResFilterLabels(resLabels);
        setTypeFilterList(eventTypeIdList);
        setTypeFilterLabels(typeLabels);
        setUserFilterList(userIdList);
        setUserFilterLabels(userLabels);
        setReasonFilterList(reasonIdList);
        setReasonFilterLabels(reasonLabels);
        dData.devFilterLabel = devLabels;
        dData.areaFilter = areaIdList;
        dData.areaFilterLabel = areaLabels
        dData.areaGroupFilterLabel = areaGroupLabels;
        dData.areaTypeFilter = areaTypeIdList;
        dData.areaTypeFilterLabel = areaTypeLabels;
        dData.resFilter = resIdList;
        dData.resFilterLabel = resLabels;
        dData.residentGroupFilterLabel = residentGroupLabels;
        dData.typeFilter = eventTypeIdList;
        dData.typeFilterLabel = typeLabels;
        dData.reasonFilter = reasonIdList;
        dData.reasonFilterLabel = reasonLabels;
        dData.userFilter = userIdList;
        dData.userFilterLabel = userLabels;
        dData.userGroupFilterLabel = userGroupLabels;
        ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
        formSuccess("Filters saved", "filterSubmit2", "loadFilterSubmit2", "cancelFilterBtn2");
        if (status !== "init"){
            setCount(() => 0);
        }
    }

    function complexDelimDisplay(originText, newText){
        // Function used to build filter display inside the schedule modal pop up.
        // This function is generic takes lists recursively and builds appending comma delim strings.
        let ret;
        if (originText){
            if (newText){
                ret = `${originText}, ${newText}`;
            }else{
                ret = originText;
            }
        }else {
            ret = newText;
        }
        return ret;
    }

    function showScheduleModal(){
        // Function that preps and displays the pop up of the schedule modal.
        let filterText;
        document.getElementById("scheduleForm").reset();
        filterText = resFilterLabels.join(", ");
        filterText = complexDelimDisplay(filterText, areaFilterLabels.join(", "));
        filterText = complexDelimDisplay(filterText, areaTypeFilterLabels.join(", "));
        filterText = complexDelimDisplay(filterText, typeFilterLabels.join(", "));
        filterText = complexDelimDisplay(filterText, userFilterLabels.join(", "));
        filterText = complexDelimDisplay(filterText, reasonFilterLabels.join(", "));
        setEmailEntities(() => []);
        document.getElementById("filter-display").innerText = filterText?.length > 0 ? filterText : "None";
        formCheck("scheduleForm", "scheduleSubmit");
        showModal("schedulemodal");
    }

    function generateFilterTerms(){
        return {
            area_filter: {values: areaFilterList.join(","), labels: areaFilterLabels.join(",")},
            area_type_filter: {values: areaTypeFilterList.join(","), labels: areaTypeFilterLabels.join(",")},
            resident_filter: {values: resFilterList.join(","), labels: resFilterLabels.join(",")},
            event_type_filter: {values: typeFilterList.join(","), labels: typeFilterLabels.join(",")},
            reason_filter: {values: reasonFilterList.join(","), labels: reasonFilterLabels.join(",")},
            user_filter: {values: userFilterList.join(","), labels: userFilterLabels.join(",")}
        }
    }

    function scheduleSubmit(){
        // Function called when submitting the scheduled report from schedule modal pop up.
        let payload, filterTerms, frequencyId, freqContainer, freqRate = [], nextSendTime, sendTime, pdf, csv,
            reportTypeId;
        handleSubmitActions("scheduleSubmit", "loadScheduleSubmit");
        filterTerms = generateFilterTerms();
        frequencyId = parseInt(document.getElementById("sch-frequency").value);
        if (frequencyId === 1){
            freqRate.push(1);
        }else if (frequencyId === 2){
            freqContainer = document.getElementsByName("sch-weekly-meta");
            for (let i=0; i < freqContainer?.length; i++){
                if (freqContainer[i].checked){
                    freqRate.push(freqContainer[i].value);
                }
            }
        }else if (frequencyId === 3){
            freqContainer = document.getElementsByName("sch-monthly-meta");
            for (let i=0; i < freqContainer?.length; i++){
                if (freqContainer[i].checked){
                    freqRate.push(freqContainer[i].value);
                }
            }
        }
        sendTime = document.getElementById("sch-sendTime").value;
        nextSendTime = calculateSendTime(frequencyId, freqRate, sendTime, parseInt(dData?.campusData[campusId]?.tz_offset ? dData?.campusData[campusId]?.tz_offset : 0))
        pdf = document.getElementById("add-pdf")?.checked;
        csv = document.getElementById("add-csv")?.checked;
        if (environTypeId === 2){
            reportTypeId = 7;
        }else if (environTypeId === 3){
            reportTypeId = 8;
        }else{
            reportTypeId = 6;
        }
        payload = [{
            campus_id: campusId,
            description: document.getElementById("sch-description").value,
            issue_freq_id: frequencyId,
            window_frequency_reference: freqRate.join(","),
            send_report_time: convertHHMMtoSec(sendTime),
            enabled: 1,
            report_type_id: reportTypeId,
            report_interval_id: rangeId,
            report_filter_terms: filterTerms,
            report_receiver: emailEntities.join(","),
            next_send_time: nextSendTime,
            pdf_summary: pdf ? 1 : 0,
            csv_details: csv ? 1 : 0
        }]
        ApiRequester({reqEndpoint: "mytransaction", TransType: "schedule_add", Payload: payload,
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
            if (data[0]) {
                formSuccess("Report schedule saved", "scheduleSubmit", "loadScheduleSubmit", "scheduleCancelBtn");
            } else {
                toast.error("Failed to schedule report.", "scheduleSubmit", "loadScheduleSubmit");
            }
        });
    }

    function scheduleFormCheck(formId, submitId){
        formCheck(formId, submitId);
        if (emailEntities?.length <= 0){
            let btn = document.getElementById(submitId);
            btn.classList.add("btn-danger");
            btn.classList.remove("btn-primary");
            btn.disabled = true;
        }
    }

    function showEmailAddModal(output){
        // This function is called from the add modal when clicking on text or voice calling to set a list of phone
        // numbers to be saved with record add. This function triggers the modal to populate and builds the entity
        // list of phone numbers to add to the system.
        let tmp = [];
        for (let i=0; i < emailEntities.length; i++){
            tmp.push(
                {id: uuidv4(), email: emailEntities[i]}
            );
        }
        setAddNewEmails(() => []);
        setAddCurEmails(() => tmp);
        swapModal("scheduleCancelBtn", "addemailmodal");
    }

    function setAddEmailItems(){
        // This function is triggered from the phone number add modal save which takes the current phone numbers
        // display items and then new numbers display items and sets those values into their appropriate output
        // entity holder text or voice.
        let tmp = [];
        handleSubmitActions("addEmailSubmit", "loadAddEmailSubmit");
        for (let i=0; i < addCurEmails.length; i++){
            tmp.push(addCurEmails[i].email);
        }
        for (let i2=0; i2 < addNewEmails.length; i2++){
            tmp.push(addNewEmails[i2].email);
        }
        setEmailEntities(() => tmp);
        document.getElementById("cancelAddEmailBtn").click();
        handleSubmitActions("loadAddEmailSubmit", "addEmailSubmit");
    }

    function sendReport(){
        let payload, description, reportTypeId;
        handleSubmitActions("downloadSubmit", "loadDownloadSubmit");
        document.getElementById("sendMsg").classList.remove("d-none");
        description = `Event Report`;
        if (environTypeId === 2){
            reportTypeId = 7;
        }else if (environTypeId === 3){
            reportTypeId = 8;
        }else{
            reportTypeId = 6;
        }
        payload = [{
            campus_id: campusId,
            description: description,
            source: "portal",
            report_id: 999,
            report_filter_terms: JSON.stringify(reportFilterTermsx),
            report_interval_id: 0,
            startDate: startDatex,
            endDate: endDatex,
            report_receiver: document.getElementById("emailx").value,
            report_type_id: reportTypeId,
            last_sent: ""
        }];
        ApiRequester({reqEndpoint: "send_report", Payload: payload, CampusId: campusId,
                            ClientId: dData.sessionId, nowait: true}).then(data => {
            if (data[0]){
                formSuccess(`Report request successful, larger reports may take several minutes.`,
                    "downloadSubmit", "loadDownloadSubmit");
            }
            else{
                formFail("Failed to send report, contact support if failure continues.",
                    "downloadSubmit", "loadDownloadSubmit");
            }
        });
    }

    function eventFiltersOn(){
        return [typeFilterList, reasonFilterList].some(filterArray => filterArray.length > 0);
    }

    function areaFiltersOn(){
        return [areaFilterList, areaTypeFilterList].some(filterArray => filterArray.length > 0);
    }

    function resFiltersOn(){
        return [resFilterList].some(filterArray => filterArray.length > 0);
    }

    function userFiltersOn(){
        return [userFilterList].some(filterArray => filterArray.length > 0);
    }

    function filtersOn(){
        return [areaFilterList, resFilterList, typeFilterList,
                reasonFilterList].some(filterArray => filterArray.length > 0);
    }

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div>
                {scheduleable && <Button text={"Schedule"} style={{height: "46px"}} class={"btn btn-primary me-4 mb-2"}
                                         iconClass={"material-icons md-18 md-dark me-2 align-text-bottom"}
                                         icon={true} iconSize={"tiny"} iconColor={"#FFFFFF"} iconType={"google"}
                                         iconName={"edit_calendar"} onClick={() => showScheduleModal()}/>}
                {csvButtonHTML}
                <button type={"button"} className={"btn btn-secondary mx-2 mb-2"} style={{height: "46.5px"}}
                        onClick={() => {
                            showFilterModal();
                        }}>
                    <i className={"fe fe-filter fs-3"}/>
                    {filtersOn() && <div className={"filter-caret"}></div>}
                </button>
                <div className={"form-floating d-inline-block me-3"} id={"add-event-type-div"}>
                    <select className={"form-select"} id={"add-event-type"} style={{width: "175px"}}
                            defaultValue={reportType} required={true}
                            onChange={(e) => {
                                setEnvironTypeId(parseInt(e.target.value));
                                if (status !== "init") {
                                    setCount(0);
                                }
                            }} >
                        {environTypeOptions}
                    </select>
                    <label htmlFor={"add-event-type"}>Report Type</label>
                </div>
                <div className={"dropdown d-inline"}>
                    <button type={"button"} className={"btn btn-secondary dropdown-toggle px-4 fs-3 mb-2 mx-2"}
                            data-bs-toggle={"dropdown"} id={"calendarDropdown"} data-bs-auto-close={"outside"}
                            style={{minWidth: "175px"}}>
                        <i className={"fe fe-calendar me-2"}><p id={"dropdownValue"}
                                                                className={"fs-5 align-text-top"}>Yesterday</p></i>
                    </button>
                    <div className={"dropdown-menu dropdown-menu-end p-3"} aria-labelledby={"calendarDropdown"}>
                        <div className={"d-flex justify-content-between"}>
                            <ul className={"d-inline-block list-group-flush px-0"} id={"calendarList"}
                                style={{listStyle: "none", width: "max-content", minWidth: "fit-content"}}>
                                <li className={"list-group-item py-1"} id={"calendar-today"}>
                                    <Button class={"btn"} text={"Today"} onClick={() => {
                                        let dates = getToday();
                                        setRangeName("Today");
                                        setRangeId(1);
                                        setScheduleable(true);
                                        setListActive("calendarList", "calendar-today");
                                        document.getElementById("dropdownValue").innerText = "Today";
                                        setStart(dates);
                                        setEnd(dates);
                                        toggleDateWindow(false);
                                    }}/>
                                </li>
                                <li className={"list-group-item py-1"} id={"calendar-week"}>
                                    <Button class={"btn"} text={"This Week"} onClick={() => {
                                        let dates = getWeekRange();
                                        setRangeName("This Week");
                                        setRangeId(3);
                                        setScheduleable(true);
                                        setListActive("calendarList", "calendar-week")
                                        document.getElementById("dropdownValue").innerText = "This Week";
                                        setStart(dates[0]);
                                        setEnd(dates[1]);
                                        toggleDateWindow(false);
                                    }}/>
                                </li>
                                <li className={"list-group-item py-1"} id={"calendar-month"}>
                                    <Button class={"btn"} text={"This Month"} onClick={() => {
                                        let dates = getMonthRange();
                                        setRangeName("This Month");
                                        setRangeId(4);
                                        setScheduleable(true);
                                        setListActive("calendarList", "calendar-month")
                                        document.getElementById("dropdownValue").innerText = "This Month";
                                        setStart(dates[0]);
                                        setEnd(dates[1]);
                                        toggleDateWindow(false);
                                    }}/>
                                </li>
                                <li className={"list-group-item py-1"} id={"calendar-quarter"}>
                                    <Button class={"btn"} text={"This Quarter"} onClick={() => {
                                        let dates = getQuarterRange();
                                        setRangeName("This Quarter");
                                        setRangeId(5);
                                        setScheduleable(true);
                                        setListActive("calendarList", "calendar-quarter")
                                        document.getElementById("dropdownValue").innerText = "This Quarter";
                                        setStart(dates[0]);
                                        setEnd(dates[1]);
                                        toggleDateWindow(false);
                                    }}/>
                                </li>
                                <li className={"list-group-item active py-1"} id={"calendar-yesterday"}>
                                    <Button class={"btn"} text={"Yesterday"} onClick={() => {
                                        let dates = getToday(-1);
                                        setRangeName("Yesterday");
                                        setRangeId(2);
                                        setScheduleable(true);
                                        setListActive("calendarList", "calendar-yesterday");
                                        document.getElementById("dropdownValue").innerText = "Yesterday";
                                        setStart(dates);
                                        setEnd(dates);
                                        toggleDateWindow(false);
                                    }}/>
                                </li>
                                <li className={"list-group-item py-1"} id={"calendar-pweek"}>
                                    <Button class={"btn"} text={"Last Week"} onClick={() => {
                                        let dates = getWeekRange(-1);
                                        setRangeName("Last Week");
                                        setRangeId(6);
                                        setScheduleable(true);
                                        setListActive("calendarList", "calendar-pweek")
                                        document.getElementById("dropdownValue").innerText = "Last Week";
                                        setStart(dates[0]);
                                        setEnd(dates[1]);
                                        toggleDateWindow(false);
                                    }}/>
                                </li>
                                <li className={"list-group-item py-1"} id={"calendar-pmonth"}>
                                    <Button class={"btn"} text={"Last Month"} onClick={() => {
                                        let dates = getMonthRange(-1);
                                        setRangeName("Last Month");
                                        setRangeId(7);
                                        setScheduleable(true);
                                        setListActive("calendarList", "calendar-pmonth")
                                        document.getElementById("dropdownValue").innerText = "Last Month";
                                        setStart(dates[0]);
                                        setEnd(dates[1]);
                                        toggleDateWindow(false);
                                    }}/>
                                </li>
                                <li className={"list-group-item py-1"} id={"calendar-pquarter"}>
                                    <Button class={"btn"} text={"Last Quarter"} onClick={() => {
                                        let dates = getQuarterRange(-1);
                                        setRangeName("Last Quarter");
                                        setRangeId(8);
                                        setScheduleable(true);
                                        setListActive("calendarList", "calendar-pquarter")
                                        document.getElementById("dropdownValue").innerText = "Last Quarter";
                                        setStart(dates[0]);
                                        setEnd(dates[1]);
                                        toggleDateWindow(false);
                                    }}/>
                                </li>
                                <li className={"list-group-item py-1"} id={"calendar-custom"}>
                                    <Button class={"btn"} text={"Custom Date Range"} id={"customBtn"} onClick={() => {
                                        setScheduleable(false);
                                        setListActive("calendarList", "calendar-custom");
                                        document.getElementById("dropdownValue").innerText = "Custom";
                                        toggleDateWindow(true);
                                    }}/>
                                </li>
                            </ul>
                            <div id={"dateWindow"} className={"d-none ps-4"}
                                 style={{minWidth: "fit-content", width: "max-content"}}>
                                <div className={"form-floating d-inline-block me-2 mb-2 align-bottom"}>
                                    <h6 className={"fs-4"}>Start Date:</h6>
                                    <DatePicker
                                        className={"form-control"}
                                        selected={start}
                                        onChange={e => setStart(e)}
                                        selectsStart
                                        startDate={start}
                                        endDate={end}
                                        id={"startDate"}
                                    />
                                </div>
                                <div className={"form-floating d-inline-block me-2 mb-2 align-bottom"}>
                                    <h6 className={"fs-4"}>End Date:</h6>
                                    <DatePicker
                                        className={"form-control"}
                                        selected={end}
                                        onChange={e => setEnd(e)}
                                        selectsEnd
                                        startDate={start}
                                        endDate={end}
                                        minDate={start}
                                        id={"endDate"}
                                    />
                                    {/*<label htmlFor={"endDate"}>Report End</label>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"d-inline-block align-bottom mb-2 mx-2"}>
                    <div className={"form-check"}>
                        <input className={"form-check-input"} type={"radio"} name={"repType"} id={"radio2"}
                               checked={repType === "Summary"} onChange={() => {
                            setSorter(prevState => {
                                return {sortColumn: "", sortOrder: "asc"};
                            });
                            setPage(1);
                            setRepType("Summary");
                        }}/>
                        <label className={"form-check-label"} htmlFor={"radio2"}>
                            Summary
                        </label>
                    </div>
                    <div className={"form-check"}>
                        <input className={"form-check-input"} type={"radio"} name={"repType"} id={"radio1"}
                               checked={repType === "Detailed"} onChange={() => {
                            setSorter(prevState => {
                                return {sortColumn: "", sortOrder: "asc"};
                            });
                            setPage(1);
                            setRepType("Detailed");
                        }}/>
                        <label className={"form-check-label"} htmlFor={"radio1"}>
                            Detailed
                        </label>
                    </div>
                </div>
                <Button id={"loadSubmitBtn"} text={""}
                        class={"btn px-4 pt-2 btn-toolbar btn-primary d-inline-block fs-3 mb-2 mx-2 d-none"}
                        spinner={true}/>
                <Button text={""} icon={true} iconClass={"fe fe-refresh-cw"} id={"submitBtn"}
                        class={"btn px-3 btn-toolbar btn-primary d-inline-block fs-3 mb-2 mx-2"}
                        onClick={() => {
                            getReportData()
                        }}/>
            </div>
            <CampusDropDown campusId={campusId} setCount={(item) => setCount(item)}
                            setCampusId={(item) => setCampusId(item)} pageAction={() => {
            }}/>
        </div>
    );


    const summaryHeaders = <TableHeaders checkbox={false} headerData={summaryHeaderData} writeAccess={false}
                                         sortOrder={sorter.sortOrder} sortColumn={sorter.sortColumn} sorter={sorter}
                                         setSorter={setSorter}/>
    const detailedHeaders = <TableHeaders checkbox={false} headerData={detailedHeaderData} writeAccess={false}
                                          sortOrder={sorter.sortOrder} sortColumn={sorter.sortColumn}
                                          sorter={sorter} setSorter={setSorter}/>

    const areaTableHeaders = <TableHeaders checkbox={true} headerData={areaHeaders} checkboxlist={areaIdList}
                                           checkAll={() => checkAll(0, areaDataCnt ? areaDataCnt : 0, areaIdList, setAreaIdList, "uCheckHead8", "uCheckArea")}
                                           writeAccess={true} sortOrder={areaSorter.sortOrder}
                                           checkboxHeaderId={"uCheckHead8"}
                                           sortColumn={areaSorter.sortColumn} sorter={areaSorter}
                                           setSorter={setAreaSorter}
                                           actionAppearance={() => {
                                           }}/>
    const areaTypeTableHeaders = <TableHeaders checkbox={true} headerData={areaTypeHeaders}
                                               checkboxlist={areaTypeIdList}
                                               checkAll={() => checkAll(0, areaTypeDataCnt ? areaTypeDataCnt : 0, areaTypeIdList, setAreaTypeIdList, "uCheckHead2", "uCheckAreaType")}
                                               writeAccess={true} sortOrder={areaTypeSorter.sortOrder}
                                               checkboxHeaderId={"uCheckHead2"}
                                               sortColumn={areaTypeSorter.sortColumn} sorter={areaTypeSorter}
                                               setSorter={setAreaTypeSorter}
                                               actionAppearance={() => {
                                               }}/>
    const residentTableHeaders = <TableHeaders checkbox={true} headerData={residentHeaders} checkboxlist={resIdList}
                                               checkAll={() => checkAll(0, resDataCnt ? resDataCnt : 0, resIdList, setResIdList, "uCheckHead4", "uCheckRes")}
                                               writeAccess={true} sortOrder={resSorter.sortOrder}
                                               checkboxHeaderId={"uCheckHead4"}
                                               sortColumn={resSorter.sortColumn} sorter={resSorter}
                                               setSorter={setResSorter}
                                               actionAppearance={() => {
                                               }}/>
    const eventTypeTableHeaders = <TableHeaders checkbox={true} headerData={eventTypeHeaders}
                                                checkboxlist={eventTypeIdList}
                                                checkAll={() => checkAll(0, eventTypeDataCnt ? eventTypeDataCnt : 0, eventTypeIdList, setEventTypeIdList, "uCheckHead5", "uCheckType")}
                                                writeAccess={true} sortOrder={eventTypeSorter.sortOrder}
                                                checkboxHeaderId={"uCheckHead5"}
                                                sortColumn={eventTypeSorter.sortColumn} sorter={eventTypeSorter}
                                                setSorter={setEventTypeSorter}
                                                actionAppearance={() => {
                                                }}/>
    const userTableHeaders = <TableHeaders checkbox={true} headerData={userHeaders} checkboxlist={userIdList}
                                           checkAll={() => checkAll(0, userDataCnt ? userDataCnt : 0, userIdList, setUserIdList, "uCheckHead6", "uCheckUser")}
                                           writeAccess={true} sortOrder={userSorter.sortOrder}
                                           checkboxHeaderId={"uCheckHead6"}
                                           sortColumn={userSorter.sortColumn} sorter={userSorter}
                                           setSorter={setUserSorter}
                                           actionAppearance={() => {
                                           }}/>
    const reasonTableHeaders = <TableHeaders checkbox={true} headerData={reasonHeaders} checkboxlist={reasonIdList}
                                             checkAll={() => checkAll(0, reasonDataCnt ? reasonDataCnt : 0, reasonIdList, setReasonIdList, "uCheckHead7", "uCheckReason")}
                                             writeAccess={true} sortOrder={reasonSorter.sortOrder}
                                             checkboxHeaderId={"uCheckHead7"}
                                             sortColumn={reasonSorter.sortColumn} sorter={reasonSorter}
                                             setSorter={setReasonSorter}
                                             actionAppearance={() => {
                                             }}/>

    const copyright = (
        <h6 className={"fs-5 pt-2"}>
            Copyright &copy; {getCurrentYear()}
        </h6>
    );
    const version = (
        <h6 className={"fs-5 pt-2"}>
            {process.env.REACT_APP_VERSION}
        </h6>
    );

    return (
        <div className={"main-content"}>
            <Header preTitle={"Environment Reports"} Title={reportType} content={headerContent}/>
            <div className={"container-fluid mt-4"}>
                {reportDisplay}
                <div className={"footer-spacer"}>

                </div>
            </div>
            <div className={"modal fade"} id={"filtermodal2"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Filter Settings</h2>
                            <Button text={"Clear All"} class={"btn ms-3 pb-0 hover-text-white"}
                                    onClick={() => clearFilters()}/>
                            <button type={"button"} className={"btn-close"} id={"filterClose2"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <form id={"filterForm2"}>
                            <div className={"modal-body"}>
                                <div className={"row align-items-center"} style={{borderBottom: "dotted 1px #b7b7b7"}}>
                                    <div className={"col"}>
                                        <ul className={"nav nav-tabs nav-overflow header-tabs mb-0"}>
                                            <li className={"mx-1 my-2 d-flex"}>
                                                <Button text={"Areas"}
                                                        class={tabCat === "areas" ? "btn btn-toggle btn-active-primary" : "btn btn-toggle"}
                                                        style={{width: "95px"}}
                                                        onClick={() => {
                                                            setTabCat("areas");
                                                            setTabItem("area");
                                                        }}/>
                                                {areaFiltersOn() && <div className={"filter-caret"}></div>}
                                            </li>
                                            <li className={"mx-1 my-2 d-flex"}>
                                                <Button text={"Residents"}
                                                        class={tabCat === "residents" ? "btn btn-toggle btn-active-primary" : "btn btn-toggle"}
                                                        style={{width: "95px"}}
                                                        onClick={() => {
                                                            setTabCat("residents");
                                                            setTabItem("resident");
                                                        }}/>
                                                {resFiltersOn() && <div className={"filter-caret"}></div>}
                                            </li>
                                            <li className={"mx-1 my-2 d-flex"}>
                                                <Button text={"Users"}
                                                        class={tabCat === "users" ? "btn btn-toggle btn-active-primary" : "btn btn-toggle"}
                                                        style={{width: "95px"}}
                                                        onClick={() => {
                                                            setTabCat("users");
                                                            setTabItem("user");
                                                        }}/>
                                                {userFiltersOn() && <div className={"filter-caret"}></div>}
                                            </li>
                                            <li className={"mx-1 my-2 d-flex"}>
                                                <Button text={"Events"}
                                                        class={tabCat === "events" ? "btn btn-toggle btn-active-primary" : "btn btn-toggle"}
                                                        style={{width: "95px"}}
                                                        onClick={() => {
                                                            setTabCat("events");
                                                            setTabItem("event");
                                                        }}/>
                                                {eventFiltersOn() && <div className={"filter-caret"}></div>}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={"mt-2"}>
                                    <div>
                                        {tabCat === "areas"
                                            ? <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                                                <li className={"nav-item"}>
                                                    <Button text={"Area"}
                                                            class={tabItem === "area" ? "nav-link active" : "nav-link"}
                                                            onClick={() => setTabItem("area")}/>
                                                </li>
                                                <li className={"nav-item"}>
                                                    <Button text={"Area Types"}
                                                            class={tabItem === "areaType" ? "nav-link active" : "nav-link"}
                                                            onClick={() => setTabItem("areaType")}/>
                                                </li>
                                            </ul>
                                            : <></>
                                        }
                                        {tabCat === "residents"
                                            ? <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                                                <li className={"nav-item"}>
                                                    <Button text={"Resident"}
                                                            class={tabItem === "resident" ? "nav-link active" : "nav-link"}
                                                            onClick={() => setTabItem("resident")}/>
                                                </li>
                                            </ul>
                                            : <></>
                                        }
                                        {tabCat === "users"
                                            ? <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                                                <li className={"nav-item"}>
                                                    <Button text={"User"}
                                                            class={tabItem === "user" ? "nav-link active" : "nav-link"}
                                                            onClick={() => setTabItem("user")}/>
                                                </li>
                                            </ul>
                                            : <></>
                                        }
                                        {tabCat === "events"
                                            ? <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                                                <li className={"nav-item"}>
                                                    <Button text={"Event Types"}
                                                            class={tabItem === "event" ? "nav-link active" : "nav-link"}
                                                            onClick={() => setTabItem("event")}/>
                                                </li>
                                                <li className={"nav-item"}>
                                                    <Button text={"Reasons"}
                                                            class={tabItem === "reason" ? "nav-link active" : "nav-link"}
                                                            onClick={() => setTabItem("reason")}/>
                                                </li>
                                            </ul>
                                            : <></>
                                        }
                                    </div>
                                    <div className={tabItem === "area" ? "d-block" : "d-none"}>
                                        <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                            <input type={"search"} id={"areaSearch"}
                                                   className={"form-control search-input"}
                                                   onKeyUp={(e) => setAreaSearch(e.target.value)}/>
                                            <Button text={""} type={"button"}
                                                    class={"btn btn-primary smooth-radius-left"}
                                                    style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                        </div>
                                        <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                            <table className={"table table-sm table-white table-hover"}>
                                                <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {areaTableHeaders}
                                                </thead>
                                                <tbody>
                                                {areaTableRows}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className={tabItem === "areaType" ? "d-block" : "d-none"}>
                                        <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                            <input type={"search"} id={"areaTypeSearch"}
                                                   className={"form-control search-input"}
                                                   onKeyUp={(e) => setAreaTypeSearch(e.target.value)}/>
                                            <Button text={""} type={"button"}
                                                    class={"btn btn-primary smooth-radius-left"}
                                                    style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                        </div>
                                        <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                            <table className={"table table-sm table-white table-hover"}>
                                                <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {areaTypeTableHeaders}
                                                </thead>
                                                <tbody>
                                                {areaTypeTableRows}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className={tabItem === "resident" ? "d-block" : "d-none"}>
                                        <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                            <input type={"search"} id={"resSearch"}
                                                   className={"form-control search-input"}
                                                   onKeyUp={(e) => setResSearch(e.target.value)}/>
                                            <Button text={""} type={"button"}
                                                    class={"btn btn-primary smooth-radius-left"}
                                                    style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                        </div>
                                        <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                            <table className={"table table-sm table-white table-hover"}>
                                                <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {residentTableHeaders}
                                                </thead>
                                                <tbody>
                                                {resTableRows}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className={tabItem === "user" ? "d-block" : "d-none"}>
                                        <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                            <input type={"search"} id={"userSearch"}
                                                   className={"form-control search-input"}
                                                   onKeyUp={(e) => setUserSearch(e.target.value)}/>
                                            <Button text={""} type={"button"}
                                                    class={"btn btn-primary smooth-radius-left"}
                                                    style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                        </div>
                                        <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                            <table className={"table table-sm table-white table-hover"}>
                                                <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {userTableHeaders}
                                                </thead>
                                                <tbody>
                                                {userTableRows}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className={tabItem === "event" ? "d-block" : "d-none"}>
                                        <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                            <input type={"search"} id={"typeSearch"}
                                                   className={"form-control search-input"}
                                                   onKeyUp={(e) => setEventTypeSearch(e.target.value)}/>
                                            <Button text={""} type={"button"}
                                                    class={"btn btn-primary smooth-radius-left"}
                                                    style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                        </div>
                                        <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                            <table className={"table table-sm table-white table-hover"}>
                                                <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {eventTypeTableHeaders}
                                                </thead>
                                                <tbody>
                                                {eventTypeTableRows}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className={tabItem === "reason" ? "d-block" : "d-none"}>
                                        <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                            <input type={"search"} id={"reasonSearch"}
                                                   className={"form-control search-input"}
                                                   onKeyUp={(e) => setReasonSearch(e.target.value)}/>
                                            <Button text={""} type={"button"}
                                                    class={"btn btn-primary smooth-radius-left"}
                                                    style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                        </div>
                                        <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                            <table className={"table table-sm table-white table-hover"}>
                                                <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {reasonTableHeaders}
                                                </thead>
                                                <tbody>
                                                {reasonTableRows}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelFilterBtn2"}
                                            data-bs-dismiss={"modal"}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadFilterSubmit2"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"filterSubmit2"}
                                            onClick={() => filterSubmit2()}>Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"schedulemodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticBackdropLabel"}>Schedule
                                Report</h2>
                            <button type={"button"} className={"btn-close"} id={"scheduleClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <form id={"scheduleForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div>
                                    <h4 className={"d-inline-block align-top"} style={{minWidth: "125px"}}>Report
                                        Type:</h4>
                                    <h4 className={"d-inline-block text-capitalize"} id={"report-display"}
                                        style={{
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            maxWidth: "350px"
                                        }}>{reportType}</h4>
                                </div>
                                <div className={"mb-3"}>
                                    <h4 className={"d-inline-block"} style={{minWidth: "125px"}}>Report Range:</h4>
                                    <h4 className={"d-inline-block"}>{rangeName}</h4>
                                </div>
                                <div className={"mb-3"}>
                                    <h4 className={"d-inline-block align-top"} style={{minWidth: "125px"}}>Filter
                                        Params:</h4>
                                    <h4 className={"d-inline-block"} id={"filter-display"}
                                        style={{
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            maxWidth: "350px"
                                        }}></h4>
                                </div>
                                <div className={"d-inline-block"}>
                                    <div className={"form-check"}>
                                        <label htmlFor={"add-pdf"}>Add PDF Attachment (Summary)</label>
                                        <input id={"add-pdf"} defaultChecked={false}
                                               className={"form-check-input"} type={"checkbox"}/>
                                    </div>
                                    <div className={"form-check"}>
                                        <label htmlFor={"add-csv"}>Add CSV Attachment (Details)</label>
                                        <input id={"add-csv"} defaultChecked={false}
                                               className={"form-check-input"} type={"checkbox"}/>
                                    </div>
                                </div>
                                <hr/>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"sch-description"}
                                           onKeyUp={() => scheduleFormCheck("scheduleForm", "scheduleSubmit")}
                                           required={true} pattern={"^[0-9a-zA-Z_@- ]+$"}/>
                                    <label htmlFor={"sch-description"}>Description</label>
                                </div>
                                <div className={"d-flex justify-content-around"}>
                                    <div className={"form-floating mb-3 me-3 w-75"}>
                                        <select className={"form-select"} required={true} id={"sch-frequency"}
                                                defaultValue={""}
                                                onChange={(e) => {
                                                    if (e.target.value.toString() === "1") {
                                                        document.getElementById("sch-weekly").classList.add("d-none");
                                                        document.getElementById("sch-monthly").classList.add("d-none");
                                                        document.getElementById("scheduler-container").classList.add("d-none");
                                                    } else if (e.target.value.toString() === "2") {
                                                        document.getElementById("sch-weekly").classList.remove("d-none");
                                                        document.getElementById("scheduler-container").classList.remove("d-none");
                                                        document.getElementById("sch-monthly").classList.add("d-none");
                                                    } else {
                                                        document.getElementById("sch-monthly").classList.remove("d-none");
                                                        document.getElementById("scheduler-container").classList.remove("d-none");
                                                        document.getElementById("sch-weekly").classList.add("d-none");
                                                    }
                                                    scheduleFormCheck("scheduleForm", "scheduleSubmit");
                                                }}>
                                            <option value={""} disabled={true}></option>
                                            {freqOptions}
                                        </select>
                                        <label htmlFor={"sch-frequency"}>Delivery Rate</label>
                                    </div>
                                    <div className={"form-floating mb-3"}>
                                        <input type={"time"} className={"form-control"} id={"sch-sendTime"}
                                               required={true} step={"300"} min={"00:00"} max={"23:59"}
                                               onFocus={(e) => {
                                                   e.target.showPicker();
                                               }}
                                               onBlur={(e) => {
                                                   if (!e.target.checkValidity()) {
                                                       e.target.stepUp();
                                                   }
                                                   scheduleFormCheck("scheduleForm", "scheduleSubmit");
                                               }}/>
                                        <label htmlFor={"sch-sendTime"}>Time of Day</label>
                                    </div>
                                </div>
                                <fieldset className={"border py-3 px-3 mt-4 d-none"} style={{borderRadius: "6px"}}
                                          id={"scheduler-container"}>
                                    <legend className={"fs-3 px-3 w-auto mb-0 legend"} style={{
                                        backgroundColor: "#FFFFFF",
                                        position: "relative",
                                        bottom: "26px"
                                    }}>Delivery Schedule
                                    </legend>
                                    <div id={"sch-weekly"} className={"d-none"}>
                                        <ul style={{columns: 3, listStyle: "none", columnGap: "30px"}}
                                            className={"float-start"}>
                                            <li>
                                                <div className={"form-check"}>
                                                    <label htmlFor={"sch-weekly-meta-0"}>Monday</label>
                                                    <input name={"sch-weekly-meta"} id={"sch-weekly-meta-0"} value={0}
                                                           defaultChecked={false}
                                                           className={"form-check-input"} type={"checkbox"}/>
                                                </div>
                                            </li>
                                            <li>
                                                <div className={"form-check"}>
                                                    <label htmlFor={"sch-weekly-meta-1"}>Tuesday</label>
                                                    <input name={"sch-weekly-meta"} id={"sch-weekly-meta-1"} value={1}
                                                           defaultChecked={false}
                                                           className={"form-check-input"} type={"checkbox"}/>
                                                </div>
                                            </li>
                                            <li>
                                                <div className={"form-check"}>
                                                    <label htmlFor={"sch-weekly-meta-2"}>Wednesday</label>
                                                    <input name={"sch-weekly-meta"} id={"sch-weekly-meta-2"} value={2}
                                                           defaultChecked={false}
                                                           className={"form-check-input"} type={"checkbox"}/>
                                                </div>
                                            </li>
                                            <li>
                                                <div className={"form-check"}>
                                                    <label htmlFor={"sch-weekly-meta-3"}>Thursday</label>
                                                    <input name={"sch-weekly-meta"} id={"sch-weekly-meta-3"} value={3}
                                                           defaultChecked={false}
                                                           className={"form-check-input"} type={"checkbox"}/>
                                                </div>
                                            </li>
                                            <li>
                                                <div className={"form-check"}>
                                                    <label htmlFor={"sch-weekly-meta-4"}>Friday</label>
                                                    <input name={"sch-weekly-meta"} id={"sch-weekly-meta-4"} value={4}
                                                           defaultChecked={false}
                                                           className={"form-check-input"} type={"checkbox"}/>
                                                </div>
                                            </li>
                                            <li>
                                                <div className={"form-check"}>
                                                    <label htmlFor={"sch-weekly-meta-5"}>Saturday</label>
                                                    <input name={"sch-weekly-meta"} id={"sch-weekly-meta-5"} value={5}
                                                           defaultChecked={false}
                                                           className={"form-check-input"} type={"checkbox"}/>
                                                </div>
                                            </li>
                                            <li>
                                                <div className={"form-check"}>
                                                    <label htmlFor={"sch-weekly-meta-6"}>Sunday</label>
                                                    <input name={"sch-weekly-meta"} id={"sch-weekly-meta-6"} value={6}
                                                           defaultChecked={false}
                                                           className={"form-check-input"} type={"checkbox"}/>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div id={"sch-monthly"} className={"d-none"}>
                                        <ul style={{columns: 4, listStyle: "none", columnGap: "30px"}}
                                            className={"float-start"}>
                                            {Array.from(range(1, 30)).map((i) => {
                                                return (
                                                    <li key={`monthly-rate-item-${i}`}>
                                                        <div className={"form-check"}>
                                                            <label htmlFor={`sch-monthly-meta-${i}`}>{i}</label>
                                                            <input name={"sch-weekly-meta"} id={`sch-monthly-meta-${i}`}
                                                                   value={i} defaultChecked={false}
                                                                   className={"form-check-input"} type={"checkbox"}/>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                            <li>
                                                <div className={"form-check"}>
                                                    <label htmlFor={"sch-monthly-meta-0"}>Last Day</label>
                                                    <input name={"sch-weekly-meta"} id={"sch-monthly-meta-0"} value={0}
                                                           defaultChecked={false}
                                                           className={"form-check-input"} type={"checkbox"}/>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </fieldset>
                                <hr className={"my-5"}/>
                                <div className={"d-inline-block"} id={"add-email-icon"}>
                                    <Button text={"Set Emails"} class={"btn btn-primary align-top me-4"}
                                            id={"add-email-groups"} onClick={() => {
                                        showEmailAddModal()
                                    }}/>
                                    <fieldset className={"border py-3 px-3 d-inline-block"}
                                              style={{borderRadius: "6px", minWidth: "200px"}}>
                                        <legend className={"legend-3"}>Emails</legend>
                                        <ul className={"ps-3"} style={{listStyle: "none"}}>
                                            {emailEntities.length > 0
                                                ? emailEntities.map((email) => {
                                                    return (
                                                        <li key={`email-display-${email}`}>{email}</li>
                                                    );
                                                })
                                                : <li>None</li>
                                            }
                                        </ul>
                                    </fieldset>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"scheduleCancelBtn"}
                                            data-bs-dismiss={"modal"}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadScheduleSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"scheduleSubmit"}
                                            onClick={() => scheduleSubmit()}>Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"addemailmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticBackdropLabel"}>Set Emails</h2>
                            <button type={"button"} className={"btn-close"} id={"addEmailClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <form id={"addEmailForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"list-group-item"}>
                                    <Button type={"button"} class={"btn btn-primary mb-3"} text={"New Email"}
                                            onClick={() => {
                                                setAddNewEmails((prevState) => {
                                                    return [...prevState, {id: uuidv4(), email: ""}]
                                                });
                                                formCheck("addEmailForm", "addEmailSubmit");
                                            }}/>
                                    {addNewEmails.map((item) => {
                                        return (
                                            <div key={`add-new-email-item${item.id}`}>
                                                <i className={"fe fe-trash as-btn d-inline-block red-hover"}
                                                   onClick={() => {
                                                       setAddNewEmails((prevState) => {
                                                           return prevState.filter(i => i.id !== item.id);
                                                       })
                                                   }}></i>
                                                <div className={"form-floating d-inline-block w-75 mb-3 mx-3"}>
                                                    <input type={"text"} name={"add-email-input"}
                                                           className={"form-control"} id={`add-new-email-${item.id}`}
                                                           onKeyUp={(e) => {
                                                               setAddNewEmails((prevState) => {
                                                                   return prevState.map((i) => {
                                                                       if (i.id === item.id) {
                                                                           return {id: i.id, email: e.target.value}
                                                                       }
                                                                       return i
                                                                   });
                                                               })
                                                               formCheck("addEmailForm", "addEmailSubmit");
                                                           }} required={true}
                                                           pattern={"[a-z0-9._%+\\-]+@[a-z0-9.\\-]+\\.[a-z]{2,}$"}
                                                           defaultValue={item.email}/>
                                                    <label htmlFor={`tmp-email-${item.id}`}>Email Address</label>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <hr/>
                                <div className={"list-group-item"}>
                                    {addCurEmails.map((item) => {
                                        return (
                                            <div key={`add-stored-email-${item.id}`}>
                                                <i className={"fe fe-trash as-btn d-inline-block red-hover"}
                                                   onClick={() => {
                                                       setAddCurEmails((prevState) => {
                                                           return prevState.filter(i => i?.id !== item.id);
                                                       })
                                                   }}></i>
                                                <div className={"form-floating d-inline-block w-75 mb-3 mx-3"}>
                                                    <input type={"text"} name={"add-email-input"}
                                                           className={"form-control"} id={`add-tmp-email-${item.id}`}
                                                           required={true}
                                                           pattern={"[a-z0-9._%+\\-]+@[a-z0-9.\\-]+\\.[a-z]{2,}$"}
                                                           value={item.email} readOnly={true}/>
                                                    <label htmlFor={`add-tmp-email-${item.id}`}>Email Address</label>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelAddEmailBtn"}
                                            onClick={() => {
                                                swapModal("addEmailClose", "schedulemodal")
                                            }}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadAddEmailSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"addEmailSubmit"}
                                            onClick={() => setAddEmailItems()}>Set
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer left={copyright} right={version} center={footer}/>
            <PerPageModal filterSubmit={() => filterSubmit()} tmpPerPage={tmpPerPage}
                          setTmpPerPage={(sudocode) => setTmpPerPage(sudocode)}
                          setPage={(sudocode) => setPage(sudocode)}/>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true}
                                autoClose={10000}/>
            </Suspense>
        </div>
    );
};

export default EnvironmentReports;