import Header from "./Header";
import Footer from "./Footer";
import {Chart, registerables} from "chart.js";
import { MapContainer, TileLayer, CircleMarker, Popup } from "react-leaflet";
import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import {useEffect, useState} from "react";
import TableHeaders from "./component-assets/TableHeaders";
import {checkAll, paginateData, sortFunc} from "../libs";
import ApiRequester from "./ApiRequester";

const LegacyCorpDash = () => {

    Chart.register(...registerables)

    let dData, headerData;

    headerData = [
        {label: "Community", key: "Campus_Name", align: "left"},
        {label: "Alarms/Day", key: "alarmsPerDay", align: "center"},
        {label: "Avg Response Time", key: "avgRespTime", align: "center"},
        {label: "Avg Service Time", key: "avgServTime", align: "center"},
        {label: "% Over 20 min", key: "perc20min", align: "center"},
    ]

    if (ReactSession.get("PAL") !== undefined){
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const [dataOrigin, setDataOrigin] = useState([]);
    const [activeData, setActiveData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [activeList, setActiveList] = useState([]);
    const [hourData, setHourData] = useState([]);
    const [count, setCount] = useState("");
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(dData.perPage);
    const [avgRT, setAvgRT] = useState("");
    const [avgST, setAvgST] = useState("");
    const [percRT, setPercRT] = useState("");
    const [alarmDay, setAlarmDay] = useState("");
    const [deviceCnt, setDeviceCnt] = useState("");
    const [resCnt, setResCnt] = useState("");
    const [totalAlarms, setTotalAlarms] = useState("");
    const [totalCampuses, setTotalCampuses] = useState("");
    const [markers, setMarkers] = useState("");
    const [sorter, setSorter] = useState({
        sortColumn: "Campus_Name",
        sortOrder: "desc"
    });

    const spinner = (
        <div className={"spinner-border"} role={"status"} style={{borderWidth: ".175em"}}>
        </div>
    )

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", pVal: dData.corp, procedure: "getcorpstats", reqType: "stored",
                    Days: 30}).then(data => {
                setDataOrigin(data);
                setTotalCampuses(data?.length ? data.length : 0);
                let tmpAvg = 0, tmpPerc = 0, tmpServ = 0, tmpAlarmDay = 0, totalAlarms = 0, totalDevices = 0, totalResidents = 0;
                for (let i=0; i < data.length; i++){
                    tmpAvg = tmpAvg + (data[i]?.avgRespTime ? data[i].avgRespTime : 0);
                    tmpPerc = tmpPerc + (data[i]?.perc20min ? data[i].perc20min : 0);
                    tmpServ = tmpServ + (data[i]?.avgServTime ? data[i].avgServTime : 0);
                    tmpAlarmDay = tmpAlarmDay + (data[i]?.alarmsPerDay ? data[i].alarmsPerDay : 0);
                    totalAlarms = totalAlarms + (data[i]?.totalAlarms ? data[i].totalAlarms : 0);
                    totalDevices = totalDevices + (data[i]?.totalDevices ? data[i].totalDevices : 0);
                    totalResidents = totalResidents + (data[i]?.totalResidents ? data[i].totalResidents : 0);
                }
                if (data.length > 0){
                    tmpAvg = tmpAvg/data.length;
                    tmpServ = tmpServ/data.length;
                    tmpPerc = tmpPerc/data.length;
                    tmpAlarmDay = tmpAlarmDay/data.length;
                }
                setDeviceCnt(totalDevices);
                setTotalAlarms(totalAlarms);
                setResCnt(totalResidents);
                setAvgRT(tmpAvg.toFixed(1));
                setAvgST(tmpServ.toFixed(1));
                setPercRT(tmpPerc.toFixed(1));
                setAlarmDay(tmpAlarmDay.toFixed(1))
            });
            ApiRequester({reqEndpoint: "myfetch", pVal: dData.corp, procedure: "getcorpchart", reqType: "stored",
                    Days: 30}).then(data => {
                setHourData(data);
            });
            setCount(count => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        // Builds the table with activeData QUAD 4
        let tmpData = []
        for (let i=0; i < dataOrigin.length; i++) {
            if (activeList.length === 0 || activeList.includes(dataOrigin[i]?.Campus_Name)) {
                tmpData.push(dataOrigin[i]);
            }
        }
        setActiveData(tmpData);
    }, [activeList, dataOrigin]);

    useEffect(() => {
        let tmp;
        tmp = dataOrigin;
        if (tmp.length > 0){
            tmp.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
        }
        setActiveData(tmp);
    }, [sorter, dataOrigin])

    useEffect(() => {
        // Builds the table with activeData QUAD 4
        let curIndex, tmp, tmpRows = [];
        // let pRules = paginateData(activeData, page, perPage);
        // setPRules(pRules);
        for (let i=0; i<dataOrigin?.length; i++){
            curIndex = i.toString();
            if (activeList.includes(dataOrigin[i].Campus_Name)){
                tmp = [<td key={"tcheckbox"+curIndex}>
                        <div className={"form-check"}>
                            <input className={"form-check-input table-checkbox"} type={"checkbox"} value={""} checked={true}
                                   name={"uCheck"+curIndex} id={"uCheck"+curIndex}
                                   onChange={() => setActiveList((prevState) => {
                                    return prevState.filter(item => item !== dataOrigin[i].Campus_Name);
                                })} style={{width: "20px", height: "20px"}} />
                            <label className={"form-check-label"} htmlFor={"uCheck"+curIndex}> </label>
                        </div>
                    </td>
                ];
            }else{
                tmp = [<td key={"tcheckbox"+curIndex}>
                        <div className={"form-check"}>
                            <input className={"form-check-input table-checkbox"} type={"checkbox"} value={""} checked={false}
                                   name={"uCheck"+curIndex} id={"uCheck"+curIndex}
                                   onChange={() => setActiveList((prevState) => {
                                        return [...prevState, dataOrigin[i].Campus_Name]
                                    })} style={{width: "20px", height: "20px"}} />
                            <label className={"form-check-label"} htmlFor={"uCheck"+curIndex}> </label>
                        </div>
                    </td>
                ];
            }
            for (let i2=0; i2<headerData.length; i2++){
               tmp.push(
                    <td className={headerData[i2]?.align ? "px-1 text-"+headerData[i2]?.align : "px-1"}
                        id={headerData[i2].key+curIndex} key={headerData[i2].key+curIndex}>
                        {dataOrigin[i][headerData[i2].key]}
                    </td>
                );
            }
            if (activeList.includes(dataOrigin[i].Campus_Name)){
                tmpRows.push(
                    <tr className={"clickable"} key={"tableRow"+curIndex} id={"tableRow"+curIndex} onClick={() => setActiveList((prevState) => {
                        return prevState.filter(item => item !== dataOrigin[i].Campus_Name);
                    })}>
                        {tmp}
                    </tr>
                );
            }else {
                tmpRows.push(
                    <tr className={"clickable"} key={"tableRow"+curIndex} id={"tableRow"+curIndex} onClick={() => setActiveList((prevState) => {
                        return [...prevState, dataOrigin[i].Campus_Name]
                    })}>
                        {tmp}
                    </tr>
                );
            }
        }
        setTableRows(tmpRows);
    }, [dataOrigin, page, sorter, perPage, activeList]);

    useEffect(() => {
        // Builds the corporate map based on activeData QUAD 1
        let tmp = [], color, cRadius, avgResp = 0.0, avgST = 0.0, avgPerc = 0.0, tmpAlarmDay = 0.0;
        for (let i=0; i < activeData.length; i++){
            avgResp = avgResp + activeData[i]?.avgRespTime;
            avgST = avgST + activeData[i]?.avgServTime;
            avgPerc = avgPerc + activeData[i]?.perc20min
            tmpAlarmDay = tmpAlarmDay + activeData[i]?.alarmsPerDay;
            if (activeData[i]?.avgRespTime > 20.0){
                color = {color: "#BE4D25"}
            }else{
                color = {color: "#0081C7"}
            }
            cRadius = (activeData[i]?.totalAlarms / totalAlarms) * 50;
            if (cRadius > 50){
                cRadius = 50
            }
            if (cRadius < 10){
                cRadius = 10
            }
            tmp.push(
                <CircleMarker center={[activeData[i]?.Campus_Latitude, activeData[i]?.Campus_Longitude]} pathOptions={color} radius={cRadius} key={"map-marker-"+i.toString()}>
                    <Popup className={"fs-4"}>{activeData[i]?.Campus_Name}<br />{activeData[i]?.totalAlarms} Alarms</Popup>
                </CircleMarker>
            )
        }
        setMarkers(tmp);
        if (activeData.length > 0){
            avgResp = avgResp/activeData.length;
            avgST = avgST/activeData.length;
            avgPerc = avgPerc/activeData.length;
            tmpAlarmDay = tmpAlarmDay/activeData.length;
        }
        setAlarmDay(tmpAlarmDay.toFixed(1));
        setAvgRT(avgResp.toFixed(1));
        setAvgST(avgST.toFixed(1));
        setPercRT(avgPerc.toFixed(1));
    }, [activeData, activeList])

    useEffect(() => {
        if (hourData !== undefined){
            let tmpLabels = [], tmpAlarms = [], tmpResp = [];
            for (let i=0; i < hourData.length; i++){
                tmpLabels.push(hourData[i]?.hourOfDay.toString());
                tmpAlarms.push(hourData[i]?.totalAlarms ? hourData[i].totalAlarms : 0);
                tmpResp.push(hourData[i]?.avgResponseTime ? hourData[i].avgResponseTime : 0.0)
            }
            if (Chart.getChart("perhour") !== undefined){
                const curChart = Chart.getChart("perhour");
                curChart.data.labels = tmpLabels;
                curChart.data.datasets[0].data = tmpAlarms;
                curChart.data.datasets[1].data = tmpResp;
                curChart.update();
            }
            else{
                new Chart('perhour', {
                    options: {
                        responsive: true,
                        plugins: {
                            legend: {
                                display: true
                            },
                        },
                        scales: {
                            x: {
                                grid: {
                                    display: false
                                },
                            },
                            a: {
                                id: "a",
                                position: "left",
                                grid: {
                                    display: false
                                },
                                suggestedMax: 100,
                                suggestedMin: 0,
                                ticks: {
                                    callback: function (value) {
                                        return value
                                    }
                                }
                            },
                            b: {
                                id: "b",
                                position: "right",
                                grid: {
                                    display: false
                                },
                                suggestedMax: 10,
                                suggestedMin: 0,
                                ticks: {
                                    callback: function (value) {
                                        return value
                                    }
                                }
                            }
                        }
                    },
                    data: {
                        labels: tmpLabels.length > 0 ? tmpLabels : ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12",
                                "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"],
                        datasets: [{
                            type: "bar",
                            label: 'Total Alerts',
                            data: tmpAlarms,
                            backgroundColor: ["#0081C7"],
                            hoverBackgroundColor: ["#0081C778"],
                            yAxisID: "a",
                            order: 2
                        },{
                            type: "line",
                            label: "Avg Resp Time",
                            data: tmpResp,
                            backgroundColor: ["#BE4D25"],
                            hoverBackgroundColor: ["#BE4D2578"],
                            borderColor: ["#BE4D2578"],
                            fill: false,
                            yAxisID: "b",
                            order: 1
                        }]
                    }
                });
            }
        }
    }, [hourData]);

    function setAllActive(){
        let tmp = [], status;
        status = document.getElementById("uCheckHead");
        if (status.checked){
            for (let i = 0; i < dataOrigin.length; i++){
                tmp.push(dataOrigin[i]?.Campus_Name);
            }
        }
        setActiveList((prevState) => {
            prevState = tmp;
            return prevState})
    }

    const tableHeaders = <TableHeaders checkbox={true} headerData={headerData} sortOrder={sorter?.sortOrder} checkAll={() => checkAll(0, dataOrigin.length)}
                                       actionAppearance={() => setAllActive()} writeAccess={dData?.access?.Users?.UserManagement?.Write}
                                       sortColumn={sorter?.sortColumn} sorter={sorter} setSorter={setSorter}/>


    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div className={"form-floating mx-4"} style={{minWidth: "200px"}}>
            </div>
            <div>
            </div>
        </div>
    )
    return (
        <div className={"main-content"}>
            <Header preTitle={"Dashboard"} Title={"Corporate"} content={headerContent} />
            <div className={"container-fluid"}>
                <h4>* Stats are based on previous 30 days containing only Pendant devices.</h4>
                <div className={"row"}>
                    <div className={"col-12 col-lg-3 col-xl"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className={"row align-items-center gx-0"}>
                                    <div className={"col"}>
                                        <h6 className={"text-uppercase text-muted mb-2"}>Total Communities</h6>
                                        <span className={"h2 mb-0"}>{totalCampuses !== "" ? totalCampuses.toLocaleString() : spinner}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-12 col-lg-3 col-xl"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className={"row align-items-center gx-0"}>
                                    <div className={"col"}>
                                        <h6 className={"text-uppercase text-muted mb-2"}>Total Devices</h6>
                                        <span className={"h2 mb-0"}>{deviceCnt !== "" ? deviceCnt.toLocaleString() : spinner}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-12 col-lg-3 col-xl"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className={"row align-items-center gx-0"}>
                                    <div className={"col"}>
                                        <h6 className={"text-uppercase text-muted mb-2"}>Total Residents</h6>
                                        <span className={"h2 mb-0"}>{resCnt !== "" ? resCnt.toLocaleString() : spinner}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-12 col-lg-3 col-xl"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className={"row align-items-center gx-0"}>
                                    <div className={"col"}>
                                        <h6 className={"text-uppercase text-muted mb-2"}>Total Alarms</h6>
                                        <span className={"h2 mb-0"}>{totalAlarms !== "" ? totalAlarms.toLocaleString() : spinner}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"row mb-3"} style={{height: "45%"}}>
                    <div className={"col-12 col-lg-6 col-xl"}>
                        <MapContainer zoom={4} center={activeData.length > 0 ? [activeData[0]?.Campus_Latitude, activeData[0]?.Campus_Longitude] : [43.022098, -87.943538]} style={{zIndex: 1}}>
                            <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                       url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                            {markers}
                        </MapContainer>
                    </div>
                    <div className={"col-12 col-lg-6 col-xl"}>
                        <div className={"card"} style={{width: "100%", height: "100%"}}>
                            <div className={"card-header"}>
                                <h4 className={"card-header-title"}>Community KPIs</h4>
                                <h5 className={"card-header-title"}>* All / selected communities</h5>
                            </div>
                            <div className={"card-body"}>
                                <div className={"row h-50"}>
                                    <div className={"col-12 col-lg-4 col-xl"}>
                                        <h1 className={"mb-0 mt-4 text-center"}>{avgRT !== "" ? avgRT : spinner}</h1>
                                        <h5 className={"mt-1 text-center"}>Avg Response Time (min)</h5>
                                    </div>
                                    <div className={"col-12 col-lg-4 col-xl"}>
                                        <h1 className={"mb-0 mt-4 text-center"}>{avgST !== "" ? avgST : spinner}</h1>
                                        <h5 className={"mt-1 text-center"}>Avg Service Time (min)</h5>
                                    </div>
                                    <div className={"col-12 col-lg-4 col-xl"}>
                                        <h1 className={"mb-0 mt-4 text-center"}>{percRT !== "" ? percRT+"%" : spinner}</h1>
                                        <h5 className={"mt-1 text-center"}>20+ Min Responses</h5>
                                    </div>
                                </div>
                                <div className={"row h-50"}>
                                    <div className={"col-12 col-lg-4 col-xl"}>
                                        <h1 className={"mb-0 mt-4 text-center"}>{alarmDay !== "" ? alarmDay.toLocaleString() : spinner}</h1>
                                        <h5 className={"mt-1 text-center"}>Total Alarms/Day</h5>
                                    </div>
                                    <div className={"col-12 col-lg-4 col-xl"}>
                                        <h1 className={"mb-0 mt-4 text-center"}> </h1>
                                        <h5 className={"mt-1 text-center"}> </h5>
                                    </div>
                                    <div className={"col-12 col-lg-4 col-xl"}>
                                        <h1 className={"mb-0 mt-4 text-center"}> </h1>
                                        <h5 className={"mt-1 text-center"}> </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-12 col-lg-6 col-xl"}>
                        <div className={"card"} style={{width: "100%", height: "100%"}}>
                            <div className={"card-header"}>
                                <h4 className={"card-header-title"}>Alerts Per Day</h4>
                                <h5>* All communities totaled only</h5>
                            </div>
                            <div className={"card-body"}>
                                <div className={"chart-container"}>
                                    <canvas className={"chart-canvas"} id={"perhour"}> </canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-12 col-lg-6 col-xl"}>
                        <div className={"card"} style={{height: "100%"}}>
                            <h5 className={"pb-0 text-end"} style={{padding: "1rem"}}>* Select table rows to show selected only</h5>
                            <div className={"card-body py-0"} style={{maxHeight: "425px", overflowY: "auto", padding: "1rem"}}>
                                <table className={"table table-sm table-white"}>
                                    <thead className={"sticky-table-head"} style={{top: 0}}>
                                        {tableHeaders}
                                    </thead>
                                    <tbody style={{maxHeight: "445px"}}>
                                        {tableRows}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default LegacyCorpDash;