import Button from "./Button";
import {swapModal} from "../../libs";

const SingleDeleteModal = (props) => {

    return (
        <div className={"modal fade"} id={"deletemodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
             tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
            <div className={"modal-dialog"}>
                <div className={"modal-content"}>
                    <div className={"modal-header bg-pal"}>
                        <h2 className={"modal-title"} id={"staticBackdropLabel"}>{props?.header}</h2>
                        <button type={"button"} className={"btn-close"} id={"confirmClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                    </div>
                    <h2 className={"m-3"} style={{textAlign: "center"}} id={"deleteModalContent"}> </h2>
                    <div className={"modal-footer"}>
                        <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}} id={"cancelDeleteBtn"} onClick={() => swapModal("confirmClose", "editmodal")}>No</button>
                        <Button type={"button"} class={"btn btn-primary d-none"} style={{width: "75px"}} disabled={true} spinner={true} text={"Deleting"} id={"loadDeleteSubmit"}/>
                        <button type={"button"} className={"btn btn-danger"} style={{width: "75px"}} id={"deleteSubmit"} onClick={() => props?.deletePerform()}>Yes</button>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default SingleDeleteModal;