import {
    secToTime,
    convertIsoToLocal,
    convertIsoToLocalDate,
    formatDate,
    secToTimeUTC,
    isThreshold,
    highlightText
} from "../../libs";
import {Link} from "react-router-dom";


const TableBodySimple = (props) => {
    let returnData, curIndex, tmp;

    returnData = [];
    if (props?.start !== undefined && props?.end !== undefined){
        for (let i=0; i<props?.tableData?.length; i++) {
            curIndex = i.toString();
            if (i >= props?.start && i <= props?.end) {
                tmp = [];
                for (let i2=0; i2<props?.tableHeaders.length; i2++){
                    if (props?.tableHeaders[i2]?.type === "obj") {
                        if (props?.tableHeaders[i2]?.t2 === "datetime"){
                            tmp.push(
                                <td className={props?.tableHeaders[i2]?.align ? "px-1 text-" + props?.tableHeaders[i2]?.align : "px-1"}
                                    id={props?.tableHeaders[i2].key[0] + curIndex}
                                    key={props?.tableHeaders[i2].key[0] + curIndex}>
                                    {props?.tableData[i][props?.tableHeaders[i2].key[0]]}<br/>{props?.tableData[i][props?.tableHeaders[i2].key[1]] ? convertIsoToLocal(props?.tableData[i][props?.tableHeaders[i2].key[1]]) : ""}
                                </td>
                            )
                        }else if (props?.tableHeaders[i2]?.link){
                            tmp.push(
                                <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                    id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+"Key"+curIndex}>
                                    <Link to={props?.tableHeaders[i2].linkto+`/${props?.tableData[i][props?.tableHeaders[i2].linkkey]}`} >
                                        <p className={"mb-0 fs-6"}>{props?.tableData[i][props?.tableHeaders[i2].key].length > 0 ? props?.tableData[i][props?.tableHeaders[i2].key][0] : ""}</p>
                                        <br/>
                                        <p className={"mb-0"}>{props?.tableData[i][props?.tableHeaders[i2].key].length > 1 ? props?.tableData[i][props?.tableHeaders[i2].key][1] : ""}
                                            {props?.tableData[i][props?.tableHeaders[i2].key].length > 2 ? props?.tableData[i][props?.tableHeaders[i2].key][2] : ""}</p>
                                    </Link>
                                </td>
                            )
                        }else{
                            tmp.push(
                                <td className={props?.tableHeaders[i2]?.align ? "px-1 text-" + props?.tableHeaders[i2]?.align : "px-1"}
                                    id={props?.tableHeaders[i2].key[0] + curIndex}
                                    key={props?.tableHeaders[i2].key[0] + curIndex}>
                                    {props?.tableData[i][props?.tableHeaders[i2].key[0]]}<br/>{props?.tableData[i][props?.tableHeaders[i2].key[1]]}
                                </td>
                            )
                        }
                    }else if (props?.tableHeaders[i2]?.type === "date"){
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                                {props?.tableData[i][props?.tableHeaders[i2].key] ? formatDate(new Date(props?.tableData[i][props?.tableHeaders[i2].key])) : ""}
                            </td>
                        )
                    }else if (props?.tableHeaders[i2]?.type === "tel"){
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                                {props?.tableData[i][props?.tableHeaders[i2].key] ? props?.tableData[i][props?.tableHeaders[i2].key].slice(0,3) + "-" + props?.tableData[i][props?.tableHeaders[i2].key].slice(3,6) + "-" + props?.tableData[i][props?.tableHeaders[i2].key].slice(6,10) : ""}
                            </td>
                        );
                    }else if (props?.tableHeaders[i2]?.type === "switch") {
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                                {props?.tableData[i][props?.tableHeaders[i2].key2]}
                            </td>
                        )
                    }else if (props?.tableHeaders[i2]?.type === "concat") {
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key[0]+curIndex} key={props?.tableHeaders[i2].key[0]+curIndex}>
                                {props?.tableData[i][props?.tableHeaders[i2].key[0]]} {props?.tableData[i][props?.tableHeaders[i2].key[1]]}
                            </td>
                        )
                    }else if (props?.tableHeaders[i2]?.type === "timeofday"){
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                                {props?.tableData[i][props?.tableHeaders[i2].key] ? secToTime(props?.tableData[i][props?.tableHeaders[i2].key]) : ""}
                            </td>
                        )
                    }else if (props?.tableHeaders[i2]?.type === "timeofday-utc"){
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                                {props?.tableData[i][props?.tableHeaders[i2].key] ? secToTimeUTC(props?.tableData[i][props?.tableHeaders[i2].key], props?.tzOffset) : ""}
                            </td>
                        )
                    }else if (props?.tableHeaders[i2]?.type === "status"){
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"} key={props?.tableHeaders[i2].key+curIndex}
                                style={props?.tableData[i][props?.tableHeaders[i2].key] ? {color: "green"} : {color: "red"}}>
                                {props?.tableHeaders[i2][props?.tableData[i][props?.tableHeaders[i2].key]]}
                            </td>
                        )
                    }else if (props?.tableHeaders[i2]?.type === "datetime"){
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                                {props?.tableData[i][props?.tableHeaders[i2].key] ? convertIsoToLocal(props?.tableData[i][props?.tableHeaders[i2].key]) : ""}
                            </td>
                        )
                    }else if (props?.tableHeaders[i2]?.type === "datetime-date"){
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                                {props?.tableData[i][props?.tableHeaders[i2].key] ? convertIsoToLocalDate(props?.tableData[i][props?.tableHeaders[i2].key]) : ""}
                            </td>
                        )
                    }else if (props?.tableHeaders[i2]?.type === "ellipsis"){
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-2 text-truncate text-"+props?.tableHeaders[i2]?.align : "px-2 text-truncate"}
                                id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}
                                onClick={!props?.modal ? () => props?.editModal(i) : () => {}} style={{maxWidth: "225px"}}>
                                {props?.tableData[i][props?.tableHeaders[i2].key]}
                            </td>
                        );
                    }else if (props?.tableHeaders[i2]?.type === "bool"){
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                                {props?.tableData[i][props?.tableHeaders[i2].key] ? props?.tableHeaders[i2].t : props?.tableHeaders[i2]?.f}
                            </td>
                        )
                    }else{
                        if (props?.tableHeaders[i2]?.link?.enabled){
                            if (props?.tableHeaders[i2]?.link?.type === "obj"){
                                let links = []
                                for (let ii=0; ii < props?.tableData[i][props?.tableHeaders[i2].key]?.length; ii++){
                                    links.push(
                                        <Link key={`link${ii}${props?.tableHeaders[i2].key}`} to={`${props?.tableHeaders[i2].link.linkto}/${props?.tableData[i][props?.tableHeaders[i2].key][ii][props?.tableHeaders[i2].linkkey]}`}>
                                            {props?.tableData[i][props?.tableHeaders[i2].key][ii][props?.tableHeaders[i2]?.link?.displayKey]}
                                        </Link>
                                    )
                                }
                                tmp.push(
                                    <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                        id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                                        {links}
                                    </td>
                                );
                            }else{
                                tmp.push(
                                    <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                        id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                                        <Link to={`${props?.tableHeaders[i2].link.linkto}/${props?.tableData[i][props?.tableHeaders[i2].link.linkkey]}`}>
                                            {props?.tableData[i][props?.tableHeaders[i2].key]}
                                        </Link>
                                    </td>
                                );
                            }
                        }else if (props?.tableHeaders[i2]?.highlight?.enabled){
                            if (isThreshold(props?.tableData[i][props?.tableHeaders[i2]?.key], props?.tableData[i][props?.tableHeaders[i2]?.highlight?.compareKey], props?.tableHeaders[i2]?.highlight?.extremeValue, props?.tableHeaders[i2]?.highlight?.weighted, props?.tableHeaders[i2]?.highlight?.overunder)){
                                if (props?.tableHeaders[i2]?.highlight?.useIcon){
                                    tmp.push(
                                        <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"} id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                                            {props?.tableData[i][props?.tableHeaders[i2].key]}
                                            <i className={`fe fe-circle text-danger ms-2 fs-4`}
                                               title={highlightText("Extreme", props?.tableHeaders[i2]?.highlight?.weighted, props?.tableHeaders[i2]?.highlight?.overunder === "over", props?.tableHeaders[i2]?.highlight?.extremeValue)}></i>
                                        </td>
                                    )
                                }else{
                                    tmp.push(
                                        <td className={props?.tableHeaders[i2]?.align ? "px-1 bg-pal-danger text-"+props?.tableHeaders[i2]?.align : "px-1 bg-pal-danger"} id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                                            {props?.tableData[i][props?.tableHeaders[i2].key]}
                                        </td>
                                    )
                                }
                            }else if (isThreshold(props?.tableData[i][props?.tableHeaders[i2]?.key], props?.tableData[i][props?.tableHeaders[i2]?.highlight?.compareKey], props?.tableHeaders[i2]?.highlight?.dangerValue,
                                props?.tableHeaders[i2]?.highlight?.weighted, props?.tableHeaders[i2]?.highlight?.overunder)) {
                                if (props?.tableHeaders[i2]?.highlight?.useIcon) {
                                    tmp.push(
                                        <td className={props?.tableHeaders[i2]?.align ? "px-1 text-" + props?.tableHeaders[i2]?.align : "px-1"}
                                            id={props?.tableHeaders[i2].key + curIndex}
                                            key={`${props?.unk}${props?.tableHeaders[i2].key}${curIndex}`}>
                                            {props?.tableData[i][props?.tableHeaders[i2].key]}
                                            <i className={`fe fe-circle text-danger ms-2 fs-4`}
                                               title={highlightText("Risk", props?.tableHeaders[i2]?.highlight?.weighted, props?.tableHeaders[i2]?.highlight?.overunder === "over", props?.tableHeaders[i2]?.highlight?.dangerValue)}></i>
                                        </td>
                                    );
                                } else {
                                    tmp.push(
                                        <td className={props?.tableHeaders[i2]?.align ? "px-1 bg-pal-danger text-" + props?.tableHeaders[i2]?.align : "px-1 bg-pal-danger"}
                                            id={props?.tableHeaders[i2].key + curIndex}
                                            key={`${props?.unk}${props?.tableHeaders[i2].key}${curIndex}`}>
                                            {props?.tableData[i][props?.tableHeaders[i2].key]}
                                        </td>
                                    );
                                }
                            }else if (isThreshold(props?.tableData[i][props?.tableHeaders[i2].key], props?.tableData[i][props?.tableHeaders[i2]?.highlight?.compareKey], props?.tableHeaders[i2]?.highlight?.warnValue, props?.tableHeaders[i2]?.highlight?.weighted, props?.tableHeaders[i2]?.highlight?.overunder)){
                                if (props?.tableHeaders[i2]?.highlight?.useIcon){
                                    tmp.push(
                                        <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"} id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                                            {props?.tableData[i][props?.tableHeaders[i2].key]}
                                            <i className={`fe fe-circle text-warning ms-2 fs-4`}
                                               title={highlightText("Warning", props?.tableHeaders[i2]?.highlight?.weighted, props?.tableHeaders[i2]?.highlight?.overunder === "over", props?.tableHeaders[i2]?.highlight?.warnValue)}></i>
                                        </td>
                                    )
                                }else{
                                    tmp.push(
                                        <td className={props?.tableHeaders[i2]?.align ? "px-1 bg-pal-warning text-"+props?.tableHeaders[i2]?.align : "px-1 bg-pal-warning"} id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                                            {props?.tableData[i][props?.tableHeaders[i2].key]}
                                        </td>
                                    )
                                }
                            }else{
                                tmp.push(
                                    <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"} id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                                        {props?.tableData[i][props?.tableHeaders[i2].key]}
                                    </td>
                                )
                            }
                        }else{
                            tmp.push(
                                <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"} id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                                    {props?.tableData[i][props?.tableHeaders[i2].key]}
                                </td>
                            )
                        }
                    }
                }
                returnData.push(
                    <tr key={"tableRow"+curIndex} id={"tableRow"+curIndex}>
                        {tmp}
                    </tr>
                )
            }
        }
    }else{
        for (let i=0; i<props?.tableData?.length; i++){
            curIndex = i.toString();
            tmp = [];
            for (let i2=0; i2<props?.tableHeaders.length; i2++){
                if (props?.tableHeaders[i2]?.type === "obj"){
                    tmp.push(
                        <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                            id={props?.tableHeaders[i2].key[0]+curIndex} key={props?.tableHeaders[i2].key[0]+curIndex}>
                            {props?.tableData[i][props?.tableHeaders[i2].key[0]]}<br/>{props?.tableData[i][props?.tableHeaders[i2].key[1]]}
                        </td>
                    )
                }else if (props?.tableHeaders[i2]?.type === "switch") {
                    tmp.push(
                        <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                            id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                            {props?.tableData[i][props?.tableHeaders[i2].key2]}
                        </td>
                    )
                }
                else if (props?.tableHeaders[i2]?.type === "concat") {
                    tmp.push(
                        <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                            id={props?.tableHeaders[i2].key[0]+curIndex} key={props?.tableHeaders[i2].key[0]+curIndex}>
                            {props?.tableData[i][props?.tableHeaders[i2].key[0]]} {props?.tableData[i][props?.tableHeaders[i2].key[1]]}
                        </td>
                    )
                }else if (props?.tableHeaders[i2]?.type === "timeofday"){
                    tmp.push(
                        <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                            id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                            {props?.tableData[i][props?.tableHeaders[i2].key] ? secToTime(props?.tableData[i][props?.tableHeaders[i2].key]) : ""}
                        </td>
                    )
                }else if (props?.tableHeaders[i2]?.type === "timeofday-utc"){
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                                {props?.tableData[i][props?.tableHeaders[i2].key] ? secToTimeUTC(props?.tableData[i][props?.tableHeaders[i2].key], props?.tzOffset) : ""}
                            </td>
                        )
                    }else if (props?.tableHeaders[i2]?.type === "datetime"){
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                                {props?.tableData[i][props?.tableHeaders[i2].key] ? convertIsoToLocal(props?.tableData[i][props?.tableHeaders[i2].key]) : ""}
                            </td>
                        )
                }else if (props?.tableHeaders[i2]?.type === "datetime-date"){
                    tmp.push(
                        <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                            id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                            {props?.tableData[i][props?.tableHeaders[i2].key] ? convertIsoToLocalDate(props?.tableData[i][props?.tableHeaders[i2].key]) : ""}
                        </td>
                    )
                }else if (props?.tableHeaders[i2]?.type === "ellipsis"){
                    tmp.push(
                        <td className={props?.tableHeaders[i2]?.align ? "px-2 text-truncate text-"+props?.tableHeaders[i2]?.align : "px-2 text-truncate"}
                            id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}
                            onClick={!props?.modal ? () => props?.editModal(i) : () => {}} style={{maxWidth: "225px"}}>
                            {props?.tableData[i][props?.tableHeaders[i2].key]}
                        </td>
                    );
                }else if (props?.tableHeaders[i2]?.type === "bool"){
                    tmp.push(
                        <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                            id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                            {props?.tableData[i][props?.tableHeaders[i2].key] ? props?.tableHeaders[i2].t : props?.tableHeaders[i2]?.f}
                        </td>
                    )
                }else{
                    if (props?.tableHeaders[i2]?.link?.enabled){
                        if (props?.tableHeaders[i2]?.link?.type === "obj"){
                            let links = []
                            for (let ii=0; ii < props?.tableData[i][props?.tableHeaders[i2].key]?.length; ii++){
                                links.push(
                                    <Link key={`link${ii}${props?.tableHeaders[i2].key}`} to={`${props?.tableHeaders[i2].link.linkto}/${props?.tableData[i][props?.tableHeaders[i2].linkkey]}`}>
                                        {props?.tableData[i][props?.tableHeaders[i2].key][ii]}
                                    </Link>
                                )
                            }
                            tmp.push(
                                <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                    id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                                    {links}
                                </td>
                            );
                        }else{
                            tmp.push(
                                <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"}
                                    id={props?.tableHeaders[i2].key+curIndex} key={`link-container-${curIndex}`}>
                                    <Link to={`${props?.tableHeaders[i2].link.linkto}/${props?.tableData[i][props?.tableHeaders[i2].link.linkkey]}`}>
                                        {props?.tableData[i][props?.tableHeaders[i2].key]}
                                    </Link>
                                </td>
                            );
                        }
                    }else if (props?.tableHeaders[i2]?.highlight?.enabled){
                        if (isThreshold(props?.tableData[i][props?.tableHeaders[i2]?.key], props?.tableData[i][props?.tableHeaders[i2]?.highlight?.compareKey], props?.tableHeaders[i2]?.highlight?.extremeValue, props?.tableHeaders[i2]?.highlight?.weighted, props?.tableHeaders[i2]?.highlight?.overunder)){
                                if (props?.tableHeaders[i2]?.highlight?.useIcon){
                                    tmp.push(
                                        <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"} id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                                            {props?.tableData[i][props?.tableHeaders[i2].key]}
                                            <i className={`fe fe-circle text-danger ms-2 fs-4`}
                                               title={highlightText("Extreme", props?.tableHeaders[i2]?.highlight?.weighted, props?.tableHeaders[i2]?.highlight?.overunder === "over", props?.tableHeaders[i2]?.highlight?.extremeValue)}></i>
                                        </td>
                                    )
                                }else{
                                    tmp.push(
                                        <td className={props?.tableHeaders[i2]?.align ? "px-1 bg-pal-danger text-"+props?.tableHeaders[i2]?.align : "px-1 bg-pal-danger"} id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                                            {props?.tableData[i][props?.tableHeaders[i2].key]}
                                        </td>
                                    )
                                }
                            }else if (isThreshold(props?.tableData[i][props?.tableHeaders[i2]?.key], props?.tableData[i][props?.tableHeaders[i2]?.highlight?.compareKey], props?.tableHeaders[i2]?.highlight?.dangerValue,
                                props?.tableHeaders[i2]?.highlight?.weighted, props?.tableHeaders[i2]?.highlight?.overunder)) {
                                if (props?.tableHeaders[i2]?.highlight?.useIcon) {
                                    tmp.push(
                                        <td className={props?.tableHeaders[i2]?.align ? "px-1 text-" + props?.tableHeaders[i2]?.align : "px-1"}
                                            id={props?.tableHeaders[i2].key + curIndex}
                                            key={`${props?.unk}${props?.tableHeaders[i2].key}${curIndex}`}>
                                            {props?.tableData[i][props?.tableHeaders[i2].key]}
                                            <i className={`fe fe-circle text-risk ms-2 fs-4`}
                                               title={highlightText("Risk", props?.tableHeaders[i2]?.highlight?.weighted, props?.tableHeaders[i2]?.highlight?.overunder === "over", props?.tableHeaders[i2]?.highlight?.dangerValue)}></i>
                                        </td>
                                    );
                                } else {
                                    tmp.push(
                                        <td className={props?.tableHeaders[i2]?.align ? "px-1 bg-pal-risk text-" + props?.tableHeaders[i2]?.align : "px-1 bg-pal-danger"}
                                            id={props?.tableHeaders[i2].key + curIndex}
                                            key={`${props?.unk}${props?.tableHeaders[i2].key}${curIndex}`}>
                                            {props?.tableData[i][props?.tableHeaders[i2].key]}
                                        </td>
                                    );
                                }
                            }else if (isThreshold(props?.tableData[i][props?.tableHeaders[i2].key], props?.tableData[i][props?.tableHeaders[i2]?.highlight?.compareKey], props?.tableHeaders[i2]?.highlight?.warnValue, props?.tableHeaders[i2]?.highlight?.weighted, props?.tableHeaders[i2]?.highlight?.overunder)){
                                if (props?.tableHeaders[i2]?.highlight?.useIcon){
                                    tmp.push(
                                        <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"} id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                                            {props?.tableData[i][props?.tableHeaders[i2].key]}
                                            <i className={`fe fe-circle text-warning ms-2 fs-4`}
                                               title={highlightText("Warning", props?.tableHeaders[i2]?.highlight?.weighted, props?.tableHeaders[i2]?.highlight?.overunder === "over", props?.tableHeaders[i2]?.highlight?.warnValue)}></i>
                                        </td>
                                    )
                                }else{
                                    tmp.push(
                                        <td className={props?.tableHeaders[i2]?.align ? "px-1 bg-pal-warning text-"+props?.tableHeaders[i2]?.align : "px-1 bg-pal-warning"} id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                                            {props?.tableData[i][props?.tableHeaders[i2].key]}
                                        </td>
                                    )
                                }
                            }else{
                                tmp.push(
                                    <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"} id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                                        {props?.tableData[i][props?.tableHeaders[i2].key]}
                                    </td>
                                )
                            }
                    }else{
                        tmp.push(
                            <td className={props?.tableHeaders[i2]?.align ? "px-1 text-"+props?.tableHeaders[i2]?.align : "px-1"} id={props?.tableHeaders[i2].key+curIndex} key={props?.tableHeaders[i2].key+curIndex}>
                                {props?.tableData[i][props?.tableHeaders[i2].key]}
                            </td>
                        )
                    }
                }
            }
            returnData.push(
                <tr key={"tableRow"+curIndex} id={"tableRow"+curIndex}>
                    {tmp}
                </tr>
            )
        }
    }

    return (
        <>
            {returnData}
        </>
    )
};

export default TableBodySimple;