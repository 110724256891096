import {Link, Navigate} from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import Header from "../Header";
import Button from "../component-assets/Button";
import ApiRequester from "../ApiRequester";
import {
    formCheck, formFail, formSuccess, getLocalTimeFromEpochMillis,
    handleSubmitActions, toggleCollapse
} from "../../libs";
import MaterialIcon from "material-icons-react";

const InovonicsDevice = (props) => {
    let dData, bData;

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const [deviceId, setDeviceId] = useState(props?.id);
    const [editable, setEditable] = useState(dData?.access?.Community?.Devices?.Write);
    const [count, setCount] = useState(0);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [pageLoaded, setPageLoaded] = useState(false);
    // Basic Information Variables
    const [basicData, setBasicData] = useState([]);
    const [debugData, setDebugData] = useState([]);
    const [areaData, setAreaData] = useState([]);
    const [residentData, setResidentData] = useState([]);
    const [basicLoaded, setBasicLoaded] = useState(false);
    const [resLoaded, setResLoaded] = useState(false);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", payload: {device_filter: deviceId, campus_id: campusId},
                          procedure: "sp_dev_getInovonicsDevices", reqType: "stored"}).then(data => {
                setBasicData(data?.length > 0 ? data[0] : {});
                setBasicLoaded(true)
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "sp_dev_getareasbycampusid", reqType: "stored"}).then(data => {
                setAreaData(data ? data : []);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "sp_dev_getresidentsbycampusid", reqType: "stored"}).then(data => {
                setResidentData(data ? data : []);
                setResLoaded(true);
            });
            setCount(() => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        if (![basicLoaded, resLoaded].includes(false)){
            setPageLoaded(true);
        }
    }, [basicLoaded, resLoaded]);

    useEffect(() => {
        if (basicData?.manufacture_uuid){
            ApiRequester({reqEndpoint: "getitems", Table: "DebugSerialNumber", PKey: "SerialNum", CampusId: campusId,
                                PVal: basicData.manufacture_uuid.toString()}).then(data => {
                let tmpData = []
                for (let i=0; i < data.length; i++){
                    data[i].Parsed = JSON.parse(data[i]?.Parsed);
                    tmpData.push(data[i])
                }
                setDebugData(tmpData ? tmpData : []);
            });
        }
    }, [basicData]);


    function basicActionHandling(){
        document.getElementById("description-static").classList.toggle("d-none");
        document.getElementById("description-edit").classList.toggle("d-none");
        document.getElementById("serialnum-static").classList.toggle("d-none");
        document.getElementById("serialnum-edit").classList.toggle("d-none");
        document.getElementById("linked-static")?.classList?.toggle("d-none");
        document.getElementById("linked-edit")?.classList?.toggle("d-none");
        if (document.getElementById("edit-basic-btn").innerText === "Edit"){
            document.getElementById("edit-basic-btn").innerText = "Cancel";
            document.getElementById("save-basic-btn").classList.toggle("d-none");
            document.getElementById("serialnum-edit").value = basicData?.manufacture_uuid;
            document.getElementById("description-edit").value = basicData?.descriptions;
            if (basicData?.device_type === "location"){
                document.getElementById("linked-edit").value = basicData?.area_id;
            }else{
                document.getElementById("linked-edit").value = basicData?.resident_id;
            }
        }else{
            document.getElementById("edit-basic-btn").innerText = "Edit";
            document.getElementById("save-basic-btn").classList.toggle("d-none");
        }
        formCheck("basic-form", "save-basic-btn");
    }

    function basicSubmit(){
        let description, linkedId, payload, linkedEntity, serialnum;
        if (editable){
            handleSubmitActions("save-basic-btn", "load-basic-btn");
            description = document.getElementById("description-edit").value;
            linkedEntity = document.getElementById("linked-edit");
            linkedId = linkedEntity?.value ? parseInt(linkedEntity.value) : 0;
            serialnum = document.getElementById("serialnum-edit").value;
            payload = [{device_id: parseInt(deviceId), descriptions: description, is_active: linkedId !== 0,
                        area_id: basicData?.is_area_device === 1 ? parseInt(linkedId) : 0,
                        resident_id: basicData?.is_resident ? parseInt(linkedId) : 0, manufacture_uuid: serialnum.toString()}];
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_device", PKey: "device_id", ClientId: dData.sessionId,
                                Condition: "primary", urlType: "POST", User: dData.user, Payload: payload, CampusId: campusId}).then(data => {
                if (data && data[0]){
                    setCount(0);
                    formSuccess("Basic information saved.", "save-basic-btn", "load-basic-btn", "edit-basic-btn")
                }
                else{
                    formFail(`Error during save, error code: ${data && data?.length > 1 ? data[1] : ""}`,
                        "save-basic-btn", "load-basic-btn");
                }
            });
        }else{
            toast.warn("You do not have permissions to make changes.");
        }
    }

    function performLogging(){
        let msg, payload, logTime;
        handleSubmitActions("log-btn", "load-log-btn");
        logTime = document.getElementById("logtimer").value;
        payload = [{manufacture_uuid: basicData?.manufacture_uuid, duration: parseInt(logTime)}];
        ApiRequester({reqEndpoint: "mqtt", Operation: "deviceLogging", CampusId: campusId,
                            ClientId: dData.sessionId, Payload: payload}).then(data => {
            if(data[0]){
                msg = "Logging has begun, refresh screen or come back later to see newest logs.";
                formSuccess(msg, "log-btn", "load-log-btn");
            }
            else{
                msg = "Error occured starting the logging process with local server. Check server and contact customer support if issue does not resolve.";
                formFail(msg, "log-btn", "load-log-btn");
            }
        });
    }

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
        </div>
    );

    return (dData?.slauth === true && dData?.tKey != null && dData?.tKey !== "" && dData?.acnt != null && dData?.access?.Community?.Devices?.Read) ? (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Device Profile"} Title={basicData?.manufacture_uuid ? `TX_ID: ${basicData.manufacture_uuid}` : "TX_ID:"}
                        content={headerContent}/>
                <div className={"container-fluid mt-4"}>
                    <div className={"card"} style={{minWidth: "550px"}}>
                        <div className={"card-header d-flex justify-content-between"}>
                            <div>
                                <h3 className={"card-header-title d-inline-block"}>Basic Information</h3>
                            </div>
                            <div>
                                <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"basic-card-btn"}
                                   onClick={() => toggleCollapse("basic-card-btn", "basic-card")}></i>
                            </div>
                        </div>
                        <div className={"card-body collapse collapse-content show"} id={"basic-card"}>
                            <form className={"d-inline align-top form-control-plaintext was-validated"} id={"basic-form"}>
                                <div className={"mx-5 mt-2 align-top d-inline-block"} id={"basic-col1-div"}>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Device Name:</p>
                                        <p className={"ms-4"} id={"name-static"}>{basicData?.device_name ? basicData.device_name : ""}</p>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Description:</p>
                                        <p className={"ms-4"} id={"description-static"}>{basicData?.descriptions ? basicData.descriptions : ""}</p>
                                        <input className={"ms-4 d-none form-control form-control-sm"}
                                               style={{height: "30px", width: "256px"}} id={"description-edit"}
                                               defaultValue={basicData?.descriptions ? basicData.descriptions : ""}
                                               pattern={"^[0-9a-zA-Z_\\- ]+$"} />
                                    </div>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Category:</p>
                                        <p className={"ms-4"} id={"category-static"}>{basicData?.device_category ? basicData.device_category : ""}</p>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Serial Number:</p>
                                        <p className={"ms-4"} id={"serialnum-static"}>{basicData?.manufacture_uuid ? basicData.manufacture_uuid : ""}</p>
                                        <input className={"ms-4 d-none form-control form-control-sm"} required={true}
                                               style={{height: "30px", width: "256px"}} id={"serialnum-edit"}
                                               defaultValue={basicData?.manufacture_uuid ? basicData.manufacture_uuid : ""}
                                               pattern={"^[0-9]+$"} />
                                    </div>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Model Number:</p>
                                        <p className={"ms-4"} id={"model-name-static"}>{basicData?.inovonics_model_name ? basicData.inovonics_model_name : ""}</p>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Attached To:</p>
                                        {basicData?.device_type
                                            ? basicData.device_type === "location"
                                                ?   <>
                                                    <p className={"ms-4"} id={"linked-static"}><Link to={`/profiles/area/${basicData?.area_id}`}>{basicData?.area_name ? basicData.area_name : ""}</Link></p>
                                                        <select className={"ms-4 d-none form-select form-select-sm"}
                                                                onChange={() => formCheck("basic-form", "save-basic-btn")}
                                                                style={{height: "30px", width: "256px"}} id={"linked-edit"}
                                                                defaultValue={basicData?.area_id ? basicData.area_id : 0}>
                                                            <option value={0}>Unassigned</option>
                                                            {areaData.map((aItem, i) => {
                                                                return <option key={`linked-item-${i}`} value={aItem?.area_id}>{aItem?.area_name}</option>
                                                            })}
                                                        </select>
                                                    </>
                                                :   <>
                                                    <p className={"ms-4"} id={"linked-static"}><Link to={`/profiles/resident/${basicData?.resident_id}`}>{basicData?.resident_name ? basicData.resident_name : ""}</Link></p>
                                                        <select className={"ms-4 d-none form-select form-select-sm"}
                                                                onChange={() => formCheck("basic-form", "save-basic-btn")}
                                                                style={{height: "30px", width: "256px"}} id={"linked-edit"}
                                                                defaultValue={basicData?.resident_id ? basicData.resident_id : 0}>
                                                            <option value={0}>Unassigned</option>
                                                            {residentData.map((rItem, i) => {
                                                                return <option key={`linked-item-${i}`} value={rItem?.resident_id}>{rItem?.resident_first_name} {rItem?.resident_last_name}</option>
                                                            })}
                                                        </select>
                                                    </>
                                            : <></>
                                        }
                                    </div>
                                </div>
                            </form>
                            {pageLoaded && <Button text={"Edit"} class={"btn btn-primary float-end"} id={"edit-basic-btn"} disabled={!editable} onClick={() => {
                                if (editable){
                                    basicActionHandling()
                                }
                            }} />}
                            <Button text={"Save"} class={"btn btn-primary float-end me-3 d-none"} id={"save-basic-btn"} disabled={!editable} onClick={() => {
                                if (editable){
                                    basicSubmit()
                                }
                            }} />
                            <Button type={"button"} class={"btn btn-primary float-end mx-3 d-none"} disabled={true} spinner={true} text={""} id={"load-basic-btn"} />
                        </div>
                    </div>
                    <div className={"card"} style={{minWidth: "500px"}}>
                        <div className={"card-header d-flex justify-content-between"}>
                            <div>
                                <h3 className={"card-header-title d-inline-block"} style={{minWidth: "80px"}}>Debug Device</h3>
                                <div style={{display: "inline-block"}} id={"debug-header-data"}>
                                    <h3 className={"ms-6 my-0 d-inline-block text-success"}>{debugData?.length ? debugData.length : 0} Logs</h3>
                                </div>
                            </div>
                            <div>
                                <i className={"fe fe-plus as-btn collapse-toggle p-2"} id={"debug-card-btn"}
                                   onClick={() => {
                                       toggleCollapse("debug-card-btn", "debug-card");
                                       document.getElementById("debug-header-data").classList.toggle("d-none");
                                   }}></i>
                            </div>
                        </div>
                        <div className={"card-body collapse collapse-content hide"} id={"debug-card"}>
                            <div>
                                <form id={"debugForm"} className={"was-validated"}>
                                    <h4>Local server device logging.</h4>
                                    <div className={"flex form-floating mb-3"} style={{maxWidth: "350px"}}>
                                        <select className={"form-select"} id={"logtimer"} value={undefined}
                                                required={true}>
                                            <option value={"600"}>10 Minutes</option>
                                            <option value={"1800"}>30 Minutes</option>
                                            <option value={"3600"}>1 Hour</option>
                                            <option value={"10800"}>3 Hours</option>
                                            <option value={"21600"}>6 Hours</option>
                                            <option value={"86400"}>24 Hours</option>
                                        </select>
                                        <label htmlFor={"logtimer"}>Log Time Length</label>
                                    </div>
                                    <Button text={"Start Logging"} class={"btn btn-primary mt-2"} id={"log-btn"}
                                            title={"Starts logging device debug data for troubleshooting, will clear and restart log if logging already running."}
                                            onClick={() => {
                                                performLogging();
                                            }} />
                                    <Button type={"button"} class={"btn btn-primary mt-2 d-none"}
                                            disabled={true} spinner={true} text={"Starting. . ."}
                                            id={"load-log-btn"}/>
                                </form>
                            </div>
                            <table className={"table table-sm mt-4"}>
                                <thead>
                                    <tr>
                                        <th className={"text-center"}>Timestamp</th>
                                        <th className={"text-center"}>Raw Bytes</th>
                                        <th className={"text-center"}>Alarm 0</th>
                                        <th className={"text-center"}>Alarm 1</th>
                                        <th className={"text-center"}>No Change</th>
                                        <th className={"text-center"}>Reset</th>
                                        <th className={"text-center"}>Stat 1</th>
                                        <th className={"text-center"}>Stat 2</th>
                                        <th className={"text-center"}>Supervision</th>
                                        <th className={"text-center"}>Hop 1st / Count</th>
                                        <th className={"text-center"}>Signal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {debugData?.length > 0
                                        ? debugData?.map((dItem, index) => {
                                            return <tr key={`debug-row-${index}`}>
                                                    <td className={"text-center"}>{getLocalTimeFromEpochMillis(dItem.EventTime, dData?.campusData[campusId.toString()]?.tz_offset)}</td>
                                                    <td className={"text-center"}>
                                                        <MaterialIcon className={"material-icons as-btn-2x"} icon={"raw_on"} title={dItem?.ByteString}
                                                                      onClick={() => {
                                                                          toast.info(dItem?.ByteString);
                                                                          navigator?.clipboard?.writeText(dItem?.ByteString);
                                                                      }}/>
                                                    </td>
                                                    <td className={"text-center"}>{dItem?.Parsed?.alarm0 != null ? dItem.Parsed.alarm0.toString() : "N/A"}</td>
                                                    <td className={"text-center"}>{dItem?.Parsed?.alarm1 != null ? dItem.Parsed.alarm1.toString() : "N/A"}</td>
                                                    <td className={"text-center"}>{dItem?.Parsed?.noChange != null ? dItem.Parsed.noChange.toString() : "N/A"}</td>
                                                    <td className={"text-center"}>{dItem?.Parsed?.reset != null ? dItem.Parsed.reset.toString() : "N/A"}</td>
                                                    <td className={"text-center"}>{dItem?.Parsed?.stat1 != null ? dItem.Parsed.stat1.toString() : "N/A"}</td>
                                                    <td className={"text-center"}>{dItem?.Parsed?.stat2 != null ? dItem.Parsed.stat2.toString() : "N/A"}</td>
                                                    <td className={"text-center"}>{dItem?.Parsed?.supervision != null ? dItem.Parsed.supervision.toString() : "N/A"}</td>
                                                    <td className={"text-center"}>
                                                        {dItem?.Parsed?.uuidFirstHop != null ? dItem.Parsed.uuidFirstHop : "N/A"}
                                                        <br/>
                                                        {dItem?.Parsed?.hopCount != null ? dItem.Parsed.hopCount : "N/A"}
                                                    </td>
                                                    <td className={"text-center"}>{dItem?.Parsed?.signal != null ? dItem.Parsed.signal : "N/A"}</td>
                                                </tr>
                                            })
                                    : <tr><td colSpan={11}>No logs to display</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </>
    ) : <Navigate to={"/"}/>;
};

export default InovonicsDevice;