import { ReactSession } from 'react-client-session';
import {enc, AES} from 'crypto-js';
import moment from "moment";
import {v4 as uuidv4} from "uuid";
import {getCurrentEpochTimeInSeconds} from "../libs";


// Response ErrorCode 10 is always a 500 return code from backend
// Response ErrorCode 11 is always a 503 connection timeout or service unavailable
// Response ErrorCode 12 is always a 403 Forbidden unauthorized connection


function PackageRequest(payload, method="POST"){
    if (method.toString().toLowerCase() === "get"){
        return {
            method: method,
            headers: {'Content-Type': 'application/json', 'x-amz-security-token': process.env.REACT_APP_AUTH_TOKEN}
        };
    }else{
        return {
            method: method,
            headers: {'Content-Type': 'application/json', 'x-amz-security-token': process.env.REACT_APP_AUTH_TOKEN},
            body: JSON.stringify(payload)
        };
    }
}

function PackageRequestCustom(payload, method="POST", custHeader, custValue){
    if (custHeader?.toLowerCase() === "authorization"){
        return {
            method: method,
            headers: {'Content-Type': 'application/json', Authorization: custValue},
            body: JSON.stringify(payload)
        }
    }else if (custValue){
        return {
            method: method,
            headers: {'Content-Type': 'application/json', 'x-amz-security-token': custValue},
            body: JSON.stringify(payload)
        }
    }else{
        return {
            method: method,
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(payload)
        }
    }
}

function ProcessSessionReload(resp, keyType, itemId, campusOverride){
    if (resp?.Status !== "Error") {
        if (ReactSession.get("PAL") !== undefined){
            let bData, dData;
            bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
            dData = JSON.parse(bData.toString(enc.Utf8));
            if (keyType === "corporate_id" && itemId){
                dData.corp = parseInt(itemId);
            }
            dData.acntList = resp?.CampusList;
            dData.acnt = campusOverride && campusOverride !== 0 ? campusOverride : resp?.CampusId;
            dData.campus = resp?.CampusName;
            dData.campusData = resp?.CampusData;
            dData.checkin = resp?.Checkin ? resp.Checkin : 0;
            dData.rounds = resp?.Rounds ? resp.Rounds : 0;
            dData.evmSiteId = resp?.evmSiteId ? resp.evmSiteId : "0";
            dData.resMobile = resp?.ResidentApp ? resp.ResidentApp : 0;
            dData.cloud = resp?.is_cloud ? resp.is_cloud : 0;
            dData.devFilter = [];
            dData.devCatFilter = [];
            dData.areaTypeFilter = [];
            dData.typeFilter = [];
            dData.resFilter = [];
            dData.reasonFilter = [];
            dData.userFilter = [];
            dData.devFilterLabel = [];
            dData.devCatFilterLabel = [];
            dData.areaTypeFilterLabel = [];
            dData.typeFilterLabel = [];
            dData.resFilterLabel = [];
            dData.reasonFilterLabel = [];
            dData.userFilterLabel = [];
            dData.userrole = resp.UserRole;
            dData.role_id = resp?.RoleId ? resp.RoleId : 0;
            dData.role_weight = resp?.RoleWeight ? resp.RoleWeight : 10;
            dData.role_name = resp?.RoleName ? resp.RoleName : "Caregiver";
            dData.access = resp.PortalAccess;
            dData.groups = resp?.GroupIds ? resp.GroupIds : [];
            dData.displayEdit = resp.UserEditDisplayName;
            dData.groupEdit = resp.CaregiverChgGroupEnabled;
            dData.hybrid = resp?.is_hybrid ? resp.is_hybrid : false;
            dData.legacy = resp?.show_legacy ? resp.show_legacy : false;
            dData.new = resp?.show_new ? resp.show_new : false;
            ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
            return [true];
        } else {
            return [false];
        }
    } else {
        return [false];
    }
}

function ProcessLogin(resp, payload, props){
    if (resp !== undefined && resp?.AccessToken !== undefined && resp?.AccessToken !== "" && (resp?.Plan || ["palcare", "palcare-admin"].includes(resp?.UserRole))) {
        const prePack = {};
        prePack.user = resp.UserId;
        prePack.slauth = true;
        prePack.end = resp.Expiration.toString();
        prePack.tKey = resp.AccessToken;
        prePack.acntList = resp?.CampusList ? resp.CampusList : resp.CampusId // list of campus ids, only one in list if non corporate
        prePack.acnt = resp.CampusId[0];
        prePack.myAcnt = resp.CampusId[0];
        prePack.corp = resp?.CorpId; // Blank if non corporate, corp id if corporate
        prePack.myCorp = resp?.CorpId;
        prePack.display = resp.DisplayName;
        prePack.email = resp.Email;
        prePack.username = payload.Username;
        prePack.campus = resp.CampusName; // Campus name for non corp members, corp name for corporate members
        prePack.campusData = resp.CampusData; // json, keys by campus id's, contains campus name and validity.
        prePack.userrole = resp.UserRole;
        prePack.role_id = resp?.RoleId ? resp.RoleId : 0;
        prePack.role_weight = resp?.RoleWeight ? resp.RoleWeight : 10;
        prePack.role_name = resp?.RoleName ? resp.RoleName : "Caregiver";
        prePack.access = resp.PortalAccess;
        prePack.perPage = 100;
        prePack.devFilter = [];
        prePack.devCatFilter = [];
        prePack.areaTypeFilter = [];
        prePack.typeFilter = [];
        prePack.resFilter = [];
        prePack.reasonFilter = [];
        prePack.userFilter = [];
        prePack.devFilterLabel = [];
        prePack.devCatFilterLabel = [];
        prePack.areaTypeFilterLabel = [];
        prePack.typeFilterLabel = [];
        prePack.resFilterLabel = [];
        prePack.reasonFilterLabel = [];
        prePack.userFilterLabel = [];
        prePack.groups = resp?.GroupIds ? resp.GroupIds : [];
        prePack.displayEdit = resp.UserEditDisplayName;
        prePack.groupEdit = resp.CaregiverChgGroupEnabled;
        prePack.serverDefault = resp.ServerMac;
        prePack.sessionId = "S"+uuidv4();
        prePack.migrated = resp?.Migrated ? resp.Migrated : false;
        prePack.cognito = resp?.CognitoId;
        prePack.checkin = resp?.Checkin ? resp.Checkin : 0;
        prePack.rounds = resp?.Rounds ? resp.Rounds : 0;
        prePack.evmSiteId = resp?.evmSiteId ? resp.evmSiteId : "0";
        prePack.resMobile = resp?.ResidentApp ? resp.ResidentApp : 0;
        prePack.cloud = resp?.is_cloud ? resp.is_cloud : false;
        prePack.soundsOff = false;
        prePack.reloadTime = parseInt(getCurrentEpochTimeInSeconds()) + 300;
        prePack.hybrid = resp?.is_hybrid ? resp.is_hybrid : false;
        prePack.legacy = resp?.show_legacy ? resp.show_legacy : false;
        prePack.new = resp?.show_new ? resp.show_new : false;
        ReactSession.set("PAL", AES.encrypt(JSON.stringify(prePack), process.env.REACT_APP_ESECRET).toString());
        window.location.reload(true);
    } else {
        if (resp !== undefined && resp?.AccessToken !== undefined && resp?.AccessToken !== "" && !resp?.Plan){
            props.setMsg.setForm(prevState => {
                return {...prevState, errMsg: "Service plan has expired, please contact billing to resolve.",
                    attempts: props.attempts + 1}
            });
        }else{
            props.setMsg.setForm(prevState => {
                return {...prevState, errMsg: "Invalid Username and Password", attempts: props.attempts + 1}
            });
        }
        document.getElementById("loadSubmit").classList.add("d-none");
        document.getElementById("subBtn").classList.remove("d-none");
    }
}


function ProcessData(resp){
    if (Array.isArray(resp) === true) {
        if (ReactSession.get("PAL") !== undefined){
            return resp;
        } else {
            return undefined;
        }
    } else {
        return undefined;
    }
}


function UserReturn(resp){
    if (resp?.Status?.toLowerCase() === "ok") {
        return [true];
    }
    else{
        return [false, resp?.ErrorCode];
    }
}

function postReturn(resp){
    if (resp?.Status === "OK"){
        return [true, ""];
    }else{
        return [false, resp?.ErrorCode];
    }
}

function SaveReturn(resp){
    if (resp?.Status === "OK"){
        return [true];
    }
    else if (resp?.Status === "Invalid"){
        return [false, "Forbidden, user not authorized."];
    }
    else if (resp?.Status === "1"){
        return [false, `Error encountered, ${resp?.Fail} failed. Please contact your customer success manager for assistance.`];
    }
    else if (resp?.Status === "2"){
        return [false, `Internal server error, ${resp?.Fail} failed. Please contact your customer success manager for assistance.`];
    }
    else{
        if (resp?.ErrorCode === "9"){
            return [false, "Server already exists, server may be removed or migrated from the Admin Panel."];
        }else{
            return [false, "Unknown failure, request not processed."];
        }
    }
}


function ProcessReportData(resp){
    if (resp?.Status?.toLowerCase() === "ok") {
        if (ReactSession.get("PAL") !== undefined){
            return resp.Res;
        } else {
            return {};
        }
    } else {
        if (resp?.Status?.toLowerCase() === "error"){
            return resp.Res;
        }
        return {};
    }
}


function ProcessGetReturn(resp){
    if (resp?.Status !== "Error" && resp?.Content?.length > 0){
        if (ReactSession.get("PAL") !== undefined){
            return resp.Content[0];
        } else {
            return [];
        }
    }
    else{
        if (resp?.Status === "Error") {
            console.log("Error Code: ", resp.ErrorCode);
        }
        return [];
    }
}


function ProcessQueryReturnV2(resp){
    if (resp?.Status !== "Error" && resp?.Content?.length > 0){
        if (ReactSession.get("PAL") !== undefined){
            return resp.Content;
        } else {
            return [];
        }
    }
    else{
        return [];
    }
}

function ProcessReturnMinimum(resp){
    if (resp?.Status !== "Error" && resp?.Content){
        if (ReactSession.get("PAL") !== undefined){
            return resp.Content;
        } else {
            return [];
        }
    }
    else{
        return [];
    }
}


function ProcessQueryReturn(resp, table){
    if (resp?.Status === "OK") {
        if (ReactSession.get("PAL") !== undefined){
            if (table?.toLowerCase() === "user"){
                let dtFormatted;
                for (let i=0; i < resp.Content.length; i++){
                    if (resp?.Content[i].hasOwnProperty("LastLoginTime")){
                        dtFormatted = resp.Content[i].LastLoginTime !== 0 ?
                            moment.utc(resp.Content[i].LastLoginTime*1000).local().format("M/D/YYYY h:mm A") : "- None";
                        resp.Content[i].FormattedLoginTime = dtFormatted.split(" ");
                    }else{
                        resp.Content[i].FormattedLoginTime = ["-", "-", ""];
                    }
                }
            }
            return resp?.Content;
        } else {
            return [];
        }
    } else {
        return [];
    }
}

function ProcessQueryExactReturn(resp){
    if (resp?.Status !== "Error" && resp?.Content?.length > 0) {
        if (ReactSession.get("PAL") !== undefined){
            return resp.Content[0];
        } else {
            return {};
        }
    } else {
        return {};
    }
}


function v2SendReturn(resp){
    if (resp?.status === "success"){
        return [true];
    }
    else{
        return [false];
    }
}


function ProcessAlerts(resp){
    if (resp?.Status === "OK"){
        if (ReactSession.get("PAL") !== undefined){
            if (resp?.Alarm){
                let ret = [], tmp = {}, edt, pdatearray;
                for(let i=0; i < resp.Alarm.length; i++){
                    if (resp.Alarm[i]?.AcceptDateTime !== undefined && resp.Alarm[i]?.AcceptDateTime !== ""){
                        tmp.AcceptUsername = resp.Alarm[i]?.AcceptUsername;
                    }else{
                        tmp.AcceptUsername = "Not Accepted";
                    }
                    edt = moment.utc(resp.Alarm[i]?.EventDateTime).local().format("M/D/YYYY h:mm A");
                    pdatearray = edt.split(" ");
                    tmp.EventDateTime = resp.Alarm[i]?.EventDateTime;
                    tmp.LocalEventDateTime = edt;
                    tmp.LocalEventDate = pdatearray[0];
                    tmp.LocalEventTime = pdatearray[1] + " " + pdatearray[2];
                    tmp.ElapsedFormatted = resp.Alarm[i]?.ElapsedFormatted;
                    tmp.Elapsed = resp.Alarm[i]?.Elapsed;
                    tmp.ResidentsParsed = resp.Alarm[i]?.ResidentsParsed;
                    tmp.ResidentsObject = resp.Alarm[i]?.ResidentsObject;
                    tmp.DeviceUse = resp.Alarm[i]?.DeviceUse;
                    tmp.LocationBuilding = resp.Alarm[i]?.LocationBuilding;
                    tmp.LocationName = resp.Alarm[i]?.LocationName;
                    tmp.LocationId = resp.Alarm[i]?.LocationId;
                    tmp.NearName = resp.Alarm[i]?.NearName;
                    tmp.AcceptDateTime = resp.Alarm[i]?.AcceptDateTime;
                    tmp.LocalAcceptDateTime = moment.utc(resp.Alarm[i]?.AcceptDateTime).local().format("M/D/YYYY h:mm A");
                    tmp.Status = resp.Alarm[i]?.Status;
                    tmp.Presses = resp.Alarm[i]?.Presses;
                    tmp.LocationArea = resp.Alarm[i]?.LocationArea;
                    tmp.AlertType = resp.Alarm[i]?.AlertType;
                    tmp.AlertSubType = resp.Alarm[i]?.AlertSubType;
                    tmp.AlarmId = resp.Alarm[i]?.AlarmId;
                    tmp.MacAddr = resp.Alarm[i].MacAddr;
                    tmp.AckDateTime = resp.Alarm[i].AckDateTime;
                    tmp.Version = resp.Alarm[i]?.Version ? resp.Alarm[i].Version.toString() : "2.0";
                    ret.push(tmp);
                    tmp = {};
                }
                if (ret.length > 0){
                    return ret;
                } else {
                    return [];
                }
            } else {
                return [];
            }
        } else {
            return [];
        }
    } else {
        return [];
    }
}

async function ApiRequester(props) {
    let payload, requestParam, resp;
    if (props.reqEndpoint === "login"){
        payload = {Username: props.username, Password: props.password};
        requestParam = PackageRequest(payload);
        if (process.env.REACT_APP_DEVMODE === "true"){
            resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_AUTH_ENDPOINT, requestParam).then(response => response.json());
        }else{
            resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_AUTH_ENDPOINT, requestParam).then(response => response.json());
        }
        return ProcessLogin(resp, payload, props);
    }else if(["perday", "perhour", "perreason", "resp"].includes(props.reqEndpoint)){
        payload = {CampusId: props?.CampusId, RequestDataType: props.reqEndpoint};
        requestParam = PackageRequest(payload);
        if (process.env.REACT_APP_DEVMODE === "true"){
            resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_DMAIN_ENDPOINT, requestParam).then(response => response.json()).catch(() => {
                return undefined;
            });
        }else{
            resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_DMAIN_ENDPOINT, requestParam).then(response => response.json()).catch(() => {
                return undefined;
            });
        }
        return ProcessData(resp);
    }else if(props.reqEndpoint === "get-alerts"){
        payload = {CampusId: props?.CampusId, ToGet: props?.ToGet, UserId: props?.UserId, Migrated: props?.Migrated,
                    Mode: props?.mode, Hybrid: props?.hybrid, Legacy: props?.legacy, New: props?.new};
        requestParam = PackageRequest(payload);
        if (process.env.REACT_APP_DEVMODE === "true"){
            resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_ALARMS_ENDPOINT, requestParam).then(response => response.json());
        }else{
            resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_ALARMS_ENDPOINT, requestParam).then(response => response.json());
        }
        return ProcessAlerts(resp);
    }else if(props.reqEndpoint === "get-alerts-station"){
        payload = {CampusId: props?.CampusId, ToGet: props?.ToGet, UserId: props?.UserId, Migrated: props?.Migrated,
            Mode: props?.mode, Hybrid: props?.hybrid, Legacy: props?.legacy, New: props?.new};
        requestParam = PackageRequest(payload);
        if (process.env.REACT_APP_DEVMODE === "true"){
            resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_ALARMS_STATION_ENDPOINT, requestParam).then(response => response.json());
        }else{
            resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_ALARMS_STATION_ENDPOINT, requestParam).then(response => response.json());
        }
        return ProcessAlerts(resp);
    }else if(props.reqEndpoint === "get-alerts-cloud"){
        payload = {
            campus_id: props?.CampusId,
            user_id: props?.UserId
        };
        requestParam = PackageRequest(payload);
        if (process.env.REACT_APP_DEVMODE === "true"){
            resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_ALARMS_CLOUD_ENDPOINT, requestParam).then(response => response.json());
        }else{
            resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_ALARMS_CLOUD_ENDPOINT, requestParam).then(response => response.json());
        }
        return ProcessQueryReturn(resp);
    }else if(props.reqEndpoint === "handle-event"){
        payload = {
            payload: props?.Payload,
            reqType: props?.ReqType,
            campus_id: props?.CampusId
        };
        requestParam = PackageRequest(payload);
        if (process.env.REACT_APP_DEVMODE === "true"){
            resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_EVENTS_ENDPOINT, requestParam).then(response => response.json());
        }else{
            resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_EVENTS_ENDPOINT, requestParam).then(response => response.json());
        }
        return SaveReturn(resp);
    }else if(props.reqEndpoint === "get-report"){
        payload = {CampusId: props?.CampusId, repType: props?.repType, startDate: props?.startDate,
                    endDate: props?.endDate, devices: props?.devices, locations: props?.locations,
                    deviceKey: props?.deviceKey, locationKey: props?.locationKey};
        requestParam = PackageRequest(payload);
        try{
            if (process.env.REACT_APP_DEVMODE === "true"){
                resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_REPORTS_ENDPOINT, requestParam).then(response => {
                    if (response?.status === 503){
                        return {"Status": "Error", "ErrorCode": 11, "Res": {}};
                    }else if (response?.status === 500){
                        return {"Status": "Error", "ErrorCode": 10, "Res": {}};
                    }else if (response?.status === 403){
                        return {"Status": "Error", "ErrorCode": 12, "Res": {}};
                    }else{
                        return response.json();
                    }
                });
            }else{
                resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_REPORTS_ENDPOINT, requestParam).then(response => {
                    if (response?.status === 503){
                        return {"Status": "Error", "ErrorCode": 11, "Res": {}};
                    }else if (response?.status === 500){
                        return {"Status": "Error", "ErrorCode": 10, "Res": {}};
                    }else if (response?.status === 403){
                        return {"Status": "Error", "ErrorCode": 12, "Res": {}};
                    }else{
                        return response.json();
                    }
                });
            }
        }catch (e) {
            console.log(`Exception in get report api request: ${e?.toString()}`);
            resp = {"Status": "Error", "ErrorCode": 10, "Res": {}};
        }
        return ProcessReportData(resp);
    }else if(props.reqEndpoint === "get-mysql-report"){
        payload = {
            campus_id: props?.campus_id,
            campus_guid: props?.campus_guid,
            corporation_id: props?.corporation_id,
            rep_type: props?.rep_type,
            start_date: props?.start_date,
            end_date: props?.end_date,
            device_category_filter: props?.device_category_filter,
            area_filter: props?.area_filter,
            area_group_filter: props?.area_group_filter,
            area_type_filter: props?.area_type_filter,
            reason_filter: props?.reason_filter,
            user_filter: props?.user_filter,
            user_group_filter: props?.user_group_filter,
            event_type_filter: props?.event_type_filter,
            resident_filter: props?.resident_filter,
            resident_group_filter: props?.resident_group_filter,
            resp_filter_items: props?.resp_filter_items,
            items: props?.items,
            username: props?.username
        };
        requestParam = PackageRequest(payload);
        try{
            if (process.env.REACT_APP_DEVMODE === "true"){
                resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_MY_REPORTS_ENDPOINT, requestParam).then(response => {
                    if (response?.status === 503){
                        return {"Status": "Error", "ErrorCode": 11, "Res": {}};
                    }else if (response?.status === 500){
                        return {"Status": "Error", "ErrorCode": 10, "Res": {}};
                    }else if (response?.status === 403){
                        return {"Status": "Error", "ErrorCode": 12, "Res": {}};
                    }else{
                        return response.json();
                    }
                });
            }else{
                resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_MY_REPORTS_ENDPOINT, requestParam).then(response => {
                    if (response?.status === 503){
                        return {"Status": "Error", "ErrorCode": 11, "Res": {}};
                    }else if (response?.status === 500){
                        return {"Status": "Error", "ErrorCode": 10, "Res": {}};
                    }else if (response?.status === 403){
                        return {"Status": "Error", "ErrorCode": 12, "Res": {}};
                    }else{
                        return response.json();
                    }
                });
            }
        }catch (e) {
            console.log(`Exception in get report api request: ${e?.toString()}`);
            resp = {"Status": "Error", "ErrorCode": 10, "Res": {}};
        }
        return ProcessReportData(resp);
    }else if(props.reqEndpoint === "user-manage"){
        requestParam = PackageRequest(props?.Payload);
        if (process.env.REACT_APP_DEVMODE === "true"){
            resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_USER_ENDPOINT, requestParam).then(response => response.json());
        }else{
            resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_USER_ENDPOINT, requestParam).then(response => response.json());
        }
        return UserReturn(resp);
    }else if(props.reqEndpoint === "v2Send"){
        /// Endpoint package
        requestParam = PackageRequest(props.Payload, props.Method);
        if (process.env.REACT_APP_DEVMODE === "true"){
            resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_DEMO_ENDPOINT, requestParam).then(response => response.json()).catch(() => {
                return [false];
            });
        }else{
            resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_DEMO_ENDPOINT, requestParam).then(response => response.json()).catch(() => {
                return [false];
            });
        }
        return v2SendReturn(resp);
    }else if(props.reqEndpoint === "save"){
        // Main Save endpoint for all tables, allows updates by primary and foreign keys, prelude to addRec use.
        payload = {
            Table: props.Tbl,
            PartitionKey: props?.PKey,
            Content: props?.Payload,
            SortKey: props?.SKey,
            CampusId: props?.CampusId,
            Overwrite: props?.Overwrite
        }
        requestParam = PackageRequest(payload);
        if (process.env.REACT_APP_DEVMODE === "true"){
            resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_POST_ENDPOINT, requestParam).then(response => response.json()).catch(() => {
                return [false, "Request timed out. Please contact your customer success manager for assistance if failure persists."];
            });
        }else{
            resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_POST_ENDPOINT, requestParam).then(response => response.json()).catch(() => {
                return [false, "Request timed out. Please contact your customer success manager for assistance if failure persists."];
            });
        }
        return SaveReturn(resp);
    }else if(props.reqEndpoint === "mysave"){
        // Main Save endpoint for all tables, allows updates by primary and foreign keys, prelude to addRec use.
        payload = {
            Table: props.Tbl,
            Content: props?.Payload,
            pKey: props?.PKey,
            pVal: props?.PVal,
            Condition: props?.Condition,
            CampusId: props?.CampusId,
            ClientId: props?.ClientId,
            User: props?.User
        }
        requestParam = PackageRequest(payload, props.urlType);
        try{
            if (process.env.REACT_APP_DEVMODE === "true"){
                resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_REST_ENDPOINT, requestParam).then(response => {
                    if (response?.status === 503){
                        return {"Status": "Error", "ErrorCode": 11, "Content": [], "Success": 0, "Fail": 0};
                    }else if (response?.status === 500){
                        return {"Status": "Error", "ErrorCode": 10, "Content": [], "Success": 0, "Fail": 0};
                    }else if (response?.status === 403){
                        return {"Status": "Error", "ErrorCode": 12, "Content": [], "Success": 0, "Fail": 0};
                    }else{
                        return response.json();
                    }
                })
            }else{
                resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_REST_ENDPOINT, requestParam).then(response => {
                    if (response?.status === 503){
                        return {"Status": "Error", "ErrorCode": 11, "Content": [], "Success": 0, "Fail": 0};
                    }else if (response?.status === 500){
                        return {"Status": "Error", "ErrorCode": 10, "Content": [], "Success": 0, "Fail": 0};
                    }else if (response?.status === 403){
                        return {"Status": "Error", "ErrorCode": 12, "Content": [], "Success": 0, "Fail": 0};
                    }else{
                        return response.json();
                    }
                })
            }
        }catch (e) {
            console.log(`Exception in save rest api call: ${e.toString()}`);
            resp = {"Status": "Error", "ErrorCode": 10, "Content": [], "Success": 0, "Fail": 0}
        }
        return SaveReturn(resp);
    }else if(props.reqEndpoint === "myfetch"){
        // Main request endpoint for all tables, allows fetching by campus id and advanced custom searches.
        payload = {
            pKey: props?.pKey,
            pVal: props?.pVal,
            Procedure: props?.procedure,
            RequestType: props?.reqType,
            Days: props?.Days,
            Table: props?.Tbl,
            Param: props?.param,
            Payload: props?.payload
        }
        requestParam = PackageRequest(payload);
        if (process.env.REACT_APP_DEVMODE === "true"){
            resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_FETCH_ENDPOINT, requestParam).then(response => response.json()).catch(() => {
                return [false, "Request timed out. Please contact your customer success manager for assistance if failure persists."];
            });
        }else{
            resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_FETCH_ENDPOINT, requestParam).then(response => response.json()).catch(() => {
                return [false, "Request timed out. Please contact your customer success manager for assistance if failure persists."];
            });
        }
        return ProcessQueryReturn(resp);
    }else if(props.reqEndpoint === "mytransaction"){
        // Used for saves with complex multi stored procedure with lambda processing aid to achieve results.
        payload = {
            RequestType: props?.TransType,
            Content: props?.Payload,
            CampusId: props?.CampusId,
            ClientId: props?.ClientId,
            Param: props?.Param,
            User: props?.User
        }
        requestParam = PackageRequest(payload, props.urlType);
        try{
            if (process.env.REACT_APP_DEVMODE === "true"){
                resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_TRANS_ENDPOINT, requestParam).then(response => {
                    if (response?.status === 503){
                        return {"Status": "Error", "ErrorCode": 11, "Content": [], "Success": 0, "Fail": 0};
                    }else if (response?.status === 500){
                        return {"Status": "Error", "ErrorCode": 10, "Content": [], "Success": 0, "Fail": 0};
                    }else if (response?.status === 403){
                        return {"Status": "Error", "ErrorCode": 12, "Content": [], "Success": 0, "Fail": 0};
                    }else{
                        return response.json();
                    }
                })
            }else{
                resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_TRANS_ENDPOINT, requestParam).then(response => {
                    if (response?.status === 503){
                        return {"Status": "Error", "ErrorCode": 11, "Content": [], "Success": 0, "Fail": 0};
                    }else if (response?.status === 500){
                        return {"Status": "Error", "ErrorCode": 10, "Content": [], "Success": 0, "Fail": 0};
                    }else if (response?.status === 403){
                        return {"Status": "Error", "ErrorCode": 12, "Content": [], "Success": 0, "Fail": 0};
                    }else{
                        return response.json();
                    }
                })
            }
        }catch (e) {
            console.log(`Exception in transaction rest api call: ${e.toString()}`);
            resp = {"Status": "Error", "ErrorCode": 10, "Content": [], "Success": 0, "Fail": 0}
        }
        return SaveReturn(resp);
    }else if(props.reqEndpoint === "mqtt"){
        // Main Save endpoint for all tables, allows updates by primary and foreign keys, prelude to addRec use.
        payload = {
            Operation: props.Operation,
            ClientId: props?.ClientId,
            ServerId: props?.ServerId,
            CampusId: props?.CampusId,
            Content: props?.Payload,
            ProductType: props?.ProductType,
            MsgClass: props?.MsgClass
        }
        requestParam = PackageRequest(payload);
        if (process.env.REACT_APP_DEVMODE === "true"){
            resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_POST_MQTT, requestParam).then(response => response.json()).catch(() => {
                return [false, "Request timed out. Please contact your customer success manager for assistance if failure persists."];
            });
        }else{
            resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_POST_MQTT, requestParam).then(response => response.json()).catch(() => {
                return [false, "Request timed out. Please contact your customer success manager for assistance if failure persists."];
            });
        }
        return postReturn(resp);
    }else if(props.reqEndpoint === "uploadprocess"){
        // Used for saves with complex multi stored procedure with lambda processing aid to achieve results.
        payload = {
            Content: props?.Payload,
            CampusId: props?.CampusId,
            ClientId: props?.ClientId,
            Process: props?.Process,
            User: props?.User
        }
        requestParam = PackageRequest(payload);
        try{
            if (process.env.REACT_APP_DEVMODE === "true"){
                resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_UPLOAD_ENDPOINT, requestParam).then(response => {
                    if (response?.status === 503){
                        return {"Status": "Error", "ErrorCode": 11, "Content": [], "Success": 0, "Fail": 0};
                    }else if (response?.status === 500){
                        return {"Status": "Error", "ErrorCode": 10, "Content": [], "Success": 0, "Fail": 0};
                    }else if (response?.status === 403){
                        return {"Status": "Error", "ErrorCode": 12, "Content": [], "Success": 0, "Fail": 0};
                    }else{
                        return response.json();
                    }
                })
            }else{
                resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_UPLOAD_ENDPOINT, requestParam).then(response => {
                    if (response?.status === 503){
                        return {"Status": "Error", "ErrorCode": 11, "Content": [], "Success": 0, "Fail": 0};
                    }else if (response?.status === 500){
                        return {"Status": "Error", "ErrorCode": 10, "Content": [], "Success": 0, "Fail": 0};
                    }else if (response?.status === 403){
                        return {"Status": "Error", "ErrorCode": 12, "Content": [], "Success": 0, "Fail": 0};
                    }else{
                        return response.json();
                    }
                })
            }
        }catch (e) {
            console.log(`Exception in upload process api call: ${e.toString()}`);
            resp = {"Status": "Error", "ErrorCode": 10, "Content": [], "Success": 0, "Fail": 0}
        }
        return ProcessReturnMinimum(resp);
    }else if(props.reqEndpoint === "getitem"){
        requestParam = PackageRequest(props.Payload);
        if (process.env.REACT_APP_DEVMODE === "true"){
            resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_GET_ITEM, requestParam).then(response => response.json()).catch(() => {
                return {};
            });
        }else{
            resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_GET_ITEM, requestParam).then(response => response.json()).catch(() => {
                return {};
            });
        }
        return ProcessQueryExactReturn(resp);
    }else if(props.reqEndpoint === "getitems"){
        payload = {Table: props?.Table, PartitionKey: props?.PKey, PartitionValue: props?.PVal, Projection: props?.Projection,
                    SecondIndex: props?.SecondIndex, CampusId: props?.CampusId, Search: props?.Search ? props.Search : ""};
        requestParam = PackageRequest(payload);
        if (process.env.REACT_APP_DEVMODE === "true"){
            resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_QUERY_GET, requestParam).then(response => response.json()).catch(() => {
                return [];
            });
        }else{
            resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_QUERY_GET, requestParam).then(response => response.json()).catch(() => {
                return [];
            });
        }
        return ProcessQueryReturn(resp, props?.Table);
    }else if(props.reqEndpoint === "getall"){
        payload = {Table: props?.Table, Search: props?.Search, CampusId: props?.CampusId,
            Projection: props?.Projection};
        requestParam = PackageRequest(payload);
        if (process.env.REACT_APP_DEVMODE === "true"){
            resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_GET_ALL, requestParam).then(response => response.json());
        }else{
            resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_GET_ALL, requestParam).then(response => response.json());
        }
        return ProcessGetReturn(resp);
    }else if(props.reqEndpoint === "redget"){
        payload = {
            Table: props?.Table ? props.Table : "",
            Column: props?.Column ? props.Column : "",
            getType: props?.GetType ? props.GetType : "",
            Search: props?.Search ? props.Search : "",
            SearchKey: props?.SearchKey ? props.SearchKey : "",
            StartValue: props?.StartValue ? props.StartValue : "",
            EndValue: props?.EndValue ? props.EndValue : "",
            Selector: props?.Selector ? props.Selector : "",
            SelectorValue: props?.SelectorValue ? props.SelectorValue : "",
            SortColumn: props?.SortColumn ? props.SortColumn : "",
            SortOrder: props?.SortOrder ? props.SortOrder : "",
            CampusId: props?.CampusId
        };
        requestParam = PackageRequest(payload);
        if (process.env.REACT_APP_DEVMODE === "true"){
            resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_RED_QUERY, requestParam).then(response => response.json()).catch(() => {
                return [];
            });
        }else{
            resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_RED_QUERY, requestParam).then(response => response.json()).catch(() => {
                return [];
            });
        }
        return ProcessQueryReturnV2(resp);
    }else if(props.reqEndpoint === "jwt"){
        payload = {data: {site_id: props?.id, duration: props?.duration},
            Authorization: props?.headerValue, url: props?.url};
        requestParam = PackageRequest(payload);
        if (process.env.REACT_APP_DEVMODE === "true"){
            resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_JWT_ENDPOINT, requestParam).then(response => response.json());
        }else{
            resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_JWT_ENDPOINT, requestParam).then(response => response.json());
        }
        return ProcessReturnMinimum(resp);
    }else if(props.reqEndpoint === "centegix"){
        payload = {
            domain: props?.site_domain,
            site: props?.site_id,
            start: props?.start,
            end: props?.end,
            token: props?.token,
            macAddr: props?.mac_addr,
            fname: props?.fname,
            lname: props?.lname,
            description: props?.description,
            checkin: props?.checkin,
            checkout: props?.checkout,
            checkinId: props?.checkin_id,
            operation: props?.request_type
        };
        requestParam = PackageRequest(payload, props?.method);
        if (process.env.REACT_APP_DEVMODE === "true"){
            resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_RTLS_CENTEGIX_ENDPOINT, requestParam).then(response => response.json());
        }else{
            resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_RTLS_CENTEGIX_ENDPOINT, requestParam).then(response => response.json());
        }
        if (props?.request_type === "get"){
            return ProcessQueryReturnV2(resp);
        }else{
            return SaveReturn(resp);
        }
    }else if(props.reqEndpoint === "send_report"){
        // Main Save endpoint for all tables, allows updates by primary and foreign keys, prelude to addRec use.
        payload = {
            Content: props?.Payload,
            CampusId: props?.CampusId,
            ClientId: props?.ClientId
        }
        requestParam = PackageRequest(payload);
        if (process.env.REACT_APP_DEVMODE === "true"){
            if (props?.nowait){
                fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_SEND_REPORT, requestParam)
                return [true]
            }else{
                resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_SEND_REPORT, requestParam).then(response => response.json()).catch(() => {
                    return [false, "Request timed out. Please contact your customer success manager for assistance if failure persists."];
                });
            }
        }else{
            if (props?.nowait){
                fetch(process.env.REACT_APP_API+process.env.REACT_APP_SEND_REPORT, requestParam)
                return [true]
            }else{
                resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_SEND_REPORT, requestParam).then(response => response.json()).catch(() => {
                    return [false, "Request timed out. Please contact your customer success manager for assistance if failure persists."];
                });
            }
        }
        return SaveReturn(resp);
    }else if(props.reqEndpoint === "custom"){
        payload = props?.payload
        requestParam = PackageRequestCustom(payload, props?.method, props?.headerKey, props?.headerValue);
        if (process.env.REACT_APP_DEVMODE === "true"){
            resp = await fetch(props?.url, requestParam).then(response => response.json()).catch(() => {
                return [];
            });
        }else{
            resp = await fetch(props?.url, requestParam).then(response => response.json()).catch(() => {
                return [];
            });
        }
        return ProcessQueryReturnV2(resp);
    }else if(props.reqEndpoint === "sessionLoad"){
        // Main Save endpoint for all tables, allows updates by primary and foreign keys, prelude to addRec use.
        payload = {
            pKey: props?.pKey,
            pVal: props?.pVal,
            myId: props?.myId,
            currentId: props?.campus_override
        }
        requestParam = PackageRequest(payload);
        if (process.env.REACT_APP_DEVMODE === "true"){
            resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_SESSION_ENDPOINT, requestParam).then(response => response.json()).catch(() => {
                return [false, "Request timed out. Please contact your customer success manager for assistance if failure persists."];
            });
        }else{
            resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_SESSION_ENDPOINT, requestParam).then(response => response.json()).catch(() => {
                return [false, "Request timed out. Please contact your customer success manager for assistance if failure persists."];
            });
        }
        return ProcessSessionReload(resp, props?.pKey, props?.pVal, props?.campus_override);
    }else if(props.reqEndpoint === "simulate"){
        // Main request endpoint for all tables, allows fetching by campus id and advanced custom searches.
        payload = {
            campus_id: props?.campus_id,
            device_id: props?.device_id,
            timeofday: props?.timeofday,
            event_category_id: props?.event_category_id,
            runtime: props?.runtime
        }
        requestParam = PackageRequest(payload);
        if (process.env.REACT_APP_DEVMODE === "true"){
            resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_SIM_ENDPOINT, requestParam).then(response => response.json()).catch(() => {
                return [false, "Request timed out. Please contact your customer success manager for assistance if failure persists."];
            });
        }else{
            resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_SIM_ENDPOINT, requestParam).then(response => response.json()).catch(() => {
                return [false, "Request timed out. Please contact your customer success manager for assistance if failure persists."];
            });
        }
        return ProcessQueryReturn(resp);
    }else if(props.reqEndpoint === "myvalidation"){
        // Main request endpoint for all tables, allows fetching by campus id and advanced custom searches.
        payload = {
            RequestType: props?.reqType,
            Payload: props?.payload
        }
        requestParam = PackageRequest(payload);
        if (process.env.REACT_APP_DEVMODE === "true"){
            resp = await fetch(process.env.REACT_APP_DEVAPI+process.env.REACT_APP_VALID_ENDPOINT, requestParam).then(response => response.json()).catch(() => {
                return [false, "Request timed out. Please contact your customer success manager for assistance if failure persists."];
            });
        }else{
            resp = await fetch(process.env.REACT_APP_API+process.env.REACT_APP_VALID_ENDPOINT, requestParam).then(response => response.json()).catch(() => {
                return [false, "Request timed out. Please contact your customer success manager for assistance if failure persists."];
            });
        }
        return ProcessQueryReturn(resp);
    }
}

export default ApiRequester;