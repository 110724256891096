import { Navigate } from 'react-router-dom';
import Navigation from "./Navigation";
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import { useParams } from "react-router";
import Areas from "./Areas";
import Residents from "./Residents";
import Devices from "./Devices";
import AreaGroups from "./AreaGroups";
import SystemDevices from "./SystemDevices";
import ServerDevices from "./ServerDevices";
import {compareRole, iAmHigherAuthority} from "../libs";
import ResidentGroups from "./ResidentGroups";
import HeaderBanner from "./component-assets/HeaderBanner";
import SessionReload from "./component-assets/SessionReload";
import WanderDevices from "./WanderDevices";

const Community = (props) => {
    let pageContent, dData, paramVars, bData;
    paramVars = useParams();

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }
    if (paramVars.dType === "areas" && (dData?.access?.Community?.Areas?.Read || !compareRole("corporate-admin", dData) || iAmHigherAuthority(80, dData?.role_weight))){
        if (paramVars?.eType === "groups"){
            pageContent = <AreaGroups />;
        }else{
            pageContent = <Areas />;
        }
    }else if (paramVars.dType === "residents" && (dData?.access?.Community?.Residents?.Read || !compareRole("corporate-admin", dData) || iAmHigherAuthority(80, dData?.role_weight))){
        if (paramVars?.eType === "groups"){
            pageContent = <ResidentGroups />;
        }else{
            pageContent = <Residents />;
        }
    }else if (paramVars.dType === "devices" && (dData?.access?.Community?.Devices?.Read || !compareRole("corporate-admin", dData) || iAmHigherAuthority(80, dData?.role_weight))){
        pageContent = <Devices />;
    }else if (paramVars.dType === "system_devices" && (!compareRole("corporate-admin", dData) || iAmHigherAuthority(80, dData?.role_weight))) {
        pageContent = <SystemDevices />;
    }else if (paramVars.dType === "server_devices" && (!compareRole("corporate-admin", dData) || iAmHigherAuthority(80, dData?.role_weight))) {
        pageContent = <ServerDevices />;
    }else if (paramVars.dType === "wander_devices" && (dData?.access?.Community?.Devices?.Read || !compareRole("corporate-admin", dData) || iAmHigherAuthority(80, dData?.role_weight))) {
        pageContent = <WanderDevices />;
    }else{
        pageContent = (
            <div>
            </div>
        );
    }

    return (dData?.slauth === true && dData?.tKey != null && dData?.tKey !== "" && dData?.acnt != null && dData?.access) ? (
        <>
            <Navigation />
            {dData?.myVersion !== dData?.currentVersion && dData?.cloud ? <HeaderBanner /> : <></>}
            {dData?.migrated && <SessionReload />}
            {pageContent}
        </>
    ) : <Navigate to={"/"}/>;
};

export default Community;