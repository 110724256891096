import { Navigate } from 'react-router-dom';
import Navigation from "./Navigation";
import { ReactSession } from 'react-client-session';
import {Suspense, useEffect, useState} from "react";
import {AES, enc} from "crypto-js";
import Header from "./Header";
import {ToastContainer} from "react-toastify";

const EVM = (props) => {
    let dData, bData;

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const [count, setCount] = useState(0);
    const [srcMap, setSrcMap] = useState(<></>);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [dashId, setDashId] = useState("4947f716-452d-4ad3-99b0-162a555bcbaf?directory_alias=palcare-insights");

    // useEffect(() => {
    //     let interval = null;
    //     interval = setInterval(() => {
    //          setCount(count => count - 1);
    //     }, 1000);
    //     if (count <= 0){
    //         ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
    //                             procedure: "sp_dev_getusersbycampusid", reqType: "stored"}).then(data => {
    //                 if (data) {
    //                     setDashId(data)
    //                 }
    //         });
    //         setCount(() => 600);
    //     }
    //     return () => clearInterval(interval);
    // }, [count]);

    useEffect(() => {
        if (dashId){
            setSrcMap(() => (<iframe style={{width: "100%", height: "1200px", maxHeight: "1000px"}}
                                     src={`https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/160611363433/dashboards/${dashId}`}></iframe>))
            // setSrcMap(() => (<iframe style={{width: "100%", height: "1200px", maxHeight: "1000px"}}
            //                          src={"https://analytics.zoho.com/open-view/2355180000000003076/44fa67defe0ba365369b78a9c3d93fd2"}></iframe>))
            // setSrcMap(() => (<iframe style={{width: "100%", height: "800px", maxHeight: "1000px"}}
            //                          className={"d-flex"}
            //                          src={`https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/${process.env.REACT_APP_HOST}/dashboards/${dashId}`}></iframe>));
        }
    }, [dashId]);

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div>
            </div>
        </div>
    );

    return (dData?.slauth === true && dData?.tKey != null && dData?.tKey !== "" && dData?.acnt != null && dData?.access?.Settings) ? (
        <>
            <Navigation />
            <div className={"main-content"}>
                <Header preTitle={"Pal Analytics"} Title={"Analytics Insights"} content={headerContent} />
                <div className={"container-fluid mt-4"}>
                    <div>
                        {srcMap}
                    </div>
                </div>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </>
    ) : <Navigate to={"/"}/>;
};

export default EVM;