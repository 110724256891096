import { Navigate } from 'react-router-dom';
import Navigation from "./Navigation";
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import { useParams } from "react-router";
import LegacyEventReports from "./LegacyEventReports";
import EventReports from "./EventReports";
import ScheduledReports from "./ScheduledReports";
import WellnessReports from "./WellnessReports";
import SessionReload from "./component-assets/SessionReload";
import HeaderBanner from "./component-assets/HeaderBanner";
import GroupReports from "./GroupReports";
import ExecutiveReport from "./ExecutiveReport";
import EnvironmentReports from "./EnvironmentReports";
import MaintenanceReports from "./MaintenanceReports";

const Reports = (props) => {
    let reportContent, dData, paramVars, bData;
    paramVars = useParams();

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    if (dData?.migrated){
        if (paramVars.dType === "alerts" && dData?.access?.Reports?.Alerts?.Read){
            reportContent = <EventReports toFetch={"alerts"} run={paramVars?.eType === "run"} />;
        }else if (paramVars.dType === "maintenance" && dData?.access?.Reports?.Alerts?.Read){
            reportContent = <MaintenanceReports />;
        }else if (paramVars.dType === "wellness" && dData?.access?.Reports?.Alerts?.Read){
            reportContent = <WellnessReports />;
        }else if (paramVars.dType === "scheduled" && dData?.access?.Reports?.Alerts?.Read){
            reportContent = <ScheduledReports />;
        }else if (paramVars.dType === "grouped" && dData?.access?.Reports?.Alerts?.Read){
            reportContent = <GroupReports />;
        }else if (paramVars.dType === "executive" && dData?.access?.Reports?.Alerts?.Read){
            reportContent = <ExecutiveReport />;
        }else if (paramVars.dType === "environmental" && dData?.access?.Reports?.Alerts?.Read){
            reportContent = <EnvironmentReports />;
        }else{
            reportContent = (
                <div>
                    <h3>Page not found. 404</h3>
                </div>
            );
        }
    }else{
        if (paramVars.dType === "alerts" && dData?.access?.Reports?.Alerts?.Read){
            reportContent = <LegacyEventReports toFetch={"alerts"}/>;
        }
        else if (paramVars.dType === "resident" && dData?.access?.Reports?.Residents?.Read){
            reportContent = <LegacyEventReports toFetch={"resident"}/>;
        }
        else if (paramVars.dType === "caregiver" && dData?.access?.Reports?.Caregiver?.Read){
            reportContent = <LegacyEventReports toFetch={"caregiver"}/>;
        }
        else if (paramVars.dType === "maintenance" && dData?.access?.Reports?.Maintenance?.Read){
            reportContent = <LegacyEventReports toFetch={"maintenance"}/>;
        }
        else if (paramVars.dType === "inspection" && dData?.access?.Reports?.Inspection?.Read){
            reportContent = <LegacyEventReports toFetch={"inspection"}/>;
        }
        else if (paramVars.dType === "checkin-transaction" && dData?.access?.Reports?.Caregiver?.Read){
            reportContent = <LegacyEventReports toFetch={"checkin-transaction"}/>;
        }
        else if (paramVars.dType === "checkin-history" && dData?.access?.Reports?.Caregiver?.Read){
            reportContent = <LegacyEventReports toFetch={"checkin-history"}/>;
        }
        else{
            reportContent = (
                <div>
                    <h3>Page not found. 404</h3>
                </div>
            );
        }
    }

    return (dData?.slauth === true && dData?.tKey != null && dData?.tKey !== "" && dData?.acnt != null && dData?.access?.Reports) ? (
        <>
            <Navigation />
            {dData?.myVersion !== dData?.currentVersion && dData?.cloud ? <HeaderBanner /> : <></>}
            {dData?.migrated && <SessionReload />}
            {reportContent}
        </>
    ) : <Navigate to={"/"}/>;
};

export default Reports;