import { Navigate } from 'react-router-dom';
import Navigation from "./Navigation";
import LegacyMainDash from "./LegacyMainDash";
import LegacyCorpDash from "./LegacyCorpDash";
import CorpDash from "./CorpDash";
import { ReactSession } from 'react-client-session';
import NullAccnt from "./NullAccnt";
import {AES, enc} from "crypto-js";
import { useParams } from "react-router"
import MainDash from "./MainDash";
import {compareRole, iAmHigherAuthority} from "../libs";
import HeaderBanner from "./component-assets/HeaderBanner";
import SessionReload from "./component-assets/SessionReload";

const Dashboard = (props) => {
    let dashContent, dData, paramVars, bData;
    paramVars = useParams();
    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    if (dData.migrated === true){
        if (paramVars.dType === "main" && dData?.access?.Dashboards?.Main?.Read){
            dashContent = <MainDash />;
        }
        else if (paramVars.dType === "corporate" && (dData.access?.Dashboards?.Corporate?.Read
                    || !compareRole("corporate-admin", dData)
                    || iAmHigherAuthority(80, dData?.role_weight))){
            dashContent = <CorpDash />;
        }
        else if (paramVars.dType === "archive"){
            dashContent = "";
        }
        else if (paramVars.dType === "null"){
            dashContent = <NullAccnt />;
        }
        else{
            dashContent = (
                <div>
                    <h3>Page not found. 404</h3>
                </div>
            );
        }
    }else{
        if (paramVars.dType === "main" && dData?.access?.Dashboards?.Main?.Read){
            dashContent = <LegacyMainDash />;
        }
        else if (paramVars.dType === "corporate" && (dData.access?.Dashboards?.Corporate?.Read || ["palcare", "palcare-admin"].includes(dData?.userrole))){
            dashContent = <LegacyCorpDash />;
        }
        else if (paramVars.dType === "archive"){
            dashContent = "";
        }
        else if (paramVars.dType === "null"){
            dashContent = <NullAccnt />;
        }
        else{
            dashContent = (
                <div>
                    <h3>Page not found. 404</h3>
                </div>
            );
        }
    }

    return (dData?.slauth === true && dData?.tKey != null && dData?.tKey !== "" && dData?.acnt != null && dData?.access?.Dashboards) ? (
        <>
            <Navigation />
            {dData?.myVersion !== dData?.currentVersion && dData?.cloud ? <HeaderBanner /> : <></>}
            {dData?.migrated && <SessionReload />}
            {dashContent}
        </>
    ) : <Navigate to={"/"} />;
};

export default Dashboard;