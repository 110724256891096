import TableHeaders from "../component-assets/TableHeaders";
import TableBodySimple from "../component-assets/TableBodySimple";

const ResidentEvents = (props) => {
    let summaries, summaryClass, detailedClass, headerData, tableBody;

    headerData = [
        {label: "Resident", key: "Resident"},
        {label: "Alarms", key: "TotalAlarms"},
        {label: "Alarms/Day", key: "AlarmsDay"},
        {label: "Response Time (Hr)", key: "RespTime"},
        {label: "Average Response Time (Min)", key: "AvgRespTime"},
        {label: "Service Time (Hr)", key: "TotalService"}
    ]

    if (props.repType === "Detailed"){
        summaryClass = "d-none";
        detailedClass = "";
    }else{
        summaryClass = "";
        detailedClass = "d-none";
    }

    summaries =(
        <>
            <td className={"ps-1"}>{props.reportData?.count}</td>
            <td className={"ps-1"}>{props.reportData?.TotalAlarms}</td>
            <td className={"ps-1"}>{props.reportData?.RespTime}</td>
            <td className={"ps-1"}>{props.reportData?.AvgRespTime}</td>
            <td className={"ps-1"}>{props.reportData?.TotalService}</td>
        </>
    )

    const tableHeaders = <TableHeaders checkbox={false} headerData={headerData} sortOrder={props?.sorter?.sortOrder}
                                       sortColumn={props?.sorter?.sortColumn} sorter={props?.sorter} setSorter={props?.setSorter}/>;
    if (props.tableRows !== undefined){
        tableBody = (<TableBodySimple tableData={props?.tableRows} checkbox={false} tableHeaders={headerData} />);
    }else{
        tableBody = "";
    }

    return (
        <>
            <div className={summaryClass}>
                <h6 className={"my-1 fs-3 px-4"}>
                    {props.repType}: <span className={"fs-3 d-inline ms-2"} style={{color: "#0081c7"}}>{props.start}</span> - <span className={"fs-3 d-inline"} style={{color: "#0081c7"}}>{props.end}</span>
                </h6>
                <div className={"px-4 pt-1 pb-4 my-2"}>
                    <div className={"text-center align-top w-100"}>
                        <table className={"table table-sm table-white table-hover"}>
                            <thead>
                                <tr>
                                    <th className={"table table-header w-auto py-1"}>
                                        Residents
                                    </th>
                                    <th className={"table table-header w-auto py-1"}>
                                        Alarms
                                    </th>
                                    <th className={"table table-header w-auto py-1"}>
                                        Response Time (Hr)
                                    </th>
                                    <th className={"table table-header w-auto py-1"}>
                                        Average Response Time (Min)
                                    </th>
                                    <th className={"table table-header w-auto py-1"}>
                                        Service Time (Hr)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {summaries}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className={detailedClass}>
                <h6 className={"my-1 fs-3 px-4"}>
                    {props.repType}: <span className={"fs-3 d-inline ms-2"} style={{color: "#0081c7"}}>{props.start}</span> - <span className={"fs-3 d-inline"} style={{color: "#0081c7"}}>{props.end}</span>
                </h6>
                <div className={"pt-1 pb-4 px-4 my-2"}>
                    <table className={"table table-sm table-white table-hover"}>
                        <thead>
                            {tableHeaders}
                        </thead>
                        <tbody>
                            {tableBody}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default ResidentEvents;