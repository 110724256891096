import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import {useEffect, useState} from "react";
import Button from "../component-assets/Button";
import ApiRequester from "../ApiRequester";
import {formSuccess, handleSubmitActions, formFail, formCheck} from "../../libs";
import alis_logo from "../../img/alis_logo.png"

const Alis = (props) => {

    let dData;
    if (ReactSession.get("PAL") != null) {
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const originData = props?.originData?.length > 0 ? props.originData[0] : {}

    const [isActive, setIsActive] = useState(originData?.active ? originData.active : false);

    useEffect(() => {
        formCheck("mainForm", "editSubmit");
    }, [isActive])

    function performSave(){
        let payload, username, pw, externalId;
        handleSubmitActions("editSubmit", "loadEditSubmit");
        username = document.getElementById("username").value;
        pw = document.getElementById("password").value;
        externalId = document.getElementById("externalid").value;
        payload = [{credentials: username, auth: pw, external_id: parseInt(externalId), active: isActive,
                    campus_id: parseInt(dData.acnt), alis_id: originData?.alis_id ? parseInt(originData.alis_id) : 0}]
        ApiRequester({reqEndpoint: "mytransaction", TransType: "edit_alis", Payload: payload,
                            CampusId: dData.acnt, User: dData.user, ClientId: dData.sessionId}).then(data => {
            if (data && data[0]){
                props?.setCount(0);
                formSuccess("Alis setting saved", "editSubmit", "loadEditSubmit");
            }
            else{
                formFail(`Error during save.`, "editSubmit", "loadEditSubmit");
            }
        });
    }


    return (
        <>
            <div className={"card"} style={{maxWidth: "700px", minWidth: "515px"}}>
                <div className={"card-header"}>
                    <div>
                        <h4 className={"card-header-title d-inline-block"}>Alis Settings</h4>
                        <div className={"form-check form-switch ms-4 d-inline-block"}>
                            <input className={"form-check-input"} type={"checkbox"} role={"switch"} id={"alis-on"}
                                   defaultChecked={isActive} onChange={(e) => {
                                document.getElementById("editSubmit").classList.remove("d-none");
                                setIsActive(e.target.checked);
                                if (e.target.checked){
                                    e.target.nextElementSibling.children[0].classList.remove("text-danger");
                                    e.target.nextElementSibling.children[0].classList.remove("fw-bold");
                                    e.target.nextElementSibling.children[1].classList.add("fw-bold");
                                    e.target.nextElementSibling.children[1].classList.add("text-success-bright");
                                }else{
                                    e.target.nextElementSibling.children[0].classList.add("text-danger");
                                    e.target.nextElementSibling.children[0].classList.add("fw-bold");
                                    e.target.nextElementSibling.children[1].classList.remove("fw-bold");
                                    e.target.nextElementSibling.children[1].classList.remove("text-success-bright");
                                }
                            }} />
                            <label className={"form-check-label"} htmlFor={"tels-on"}>
                                <span id={"in-label-off"} className={!isActive ? "text-danger fw-bold" : ""}>Off</span>/<span id={"in-label-on"} className={isActive ? "text-success-bright fw-bold" : ""}>On</span>
                            </label>
                        </div>
                        <a href={"https://www.medtelligent.com/"} target={"_blank"}>
                            <img className={"ms-4"} width={"120px"} height={"36px"} src={alis_logo} alt={"Alis Logo"} />
                        </a>
                    </div>
                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadEditSubmit"}/>
                    <Button id={"editSubmit"} style={{width: "75px"}} text={"Save"} class={"btn btn-primary d-none"} onClick={() => performSave()} />
                </div>
                <div className={"card-body"}>
                    <div className={isActive ? "list-group list-group-flush my-n3" : "list-group list-group-flush my-n3 d-none"}>
                        <form id={"mainForm"} className={"was-validated"}>
                            <div className={"form-floating mb-3 mt-3"} style={{maxWidth: "350px"}}>
                                <input type={"text"} className={"form-control"} id={"externalid"} required={isActive} pattern={"^[0-9]+$"}
                                        onKeyUp={() => formCheck("mainForm", "editSubmit")} />
                                <label htmlFor={"externalid"}>Community ID</label>
                                <div className={"invalid-feedback"}>
                                    Number based community needed for ALIS integration
                                </div>
                            </div>
                            <div className={"form-floating mb-3"} style={{maxWidth: "350px"}}>
                                <input type={"text"} className={"form-control"} id={"username"} required={isActive}
                                        onKeyUp={() => formCheck("mainForm", "editSubmit")} />
                                <label htmlFor={"username"}>Username</label>
                                <div className={"invalid-feedback"}>
                                    Username needed for ALIS integration
                                </div>
                            </div>
                            <div className={"form-floating mb-3"} style={{maxWidth: "350px"}}>
                                <input type={"text"} className={"form-control"} id={"password"} required={isActive}
                                        onKeyUp={() => formCheck("mainForm", "editSubmit")} />
                                <label htmlFor={"password"}>Password</label>
                                <div className={"invalid-feedback"}>
                                    Passcode needed for ALIS integration
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Alis;