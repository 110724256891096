import { ReactSession } from 'react-client-session';
import {Link, Navigate} from "react-router-dom";
import {AES, enc} from "crypto-js";
import Header from "./Header";
import Button from "./component-assets/Button";
import {Suspense, useEffect, useState} from "react";
import { toast, ToastContainer } from "react-toastify";
import {v4 as uuidv4} from "uuid"
import {
    campusToGuid, durationToNow, getLocalTimeWithOffset, getLuxonUTCTime, convertIsoToLocalTime,
    getUTCTimestamp, handleSubmitActions, sortFunc, userToGuid, formCheck, convertHHMMtoSec
} from "../libs";
import ApiRequester from "./ApiRequester";
import Footer from "./Footer";
import CampusDropDown from "./component-assets/CampusDropDown";
import TableHeaders from "./component-assets/TableHeaders";

function DemoPanel(props) {
    let dData, bData, headerData;

    headerData = [
        {label: "Event Date / Time", key: "EventDateTime"},
        {label: "Duration", key: "Elapsed"},
        {label: "Alert Category", key: "AlertType"},
        {label: "Resident Name", key: "ResidentsParsed"},
        {label: "Area / Device", key: "DeviceUse"},
        {label: "Building / Location", key: "LocationName"},
        {label: "Status", key: "Status"},
        {label: "Action", key: "Action"}
    ];

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const [campusId, setCampusId] = useState(dData.acnt);
    const [server, setServer] = useState(dData.campusData[dData.acnt]?.Servers[0]?.mac_addr);
    const [serverOptions, setServerOptions] = useState([]);
    const [linkType, setLinkType] = useState("");
    const [deviceOptions, setDeviceOptions] = useState([]);
    const [entityOptions, setEntityOptions] = useState([]);
    const [residentData, setResidentData] = useState([]);
    const [areaData, setAreaData] = useState([]);
    const [simulateData, setSimulateData] = useState([]);
    const [eventCatId, setEventCatId] = useState(1);
    const [eventCatData, setEventCatData] = useState([]);
    const [eventCatOptions, setEventCatOptions] = useState([]);
    const [count, setCount] = useState(0);
    const [alertCount, setAlertCount] = useState(0);
    const [gData, setData] = useState({
        alertData: [],
        tableData: [],
        dataLength: 0
    });
    const [tableRows, setTableRows] = useState([]);
    const [sorter, setSorter] = useState({
        sortColumn: "EventDateTime",
        sortOrder: "asc"
    });
    const [resSorter, setResSorter] = useState({
        sortColumn: "resident_first_name",
        sortOrder: "desc"
    });
    const [areaSorter, setAreaSorter] = useState({
        sortColumn: "area_name",
        sortOrder: "desc"
    });

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId,
                                procedure: "sp_dev_getallresidentsanddevicesbycampusid", reqType: "stored"}).then(data => {
                setResidentData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId,
                                procedure: "sp_dev_getallareadevicesbycampusid", reqType: "stored"}).then(data3 => {
                setAreaData(data3);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "att_event_categories",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setEventCatData(data);
            });
            setCount(count => 1200);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        let interval, tmp, ie;
        interval = null;
        interval = setInterval(() => {
             setAlertCount(alertCount => alertCount - 1);
        }, 1000);
        if (alertCount <= 0){
            ApiRequester({reqEndpoint: "get-alerts", CampusId: campusToGuid(dData, campusId),
                                ToGet: "emergency", UserId: userToGuid(dData, dData.user), hybrid: dData?.hybrid,
                                legacy: dData?.legacy, new: dData?.new, Migrated: dData.migrated}).then(data => {
                if (data.length > 0){
                    tmp = data;
                    for (ie=0; ie < tmp.length; ie++){
                        tmp[ie].ElapsedFormatted = durationToNow(tmp[ie].EventDateTime);
                    }
                    setData(prevState => {
                        return {...prevState, tableData: tmp}});
                }else{
                    setData(prevState => {
                        return {...prevState, tableData: []}});
                }
            })
            setAlertCount(alertCount => 9);
        }
        else if (gData.tableData.length > 0 && gData.tableData[0]){
            tmp = gData.tableData;
            for (ie=0; ie < tmp.length; ie++){
                tmp[ie].ElapsedFormatted = durationToNow(tmp[ie].EventDateTime);
            }
            setData(prevState => {
                return {...prevState, tableData: tmp}});
        }
        return () => clearInterval(interval);
    }, [alertCount]);

    useEffect(() => {
        let clearSec, index = 0;
        let tmpTD;
        let tmp = [];
        if (gData.tableData?.length > 0) {
            gData.tableData.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
        }
        for (index = 0; index < gData.tableData?.length; index++){
            let curIndex = index.toString();
            if (gData.tableData[curIndex]?.Status === "Waiting"){
                tmpTD = (<td className={"text-warning text-center"}>{gData.tableData[curIndex]?.Status}</td>);
            }
            else if(gData.tableData[curIndex]?.Status === "Overdue"){
                tmpTD = (<td className={"text-danger text-center"}>{gData.tableData[curIndex]?.Status}</td>);
            }
            else{
                tmpTD = (<td className={"text-primary text-center"}>{gData.tableData[curIndex]?.Status}</td>);
            }
            if (!gData.tableData[curIndex]?.AckDateTime){
                clearSec = (<td className={"text-center"}>
                                <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={""} id={"loadEditSubmit"+curIndex.toString()}/>
                                <Button text={"Clear"} type={"button"} class={"btn btn-primary ms-3"} id={"editSubmit"+curIndex.toString()}
                                        onClick={() => clearAlarm(gData.tableData[curIndex]?.AlarmId, gData.tableData[curIndex]?.DeviceUse,
                                                                    gData.tableData[curIndex]?.MacAddr, gData.tableData[curIndex]?.EventDateTime,
                                                                    gData.tableData[curIndex]?.AlertType, curIndex.toString())} />
                            </td>)
            }else{
                clearSec = (<td className={"text-center"}>
                    </td>)
            }
            tmp.push(
                <tr key={"tableRow"+index} className={["fall", "assist"].includes(gData.tableData[curIndex]?.AlertType?.toLowerCase()) ? "importantTR" : ""}>
                    <td className={"text-center"} style={{whiteSpace: "pre"}}>
                        <p className={"mb-0 fs-6"}>{gData.tableData[curIndex]?.LocalEventDate}</p>
                        <br/>
                        <p className={"mb-0"}>{gData.tableData[curIndex]?.LocalEventTime}</p>
                    </td>
                    <td className={"text-center"} style={{whiteSpace: "pre-line"}}>
                        {gData.tableData[curIndex]?.ElapsedFormatted}
                    </td>
                    <td className={"text-center"} style={{whiteSpace: "pre-line"}}>
                        {gData.tableData[curIndex]?.AlertType}
                    </td>
                    <td style={{whiteSpace: "pre-line"}}>
                        {gData.tableData[curIndex]?.ResidentsObject.map((resRec, i) => {
                            if (gData.tableData[curIndex]?.Version === "4.0"){
                                return (
                                    <>
                                        {resRec?.resident_id
                                            ? <Link to={`/profiles/resident/${resRec?.resident_id}`}>
                                                {resRec?.firstName} {resRec?.lastName}
                                              </Link>
                                            : <p>{resRec?.firstName} {resRec?.lastName}</p>
                                        }
                                        {i+1 !== gData.tableData[curIndex]?.ResidentsObject.length ? <br/> : <></>}
                                    </>
                                )
                            }else{
                                return (
                                    <>
                                        {resRec?.firstName} {resRec?.lastName}
                                        {i+1 !== gData.tableData[curIndex]?.ResidentsObject.length ? <br/> : <></>}
                                    </>
                                )
                            }
                        })}
                    </td>
                    <td className={"text-center"}>
                        <p className={"mb-0 fs-6"}>{gData.tableData[curIndex]?.LocationArea}</p>
                        <br/>
                        <p className={"mb-0"}>{gData.tableData[curIndex]?.DeviceUse}</p>
                    </td>
                    <td className={"text-center"}>
                        <p className={"mb-0 fs-6"}>{gData.tableData[curIndex]?.LocationBuilding}</p>
                        <br/>
                        {gData.tableData[curIndex]?.Version === "4.0" && gData.tableData[curIndex]?.LocationId
                            ?
                            <Link className={"mb-0"} to={`/profiles/area/${gData.tableData[curIndex]?.LocationId}`}>
                                {gData.tableData[curIndex]?.LocationName}
                            </Link>
                            :
                            <p className={"mb-0"}>{gData.tableData[curIndex]?.LocationName}</p>
                        }
                    </td>
                    {tmpTD}
                    {clearSec}
                </tr>
            );
        }
        setTableRows(tmp);
    }, [gData.tableData, sorter, alertCount]);

    useEffect(() => {
        let tmp = [], device, submitBtn, simulateBtn;
        for (let i=0; i < dData.campusData[campusId].Servers.length; i++){
            if (dData.campusData[campusId]?.Servers[i]?.mac_addr){
                tmp.push(
                    <option key={"server-option"+i} value={dData.campusData[campusId].Servers[i].mac_addr}>
                        {dData.campusData[campusId].Servers[i].description} ({dData.campusData[campusId].Servers[i].mac_addr})
                    </option>
                );
            }
        }
        if (tmp.length <= 0){
            setServer(() => "");
            tmp.push(
                <option key={"server-option-null"} value={""}>No available servers</option>
            )
        }
        setServerOptions(tmp);
        setEntityOptions(() => []);
        setDeviceOptions(() => []);
        document.getElementById("datatype").value = "";
        document.getElementById("performRounds").classList.add("d-none");
        document.getElementById("performCheckin").classList.add("d-none");
        device = document.getElementById("device");
        submitBtn = document.getElementById("performSubmit");
        simulateBtn = document.getElementById("performSimulate");
        submitBtn.disabled = !(device && device.value !== "");
        simulateBtn.disabled = !(device && device.value !== "");
    }, [campusId])

    useEffect(() => {
        if (simulateData && simulateData?.length > 0){
            document.getElementById("simulate-table-div").classList.remove("d-none");
        }else{
            document.getElementById("simulate-table-div").classList.add("d-none");
        }
    }, [simulateData]);

    useEffect(() => {
        let tmp = [];
        if (eventCatData?.length > 0){
            eventCatData.sort((a, b) => sortFunc(a, b, "event_category_name", "desc"));
            for (let i=0; i < eventCatData.length; i++){
                tmp.push(
                    <option key={`escalation-type-option${i}`} value={eventCatData[i]?.event_category_id}>
                        {eventCatData[i]?.event_category_name}
                    </option>);
            }
            setEventCatOptions(tmp);
        }
    }, [eventCatData]);

    function buildEntityOne(){
        let tmpA = [], tmpB = [], dataType
        dataType = document.getElementById("datatype").value;
        setDeviceOptions(() => []);
        if (dataType === "resident"){
            setLinkType(() => "Resident");
            residentData.sort((a, b) => sortFunc(a, b, resSorter.sortColumn, resSorter.sortOrder));
            for (let i = 0; i < residentData.length; i++){
                if (residentData[i]?.devices?.length > 0){
                    tmpA.push(<option key={"resident-"+i+"-option"} value={i.toString()}>{residentData[i].resident_first_name} {residentData[i].resident_last_name}</option>)
                }else{
                    tmpB.push(<option key={"resident-"+i+"-option"} value={i.toString()} disabled={true}>{residentData[i].resident_first_name} {residentData[i].resident_last_name} (NO DEVICES)</option>)
                }
            }
        }else{
            setLinkType(() => "Location")
            areaData.sort((a, b) => sortFunc(a, b, areaSorter.sortColumn, areaSorter.sortOrder));
            for (let i = 0; i < areaData.length; i++){
                if (areaData[i]?.devices?.length > 0){
                    tmpA.push(<option key={"area-"+i+"-option"} value={i.toString()}>{areaData[i].area_name}</option>)
                }else{
                    tmpB.push(<option key={"area-"+i+"-option"} value={i.toString()} disabled={true}>{areaData[i].area_name} (NO DEVICES)</option>)
                }
            }
        }
        tmpA = tmpA.concat(tmpB)
        setEntityOptions(() => tmpA)
    }

    function buildEntityTwo(){
        let tmp = [], dataType, entityIndex;
        dataType = document.getElementById("datatype").value;
        entityIndex = document.getElementById("dataindex").value;
        if (dataType === "resident"){
            residentData[parseInt(entityIndex)]?.devices.sort((a, b) => sortFunc(a, b, "description", "desc"));
            for (let i = 0; i < residentData[parseInt(entityIndex)]?.devices?.length; i++){
                tmp.push(<option key={"resident-"+i+"-option"} value={i.toString()}>{residentData[parseInt(entityIndex)].devices[i]?.description} ({residentData[parseInt(entityIndex)].devices[i]?.manufacture_uuid})</option>)
            }
        }else{
            areaData[parseInt(entityIndex)]?.devices.sort((a, b) => sortFunc(a, b, "description", "desc"));
            for (let i = 0; i < areaData[parseInt(entityIndex)]?.devices?.length; i++){
                tmp.push(<option key={"area-"+i+"-option"} value={i.toString()}>{areaData[parseInt(entityIndex)].devices[i]?.description} ({areaData[parseInt(entityIndex)].devices[i]?.manufacture_uuid})</option>)
            }
        }
        setDeviceOptions(() => tmp)
    }

    function deviceSet(deviceName){
        let device = document.getElementById("device");
        let submitBtn = document.getElementById("performSubmit");
        let simulateBtn = document.getElementById("performSimulate");
        if (device && deviceName && deviceName.toLowerCase().search("aio") !== -1){
            document.getElementById("performRounds").classList.remove("d-none");
            document.getElementById("performCheckin").classList.remove("d-none");
        }else{
            document.getElementById("performRounds").classList.add("d-none");
            document.getElementById("performCheckin").classList.add("d-none");
        }
        submitBtn.disabled = !(device && device.value !== "");
        simulateBtn.disabled = !(device && device.value !== "");
    }

    // Alert Sending Functions

    function alertSubmit(){
        let payload, device, dataType, entityIndex, deviceIndex;
        handleSubmitActions("performSubmit", "loadPerformSubmit");
        dataType = document.getElementById("datatype").value;
        entityIndex = document.getElementById("dataindex").value;
        deviceIndex = document.getElementById("device").value;
        if (dataType === "resident"){
            let resident = residentData[parseInt(entityIndex)];
            device = residentData[parseInt(entityIndex)]?.devices[parseInt(deviceIndex)];
            payload = getV2(dataType, resident, "", device, server, "Emergency");
        }else{
            let area = areaData[parseInt(entityIndex)];
            device = areaData[parseInt(entityIndex)]?.devices[parseInt(deviceIndex)];
            payload = getV2(dataType, "", area, device, server, "Emergency");
        }
        ApiRequester({reqEndpoint: "v2Send", Method: "POST", Payload: payload}).then(data => {
            if (data[0]) {
                toast.success("Alert created for " + device?.description);
                setAlertCount(() => 0);
            } else {
                toast.error("Failed to create alarm for " + device?.description);
            }
            handleSubmitActions("loadPerformSubmit", "performSubmit");
        });
    }

    function roundsSubmit(){
        let entityIndex, deviceIndex, area, device, payload, v2Data;
        handleSubmitActions("performRounds", "loadPerformRounds");
        entityIndex = document.getElementById("dataindex").value;
        deviceIndex = document.getElementById("device").value;
        area = areaData[parseInt(entityIndex)];
        device = areaData[parseInt(entityIndex)]?.devices[parseInt(deviceIndex)];
        v2Data = getV2("area", "", area, device, server, "");
        payload = [{device_id: device.device_id, resident_id: v2Data?.residents[0]?.id, area_id: v2Data?.location?.id, campus_id: campusId,
                    local_create_time: getLocalTimeWithOffset(dData?.campusData[campusId.toString()]?.tz_offset),
                    create_time: getLuxonUTCTime()}];
        ApiRequester({reqEndpoint: "mysave", Tbl: "core_rounds", Payload: payload, urlType: "PUT", User: dData.user,
                            PKey: "rounds_id", Condition: "primary", CampusId: campusId, ClientId: dData.sessionId}).then(data => {
            if (data[0]) {
                toast.success("Rounds triggered with " + device?.description + " in room " + area?.area_name);
            } else {
                toast.error("Failed to create rounds with " + device?.description + " in room " + area?.area_name);
            }
            handleSubmitActions("loadPerformRounds", "performRounds");
        });
    }

    function checkinSubmit(){
        let entityIndex, deviceIndex, area, device, payload, v2Data;
        handleSubmitActions("performCheckin", "loadPerformCheckin");
        entityIndex = document.getElementById("dataindex").value;
        deviceIndex = document.getElementById("device").value;
        area = areaData[parseInt(entityIndex)];
        device = areaData[parseInt(entityIndex)]?.devices[parseInt(deviceIndex)];
        v2Data = getV2("area", "", area, device, server, "");
        payload = [{device_id: device.device_id, resident_id: v2Data?.residents[0]?.id, area_id: v2Data?.location?.id,
                    campus_id: campusId, local_create_time: getLocalTimeWithOffset(dData?.campusData[campusId.toString()]?.tz_offset),
                    create_time: getLuxonUTCTime()}];
        ApiRequester({reqEndpoint: "mysave", Tbl: "core_checkin", Payload: payload, urlType: "PUT", User: dData.user,
                            PKey: "checkin_id", Condition: "primary", CampusId: campusId, ClientId: dData.sessionId}).then(data => {
            if (data[0]) {
                toast.success(v2Data?.residents[0]?.firstName + " " + v2Data?.residents[0]?.lastName + " Checked in.");
            } else {
                toast.error("Failed to check-in " + v2Data?.residents[0]?.firstName + " " + v2Data?.residents[0]?.lastName);
            }
            handleSubmitActions("loadPerformCheckin", "performCheckin");
        });
    }

    function getV2(datatype, resident, area, device, serverId, eType){
        let payload, location, locationId, timestamp, residents;

        timestamp = getUTCTimestamp();
        if (datatype === "resident"){
            residents = [{id: resident?.resident_id, firstName: resident?.resident_first_name, lastName: resident?.resident_last_name}];
            locationId = resident?.area_id;
            location = resident?.area_name;
        }else{
            locationId = area?.area_id;
            location = area?.area_name;
            residents = []
            for (let i = 0; i < residentData.length; i++){
                if (residentData[i]?.area_id === area?.area_id){
                    residents.push({id: residentData[i]?.resident_id, firstName: residentData[i]?.resident_first_name, lastName: residentData[i]?.resident_last_name})
                }
            }
        }

        payload = {id: uuidv4(), type: eType, stage: "CREATE",
            text: "Alarm "+device?.description+" in "+ location, capCodes: ["0220001"],
            location: {id: locationId, name: location, building: "Main", uid: ""},
            device: {id: device?.device_id, name: device?.description, type: "", area: "", presses: 1, uid: device?.manufacture_uuid},
            residents: residents, near: [], escalation: [0], capcodes: "0220001", vendor: "PalatiumCare",
            version: "4.0", eVersion: "2.51", ttl: 0, iTime: 0, eventDateTime: timestamp, ackDateTime: "",
            timeZone: dData?.campusData[campusId]?.tz_offset, actions: "", messageNumber: "", mac: serverId};
        return payload;
    }

    function clearAlarm(alarmId, deviceName, macAddr, eventDateTime, alertType, curIndex){
        handleSubmitActions("editSubmit"+curIndex.toString(), "loadEditSubmit"+curIndex.toString());
        let payload = {id: alarmId, eventDateTime: eventDateTime, mac: macAddr, ackDateTime: getUTCTimestamp(),
            stage: "DONE", type: alertType, CampusId: campusToGuid(dData, campusId)}
        ApiRequester({reqEndpoint: "v2Send", Method: "PATCH", Payload: payload}).then(data => {
            if (data[0]){
                document.getElementById("loadEditSubmit"+curIndex)?.classList?.add("d-none");
                toast.success(`${deviceName} cleared.`);
                setAlertCount(() => 0);
            }
            else{
                handleSubmitActions("loadEditSubmit"+curIndex.toString(), "editSubmit"+curIndex.toString());
                toast.error(`Failed to clear ${deviceName}`);
            }
        });
    }

    function simulateSubmit(){
        let dataType, entityIndex, deviceIndex, device, durationtime, timeofday, timeItem, durationItem;
        handleSubmitActions("performSimulate", "loadPerformSimulate");
        dataType = document.getElementById("datatype").value;
        entityIndex = document.getElementById("dataindex").value;
        deviceIndex = document.getElementById("device").value;
        durationItem = document.getElementById("sim-end")?.value;
        durationtime = durationItem != undefined ? durationItem : 30
        timeItem = document.getElementById("sim-time")?.value;
        timeofday = timeItem != undefined ? convertHHMMtoSec(timeItem) : undefined
        if (dataType === "resident"){
            device = residentData[parseInt(entityIndex)]?.devices[parseInt(deviceIndex)];
        }else{
            device = areaData[parseInt(entityIndex)]?.devices[parseInt(deviceIndex)];
        }
        ApiRequester({reqEndpoint: "simulate", campus_id: campusId, device_id: device?.device_id,
                            timeofday: timeofday, runtime: parseInt(durationtime),
                            event_category_id: parseInt(eventCatId)}).then(data => {
            setSimulateData(data ? data : []);
            handleSubmitActions("loadPerformSimulate", "performSimulate");
        });
    }

    const tableHeaders = <TableHeaders checkbox={false} headerData={headerData} sortOrder={sorter.sortOrder}
                                       sortColumn={sorter.sortColumn} sorter={sorter} setSorter={setSorter} />

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div>
            </div>
            <CampusDropDown campusId={campusId} setCampusId={(item) => setCampusId(item)} setCount={(item) => {setCount(item); setAlertCount(item)}} pageAction={() => {}}/>
        </div>
    )
    return (dData?.slauth === true && dData?.tKey != null && dData?.tKey !== "" && dData?.acnt != null) ? (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Demo"} Title={"Demo Panel"} content={headerContent}/>
                <div className={"container-fluid"}>
                    <div className={"row"}>
                        <div className={"d-flex justify-content-start"}>
                            <div className={"mb-3 w-50"} style={{minWidth: "300px", maxWidth: "500px"}}>
                                <div className={"form-floating mb-3"}>
                                    <select className={"form-select"} required={true} id={"serverId"} value={server}
                                            onChange={(e) => {
                                                setServer(e.target.value)
                                            }}>
                                        {serverOptions}
                                    </select>
                                    <label htmlFor={"serverId"}>Server</label>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <select className={"form-select"} required={true} defaultValue={""} id={"datatype"}
                                            onChange={(e) => {
                                                buildEntityOne(e.target.value);
                                                document.getElementById("dataindex").value = "";
                                                document.getElementById("device").value = "";
                                                deviceSet("");
                                            }}>
                                        <option value={""} disabled={true}></option>
                                        <option value={"area"}>Area</option>
                                        <option value={"resident"}>Resident</option>
                                    </select>
                                    <label htmlFor={"datatype"}>Device Type</label>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <select className={"form-select"} required={true} defaultValue={""} id={"dataindex"}
                                            onChange={(e) => {
                                                buildEntityTwo(e.target.value);
                                                document.getElementById("device").value = "";
                                                deviceSet("");
                                            }}>
                                        <option value={""} disabled={true}></option>
                                        {entityOptions}
                                    </select>
                                    <label htmlFor={"dataindex"}>{linkType}</label>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <select className={"form-select"} required={true} id={"device"} defaultValue={""}
                                            onChange={(e) => {
                                                deviceSet(e.target.innerText)
                                            }}>
                                        <option value={""} disabled={true}></option>
                                        {deviceOptions}
                                    </select>
                                    <label htmlFor={"device"}>Device</label>
                                </div>
                                <div className={"mx-0"}>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Creating"} id={"loadPerformSubmit"}/>
                                    <button type={"button"} className={"btn btn-danger ms-3"} id={"performSubmit"}
                                            onClick={() => alertSubmit()}>Create Alarm
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Creating"} id={"loadPerformRounds"}/>
                                    <button type={"button"} className={"btn btn-primary d-none ms-3"}
                                            id={"performRounds"}
                                            onClick={() => roundsSubmit()}>Rounds
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Creating"} id={"loadPerformCheckin"}/>
                                    <button type={"button"} className={"btn btn-primary d-none ms-3"}
                                            id={"performCheckin"}
                                            onClick={() => checkinSubmit()}>Check-In
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Calculating"} id={"loadPerformSimulate"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"performSimulate"}
                                            onClick={() => {
                                                setSimulateData([]);
                                                simulateSubmit();
                                            }}>Simulate Careflow
                                    </button>
                                </div>
                            </div>
                            <div className={"mb-3 ps-4 w-50"} style={{maxWidth: "650px"}}>
                                <div className={"text-center mb-2"}>
                                    <h4>Simulator Panel</h4>
                                </div>
                                <div className={"d-flex justify-content-start"}>
                                    <div className={"form-floating mb-3"} style={{width: "40%"}}>
                                        <select className={"form-select"} required={true} id={"event_category_id"}
                                                value={eventCatId}
                                                onChange={(e) => {
                                                    setEventCatId(e.target.value)
                                                }}>
                                            {eventCatOptions}
                                        </select>
                                        <label className={"fs-5"} htmlFor={"event_category_id"}>Event Category</label>
                                    </div>
                                    <div className={"form-floating mb-3 mx-3"} style={{width: "25%"}}>
                                        <input type={"number"} className={"form-control"} step={1} min={1} max={60}
                                               defaultValue={30} required={true} id={"sim-end"} />
                                        <label className={"fs-5"} htmlFor={"sim-end"}>Duration (Min)</label>
                                    </div>
                                    <div className={"form-floating mb-3"} style={{width: "25%"}}>
                                        <input type={"time"} className={"form-control"}
                                               onKeyUp={() => formCheck("addForm", "addSubmit")}
                                               id={"sim-time"}/>
                                        <label className={"fs-5"} htmlFor={"sim-time"}>Time of Day</label>
                                    </div>
                                </div>
                                <div className={"py-2 px-3"} style={{border: "1px inset lightgrey", overflowY: "auto",
                                                                maxHeight: "350px", minHeight: "225px",
                                                                backgroundColor: "#FFFFFF", borderRadius: "7px"}}>
                                    <table id={"simulate-table-div"} className={"d-none"}>
                                        <thead>
                                        <tr style={{borderBottom: "2px solid #d2ddec"}}>
                                            <th>Event Time</th>
                                            <th>Event Action</th>
                                            <th>To</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {simulateData.map((simRec) => {
                                            return (
                                                <tr style={{borderBottom: "1px solid #d2ddec"}}>
                                                    <td className={"align-top"} style={{minWidth: "100px"}}>{convertIsoToLocalTime(simRec?.timestamp)}</td>
                                                    <td className={"align-top"} style={{minWidth: "175px"}}>{simRec?.handler}</td>
                                                    {typeof simRec?.to === "object"
                                                        ? <td className={"align-top"} style={{minWidth: "100px"}}>{simRec?.to.map((item, i) => {
                                                            if (i === 0) {
                                                                return item
                                                            } else {
                                                                return (<><br/>{item}</>)
                                                            }
                                                        })}</td>
                                                        :   <td className={"align-top"} style={{minWidth: "100px"}}>{simRec?.to}</td>
                                                    }
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"row"}>
                        <table className={"table table-sm table-white table-hover"}>
                            <thead className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString() ? "sticky-table-headX" : "sticky-table-head"}>
                            {tableHeaders}
                            </thead>
                            <tbody>
                            {tableRows}
                            </tbody>
                        </table>
                    </div>
                    <div className={"footer-spacer"}>
                    </div>
                </div>
                <Footer/>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true}
                                autoClose={10000}/>
            </Suspense>
        </>
    ) : <Navigate to={"/"}/>;
};

export default DemoPanel;