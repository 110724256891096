import Button from "./Button";

const HeaderBanner = (props) => {

    function scheduleSubmit(){

    }

    return (
        <div className={"main-content header-banner"}>
            <div className={"d-flex justify-content-evenly"}>
                <div className={"text-center p-3 m-0"}>
                    <h3 className={"pt-2 mt-1"} style={{color: "white"}}>A new version of the palcare system is available. Please schedule a time to update your local on site server.</h3>
                </div>
                <div className={"text-center p-3 m-0"}>
                    <Button text={"Schedule Update"} class={"btn btn-secondary me-3 mt-1"} style={{width: "150px"}} />
                    <Button text={"Preview Features"} class={"btn btn-secondary me-3 mt-1"} style={{width: "150px"}} />
                </div>
            </div>

        </div>
    );
};

export default HeaderBanner;