import Button from "./component-assets/Button";
import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import {reloadSessionCorp} from "../libs";
import logo from "../img/PCLC.svg";
import MaterialIcon from "material-icons-react";
import {Link} from "react-router-dom";



const Header = (props) => {

    let buttonItem, content, msgItem, bData, dData;

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    if (props.Refresh === true){
        buttonItem = (<div>
            <Button text={"Refresh"} type={"button"} class={"btn px-4 btn-toolbar btn-primary me-4"} onClick={() => props.setCount(0)}/>
        </div>);
    }
    else{
        buttonItem = (<div className={{"display": "none"}}></div>);
    }
    if (props.msg !== ""){
        msgItem = (<div style={{"display": "inline-block", "marginRight": "10px"}}>{props.msg}</div>);
    }
    else{
        msgItem = (<div className={{"display": "none"}}></div>);
    }


    if (props?.content){
        content = props.content
    }else{
        content = (
            <div className={"col-auto"} style={{"display": "flex"}}>
                {buttonItem}
                {msgItem}
                <div style={{"display": "inline-block"}}>{props.element}</div>
            </div>
        )
    }

    return (
        <>
            <div className={"header"}>
                {dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString()
                    ?   <div style={{height: "55px", backgroundColor: "#4ad0fb", boxShadow: "0 1px 4px"}} className={"text-center pt-3"}>
                            <Link to={`/profiles/campus/${dData.acnt}`} >
                                <MaterialIcon size={"small"} className={"material-icons align-middle icon-hover-white"}
                                              icon={"business"} title={"Campus profile"} />
                            </Link>
                            <h4 className={"d-inline-block mb-0 mx-3"}>Currently viewing <span className={"fw-bold"}>{dData.campusData[dData.acnt]?.name}</span></h4>
                            <Button text={"Stop Impersonating"} class={"d-inline-block py-1 ms-3 btn btn-secondary"}
                                    onClick={() => reloadSessionCorp(dData.myCorp, -99, dData.cognito, dData.myAcnt)} />
                        </div>
                    : <></>
                }
                <div className={"container-fluid"}>
                    <div className={"header-body"}>
                        <div className={"d-flex justify-content-between"}>
                            <div>
                                <h6 className={"header-pretitle"}>{props.preTitle}</h6>
                                <h1 className={"header-title mt-3"}>{props.Title}</h1>
                            </div>
                            {props?.fullscreen && <div style={{"width": "15rem"}}>
                                <img src={logo} alt={"Palcare Logo Dark Varient"} />
                            </div>
                            }
                            {content}
                        </div>
                        {props?.Navigation ? props.Navigation : ""}
                    </div>
                </div>
            </div>
            <div className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString() ? "header-spacerX" : "header-spacer"}></div>
        </>
    )
};

Header.defaultProps = {
    preTitle: "",
    Title: "",
    msg: "",
    element: <div></div>
};

export default Header;