import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import Header from "./Header";
import {useEffect, useState} from "react";
import ApiRequester from "./ApiRequester";
import Button from "./component-assets/Button";
import Footer from "./Footer";
import CampusDropDown from "./component-assets/CampusDropDown";
import TemperatureSettings from "./environment-settings-templates/TemperatureSettings";
import HumiditySettings from "./environment-settings-templates/HumiditySettings";
import BatterySettings from "./environment-settings-templates/BatterySettings";

const EnvironmentSettings = (props) => {

    let dData;
    if (ReactSession.get("PAL") != null) {
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    // Main Needed data for page
    const [loadState, setLoadState] = useState("temp");
    const [environContent, setEnvironContent] = useState(<></>);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [count, setCount] = useState(1);

    // Data collected for sub components
    const [tempData, setTempData] = useState([]);
    const [humidityData, setHumidityData] = useState([]);
    const [batteryData, setBatteryData] = useState([]);
    const [tempDevData, setTempDevData] = useState([]);
    const [humidityDevData, setHumidityDevData] = useState(false);

    // Loading state flags.
    const [allLoaded, setAllLoaded] = useState(false);
    const [tempLoaded, setTempLoaded] = useState(false);
    const [humidityLoaded, setHumidityLoaded] = useState(false);
    const [batteryLoaded, setBatteryLoaded] = useState(false);
    const [tempDevLoaded, setTempDevLoaded] = useState(false);
    const [humidityDevLoaded, setHumidityDevLoaded] = useState(false);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0) {
            ApiRequester({reqEndpoint: "myfetch", payload: {device_filter: "", campus_id: campusId,
                                device_category_filter: "1,17", device_type_filter: ""},
                                procedure: "sp_dev_getInovonicsDevices", reqType: "stored"}).then(data => {
                setTempDevData(data ? data : []);
                setTempDevLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", payload: {device_filter: "", campus_id: campusId,
                                device_category_filter: "17", device_type_filter: ""},
                                procedure: "sp_dev_getInovonicsDevices", reqType: "stored"}).then(data => {
                setHumidityDevData(data ? data : []);
                setHumidityDevLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", payload: {campus_id: campusId, environment_type_filter: 1},
                                procedure: "sp_dev_getEnvironmentControls", reqType: "stored"}).then(data => {
                setTempData(data ? data : []);
                setTempLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", payload: {campus_id: campusId, environment_type_filter: 2},
                                procedure: "sp_dev_getEnvironmentControls", reqType: "stored"}).then(data => {
                setHumidityData(data ? data : []);
                setHumidityLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", payload: {campus_id: campusId, environment_type_filter: 3},
                                procedure: "sp_dev_getEnvironmentControls", reqType: "stored"}).then(data => {
                setBatteryData(data ? data : []);
                setBatteryLoaded(true);
            });
            setCount(count => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    const navigation = (
        <div className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString()
            ? "row align-items-center sticky-table-headX"
            : "row align-items-center sticky-table-head"} style={{backgroundColor: "#f9fbfd"}}>
            <div className={"col"}>
                <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                    {allLoaded
                        ? <>
                            <li key={"set-temp"} className={"nav-item"}>
                                <Button text={"Temperature"} class={loadState === "temp" ? "nav-link active" : "nav-link"}
                                        onClick={() => setLoadState("temp")} />
                            </li>
                            <li key={"set-humidity"} className={"nav-item"}>
                                <Button text={"Humidity"} class={loadState === "humidity" ? "nav-link active" : "nav-link"}
                                        onClick={() => setLoadState("humidity")} />
                            </li>
                            <li key={"set-battery"} className={"nav-item"}>
                                <Button text={"Battery"} class={loadState === "battery" ? "nav-link active" : "nav-link"}
                                        onClick={() => setLoadState("battery")} />
                            </li>
                        </>
                        : <></>
                    }
                </ul>
            </div>
        </div>);


    useEffect(() => {
        if (allLoaded){
            if (loadState === "temp"){
                setEnvironContent(<TemperatureSettings setCount={(value) => setCount(value)}
                                                       data={tempData} deviceData={tempDevData} />);
            }else if (loadState === "humidity"){
                setEnvironContent(<HumiditySettings setCount={(value) => setCount(value)}
                                                    data={humidityData} />);
            }else if (loadState === "battery"){
                setEnvironContent(<BatterySettings setCount={(value) => setCount(value)}
                                                   data={batteryData} />);
            }else{
                setEnvironContent(<></>);
            }
        }else{
            setEnvironContent(<p>Environment settings loading. . .</p>);
        }
    }, [loadState, tempData, allLoaded, humidityData, batteryData, loadState, tempDevLoaded]);

    useEffect(() => {
        if (![tempLoaded, humidityLoaded, batteryLoaded, tempDevLoaded].includes(false)){
            setAllLoaded(true);
        }
    }, [tempLoaded, humidityLoaded, batteryLoaded, tempDevLoaded]);

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div>
            </div>
            <CampusDropDown campusId={campusId} setCampusId={(item) => setCampusId(item)} setCount={(item) => setCount(item)} pageAction={() => {
                setAllLoaded(false);
                setTempLoaded(false);
                setHumidityLoaded(false);
                setBatteryLoaded(false);
                setTempDevLoaded(false);
            }} />
        </div>
    )

    return (
        <div className={"main-content"}>
            <Header preTitle={"Settings"} Title={"Environmental Settings"} content={headerContent} />
            <div className={"container-fluid"}>
                {navigation}
                {environContent}
            </div>
            <div className={"footer-spacer"}> </div>
            <Footer left={<></>} />
        </div>
    );
};

export default EnvironmentSettings;