import { ReactSession } from 'react-client-session';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import { AES, enc } from 'crypto-js';
import Dashboard from "./components/Dashboard";
import Landing from './components/auth/Landing';
import Profile from "./components/Profile";
import Alerts from "./components/Alerts";
import Reports from "./components/Reports";
import Settings from "./components/Settings";
import Users from "./components/Users";
import Demo from "./components/Demo";
import Community from "./components/Community";
import UnderConstruction from "./components/auth/UnderConstruction";
import Profiles from "./components/Profiles";
import EVM from "./components/EVM";
import Insights from "./components/Insights";
import Customers from "./components/Customers";
import AuditLogs from "./components/AuditLogs";
import 'react-material-symbols'

const App = () => {

    let dData;
    ReactSession.setStoreType("sessionStorage");
    if (process.env.REACT_APP_MAINTENANCE === "true"){
        return (
            <BrowserRouter>
                <Routes>
                    <Route path={"/"} element={<UnderConstruction />} />
                </Routes>
            </BrowserRouter>
        );
    }
    else if (ReactSession.get("PAL") != null){
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
        return (
            <BrowserRouter>
                <Routes>
                    <Route path={"/"} element={<Landing />} />
                    <Route path={"/dash/null"} element={<Dashboard />} />
                    <Route path={"/dash/:dType"} element={<Dashboard />} />
                    <Route path={"/alerts/fullscreen/:dType"} element={<Alerts fullscreen={true} />} />
                    <Route path={"/alerts/:dType"} element={<Alerts />} />
                    <Route path={"/reports/:dType"} element={<Reports />} />
                    <Route path={"/reports/:dType/:eType"} element={<Reports />} />
                    <Route path={"/settings/:dType"} element={<Settings />} />
                    <Route path={"/community/:dType"} element={<Community />} />
                    <Route path={"/community/:dType/:eType"} element={<Community />} />
                    <Route path={"/logs/:dType/:eType"} element={<AuditLogs />} />
                    <Route path={"/profiles/:dType/:eType"} element={<Profiles />} />
                    <Route path={"/profiles/:dType/:eType/:kType"} element={<Profiles />} />
                    <Route path={"/users"} element={<Users />} />
                    <Route path={"/customers/:dType"} element={<Customers />} />
                    <Route path={"/users/:dType"} element={<Users />} />
                    dData?.acnt === 2 ? <Route path={"/insights"} element={<Insights />} /> : <></>
                    dData?.evmSiteId ? <Route path={"/evm"} element={<EVM />} /> : <></>
                    <Route path={"/profile/" + dData?.username} element={<Profile />} />
                    <Route path={"/demo"} element={<Demo />} />
                </Routes>
            </BrowserRouter>
        );
    }
    else if (window.location.pathname !== "/"){
        return window.location.href = "/";
    }
    else {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path={"/:dType"} element={<Landing />} />
                    <Route path={"/"} element={<Landing />} />
                </Routes>
            </BrowserRouter>
        );
    }
};

export default App;
