import React from 'react';
import { Navigate } from 'react-router-dom';
import LoginForm from './LoginForm';
import Navbar from "../Navbar";
import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import { useParams } from "react-router";
import PasswordRecovery from "./PasswordRecovery";

const Landing = () => {

    let dData, bData, paramVars, container;

    paramVars = useParams();

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    if (paramVars?.dType === "recovery"){
        container = <PasswordRecovery />
    }else{
        container = <LoginForm />
    }

    return (dData?.slauth !== true && (dData?.tKey == null || dData?.tkey === "")) ? (
        <div className={"landing-body"}>
            <section className={"landing"}>
                <div className={"light-overlay"}>
                    <Navbar />
                    <div className={"landing-inner"}>
                        {container}
                    </div>
                </div>
            </section>
        </div>
        ) : dData?.acnt != null ? <Navigate to={"/dash/main"} />
            : <Navigate to={"/dash/null"} />
    };

export default Landing;