import {Pagination} from "react-bootstrap";

const PaginationItems = (props) => {
    let pItems = [];
    // pItems.push(<Pagination.First onClick={() => props.setPage(1)}/>);
    pItems.push(<Pagination.Prev key={"PagePrevArrow"} onClick={() => props.setPage(props.page > 1 ? props.page - 1 : 1)}/>);
    if (props.beginDot){
        pItems.push(<Pagination.Item key={1} active={false} onClick={() => props.setPage(1)}>1</Pagination.Item>);
        pItems.push(<Pagination.Ellipsis key={"e1"} disabled/>);
    }
    for (let number=props.pageMin; number <= props.pageMax; number++){
        pItems.push(
            <Pagination.Item key={number+"-PItem"} active={number === props.page} onClick={() => props.setPage(number)}>
                {number}
            </Pagination.Item>
        );
    }
    if (props.endDot){
        pItems.push(<Pagination.Ellipsis key={"e2"} disabled/>);
        pItems.push(<Pagination.Item key={"maxPage"} active={false} onClick={() => props.setPage(props.max)}>{props.max}</Pagination.Item>);
    }
    pItems.push(<Pagination.Next key={"PageNextArrow"} onClick={() => props.setPage(props.page < props.max ? props.page + 1 : props.max)}/>);

    return pItems;
};

export default PaginationItems;