import Button from "./Button";

const PerPageModal = (props) => {

    return (
        <div className={"modal fade"} id={"filtermodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
             tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
            <div className={"modal-dialog"}>
                <div className={"modal-content"}>
                    <div className={"modal-header bg-pal"}>
                        <h2 className={"modal-title"} id={"staticBackdropLabel"}>Table Page Settings</h2>
                        <button type={"button"} className={"btn-close"} id={"filterClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                    </div>
                    <form id={"filterForm"}>
                        <div className={"modal-body"}>
                            <h3>Items per page:</h3>
                            <div className={"form-check mb-3"}>
                                <input type={"radio"} className={"form-check-input"} id={"perPage50"} name={"perPage"}
                                       value={50} checked={props?.tmpPerPage === 50} onChange={() => props?.setTmpPerPage(50)}/>
                                <label className={"form-check-label"} htmlFor={"perPage50"}>50</label>
                            </div>
                            <div className={"form-check mb-3"}>
                                <input type={"radio"} className={"form-check-input"} id={"perPage100"} name={"perPage"}
                                       value={100} checked={props?.tmpPerPage === 100} onChange={() => props?.setTmpPerPage(100)}/>
                                <label className={"form-check-label"} htmlFor={"perPage100"}>100</label>
                            </div>
                            <div className={"form-check mb-3"}>
                                <input type={"radio"} className={"form-check-input"} id={"perPage200"} name={"perPage"}
                                       value={200} checked={props?.tmpPerPage === 200} onChange={() => props?.setTmpPerPage(200)}/>
                                <label className={"form-check-label"} htmlFor={"perPage200"}>200</label>
                            </div>
                            <div className={"form-check mb-3"}>
                                <input type={"radio"} className={"form-check-input"} id={"perPage300"} name={"perPage"}
                                       value={300} checked={props?.tmpPerPage === 300} onChange={() => props?.setTmpPerPage(300)}/>
                                <label className={"form-check-label"} htmlFor={"perPage300"}>300</label>
                            </div>
                        </div>
                        <div className={"modal-footer"}>
                            <div>
                                <button type={"button"} className={"btn btn-secondary"} id={"cancelFilterBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadFilterSubmit"}/>
                                <button type={"button"} className={"btn btn-danger ms-3"} id={"filterSubmit"} onClick={() => props?.filterSubmit()}>Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )

};

export default PerPageModal;