import {Link, Navigate} from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import Header from "../Header";
import Button from "../component-assets/Button";
import ApiRequester from "../ApiRequester";
import {
    formCheck, formFail, formSuccess, getLocalTimeFromEpochMillis,
    handleSubmitActions, toggleCollapse
} from "../../libs";
import MaterialIcon from "material-icons-react";

const InovonicsDevice = (props) => {
    let dData, bData;

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const [deviceId, setDeviceId] = useState(props?.id);
    const [editable, setEditable] = useState(dData?.access?.Community?.Devices?.Write);
    const [count, setCount] = useState(0);
    const [campusId, setCampusId] = useState(dData.acnt);
    // Basic Information Variables
    const [basicData, setBasicData] = useState([]);
    const [areaData, setAreaData] = useState([]);
    const [residentData, setResidentData] = useState([]);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", payload: {device_filter: deviceId, campus_id: campusId},
                          procedure: "getaccutechdevices", reqType: "stored"}).then(data => {
                setBasicData(data?.length > 0 ? data[0] : {});
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "sp_dev_getresidentsbycampusid", reqType: "stored"}).then(data => {
                setResidentData(data ? data : []);
            });
            setCount(() => 600);
        }
        return () => clearInterval(interval);
    }, [count]);


    function basicActionHandling(){
        document.getElementById("description-static").classList.toggle("d-none");
        document.getElementById("description-edit").classList.toggle("d-none");
        document.getElementById("serialnum-static").classList.toggle("d-none");
        document.getElementById("serialnum-edit").classList.toggle("d-none");
        document.getElementById("linked-static")?.classList?.toggle("d-none");
        document.getElementById("linked-edit")?.classList?.toggle("d-none");
        if (document.getElementById("edit-basic-btn").innerText === "Edit"){
            document.getElementById("edit-basic-btn").innerText = "Cancel";
            document.getElementById("save-basic-btn").classList.toggle("d-none");
            document.getElementById("serialnum-edit").value = basicData?.manufacture_uuid;
            document.getElementById("description-edit").value = basicData?.descriptions;
            document.getElementById("linked-edit").value = basicData?.resident_id;
        }else{
            document.getElementById("edit-basic-btn").innerText = "Edit";
            document.getElementById("save-basic-btn").classList.toggle("d-none");
        }
        formCheck("basic-form", "save-basic-btn");
    }

    function basicSubmit(){
        let description, linkedId, payload, linkedEntity, serialnum;
        if (editable){
            handleSubmitActions("save-basic-btn", "load-basic-btn");
            description = document.getElementById("description-edit").value;
            linkedEntity = document.getElementById("linked-edit");
            linkedId = linkedEntity?.value ? parseInt(linkedEntity.value) : 0;
            serialnum = document.getElementById("serialnum-edit").value;
            payload = [{device_id: parseInt(deviceId), descriptions: description,
                        resident_id: parseInt(linkedId), manufacture_uuid: serialnum.toString()}];
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_device", PKey: "device_id", ClientId: dData.sessionId,
                                Condition: "primary", urlType: "POST", Payload: payload, User: dData.user, CampusId: campusId}).then(data => {
                if (data && data[0]){
                    setCount(0);
                    formSuccess("Basic information saved.", "save-basic-btn", "load-basic-btn", "edit-basic-btn")
                }
                else{
                    formFail(`Error during save, error code: ${data && data?.length > 1 ? data[1] : ""}`,
                        "save-basic-btn", "load-basic-btn");
                }
            });
        }else{
            toast.warn("You do not have permissions to make changes.")
        }
    }

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
        </div>
    )

    return (dData?.slauth === true && dData?.tKey != null && dData?.tKey !== "" && dData?.acnt != null && dData?.access?.Community?.Devices?.Read) ? (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Device Profile"} Title={basicData?.manufacture_uuid ? `RF: ${basicData.manufacture_uuid}` : "RF:"}
                        content={headerContent}/>
                <div className={"container-fluid mt-4"}>
                    <div className={"card"} style={{minWidth: "550px"}}>
                        <div className={"card-header d-flex justify-content-between"}>
                            <div>
                                <h3 className={"card-header-title d-inline-block"}>Basic Information</h3>
                            </div>
                            <div>
                                <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"basic-card-btn"}
                                   onClick={() => toggleCollapse("basic-card-btn", "basic-card")}></i>
                            </div>
                        </div>
                        <div className={"card-body collapse collapse-content show"} id={"basic-card"}>
                            <form className={"d-inline align-top form-control-plaintext was-validated"} id={"basic-form"}>
                                <div className={"mx-5 mt-2 align-top d-inline-block"} id={"basic-col1-div"}>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Device Name:</p>
                                        <p className={"ms-4"} id={"name-static"}>{basicData?.device_name ? basicData.device_name : ""}</p>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Description:</p>
                                        <p className={"ms-4"} id={"description-static"}>{basicData?.descriptions ? basicData.descriptions : ""}</p>
                                        <input className={"ms-4 d-none form-control form-control-sm"}
                                               style={{height: "30px", width: "256px"}} id={"description-edit"}
                                               defaultValue={basicData?.descriptions ? basicData.descriptions : ""}
                                               pattern={"^[0-9a-zA-Z_\\- ]+$"} />
                                    </div>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Badge Number:</p>
                                        <p className={"ms-4"} id={"serialnum-static"}>{basicData?.manufacture_uuid ? basicData.manufacture_uuid : ""}</p>
                                        <input className={"ms-4 d-none form-control form-control-sm"} required={true}
                                               style={{height: "30px", width: "256px"}} id={"serialnum-edit"}
                                               defaultValue={basicData?.manufacture_uuid ? basicData.manufacture_uuid : ""}
                                               pattern={"^[0-9]+$"} />
                                    </div>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Model Number:</p>
                                        <p className={"ms-4"} id={"model-name-static"}>{basicData?.model_name ? basicData.model_name : ""}</p>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Attached To:</p>
                                        <p className={"ms-4"} id={"linked-static"}><Link to={`/profiles/resident/${basicData?.resident_id}`}>{basicData?.resident_name ? basicData.resident_name : ""}</Link></p>
                                        <select className={"ms-4 d-none form-select form-select-sm"}
                                                onChange={() => formCheck("basic-form", "save-basic-btn")}
                                                style={{height: "30px", width: "256px"}} id={"linked-edit"}
                                                defaultValue={basicData?.resident_id ? basicData.resident_id : 0}>
                                            <option value={0}>Unassigned</option>
                                            {residentData.map((rItem, i) => {
                                                return <option key={`linked-item-${i}`} value={rItem?.resident_id}>{rItem?.resident_first_name} {rItem?.resident_last_name}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </form>
                            <Button text={"Edit"} class={"btn btn-primary float-end"} id={"edit-basic-btn"} disabled={!editable} onClick={() => {
                                if (editable){
                                    basicActionHandling()
                                }
                            }} />
                            <Button text={"Save"} class={"btn btn-primary float-end me-3 d-none"} id={"save-basic-btn"} disabled={!editable} onClick={() => {
                                if (editable){
                                    basicSubmit()
                                }
                            }} />
                            <Button type={"button"} class={"btn btn-primary float-end mx-3 d-none"} disabled={true} spinner={true} text={""} id={"load-basic-btn"} />
                        </div>
                    </div>
                </div>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </>
    ) : <Navigate to={"/"}/>;
};

export default InovonicsDevice;