import {Link} from "react-router-dom";
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import Logout from "./Logout";
import Button from "./component-assets/Button";
import logo from "../img/PCLC.svg";
import {iAmHigherAuthority, compareRole, reloadSessionCorp} from "../libs";


function dashMainItem(dData){
    let items = [];
    if (dData.access?.Dashboards?.Main?.Read){
        items.push(<li key={"dash1"} className={"nav-item"}>
                    <Link to={"/dash/main"} className={"nav-link"}>
                         Main
                    </Link>
                </li>);
    }
    if ((dData.access?.Dashboards?.Corporate?.Read || !compareRole("corporate-admin", dData) || iAmHigherAuthority(80, dData?.role_weight)) && dData.migrated){
        items.push(<li key={"dash2"} className={"nav-item"}>
                    <Link to={"/dash/corporate"} className={"nav-link"}>
                         Corporate
                    </Link>
                </li>);
    }
    if (dData?.acnt === 2 && dData.migrated && iAmHigherAuthority(80, dData?.role_weight)){
        items.push(<li key={"insights1"} className={"nav-item"}>
                    <Link to={"/insights"} className={"nav-link"}>
                         Insights
                    </Link>
                </li>)
    }
    return (<ul className={"nav nav-sm flex-column"}>
                {items}
            </ul>);
}

function alertsMainItem(dData){
    let items = [];
    if (dData.access?.Alerts?.Emergency?.Read) {
        items.push(<li key={"alerts2"} className={"nav-item"}>
            <Link to={"/alerts/emergency"} className={"nav-link"}>
                Emergency
            </Link>
        </li>);
    }
    if (dData.access?.Alerts?.Maintenance?.Read) {
        items.push(<li key={"alerts3"} className={"nav-item"}>
            <Link to={"/alerts/maintenance"} className={"nav-link"}>
                Maintenance
            </Link>
        </li>);
    }
    if (dData.access?.Alerts?.All?.Read) {
        items.push(<li key={"alerts1"} className={"nav-item"}>
            <Link to={"/alerts/all"} className={"nav-link"}>
                All
            </Link>
        </li>);
    }
    return (<ul className={"nav nav-sm flex-column"}>
                {items}
            </ul>);
}

function reportMainItem(dData){
    let items = [];
    if (dData.migrated){
        if (dData.access?.Reports?.Alerts?.Read){
            items.push(<li key={"reports1"} className={"nav-item"}>
                        <Link to={"/reports/alerts"} className={"nav-link"}>
                             Alert Details
                        </Link>
                    </li>);
        }
        if (dData.access?.Reports?.Alerts?.Read){
            items.push(<li key={"reports5"} className={"nav-item"}>
                        <Link to={"/reports/environmental"} className={"nav-link"}>
                             Environment
                        </Link>
                    </li>);
        }
        if (dData.access?.Reports?.Alerts?.Read){
            items.push(<li key={"reports2"} className={"nav-item"}>
                        <Link to={"/reports/executive"} className={"nav-link"}>
                             Executive
                        </Link>
                    </li>);
        }
        if (dData.access?.Reports?.Alerts?.Read){
            items.push(<li key={"reports3"} className={"nav-item"}>
                        <Link to={"/reports/grouped"} className={"nav-link"}>
                             Grouped Reports
                        </Link>
                    </li>);
        }
        if (dData.access?.Reports?.Alerts?.Read){
            items.push(<li key={"reports7"} className={"nav-item"}>
                        <Link to={"/reports/maintenance"} className={"nav-link"}>
                             Maintenance
                        </Link>
                    </li>);
        }
        if (dData.access?.Reports?.Alerts?.Read){
            items.push(<li key={"reports4"} className={"nav-item"}>
                        <Link to={"/reports/wellness"} className={"nav-link"}>
                             Wellness
                        </Link>
                    </li>);
        }
        if (dData.access?.Reports?.Alerts?.Read){
            items.push(<li key={"reports6"} className={"nav-item"}>
                        <Link to={"/reports/scheduled"} className={"nav-link"}>
                             Scheduled
                        </Link>
                    </li>);
        }
    }else{
        if (dData.access?.Reports?.Alerts?.Read){
            items.push(<li key={"reports1"} className={"nav-item"}>
                        <Link to={"/reports/alerts"} className={"nav-link"}>
                             Alerts
                        </Link>
                    </li>);
        }
        if (dData.access?.Reports?.Residents?.Read){
            items.push(<li key={"reports2"} className={"nav-item"}>
                        <Link to={"/reports/resident"} className={"nav-link"}>
                             Resident
                        </Link>
                    </li>);
        }
        if (dData.access?.Reports?.Caregiver?.Read){
            items.push(<li key={"reports3"} className={"nav-item"}>
                        <Link to={"/reports/caregiver"} className={"nav-link"}>
                             Caregiver
                        </Link>
                    </li>);
        }
        if (dData.access?.Reports?.Inspection?.Read){
            items.push(<li key={"reports4"} className={"nav-item"}>
                        <Link to={"/reports/inspection"} className={"nav-link"}>
                             Inspection
                        </Link>
                    </li>)
        }
    }
    return (<ul className={"nav nav-sm flex-column"}>
                {items}
            </ul>);
}

function demoMainItem(dData) {
    let items = [];
    if (dData?.role_id){
        if (iAmHigherAuthority(80, dData?.role_weight)){
            items.push(<li key={"demo1"} className={"nav-item"}>
                <Link to={"/demo"} className={"nav-link"}>
                    Demo Panel
                </Link>
            </li>);
        }
    }else{
        if (!compareRole("corporate-admin", dData)) {
            items.push(<li key={"demo1"} className={"nav-item"}>
                <Link to={"/demo"} className={"nav-link"}>
                    Demo Panel
                </Link>
            </li>);
        }
    }
    return (<ul className={"nav nav-sm flex-column"}>
        {items}
    </ul>);
}

function settingsMainItem(dData){
    let items = [];
    if (dData.access?.Settings?.Mobile?.Read){
        if (dData?.role_id){
            if (iAmHigherAuthority(20, dData?.role_weight) && dData.migrated){
                items.push(<li key={"settings1"} className={"nav-item"}>
                    <Link to={"/settings/system"} className={"nav-link"}>
                         System Settings
                    </Link>
                </li>);
            }
        }else{
            if (!compareRole("super-admin", dData) && dData.migrated){
                items.push(<li key={"settings1"} className={"nav-item"}>
                    <Link to={"/settings/system"} className={"nav-link"}>
                         System Settings
                    </Link>
                </li>);
            }
        }
    }
    if (dData.access?.Settings?.Mobile?.Read){
        if (dData?.role_id){
            if (iAmHigherAuthority(20, dData?.role_weight) && dData.migrated){
                items.push(<li key={"settings2"} className={"nav-item"}>
                            <Link to={"/settings/wellness"} className={"nav-link"}>
                                 Wellness Settings
                            </Link>
                        </li>);
            }
        }else{
            if (!compareRole("super-admin", dData) && dData.migrated){
                items.push(<li key={"settings2"} className={"nav-item"}>
                            <Link to={"/settings/wellness"} className={"nav-link"}>
                                 Wellness Settings
                            </Link>
                        </li>);
            }
        }
    }
    if (dData.access?.Settings?.Mobile?.Read && dData.migrated && iAmHigherAuthority(80, dData?.role_weight)){
        items.push(<li key={"settings3"} className={"nav-item"}>
                    <Link to={"/settings/integrations"} className={"nav-link"}>
                         Integrations
                    </Link>
                </li>);
    }
    if (dData.access?.Settings?.Mobile?.Read && dData.migrated && iAmHigherAuthority(20, dData?.role_weight)){
        items.push(<li key={"settings4"} className={"nav-item"}>
                    <Link to={"/settings/environmental"} className={"nav-link"}>
                         Environmental
                    </Link>
                </li>);
    }
    return (<ul className={"nav nav-sm flex-column"}>
                {items}
            </ul>);
}

function profileMainItem(dData) {
    let items = [];
    if (dData.access?.Profile?.Read) {
        items.push(<li key={"profile1"} className={"nav-item"}>
            <Link to={"/profile/" + dData?.username} className={"nav-link"}>
                View Profile
            </Link>
        </li>);
    }
    return (<ul className={"nav nav-sm flex-column"}>
        {items}
    </ul>);
}

function nullItem(key){
    return (<ul className={"nav nav-sm flex-column"}>
                <li key={key} className={"nav-item"}>
                </li>
            </ul>);
}

function nullProfile(){
    return (<ul className={"nav nav-sm flex-column"}>
                <li key={"profile99"} className={"nav-item"}>
                </li>
            </ul>);
}

function dashItem(dData, dashTypes, alarmTypes, reportTypes, settingTypes, profileTypes, demoTypes){
    let items = [];
    if (dData.access?.Dashboards?.Main?.Read || dData.access?.Dashboards?.Corporate?.Read){
        items.push(<li key={"dash0"} className={"nav-item"}>
                <Link to={"#sidebarDashboards"} data-bs-toggle={"collapse"} role={"button"}
                      aria-expanded={"false"} aria-controls={"sidebarDashboards"} className={"nav-link"}>
                    <i className={"fe fe-bar-chart-2"}><p>Dashboards</p></i>
                </Link>
                <div className={"collapse"} id={"sidebarDashboards"}>
                    {dashTypes}
                </div>
            </li>);
    }
    if (dData.access?.Alerts?.All?.Read || dData.access?.Alerts?.Emergency?.Read ||
        dData.access?.Alerts?.Maintenance?.Read || dData.access?.Alerts?.System?.Read){
        items.push(<li key={"alerts0"} className={"nav-item"}>
                <Link to={"#sidebarAlerts"} data-bs-toggle={"collapse"} role={"button"}
                      aria-expanded={"false"} aria-controls={"sidebarAlerts"} className={"nav-link"}>
                    <i className={"fe fe-bell"}><p>Active Alerts</p></i>
                </Link>
                <div className={"collapse"} id={"sidebarAlerts"}>
                    {alarmTypes}
                </div>
            </li>);
        }
    if (dData.access?.Reports?.Alerts?.Read || dData.access?.Reports?.Residents?.Read ||
        dData.access?.Reports?.Caregiver?.Read || dData.access?.Reports?.Inspection?.Read ||
        dData.access?.Reports?.Checkin?.Read){
        items.push(<li key={"reports0"} className={"nav-item"}>
                <Link to={"#sidebarReports"} data-bs-toggle={"collapse"} role={"button"}
                      aria-expanded={"false"} aria-controls={"sidebarReports"} className={"nav-link"}>
                    <i className={"fe fe-file-text"}><p>Reports</p></i>
                </Link>
                <div className={"collapse"} id={"sidebarReports"}>
                    {reportTypes}
                </div>
            </li>);
        }
    if (dData.access?.Users?.UserManagement?.Read || dData.access?.Users?.Groups?.Read){
        items.push(<li key={"users0"} className={"nav-item"}>
                    <Link to={"/users/usermanagement"} className={"nav-link"}>
                         <i className={"fe fe-users"}><p>Users</p></i>
                    </Link>
                </li>);
        }
    if ((dData.access?.Users?.UserManagement?.Read || dData.access?.Users?.Groups?.Read) && !dData.migrated){
        items.push(<li key={"users1"} className={"nav-item"}>
                    <Link to={"/users/groups"} className={"nav-link"}>
                         <i className={"fe fe-users"}><p>Groups</p></i>
                    </Link>
                </li>);
        }
    if ((dData.access?.Community?.Residents?.Read) || (iAmHigherAuthority(80, dData?.role_weight) && dData?.migrated)){
        items.push(<li key={"community0"} className={"nav-item"}>
            <Link to={"/community/residents"} className={"nav-link"}>
                 <i className={"fe fe-home"}><p>Residents</p></i>
            </Link>
        </li>);
    }
    if ((dData.access?.Community?.Areas?.Read) || (!compareRole("corporate-admin", dData) && dData?.migrated) || (iAmHigherAuthority(80, dData?.role_weight) && dData?.migrated)){
        items.push(<li key={"community1"} className={"nav-item"}>
            <Link to={"/community/areas"} className={"nav-link"}>
                 <i className={"fe fe-map-pin"}><p>Areas</p></i>
            </Link>
        </li>);
    }
    if ((iAmHigherAuthority(10, dData?.role_weight) && dData?.migrated)){
        items.push(<li key={"community2"} className={"nav-item"}>
            <Link to={"/community/devices"} className={"nav-link"}>
                 <i className={"fe fe-tablet"}><p>Devices</p></i>
            </Link>
        </li>);
    }
    if (iAmHigherAuthority(10, dData?.role_weight) && dData?.evmSiteId && parseInt(dData.evmSiteId) !== 0){
        items.push(<li key={"evm1"} className={"nav-item"}>
            <Link to={"/evm"} className={"nav-link"}>
                 <i className={"fe fe-map"}><p>RTLS</p></i>
            </Link>
        </li>);
    }
    if (dData.access?.Settings?.Mobile?.Read && iAmHigherAuthority(20, dData?.role_weight)){
        items.push(<li key={"settings0"} className={"nav-item"}>
                <Link to={"#sidebarSettings"} data-bs-toggle={"collapse"} role={"button"}
                      aria-expanded={"false"} aria-controls={"sidebarSettings"} className={"nav-link"}>
                    <i className={"fe fe-settings"}><p>Settings</p></i>
                </Link>
                <div className={"collapse"} id={"sidebarSettings"}>
                    {settingTypes}
                </div>
            </li>);
    }
    if (!compareRole("corporate-admin", dData) || iAmHigherAuthority(80, dData?.role_weight)){
        items.push(<li key={"demo0"} className={"nav-item"}>
                <Link to={"#sidebarDemo"} data-bs-toggle={"collapse"} role={"button"}
                      aria-expanded={"false"} aria-controls={"sidebarDemo"} className={"nav-link"}>
                    <i className={"fe fe-airplay"}><p>Demo</p></i>
                </Link>
                <div className={"collapse"} id={"sidebarDemo"}>
                    {demoTypes}
                </div>
            </li>);
    }
    items.push(<li key={"faqs"} className={"nav-item"}>
        <a href="https://www.palcare.com/academy/" target={"_blank"} className={"nav-link"} rel={"noreferrer"}>
            <i className={"fe fe-help-circle"}><p>FAQ</p></i><i className={"fe fe-external-link ms-4"}></i>
        </a>
    </li>
)
if (dData.access?.Profile?.Read){
        items.push(<li key={"profile0"} className={"nav-item"}>
                <Link to={"#sidebarProfile"} data-bs-toggle={"collapse"} role={"button"}
                      aria-expanded={"false"} aria-controls={"sidebarProfile"} className={"nav-link"}>
                    <i className={"fe fe-user"}><p>Profile</p></i>
                </Link>
                <div className={"collapse"} id={"sidebarProfile"}>
                    {profileTypes}
                </div>
            </li>);
        }
    return items;
}

const Navigation = () => {

    let dData, alarmTypes, dashTypes, profileTypes, dashElements, bData, reportTypes, settingTypes,
        demoTypes, logoutBtn;

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    if (dData?.acnt != null){
        dashTypes = dashMainItem(dData);
        alarmTypes = alertsMainItem(dData);
        reportTypes = reportMainItem(dData);
        settingTypes = settingsMainItem(dData);
        demoTypes = demoMainItem(dData);
        profileTypes = profileMainItem(dData);
    }
    else{
        dashTypes = nullItem("dash99");
        alarmTypes = nullItem("alarm99");
        reportTypes = nullItem("reports99");
        settingTypes = nullItem("settings99");
        demoTypes = nullItem("demo99");
        profileTypes = nullProfile();
    }

    logoutBtn = (
        <Button icon={true} iconClass={"fe fe-log-out me-2"} text={"Logout"} type={"button"} style={{minWidth: "50%"}}
                            class={"btn btn-primary w-75"} onClick={() => Logout()} />
    );

    dashElements = dashItem(dData, dashTypes, alarmTypes, reportTypes, settingTypes, profileTypes, demoTypes);

    return (
        <nav className={"navbar navbar-vertical fixed-start navbar-expand-md navbar-light card-shadow"} id={"sidebar"}
             style={process.env.REACT_APP_DEVMODE === "true" ? {backgroundColor: "ghostwhite"} : {}}>
            <div className={"container-fluid"} style={{minHeight: "unset"}}>
                <button className={"navbar-toggler collapsed"} type={"button"} data-bs-toggle={"collapse"}
                        data-bs-target={"#sidebarCollapse"} aria-controls={"sidebarCollapse"} aria-expanded={"false"}
                        aria-label={"Toggle navigation"}>
                    <span className={"navbar-toggler-icon"} />
                </button>
                <div className={"navbar-brand pb-2"}>
                    <img src={logo} alt={"Palcare Logo Dark Varient"}
                         className={"navbar-brand-img mx-auto"} style={{"maxHeight": "3rem"}}/>
                    <h3 className={"d-block mt-3"} style={{whiteSpace: "break-spaces"}}>{dData.campus}</h3>
                    {dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString()
                        ? <>
                            <h5 className={"d-inline-block mt-0 text-success align-bottom me-3"}>Impersonating</h5>
                            <i className={"fe fe-eye-off as-btn-1x d-inline-block"} title={"Stop Impersonate"}
                               onClick={() => reloadSessionCorp(dData.myCorp, -99, dData.cognito, dData.myAcnt)}></i>
                        </>
                        : <></>
                    }
                </div>
                <div className={"d-md-none"}>

                </div>
            </div>
            {dData?.migrated && iAmHigherAuthority(80, dData?.role_weight)
                ?
                <div className={"collapse navbar-collapse"} id={"sidebarCollapse"} style={{borderTop: "#e3ebf6 solid 1px"}}>
                    <div className={"text-center mt-3 fw-bold"}>
                        Admin Panel
                    </div>
                    <ul className={"navbar-nav my-2"}>
                        <li key={"admin0"} className={"nav-item"}>
                            <Link to={"#sidebarAdmin"} data-bs-toggle={"collapse"} role={"button"}
                                  aria-expanded={"false"} aria-controls={"sidebarAdmin"} className={"nav-link"}>
                                <i className={"fe fe-aperture"}><p>Customers</p></i>
                            </Link>
                            <div className={"collapse"} id={"sidebarAdmin"}>
                                <ul className={"nav nav-sm flex-column"}>
                                    <li key={"admin1"} className={"nav-item"}>
                                        <Link to={"/customers/corporations"} className={"nav-link"}>
                                             Corporations
                                        </Link>
                                    </li>
                                    <li key={"admin2"} className={"nav-item"}>
                                        <Link to={"/customers/campuses"} className={"nav-link"}>
                                             Campuses
                                        </Link>
                                    </li>
                                    <li key={"admin3"} className={"nav-item"}>
                                        <Link to={"/customers/servers"} className={"nav-link"}>
                                             Servers
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
                :
                ""
            }
            <div className={"collapse navbar-collapse"} id={"sidebarCollapse"} style={{borderTop: "#e3ebf6 solid 1px"}}>
                <ul className={"navbar-nav mt-1"}>
                    {dashElements}
                </ul>
                <div className={"text-center my-4"}>
                    {logoutBtn}
                </div>
            </div>
            <div className={"mt-auto"}> </div>
            <div className={"navbar-user d-none d-md-flex"} id={"sidebarUser"}>
                v. {dData.migrated ? process.env.REACT_APP_VERSION : "1.0.8"}
                <br />
                {iAmHigherAuthority(1000, dData?.role_weight) && "Build: " + process.env.REACT_APP_BUILD}
            </div>
            {process.env.REACT_APP_DEVMODE === "true" &&
                <>
                    <br />
                    <h3 className={"text-danger text-center"}>Development Mode</h3>
                </>
            }
        </nav>
    );
};

export default Navigation;