import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import {useEffect, useState} from "react";
import Button from "../component-assets/Button";
import ApiRequester from "../ApiRequester";
import {formSuccess, handleSubmitActions, formFail, formCheck} from "../../libs";
import tels_logo from "../../img/tels_logo.png"

const Tels = (props) => {

    let dData;
    if (ReactSession.get("PAL") != null) {
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const originData = props?.originData?.length > 0 ? props.originData[0] : {}
    const eventTypes = props?.eventTypeData?.length > 0 ? props.eventTypeData : []

    const [isActive, setIsActive] = useState(originData?.active ? originData.active : false);

    useEffect(() => {
        formCheck("mainForm", "editSubmit");
    }, [isActive])

    function performSave(){
        let payload, externalId, idList=[];
        handleSubmitActions("editSubmit", "loadEditSubmit");
        externalId = document.getElementById("externalid").value;
        for (let i=0; i < eventTypes?.length; i++){
            if (document.getElementById(`eType${eventTypes[i]?.event_type_id}`)?.checked === true){
                idList.push(eventTypes[i].event_type_id.toString());
            }
        }
        payload = [{event_types: idList.join(","), campus_id: parseInt(dData.acnt), external_id: externalId,
                    device_types: "", active: isActive, tels_id: originData?.tels_id ? originData.tels_id : 0}]
        ApiRequester({reqEndpoint: "mytransaction", TransType: "tels_edit", Payload: payload,
                            CampusId: parseInt(dData.acnt), User: dData.user, ClientId: dData.sessionId}).then(data => {
            if (data[0] === true){
                formSuccess("Tels settings updated", "editSubmit", "loadEditSubmit");
                props?.setCount(0);
            }
            else{
                formFail(`Error during save, error code: ${data[1]}`, "editSubmit", "loadEditSubmit");
            }
        });
    }


    return (
        <>
            <div className={"card"} style={{maxWidth: "700px", minWidth: "515px"}}>
                <div className={"card-header"}>
                    <div>
                        <h4 className={"card-header-title d-inline-block"}>Tels Settings</h4>
                        <div className={"form-check form-switch ms-4 d-inline-block"}>
                            <input className={"form-check-input"} type={"checkbox"} role={"switch"} id={"tels-on"}
                                   defaultChecked={isActive} onChange={(e) => {
                                document.getElementById("editSubmit").classList.remove("d-none");
                                setIsActive(e.target.checked);
                                if (e.target.checked) {
                                    e.target.nextElementSibling.children[0].classList.remove("text-danger");
                                    e.target.nextElementSibling.children[0].classList.remove("fw-bold");
                                    e.target.nextElementSibling.children[1].classList.add("fw-bold");
                                    e.target.nextElementSibling.children[1].classList.add("text-success-bright");
                                } else {
                                    e.target.nextElementSibling.children[0].classList.add("text-danger");
                                    e.target.nextElementSibling.children[0].classList.add("fw-bold");
                                    e.target.nextElementSibling.children[1].classList.remove("fw-bold");
                                    e.target.nextElementSibling.children[1].classList.remove("text-success-bright");
                                }
                            }}/>
                            <label className={"form-check-label"} htmlFor={"tels-on"}>
                                <span id={"in-label-off"} className={!isActive ? "text-danger fw-bold" : ""}>Off</span>/<span
                                id={"in-label-on"} className={isActive ? "text-success-bright fw-bold" : ""}>On</span>
                            </label>
                        </div>
                        <a href={"https://tels.directsupply.com/"} target={"_blank"}>
                            <img width={"100px"} height={"100px"}  className={"ms-4"} src={tels_logo}
                                 alt={"Direct Supply Tels Logo"}/>
                        </a>
                    </div>
                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true}
                            text={"Loading"} id={"loadEditSubmit"}/>
                    <Button id={"editSubmit"} style={{width: "75px"}} text={"Save"} class={"btn btn-primary d-none"} onClick={() => performSave()} />
                </div>
                <div className={"card-body"}>
                    <div className={isActive ? "list-group list-group-flush my-n3" : "list-group list-group-flush my-n3 d-none"}>
                        <form id={"mainForm"} className={"was-validated"}>
                            <div className={"form-floating mb-3 mt-3"} style={{maxWidth: "350px"}}>
                                <input type={"text"} className={"form-control"} id={"externalid"} required={isActive} pattern={"^[0-9]+$"}
                                        onKeyUp={() => formCheck("mainForm", "editSubmit")} />
                                <label htmlFor={"externalid"}>Tels Business Unit ID</label>
                                <div className={"invalid-feedback"}>
                                    Tels unique customer identification number needed
                                </div>
                            </div>
                            <div className={"list-group mt-5 ms-2"}>
                                <h3>Create work orders for:</h3>
                                {eventTypes.map((eType) => {
                                    return <div className={"form-check form-switch my-2 ms-5"}>
                                        <input className={"form-check-input"} defaultChecked={originData?.entity_ids?.includes(eType?.event_type_id)}
                                               id={`eType${eType?.event_type_id}`} type={"checkbox"}
                                               onChange={(e) => {
                                                   if (e.target.checked === true){
                                                       e.target.nextElementSibling.classList.add("text-success-bright")
                                                       e.target.nextElementSibling.classList.add("fw-bold")
                                                   }else{
                                                       e.target.nextElementSibling.classList.remove("text-success-bright")
                                                       e.target.nextElementSibling.classList.remove("fw-bold")
                                                   }
                                        }}/>
                                        <label htmlFor={`eType${eType?.event_type_id}`} className={originData?.entity_ids?.includes(eType?.event_type_id) ? "text-success-bright fw-bold mt-0" : "mt-0"}
                                               id={`eTypeLabel${eType?.event_type_id}`}>{eType?.event_type_name}</label>
                                    </div>
                                })}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Tels;