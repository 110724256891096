import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import Header from "./Header";
import {useState, useEffect} from "react";
import ApiRequester from "./ApiRequester";
import {Chart, registerables} from "chart.js";
import annotationPlugin from 'chartjs-plugin-annotation';
import Footer from "./Footer";
import CampusDropDown from "./component-assets/CampusDropDown";
import {campusToGuid} from "../libs";

const LegacyMainDash = () => {

    Chart.register(...registerables)

    let dData;
    let colorlist = ["#2C7BE5", "#392CE5", "#05B2FF", "#A6C5F7", "#8989FF", "#D2DDEC", "#E52C7B", "#962CE5", "#E52CD8",
        "#E52C7B", "#E5392C", "#09BA00", "#0CFF00", "#8AFF85", "#AEFF00", "#F2FF00", "#A7B000"];

    if (ReactSession.get("PAL") !== undefined){
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }


    const [gData, setData] = useState({
        graphData: [[], [], 0],
        reasonData: [[], [], []],
        hourData: [[], []],
        respData: ["", "", ""]
    });
    const [count, setCount] = useState(0);
    const [campusId, setCampusId] = useState(dData.acnt)

    useEffect(() => {
        let interval;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "perday", CampusId: campusToGuid(dData, campusId)}).then(data => {
                if (data == null || data === "" || data === [] || data === {} || data === [[], []]){
                    throw new Error("Error encountered in request");
                }else{
                    setData(prevState => {
                        return {...prevState, graphData: data};
                    })
                }}).catch(error => {console.log(error)});

            ApiRequester({reqEndpoint: "perhour", CampusId: campusToGuid(dData, campusId)}).then(data => {
                if (data == null || data === "" || data === [] || data === {} || data === [[], []]) {
                    throw new Error("Error encountered in request");
                }else{
                    setData(prevState => {
                        return {...prevState, hourData: data};
                    })
                }}).catch(error => {console.log(error)});

            ApiRequester({reqEndpoint: "perreason", CampusId: campusToGuid(dData, campusId)}).then(data => {
                if (data == null || data === "" || data === [] || data === {} || data === [[], [], []]) {
                    throw new Error("Error encountered in request");
                } else {
                    setData(prevState => {
                        return {...prevState, reasonData: data};
                    });
                }
            }).catch(error => {console.log(error)});

            ApiRequester({reqEndpoint: "resp", CampusId: campusToGuid(dData, campusId)}).then(data => {
                if(data == null || data === "" || data === [] || data === {} || data === [[], []]){
                    throw new Error("Error encountered in request");
                }else{
                    setData(prevState => {
                        return {...prevState, respData: data};
                    })
                }}).catch(error => {console.log(error)});
            setCount(count => 600);
        }
        return () => clearInterval(interval);
    }, [count]);


    useEffect(() => {
        if (gData?.graphData !== undefined) {
            if (Chart.getChart("perday") !== undefined){
                const curChart = Chart.getChart("perday");
                curChart.data.datasets[0].data = gData.graphData[1];
                curChart.data.labels = gData.graphData[0];
                curChart.options.plugins.annotation.annotations.line1.value = gData.graphData[4];
                curChart.update();
            }
            else{
                Chart.register(annotationPlugin);
                new Chart('perday', {
                    type: 'bar',
                    options: {
                        responsive: true,
                        plugins: {
                            annotation: {
                                drawTime: 'afterDatasetsDraw',
                                annotations: {
                                    line1: {
                                        type: 'line',
                                        borderDash: [8, 4],
                                        borderWidth: 2,
                                        scaleID: "y",
                                        value: gData.graphData[4],
                                        label: {
                                            enabled: false,
                                        },
                                    }
                                }
                            },
                            legend: {
                                display: false
                            },
                        },
                        scales: {
                            y: {
                                grid: {
                                    display: false
                                },
                                ticks: {
                                    callback: function (value) {
                                        return value
                                    }
                                }
                            },
                            x: {
                                grid: {
                                    display: false
                                }
                            }
                        }
                    },
                    data: {
                        labels: gData.graphData[0],
                        datasets: [{
                            label: 'Alerts',
                            data: gData.graphData[1],
                            backgroundColor: ["#0081C7"],
                            hoverBackgroundColor: ["#0081C778"]
                        }]
                    }
                });
            }
        }
    }, [gData?.graphData]);
    useEffect(() => {
        if (gData?.hourData !== undefined){
            if (Chart.getChart("perhour") !== undefined){
                const curChart2 = Chart.getChart("perhour");
                curChart2.data.datasets[0].data = gData.hourData[1];
                curChart2.data.labels = gData.hourData[0];
                curChart2.options.plugins.annotation.annotations.line1.value = gData.hourData[2];
                curChart2.update();
            }
            else{
                new Chart('perhour', {
                    type: 'bar',
                    options: {
                        responsive: true,
                        plugins: {
                            annotation: {
                                drawTime: 'afterDatasetsDraw',
                                annotations: {
                                    line1: {
                                        type: 'line',
                                        borderDash: [8, 4],
                                        borderWidth: 2,
                                        scaleID: "y",
                                        value: gData.hourData[2],
                                        label: {
                                            enabled: false,
                                        },
                                    }
                                }
                            },
                            legend: {
                                display: false
                            },
                        },
                        scales: {
                            y: {
                                grid: {
                                    display: false
                                },
                                ticks: {
                                    callback: function (value) {
                                        return value
                                    }
                                }
                            },
                            x: {
                                grid: {
                                    display: false
                                },
                            }
                        }
                    },
                    data: {
                        labels: gData.hourData[0],
                        datasets: [{
                            label: 'Alerts',
                            data: gData.hourData[1],
                            backgroundColor: ["#0081C7"],
                            hoverBackgroundColor: ["#0081C778"]
                        }]
                    }
                });
            }
        }
    }, [gData?.hourData]);
    useEffect(() => {
        if (gData?.reasonData !== undefined){
            const bcolor = [];
            if (gData?.reasonData[0] !== undefined){
                for (let ix=0; ix <= gData?.reasonData[0].length; ix++) {
                    bcolor.push(colorlist[ix]);
                }
            }
            if (Chart.getChart("perreason") !== undefined){
                const curChart3 = Chart.getChart("perreason");
                curChart3.data.datasets[0].data = gData.reasonData[1];
                curChart3.data.labels = gData.reasonData[0];
                curChart3.data.datasets[0].backgroundColor = bcolor;
                curChart3.update();
            }
            else{
                new Chart('perreason', {
                    type: 'pie',
                    options: {
                        cutout: "70%",
                        plugins: {
                            tooltip: {
                                callbacks: {
                                    label: function(tooltipItem){
                                        return " "+tooltipItem.label+": "+tooltipItem.formattedValue
                                    }
                                }
                            },
                            legend: {
                                display: true,
                                position: "top"
                            },

                        }
                    },
                    data: {
                        labels: gData.reasonData[0],
                        datasets: [{
                            data: gData.reasonData[1],
                            backgroundColor: bcolor
                        }]
                    }
                });
            }
            if (Chart.getChart("perservice") !== undefined){
                const curChart4 = Chart.getChart("perservice");
                curChart4.data.datasets[0].data = gData.reasonData[2];
                curChart4.data.labels = gData.reasonData[0];
                curChart4.data.datasets[0].backgroundColor = bcolor;
                curChart4.update();
            }else{
                new Chart('perservice', {
                    type: 'pie',
                    options: {
                        cutout: "70%",
                        plugins: {
                            tooltip: {
                                callbacks: {
                                    label: function(tooltipItem){
                                        return " "+tooltipItem.label+": "+tooltipItem.formattedValue+"%"
                                    }
                                }
                            },
                            legend: {
                                display: true,
                                position: "top"
                            },

                        }
                    },
                    data: {
                        labels: gData.reasonData[0],
                        datasets: [{
                            data: gData.reasonData[2],
                            backgroundColor: bcolor
                        }]
                    }
                });
            }
        }
    }, [gData?.reasonData]);

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div>
            </div>
            <CampusDropDown campusId={campusId} setCampusId={(item) => setCampusId(item)} setCount={(item) => setCount(item)} pageAction={() => {}}/>
        </div>
    )

    return (
        <div className={"main-content"}>
            <Header preTitle={"Dashboard"} Title={"Main"} content={headerContent}/>
            <div className={"container-fluid"}>
                <h4>* Stats are based on previous 30 days</h4>
                <div className={"row"}>
                    <div className={"col-12 col-lg-4 col-xl"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className={"row align-items-center gx-0"}>
                                    <div className={"col"}>
                                        <h6 className={"text-uppercase text-muted mb-2"}>Avg Daily Alarms</h6>
                                        <span className={"h2 mb-0"}>{(gData?.graphData !== undefined && [2] !== undefined && gData?.graphData[2] !== "")?
                                            gData.graphData[3]:("0")}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-12 col-lg-4 col-xl"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className={"row align-items-center gx-0"}>
                                    <div className={"col"}>
                                        <h6 className={"text-uppercase text-muted mb-2"}>Avg Response Rate</h6>
                                        <span className={"h2 mb-0"}>{(gData?.respData !== undefined && gData?.respData[0] !== undefined && gData?.respData[0] !== "")?
                                            (gData.respData[0]):("None")}</span>
                                        <span className={"badge bg-" + (gData?.respData !== undefined && gData?.respData[3] !== undefined && gData.respData[3][0] !== "-"?"danger":"success") + "-soft mx-3"}>{(gData?.respData !== undefined && gData?.respData[3] !== undefined && gData?.respData[3] !== "")?
                                            (gData.respData[3]):("")}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-12 col-lg-4 col-xl"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className={"row align-items-center gx-0"}>
                                    <div className={"col"}>
                                        <h6 className={"text-uppercase text-muted mb-2"}>Alarms Over 20 Mins</h6>
                                        <span className={"h2 mb-0"}>{(gData?.respData !== undefined && gData?.respData[2] !== undefined && gData?.respData[2] !== "")?
                                            (gData.respData[2]):("None")}</span>
                                        <span className={"badge bg-" + (gData?.respData !== undefined && gData?.respData[4] !== undefined && gData.respData[4] !== "0.0" && gData.respData[4][0] !== "-"?"danger":"success") + "-soft mx-3"}>{(gData?.respData !== undefined && gData?.respData[4] !== undefined && gData?.respData[4] !== "")?
                                            (gData.respData[4]):("")}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-12 col-xl-6"}>
                        <div className={"card"}>
                            <div className={"card-header"}>
                                <h4 className={"card-header-title"}>Alerts Per Day</h4>
                            </div>
                            <div className={"card-body"}>
                                <div className={"chart-container"}>
                                    <canvas className={"chart-canvas"} id={"perday"}> </canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-12 col-xl-6"}>
                        <div className={"card"}>
                            <div className={"card-header"}>
                                <h4 className={"card-header-title"}>Alerts Per Hour</h4>
                            </div>
                            <div className={"card-body"}>
                                <div className={"chart-container"}>
                                    <canvas className={"chart-canvas"} id={"perhour"}> </canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-12 col-xl-4"}>
                        <div className={"card"}>
                            <div className={"card-header"}>
                                <h4 className={"card-header-title"}>Alarms by Reason</h4>
                            </div>
                            <div className={"card-body"}>
                                <div className={"chart-container"}>
                                    <canvas className={"chart-canvas p-1"} id={"perreason"}> </canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-12 col-xl-4"}>
                        <div className={"card"}>
                            <div className={"card-header"}>
                                <h4 className={"card-header-title"}>% Service Time by Reason</h4>
                            </div>
                            <div className={"card-body"}>
                                <div className={"chart-container"}>
                                    <canvas className={"chart-canvas p-1"} id={"perservice"}> </canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"footer-spacer"}>

                </div>
            </div>
            <Footer />
        </div>
    );
};

export default LegacyMainDash;