import Button from "./Button";

const BulkActionModal = (props) => {

    return (
        <div className={"modal fade"} id={props?.modalName ? props.modalName : "bulkactionmodal"}
             data-bs-backdrop={"static"} data-bs-keyboard={"false"}
             tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
            <div className={"modal-dialog"}>
                <div className={"modal-content"}>
                    <div className={"modal-header bg-pal"}>
                        <h2 className={"modal-title"} id={"staticBackdropLabel"}>{props?.header}</h2>
                        <button type={"button"} className={"btn-close"} id={props?.closeId ? props.closeId : "bulkConfirmClose"}
                                data-bs-dismiss={"modal"} aria-label={"Close"} />
                    </div>
                    <div className={"modal-body"}>
                        <h2 className={"m-3"} style={{textAlign: "left"}} id={props?.modalContentId ? props.modalContentId : "deleteBulkModalContent"}> </h2>
                    </div>
                    <div className={"modal-footer"}>
                        <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}} data-bs-dismiss={"modal"} id={props?.cancelId ? props.cancelId : "bulkCancelDelete"}>No</button>
                        <Button type={"button"} class={"btn btn-primary d-none"} style={{width: "75px"}} disabled={true} spinner={true} text={"Loading"} id={props?.spinnerId ? props.spinnerId : "loadBulkDeleteSubmit"}/>
                        <button type={"button"} className={"btn btn-danger"} style={{width: "75px"}} id={props?.submitId ? props.submitId : "bulkDeleteSubmit"} onClick={() => props?.clickFunction()}>Yes</button>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default BulkActionModal;