import {Link, Navigate} from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import {useNavigate} from 'react-router-dom';
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import Header from "../Header";
import stockPhoto from "../../img/nophoto.png";
import Button from "../component-assets/Button";
import ApiRequester from "../ApiRequester";
import {v4 as uuidv4} from "uuid";
import {
    formatPhone, formFail, handleSubmitActions, toggleCollapse, formSuccess, determineUseId,
    showModal, swapModal, formCheckWithElement, formClear, formCheck, formatDateIso, getToday, hideModal,
    sortFunc, setResFilters, determineDevName
} from "../../libs";
import {Chart, registerables} from "chart.js";
import annotationPlugin from 'chartjs-plugin-annotation';
import TableBody from "../component-assets/TableBody";
import TableHeaders from "../component-assets/TableHeaders";

const ResidentProfile = (props) => {
    let dData, bData, startDate, endDate, eventHeaderData;

    const navigate = useNavigate();

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    Chart.register(...registerables);
    Chart.register(annotationPlugin);

    eventHeaderData = [
        {label: "Event Time", key: "event_datetime_local", align: "center", type: "datetime", sorts: false},
        {label: "Response Time (min)", key: "response_time", align: "center", sorts: false},
        {label: "Device", key: "description", align: "left", sorts: false},
        {label: "Location", key: "area_name", align: "left", sorts: false,
            link: {enabled: true, type: "standard", linkto: "/profiles/area", linkkey: "area_id"}},
        {label: "Reason", key: "reason_code_description", align: "left", sorts: false},
    ];

    const [residentId, setResidentId] = useState(props?.id);
    const [count, setCount] = useState(0);
    const [editable, setEditable] = useState(dData?.access?.Community?.Residents?.Write);
    const [repCount, setRepCount] = useState(0);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [pageLoaded, setPageLoaded] = useState(false);
    // Basic Information Variables
    const [resBasicData, setResBasicData] = useState({});
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [nickName, setNickName] = useState("");
    const [area, setArea] = useState("");
    const [areaId, setAreaId] = useState("");
    const [locationData, setLocationData] = useState([]);
    const [locationOptions, setLocationOptions] = useState("");
    const [imageData, setImageData] = useState("");
    const [isMobileActive, setIsMobileActive] = useState(dData.resMobile);
    const [isActive, setIsActive] = useState(false);
    const [resGroups, setResGroups] = useState([]);
    const [notes, setNotes] = useState("");
    const [ehrId, setEhrId] = useState("");
    const [basicLoaded, setBasicLoaded] = useState(false);
    const [areaLoaded, setAreaLoaded] = useState(false);
    // Device Information Variables
    const [resDeviceData, setResDeviceData] = useState([]);
    const [devices, setDevices] = useState([]);
    const [deviceData, setDeviceData] = useState([]);
    const [assignedRes, setAssignedRes] = useState("");
    const [deviceToRemove, setDeviceToRemove] = useState("");
    const [manufactureData, setManufactureData] = useState([]);
    const [manufactureOptions, setManufactureOptions] = useState([]);
    const [manufactureId, setManufactureId] = useState(0);
    const [deviceCatId, setDeviceCatId] = useState(0);
    const [deviceCatData, setDeviceCatData] = useState([]);
    const [deviceCatOptions, setDeviceCatOptions] = useState([]);
    const [deviceTypeData, setDeviceTypeData] = useState([]);
    const [deviceTypeId, setDeviceTypeId] = useState([]);
    const [deviceTypeOptions, setDeviceTypeOptions] = useState([]);
    const [accutechDeviceData, setAccutechDeviceData] = useState([]);
    const [eventData, setEventData] = useState([]);
    const [eventTableRows, setEventTableRows] = useState([]);
    const [serialPattern, setSerialPattern] = useState("^[0-9]+$");
    // Device Listener Variables
    const [listenDevice, setListenDevice] = useState("");
    const [listening, setListening] = useState(false);
    const [listenIcon, setListenIcon] = useState(<i className={"fe fe-rss pointer-no fs-2"}></i>);
    const [listenCount, setListenCount] = useState(60);
    const [listenData, setListenData] = useState([]);
    const [listenList, setListenList] = useState([]);
    const [selectReq, setSelectReq] = useState(false);
    const [onLoad, setOnLoad] = useState(true);
    // Wellness Information Variables
    const [resWellData, setResWellData] = useState([]);
    const [checkin, setCheckin] = useState("");
    const [rounds, setRounds] = useState("");
    const [careflow, setCareflow] = useState("");
    const [ehs, setEhs] = useState("");
    const [ehsurl, setEhsUrl] = useState("");
    const [newFileName, setNewFileName] = useState("");
    const [newFile, setNewFile] = useState("");
    const [newFileExt, setNewFileExt] = useState("");
    // Reporting Information Variables
    const [weekData, setWeekData] = useState({});
    const [hourData, setHourData] = useState({});
    const [hourDataLoaded, setHourDataLoaded] = useState(false);
    const [weekDataLoaded, setWeekDataLoaded] = useState(false);
    const [reasonData, setReasonData] = useState({});
    const [reasonRows, setReasonRows] = useState([]);
    // Mobile Variables
    const [cognitoId, setCognitoId] = useState("");
    const [username, setUsername] = useState("");
    const [mobilePhone, setMobilePhone] = useState("");
    const [mobileEmail, setMobileEmail] = useState("");

    startDate = formatDateIso(getToday(-28));
    endDate = formatDateIso(getToday());

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", payload: {resident_id: residentId, campus_id: campusId},
                          procedure: "getresidentprofile", reqType: "stored"}).then(data => {
                if (data !== undefined){
                    setResBasicData(data?.length > 0 ? data[0] : {});
                }
            });
            ApiRequester({reqEndpoint: "myfetch", payload: {resident_id: residentId, campus_id: campusId},
                          procedure: "getresidentdevices", reqType: "stored"}).then(data => {
                setResDeviceData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", payload: {resident_id: residentId, campus_id: campusId},
                          procedure: "getresidentwellness", reqType: "stored"}).then(data => {
                setResWellData(data[0]);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "sp_dev_getareasbycampusid", reqType: "stored"}).then(data => {
                setLocationData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", payload: {device_filter: "", campus_id: campusId},
                                procedure: "sp_dev_getInovonicsDevices", reqType: "stored"}).then(data => {
                setDeviceData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "getresidentdevicecategories", reqType: "stored"}).then(data => {
                setDeviceCatData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "getinovonicsdevicetypes", reqType: "stored"}).then(data => {
                setDeviceTypeData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "getaccutechresidentdevices", reqType: "stored"}).then(data => {
                setAccutechDeviceData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "att_manufactures",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setManufactureData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", payload: {resident_id: residentId},
                                procedure: "getresidentevents", reqType: "stored"}).then(data => {
                setEventData(data);
            });
            setCount(count => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        if (![basicLoaded, areaLoaded].includes(false)){
            setPageLoaded(true);
        }
    }, [basicLoaded, areaLoaded]);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setRepCount(repCount => repCount - 1);
        }, 1000);
        if (repCount <= 0){
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "dashboardhourchart",
                                campus_id: campusId, resident_filter: residentId.toString()}).then(data => {
                setHourData(data);
                setHourDataLoaded(true);
            });
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "dashboardweekchart",
                                campus_id: campusId, resident_filter: residentId.toString()}).then(data => {
                setWeekData(data);
                setWeekDataLoaded(true);
            });
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "eventReasonSummary",
                        campus_id: campusId, start_date: startDate, end_date: endDate,
                        device_category_filter: "", area_type_filter: "", reason_filter: "", user_filter: "",
                        event_type_filter: "", resident_filter: residentId.toString()}).then(data => {
                setReasonData(() => data ? data : []);
            });
            setRepCount(repCount => 1200);
        }
        return () => clearInterval(interval);
    }, [repCount]);

    useEffect(() => {
        if (resBasicData !== {}){
            setFirstName(() => resBasicData?.resident_first_name ? resBasicData.resident_first_name : "");
            setLastName(() => resBasicData?.resident_last_name ? resBasicData.resident_last_name : "");
            setPhone(() => resBasicData?.resident_phone ? formatPhone(resBasicData.resident_phone) : "");
            setEmail(() => resBasicData?.resident_email ? resBasicData.resident_email : "");
            setNickName(() => resBasicData?.resident_nickname ? resBasicData.resident_nickname : "");
            setArea(() => resBasicData?.area_name ? resBasicData.area_name : "");
            setAreaId(() => resBasicData?.area_id ? resBasicData.area_id : "");
            setImageData(() => resBasicData?.image_url ? resBasicData?.image_url : "");
            setCognitoId(() => resBasicData?.cognito_id ? resBasicData.cognito_id : "");
            setUsername(() => resBasicData?.username ? resBasicData.username : "");
            setMobilePhone(() => resBasicData?.mobile_phone ? resBasicData.mobile_phone : "");
            setMobileEmail(() => resBasicData?.mobile_email ? resBasicData.mobile_email : "");
            setIsActive(() => resBasicData?.is_active ? resBasicData.is_active : false);
            setNotes(() => resBasicData?.notes ? resBasicData.notes : "")
            setResGroups(() => resBasicData?.groups ? resBasicData.groups : [])
            setEhrId(() => resBasicData?.ehr_external_id ? resBasicData.ehr_external_id : "");
            if (dData?.access?.Community?.Residents?.Write && resBasicData?.is_active !== undefined){
                setEditable(() => resBasicData?.is_active ? resBasicData.is_active : false);
            }
            setBasicLoaded(true);
        }
    }, [resBasicData]);

    useEffect(() => {
        let tmp = [];
        if (resBasicData !== {}){
            for (let i = 0; i < resDeviceData?.length; i++){
                if (resDeviceData[i].manufacture_id === 6){
                    tmp.push(
                        <li key={"device-name-"+i.toString()} className={"mt-2"}>
                            {editable ?
                            <i className={"fe fe-x-square red-hover fs-1 me-3"} style={{verticalAlign: "sub"}} title={"Detach device"}
                               onClick={() => {
                                   setDeviceToRemove(() => resDeviceData[i]?.device_id);
                                   showModal("deviceconfirmmodal");
                               }}/> : <></>}
                            <Link to={`/profiles/accutech/${resDeviceData[i].device_id}`}>{resDeviceData[i].description} ({resDeviceData[i].inovonics_serial_number})</Link>
                        </li>
                    );
                }else{
                    tmp.push(
                        <li key={"device-name-"+i.toString()} className={"mt-2"}>
                            {editable ?
                            <i className={"fe fe-x-square red-hover fs-1 me-3"} style={{verticalAlign: "sub"}} title={"Detach device"}
                               onClick={() => {
                                   setDeviceToRemove(() => resDeviceData[i]?.device_id);
                                   showModal("deviceconfirmmodal");
                               }}/> : <></>}
                            <Link to={`/profiles/inovonics/${resDeviceData[i].device_id}`}>{resDeviceData[i].description} ({resDeviceData[i].inovonics_serial_number})</Link>
                        </li>
                    );
                }
            }
        }
        setDevices(() => tmp);
    }, [resDeviceData, editable]);

    useEffect(() => {
        if (resWellData){
            setCheckin(() => resWellData?.is_resident_checkin);
            setRounds(() => resWellData?.is_rounding);
            setCareflow(() => resWellData?.careflow_name);
            setEhs(() => resWellData?.ehs_provider);
            setEhsUrl(() => resWellData?.ehs_url);
        }
    }, [resWellData]);

    useEffect(() => {
        setEventTableRows(<TableBody start={0} end={eventData.length} tableData={eventData} checkbox={false}
                            writeAccess={dData?.access?.Community?.Residents?.Write} unk={"a-"}
                            actionAppearance={() => {}} parentComponent={"Resident"}
                            tableHeaders={eventHeaderData} editModal={() => {}} modal={false}
                            dData={dData} checkboxlist={[]}
                            setCheckboxId={() => {}} checkidkey={"area_id"} />);
    }, [eventData]);

    useEffect(() => {
        let i, tmpOc = [], tmpVa = [], tmp, pOccupancy;
        for (i = 0; i < locationData?.length; i++){
            pOccupancy = locationData[i]?.number_residents;
            if (pOccupancy && pOccupancy >= locationData[i].max_occupancy && areaId.toString() === locationData[i]?.area_id?.toString()){
                tmpOc.push(
                    <option key={"location-option"+i.toString()} value={locationData[i].area_id}>{locationData[i].area_name}</option>
                );
            }else{
                tmpVa.push(
                    <option key={"location-option"+i.toString()} value={locationData[i].area_id}>{locationData[i].area_name}</option>
                );
            }
        }
        tmp = tmpVa.concat(tmpOc);
        setLocationOptions(() => tmp);
        setAreaLoaded(true);
    }, [locationData]);

    useEffect(() => {
        let tmp = [];
        let reasons = reasonData?.reasons ? reasonData.reasons : [];
        for (let i=0; i < reasons?.length; i++){
            tmp.push(
                <tr key={`summaryRow-reasons-${i}`}>
                    <td className={"ps-1"}>{reasons[i].reason_name}</td>
                    <td className={"ps-1 text-center"}>{reasons[i].count}</td>
                    <td className={"ps-1 text-center"}>{reasons[i].percent}</td>
                    <td className={"ps-1 text-center"}>{reasons[i]?.avg_response_time}</td>
                </tr>
            );
        }
        setReasonRows(() => tmp);
    }, [reasonData]);

    useEffect(() => {
        let i, tmp = [];
        manufactureData.sort((a, b) => sortFunc(a, b, "manufacture_name", "desc"));
        for (i = 0; i < manufactureData.length; i++){
            tmp.push(
                <option key={"manufacture-option-"+manufactureData[i]?.manufacture_id.toString()}
                        value={manufactureData[i]?.manufacture_id}>{manufactureData[i]?.manufacture_name}
                </option>
            );
        }
        setManufactureOptions(() => tmp);
    }, [manufactureData]);

    useEffect(() => {
        let i, tmp = [];
        if (manufactureId.toString() === "1"){
            deviceTypeData.sort((a, b) => sortFunc(a, b, "device_type_name", "desc"));
            for (i = 0; i < deviceTypeData.length; i++){
                if (deviceTypeData[i]?.device_category_id.toString() === deviceCatId.toString()){
                    tmp.push(
                        <option key={"device-use-option-"+deviceTypeData[i]?.inovonics_type_id.toString()}
                                value={deviceTypeData[i]?.inovonics_type_id}>{deviceTypeData[i]?.device_type_name}
                        </option>
                    );
                }
            }
        }else if (manufactureId.toString() === "6"){
            accutechDeviceData.sort((a, b) => sortFunc(a, b, "description", "desc"));
            for (i = 0; i < accutechDeviceData.length; i++){
                tmp.push(
                    <option key={"device-use-option-"+accutechDeviceData[i]?.accutech_device_id.toString()}
                            value={accutechDeviceData[i]?.accutech_device_id}>{accutechDeviceData[i]?.description}
                    </option>
                );
            }
        }
        setDeviceTypeOptions(() => tmp);
    }, [deviceTypeData, deviceCatId, accutechDeviceData, manufactureId]);

    useEffect(() => {
        let tmpOptions = []
        if (manufactureId.toString() === "1"){
            for (let i=0; i < deviceCatData.length; i++){
                tmpOptions.push(
                    <option key={"device-category-option-"+deviceCatData[i].device_category_id.toString()}
                            value={deviceCatData[i].device_category_id}>{deviceCatData[i].device_category_short_name}
                    </option>
                );
            }
        }else if (manufactureId.toString() === "6"){
            tmpOptions.push(
                <option key={"device-category-option-1"}
                        value={0}>{"RF Tags"}
                </option>
            )
        }
        setDeviceCatOptions(() => tmpOptions);
    }, [deviceCatData, accutechDeviceData, manufactureId]);

    useEffect(() => {
        formCheck("pendantForm", "pendantSubmit");
    }, [listenDevice]);

    useEffect(() => {
        formCheck("pendantForm", "pendantSubmit");
    }, [selectReq]);

    useEffect(() => {
        let product = document.getElementById("description").value;
        if (!listening){
            // just toggled off & possible first run
            if (!onLoad){
                setListenIcon(
                    <i className={"fe fe-rss pointer-no fs-2"}></i>
                );
                toast.info("Listening stopped.");
                hideListenList();
            }else{
                setOnLoad(false);
            }
        }else{
            if (product !== undefined && product !== ""){
                setListenIcon(
                    <i className={"fe fe-rss icon-vibrant pointer-no fs-2"}></i>
                );
                publishListen(product);
                showListenList();
            }else{
                toast.warning("Listening toggled on, no product selected.");
            }
        }
    }, [listening]);

    useEffect(() => {
        let interval;
        if (listening){
            interval = setInterval(() => {
                setListenCount(listenCount  => listenCount  + 1);
            }, 1000);
            if (listenCount < 60){
                if (listenCount % 4 === 0){
                    let pType = document.getElementById("deviceProductType").value;
                    ApiRequester({reqEndpoint: "getitems", Table: "LearnIn", PKey: "ClientId",
                        PVal: dData.sessionId, CampusId: campusId, Search: parseInt(pType)}).then(data => {
                            setListenData(data);
                    });
                }
            }
            if (listenCount === 60){
                toast.info("Capture timeout, restart capture if device not in list.");
            }
        }
        return () => clearInterval(interval);
    }, [listenCount]);

    useEffect(() => {
        let tmpList = [];
        for (let i=0; i < listenData.length; i++){
            tmpList.push(
                <option key={"listen-"+listenData[i]?.trx_id} value={listenData[i]?.trx_id}>{listenData[i]?.name} ({listenData[i]?.trx_id})</option>
            );
        }
        setListenList(tmpList);
        if (listenDevice === "" && listenData.length > 0){
            setListenDevice(() => listenData[0]?.trx_id);
            toast.success("Devices found.");
        }
    }, [listenData]);

    useEffect(() => {
        if (hourData !== undefined && hourDataLoaded){
            if (Chart.getChart("perhour") !== undefined){
                const curChart = Chart.getChart("perhour");
                curChart.data.labels = hourData?.labels ? hourData.labels : [];
                curChart.data.datasets[0].data = hourData?.events ? hourData.events : [];
                curChart.data.datasets[1].data = hourData?.responses ? hourData.responses : [];
                curChart.options.plugins.annotation.annotations.line1.value = hourData?.avgValue ? hourData.avgValue : 0
                curChart.options.plugins.annotation.annotations.line2.value = hourData?.avgResp ? hourData.avgResp : 0;
                curChart.resize();
                curChart.update();
            }
            else{
                new Chart('perhour', {
                    options: {
                        responsive: true,
                        plugins: {
                            annotation: {
                                drawTime: 'afterDatasetsDraw',
                                annotations: {
                                    line1: {
                                        type: 'line',
                                        borderDash: [8, 4],
                                        borderWidth: 4,
                                        scaleID: "a",
                                        borderColor: "#0081C778",
                                        value: hourData?.avgValue ? hourData.avgValue : 0,
                                        label: {
                                            display: false,
                                            backgroundColor: "#0081C7",
                                            borderColor: "#000000",
                                            borderWidth: 2,
                                            content: `Alarm Average: ${hourData?.avgValue ? hourData.avgValue : 0}`,
                                        },
                                        enter({element}, event) {
                                            element.label.options.display = true;
                                            return true;
                                        },
                                        leave({element}, event) {
                                            element.label.options.display = false;
                                            return true;
                                        }
                                    },
                                    line2: {
                                        type: 'line',
                                        borderDash: [8, 4],
                                        borderWidth: 4,
                                        scaleID: "b",
                                        borderColor: "#BE4D2578",
                                        value: hourData?.avgResp ? hourData.avgResp : 0,
                                        label: {
                                            display: false,
                                            backgroundColor: "#BE4D25",
                                            borderColor: "#000000",
                                            borderWidth: 2,
                                            content: `Response Time Average: ${hourData?.avgResp ? hourData.avgResp : 0}`,
                                        },
                                        enter({element}, event) {
                                            element.label.options.display = true;
                                            return true;
                                        },
                                        leave({element}, event) {
                                            element.label.options.display = false;
                                            return true;
                                        }
                                    }
                                }
                            },
                            legend: {
                                display: true
                            },
                        },
                        scales: {
                            x: {
                                title: {
                                    display: true,
                                    text: "Time of the Day"
                                },
                                grid: {
                                    display: false
                                },
                            },
                            a: {
                                id: "a",
                                position: "left",
                                grid: {
                                    display: false
                                },
                                title: {
                                    display: true,
                                    text: "Total Alarms"
                                },
                                suggestedMax: 10,
                                suggestedMin: 0,
                                ticks: {
                                    callback: function (value) {
                                        return value
                                    }
                                }
                            },
                            b: {
                                id: "b",
                                position: "right",
                                title: {
                                    display: true,
                                    text: "Average Response Time"
                                },
                                grid: {
                                    display: false
                                },
                                suggestedMax: 10,
                                suggestedMin: 0,
                                ticks: {
                                    callback: function (value) {
                                        return value
                                    }
                                }
                            }
                        }
                    },
                    data: {
                        labels: hourData?.labels?.length > 0 ? hourData.labels : ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12",
                                "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"],
                        datasets: [{
                            type: "bar",
                            label: 'Total Alarms',
                            data: hourData?.events ? hourData.events : [],
                            backgroundColor: ["#0081C7"],
                            hoverBackgroundColor: ["#0081C778"],
                            yAxisID: "a",
                            order: 2
                        },{
                            type: "line",
                            label: "Average Response Time",
                            data: hourData?.responses ? hourData.responses : [],
                            backgroundColor: ["#BE4D25"],
                            hoverBackgroundColor: ["#BE4D2578"],
                            borderColor: ["#BE4D2578"],
                            fill: false,
                            yAxisID: "b",
                            order: 1
                        }]
                    }
                });
            }
        }
    }, [hourData]);

    useEffect(() => {
        if (weekData !== undefined && weekDataLoaded){
            if (Chart.getChart("perweek") !== undefined){
                const curChart = Chart.getChart("perweek");
                curChart.data.labels = weekData?.labels ? weekData.labels : [];
                curChart.data.datasets[0].data = weekData?.events ? weekData.events : [];
                curChart.resize();
                curChart.update();
            }
            else{
                new Chart('perweek', {
                    options: {
                        responsive: true,
                        plugins: {
                            legend: {
                                display: true
                            },
                        },
                        scales: {
                            x: {
                                title: {
                                    display: true,
                                    text: "Weeks"
                                },
                                grid: {
                                    display: false
                                },
                            },
                            a: {
                                id: "a",
                                position: "left",
                                title: {
                                    display: true,
                                    text: "Total Alarms"
                                },
                                grid: {
                                    display: false
                                },
                                suggestedMax: 10,
                                suggestedMin: 0,
                                ticks: {
                                    callback: function (value) {
                                        return value
                                    }
                                }
                            }
                        }
                    },
                    data: {
                        labels: weekData?.labels?.length > 0 ? weekData.labels : [],
                        datasets: [{
                            type: "line",
                            label: "Total Alarms",
                            data: weekData?.events?.length > 0 ? weekData.events : [],
                            backgroundColor: ["#0081C7"],
                            hoverBackgroundColor: ["#0081C778"],
                            borderColor: ["#0081C778"],
                            fill: false,
                            yAxisID: "a",
                            order: 1
                        }]
                    }
                });
            }
        }
    }, [weekData]);

    function basicActionHandling(){
        document.getElementById("name-static").classList.toggle("d-none");
        document.getElementById("first-name-edit").classList.toggle("d-none");
        document.getElementById("last-name-edit").classList.toggle("d-none");
        document.getElementById("nickname-static").classList.toggle("d-none");
        document.getElementById("nickname-edit").classList.toggle("d-none");
        document.getElementById("phone-static").classList.toggle("d-none");
        document.getElementById("phone-edit").classList.toggle("d-none");
        document.getElementById("email-static").classList.toggle("d-none");
        document.getElementById("email-edit").classList.toggle("d-none");
        document.getElementById("area-static").classList.toggle("d-none");
        document.getElementById("area-edit").classList.toggle("d-none");
        document.getElementById("notes-edit").classList.toggle("d-none");
        document.getElementById("notes-static").classList.toggle("d-none");
        document.getElementById("basic-col2-div").classList.toggle("mx-5");
        if (document.getElementById("edit-basic-btn").innerText === "Edit"){
            document.getElementById("edit-basic-btn").innerText = "Cancel";
            document.getElementById("save-basic-btn").classList.toggle("d-none");
            document.getElementById("first-name-edit").value = firstName;
            document.getElementById("last-name-edit").value = lastName;
            document.getElementById("nickname-edit").value = nickName;
            document.getElementById("phone-edit").value = phone;
            document.getElementById("email-edit").value = email;
            document.getElementById("area-edit").value = areaId;
            document.getElementById("notes-edit").value = notes;
        }else{
            document.getElementById("edit-basic-btn").innerText = "Edit";
            document.getElementById("save-basic-btn").classList.toggle("d-none");
        }
        formCheck("basic-form", "save-basic-btn");
    }

    function basicSubmit(){
        let first, last, phone, email, aId, nickname, payload, noteValue;
        if (editable){
            handleSubmitActions("save-basic-btn", "load-basic-btn");
            first = document.getElementById("first-name-edit").value;
            last = document.getElementById("last-name-edit").value;
            nickname = document.getElementById("nickname-edit").value;
            phone = document.getElementById("phone-edit").value.replaceAll("-", "");
            email = document.getElementById("email-edit").value;
            aId = document.getElementById("area-edit").value;
            noteValue = document.getElementById("notes-edit").value;
            payload = [{resident_id: parseInt(residentId), resident_first_name: first, resident_last_name: last, notes: noteValue,
                        resident_email: email.toLowerCase(), resident_phone: phone, area_id: parseInt(aId), resident_nickname: nickname}];
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_resident", PKey: "resident_id", ClientId: dData.sessionId,
                                Condition: "primary", urlType: "POST", User: dData.user, Payload: payload, CampusId: campusId}).then(data => {
                if (data && data[0]){
                    setCount(0);
                    formSuccess("Basic information saved.", "save-basic-btn", "load-basic-btn", "edit-basic-btn");
                }
                else{
                    formFail(`Error during save, error code: ${data && data?.length > 1 ? data[1] : ""}`, "save-basic-btn", "load-basic-btn");
                }
            });
        }else{
            toast.warn("You do not have permissions to make changes.");
        }
    }

    function performEhrSubmit(){
        let ehrid, payload;
        if (editable){
            handleSubmitActions("save-basic-btn", "load-basic-btn");
            ehrid = document.getElementById("ehr-id-edit").value;
            payload = [{resident_id: parseInt(residentId), ehr_external_id: ehrid}];
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_resident", PKey: "resident_id", ClientId: dData.sessionId,
                                Condition: "primary", urlType: "POST", User: dData.user, Payload: payload, CampusId: campusId}).then(data => {
                if (data && data[0]){
                    setCount(0);
                    handleSubmitActions("ehrSubmit", "loadEhrSubmit");
                }
                else{
                    formFail(`Error during save, error code: ${data && data?.length > 1 ? data[1] : ""}`, "save-basic-btn", "load-basic-btn");
                }
            });
        }else{
            toast.warn("You do not have permissions to make changes.");
        }
    }

    function handleFileUpload(){
        let file, filereader;
        if (editable){
            file = document.getElementById('image-upload').files[0];
            setNewFileName(() => file.name);
            setNewFileExt(() => file.name.split(".")[1]);
            filereader = new FileReader();
            filereader.onload = function(event) {
                setNewFile(() => event.target.result);
            }
            filereader.readAsDataURL(file);
        }else{
            toast.warn("You do not have permissions to make changes.");
        }
    }

    function imageSubmit(){
        let oldURL, oldFilename;
        if (editable){
            handleSubmitActions("imageUploadSubmit", "loadImageUploadSubmit");

            oldFilename = "";
            if (resBasicData?.image_url){
                oldURL = resBasicData.image_url.split("/");
                oldFilename = oldURL.slice(-1)[0];
            }

            let payload = {image_file: newFile, filename: `${uuidv4()}.${newFileExt}`, resident_id: residentId, campus_id: campusId,
                            oldfilename: oldFilename};
            ApiRequester({reqEndpoint: "mytransaction", TransType: "resident_photo_upload", ClientId: dData.sessionId,
                                Payload: payload, User: dData.user, CampusId: campusId}).then(data => {
                if (data && data[0]){
                    setCount(0);
                    formSuccess("File uploaded successfully.", "imageUploadSubmit", "loadImageUploadSubmit", "cancelImageUploadBtn");
                }
                else{
                    formFail(`Error during file upload: ${data[1]}`, "imageUploadSubmit", "loadImageUploadSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to make changes.");
        }
    }

    function pendantSubmit(initial){
        let description, msg, payload, serialNum, useId, modelId, dRec, devName;
        if (editable){
            serialNum = listenDevice;
            if (!serialNum || serialNum === ""){
                serialNum = document.getElementById("serialNum")?.value;
                if (!serialNum || serialNum === ""){
                    serialNum = document.getElementById("serialNum2")?.value;
                }
            }
            if (serialNum && !isNaN(serialNum)){
                serialNum = parseInt(serialNum);
            }
            dRec = deviceExists(serialNum);
            if (initial){
                if (dRec && dRec?.is_active){
                    setAssignedRes(dRec?.resident_name);
                    hideModal("pendantmodal");
                    showModal("pendantconfirmmodal");
                    return
                }else{
                    handleSubmitActions("pendantSubmit", "loadPendantSubmit");
                }
            } else{
                handleSubmitActions("pendantConfirmSubmit", "loadPendantConfirmSubmit");
            }

            if (listening){
                setListening(false);
                setListenCount(60);
            }

            description = document.getElementById("description").value;
            devName = document.getElementById("device_name").value;
            modelId = document.getElementById("deviceModelId").value;

            if (manufactureId.toString() === "1"){
                useId = determineUseId(deviceCatId, deviceTypeId);
            }else{
                useId = 0;
            }

            devName = determineDevName(devName, deviceTypeId);

            if (dRec){
                // Edit device and add to resident
                payload = [{device_id: dRec.device_id, campus_id: campusId, is_active: 1, resident_id: residentId,
                            device_name: devName, manufacture_model_id: parseInt(modelId), device_use_id: parseInt(useId),
                            descriptions: description}]
                ApiRequester({reqEndpoint: "mysave", Tbl: "core_device", PKey: "device_id", ClientId: dData.sessionId,
                                Condition: "primary", urlType: "POST", User: dData.user, Payload: payload, CampusId: campusId}).then(data => {
                    if (data && data[0] === true){
                        msg = `${devName} has been added to ${firstName} ${lastName}`;
                        if (!initial){
                            handleSubmitActions("loadPendantConfirmSubmit", "pendantConfirmSubmit");
                            handleSubmitActions("loadPendantSubmit", "pendantSubmit");
                            document.getElementById("pendantForm").reset();
                            document.getElementById("pendantConfirmClose").click();
                            toast.success(msg);
                            setCount(0);
                        }else{
                            setCount(0);
                            formSuccess(msg, "pendantSubmit", "loadPendantSubmit", "cancelPendantBtn", "pendantForm");
                        }
                    }
                    else{
                        toast.error(`Failed to add ${devName}`);
                    }
                });
            }else{
                // Add device and add to resident
                payload = [{campus_id: campusId, manufacture_id: parseInt(manufactureId), manufacture_uuid: serialNum.toString(), device_name: devName,
                            device_use_id: parseInt(useId), is_active: true, is_alerting_now: false, is_server: false,
                            is_area_device: false, is_resident: true, resident_id: residentId, manufacture_model_id: parseInt(modelId),
                            escalation_enabled: true, careflow_id: 1, descriptions: description}]
                ApiRequester({reqEndpoint: "mysave", Tbl: "core_device", Payload: payload, urlType: "PUT",
                                    CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
                    if (data && data[0] === true){
                        msg = `${devName} has been added to ${firstName} ${lastName}`;
                        formSuccess(msg, "pendantSubmit", "loadPendantSubmit", "cancelPendantBtn", "pendantForm");
                        setCount(0);
                    }
                    else{
                        toast.error(`Failed to add ${devName}`);
                        handleSubmitActions("loadPendantSubmit", "pendantSubmit")
                    }
                });
            }
        }else{
            toast.warn("You do not have permissions to make changes.");
        }
    }

    function deviceExists(serialNum){
        for (let i=0; i < deviceData?.length; i++){
            if (serialNum.toString() === deviceData[i]?.manufacture_uuid.toString()) {
                return deviceData[i];
            }
        }
        return false;
    }

    function setDeviceIds(devTypeId){
        let productCode, modelId, msgClass, pType, devName;
        productCode = document.getElementById("productCode");
        modelId = document.getElementById("deviceModelId");
        devName = document.getElementById("device_name");
        msgClass = document.getElementById("deviceMsgClass");
        pType = document.getElementById("deviceProductType");
        if (manufactureId?.toString() === "1"){
            for (let i = 0; i < deviceTypeData.length; i++){
                if (deviceTypeData[i]?.inovonics_type_id.toString() === devTypeId.toString()){
                    productCode.value = deviceTypeData[i]?.model_name;
                    modelId.value = deviceTypeData[i]?.inovonics_device_id;
                    devName.value = deviceTypeData[i]?.device_type_name;
                    msgClass.value = deviceTypeData[i]?.message_class;
                    pType.value = deviceTypeData[i]?.product_type_id;
                    checkListening(deviceTypeData[i]?.device_type_name);
                    break
                }
            }
        }else if (manufactureId?.toString() === "6"){
            for (let i = 0; i < accutechDeviceData.length; i++){
                if (accutechDeviceData[i]?.accutech_device_id.toString() === devTypeId.toString()){
                    productCode.value = accutechDeviceData[i]?.Accutech_model_id;
                    modelId.value = accutechDeviceData[i]?.accutech_device_id;
                    devName.value = accutechDeviceData[i]?.description;
                    msgClass.value = 0;
                    pType.value = 0;
                    break
                }
            }
        }
    }

    function checkListening(device){
        if (listening && device){
            // Toggle already on, and just set device
            setListenIcon(
                <i className={"fe fe-rss icon-vibrant pointer-no fs-2"}></i>
            );
            showListenList();
            publishListen(device);
        }else{
            if (!device && listening){
                // Listening but just cleared the device
                setListenIcon(
                    <i className={"fe fe-rss pointer-no fs-2"}></i>
                );
                setListening(false);
            }else{
                // Not listening
            }
        }
    }

    function showListenList(){
        setSelectReq(true);
        setListenDevice(() => "");
        setListenList(() => []);
        document.getElementById("serialNum").required = false;
        document.getElementById("serialToggle").classList.add("d-none");
        document.getElementById("serial2Toggle").classList.remove("d-none");
        document.getElementById("serialNum").value = "";
    }

    function hideListenList(){
        setSelectReq(false);
        setListenDevice(() => "");
        setListenList(() => []);
        document.getElementById("serialNum").required = true;
        document.getElementById("serialToggle").classList.remove("d-none");
        document.getElementById("serial2Toggle").classList.add("d-none");
    }


    function publishListen(device){
        let msgClass, pType;
        msgClass = document.getElementById("deviceMsgClass").value;
        pType = document.getElementById("deviceProductType").value;
        ApiRequester({reqEndpoint: "mqtt", Operation: "learnin", ClientId: dData.sessionId, ProductType: pType,
                            CampusId: dData?.acnt, MsgClass: msgClass}).then(data => {

            if (data[0]){
                toast.info("Listening for "+device);
                setListenCount(0);
            }
            else{
                toast.error("Error with auto listening, try again, " +
                    "if persist contact your customer success for assistance.");
                setListening(false);
            }
        });
    }

    function addMobileSubmit(){
        // User add form submit function
        let roomPh, payload, email, msg, newUsername, passwd;
        handleSubmitActions("addMobileSubmit", "loadAddMobileSubmit");
        email = document.getElementById("mobile-email-add").value;
        roomPh = document.getElementById("mobile-phone-add").value.replaceAll("-", "");
        newUsername = document.getElementById("username").value;
        passwd = document.getElementById("password").value;
        payload = [{Action: "new", UserEmail: email.toLowerCase(), Username: newUsername, UserPass: passwd, CampusId: campusId,
                    ClientId: dData.sessionId, PhoneNumber: roomPh, RegisterType: "resident", resident_id: residentId}];
        ApiRequester({reqEndpoint: "user-manage", Payload: payload}).then(data => {
            if (data[0] === true){
                setCount(0);
                formSuccess(`${firstName} ${lastName} has been signed up for the PalCare resident mobile app.`,
                    "addMobileSubmit", "loadAddMobileSubmit", "cancelAddMobileBtn", "addMobileForm");
            }
            else{
                if (data[1] === "1"){
                    msg = "Username already exists, please change username.";
                }
                else if (data[1] === "2"){
                    msg = "Error, user created, failed saving user details. Contact your customer success manager for assistance.";
                }
                else if (data[1] === "8"){
                    msg = "Request aborted, missing required data. Contact your customer success manager for assistance.";
                }
                else if (data[1] === "9"){
                    msg = "Request aborted, no matching action. Contact your customer success manager for assistance.";
                }
                else{
                    msg = "Unknown failure, request not processed.";
                }
                formFail(msg, "addMobileSubmit", "loadAddMobileSubmit");
            }
        });
    }

    function removeDevice(deviceId){
        let payload;
        if (editable){
            handleSubmitActions("deviceConfirmSubmit", "loadDeviceConfirmSubmit");
            payload = [{device_id: deviceId, is_active: false, resident_id: null}];
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_device", Payload: payload, urlType: "POST", User: dData.user,
                                PKey: "device_id", Condition: "primary", CampusId: campusId, ClientId: dData.sessionId}).then(data => {
                if (data && data[0] === true){
                    setCount(0);
                    formSuccess("Device has been removed", "deviceConfirmSubmit", "loadDeviceConfirmSubmit", "deviceCancelBtn");
                }
                else{
                    formFail("Failed to remove device", "deviceConfirmSubmit", "loadDeviceConfirmSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to make changes.");
        }
    }

    function passSubmit(){
        let password, username, msg, payload;
        handleSubmitActions("passSubmit", "loadPassSubmit");
        password = document.getElementById("passwordR").value;
        payload = [{Action: "reset", CampusId: campusId, Username: username, UserPass: password, RegisterType: "resident"}];
        ApiRequester({reqEndpoint: "user-manage", Payload: payload}).then(data => {
            if (data[0] === true){
                setCount(0);
                formSuccess("Password reset successfully.", "passSubmit", "loadPassSubmit",
                    "cancelPassBtn", "passForm");
            }
            else{
                if (data[1] === "3"){
                    msg = "Current password does not match records.";
                }
                else if (data[1] === "4"){
                    msg = "User not authorized.";
                }
                else if (data[1] === "5"){
                    msg = "Error encountered authenticating user. Please contact support for assistance if error persists.";
                }
                else if (data[1] === "6"){
                    msg = "New password failed to be set, check password and try again. Contact support if failure persists.";
                }
                else{
                    msg = "Unknown failure, request not processed.";
                }
                formFail(msg, "passSubmit", "loadPassSubmit");
            }
        });
    }

    function mobileDeleteSubmit(deviceId){
        let payload
        if (editable){
            handleSubmitActions("mobileConfirmSubmit", "loadMobileConfirmSubmit");
            payload = [{resident_id: residentId}]
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_resident_mobile", Payload: payload, urlType: "DELETE",
                                PKey: "resident_id", Condition: "primary", CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
                if (data && data[0] === true){
                    setCount(0);
                    formSuccess(`${username} no longer has access to the resident mobile app`, "mobileConfirmSubmit", "loadMobileConfirmSubmit", "mobileCancelBtn");
                }
                else{
                    formFail("Account failed to be removed, contact support if error persists", "mobileConfirmSubmit", "loadMobileConfirmSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to make changes.");
        }
    }

    function deletePerform(submitBtn, loadBtn, closeId, start, end){
        let payload;
        handleSubmitActions(submitBtn, loadBtn);
        for (let i=0; i < resDeviceData.length; i++){
            removeDevice(resDeviceData[i].device_id);
        }
        payload = [{resident_id: parseInt(residentId)}];
        ApiRequester({reqEndpoint: "mysave", Tbl: "core_resident", Payload: payload, urlType: "DELETE", User: dData.user,
                            PKey: "resident_id", Condition: "primary", CampusId: campusId, ClientId: dData.sessionId}).then(data => {
            if (data[0]){
                document.getElementById(closeId).click();
                navigate("/community/residents");
            }
            else{
                formFail(`Error during delete, error code: ${data[1]}`, submitBtn, loadBtn);
            }
        });
    }

    function moveOutPerform(){
        let payload, msg;
        handleSubmitActions("moveOutConfirmSubmit", "loadMoveOutConfirmSubmit");
        for (let i=0; i < resDeviceData.length; i++){
            removeDevice(resDeviceData[i].device_id);
        }
        payload = [{resident_id: parseInt(residentId), area_id: 0, is_active: 0}];
        ApiRequester({reqEndpoint: "mysave", Tbl: "core_resident", Payload: payload, urlType: "POST", User: dData.user,
                            PKey: "resident_id", Condition: "primary", CampusId: campusId, ClientId: dData.sessionId}).then(data => {
            if (data[0]){
                msg = "Resident has been marked in-active"
                formSuccess(msg, "moveOutConfirmSubmit", "loadMoveOutConfirmSubmit",
                    "moveOutConfirmCancelBtn");
                setCount(0);
            }
            else{
                msg = `Error during delete, error code: ${data[1]}`
                formFail(msg, "moveOutConfirmSubmit", "loadMoveOutConfirmSubmit");
            }
        });
    }

    function moveInPerform(){
        let payload, msg, areaId;
        handleSubmitActions("moveInConfirmSubmit", "loadMoveInConfirmSubmit");
        areaId = document.getElementById("locationMoveIn")?.value;
        payload = [{resident_id: parseInt(residentId), area_id: areaId !== undefined ? parseInt(areaId) : 0, is_active: 1}];
        ApiRequester({reqEndpoint: "mysave", Tbl: "core_resident", Payload: payload, urlType: "POST", User: dData.user,
                            PKey: "resident_id", Condition: "primary", CampusId: campusId, ClientId: dData.sessionId}).then(data => {
            if (data[0]){
                msg = "Resident has been marked in-active"
                formSuccess(msg, "moveInConfirmSubmit", "loadMoveInConfirmSubmit",
                    "moveInConfirmCancelBtn");
                setCount(0);

            }
            else{
                msg = `Error during delete, error code: ${data[1]}`
                formFail(msg, "moveInConfirmSubmit", "loadMoveInConfirmSubmit");
            }
        });
    }

    const eventTableHeaders = <TableHeaders checkbox={false} headerData={eventHeaderData} checkAll={() => {}}
                                            writeAccess={dData?.access?.Community?.Residents?.Write} sortOrder={""}
                                            checkboxHeaderId={"uCheckHead"} sortColumn={""} sorter={{}}
                                            setSorter={() => {}} modal={false} checkboxlist={[]}
                                            actionAppearance={() => {}}/>

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div></div>
            <div>
                {isActive
                    ? <Button text={"Move Out"} class={"btn btn-danger me-3"} id={"moveOutBtn"}
                         style={{marginTop: "1.1rem"}} onClick={() => {
                            showModal("moveoutconfirmmodal");
                        }} />
                    : <Button text={"Move Back In"} class={"btn btn-primary me-3"} id={"moveOutBtn"}
                         style={{marginTop: "1.1rem"}} onClick={() => {
                             formCheck("moveInForm", "moveInConfirmSubmit");
                             showModal("moveinconfirmmodal");
                        }} />
                }
                <Button text={"Delete"} class={"btn btn-danger"} id={"deleteBtn"}
                         style={{marginTop: "1.1rem"}} onClick={() => {
                    showModal("deleteconfirmmodal");
                }} />
            </div>
        </div>
    );

    return (dData?.slauth === true && dData?.tKey != null && dData?.tKey !== "" && dData?.acnt != null && dData?.access?.Community?.Residents?.Read) ? (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Resident Profile"} Title={`${firstName} ${lastName}`} content={headerContent}/>
                <div className={"container-fluid mt-4"}>
                    <div className={"card"} style={{minWidth: "550px"}}>
                        <div className={"card-header d-flex justify-content-between"}>
                            <div>
                                <h3 className={"card-header-title d-inline-block"}>Basic Information</h3>
                                {!isActive && <h3 className={"text-danger d-inline-block ms-3 mb-0"}>- Moved Out</h3>}
                            </div>
                            <div>
                                <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"basic-card-btn"}
                                   onClick={() => toggleCollapse("basic-card-btn", "basic-card")}></i>
                            </div>
                        </div>
                        <div className={"card-body collapse collapse-content show"} id={"basic-card"}>
                            <div className={"text-center d-inline-block align-top p-2"}
                                 style={{borderRadius: "7px", backgroundColor: "#f9fbfd"}}>
                                <img style={{borderRadius: "12px"}} className={"align-top d-block"} width={"150px"}
                                     height={"150px"}
                                     src={imageData ? imageData : stockPhoto} alt={"Profile Headshot"}/>
                                {imageData
                                    ? <Button class={"mt-2 btn btn-outline-primary white"} text={"Update Image"}
                                              title={"Upload Image"} disabled={!editable}
                                              onClick={() => {
                                                  if (editable) {
                                                      showModal("imageuploadmodal");
                                                      document.getElementById("image-upload").click();
                                                  }
                                              }}/>
                                    : <Button class={"mt-2 btn btn-outline-primary white"} text={"Add Image"}
                                              title={"Upload Image"}
                                              onClick={() => {
                                                  if (editable) {
                                                      showModal("imageuploadmodal");
                                                      document.getElementById("image-upload").click();
                                                  }
                                              }} disabled={!editable}/>}
                                <input className={"d-none"} id={"image-upload"} type={"file"} accept={"image/*"}
                                       onChange={() => handleFileUpload()}/>
                            </div>
                            <form className={"d-inline align-top form-control-plaintext was-validated"}
                                  id={"basic-form"}>
                                <div className={"mx-5 mt-2 align-top d-inline-block"} id={"basic-col1-div"}>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "75px"}}>Name:</p>
                                        <p className={"ms-4"} id={"name-static"}>{firstName} {lastName}</p>
                                        <input className={"ms-4 d-none form-control form-control-sm"} required={true}
                                               style={{height: "30px", width: "125px"}} id={"first-name-edit"}
                                               defaultValue={firstName} pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                        <input className={"ms-2 d-none form-control form-control-sm"} required={true}
                                               style={{height: "30px", width: "125px"}} id={"last-name-edit"}
                                               defaultValue={lastName}
                                               onKeyUp={() => formCheck("basic-form", "save-basic-btn")}
                                               pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                    </div>
                                    <div className={"mt-2 d-flex"}>
                                        <p style={{minWidth: "75px"}}>Nickname:</p>
                                        <p className={"ms-4"} id={"nickname-static"}>{nickName}</p>
                                        <input className={"ms-4 d-none form-control form-control-sm"}
                                               style={{height: "30px", width: "256px"}} id={"nickname-edit"}
                                               defaultValue={nickName}
                                               onKeyUp={() => formCheck("basic-form", "save-basic-btn")}
                                               pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                    </div>
                                    <div className={"mt-2 d-flex"}>
                                        <p style={{minWidth: "75px"}}>Phone:</p>
                                        <p className={"ms-4"} id={"phone-static"}>{phone}</p>
                                        <input className={"ms-4 d-none form-control form-control-sm"}
                                               style={{height: "30px", width: "256px"}} id={"phone-edit"}
                                               defaultValue={phone}
                                               onKeyUp={() => formCheck("basic-form", "save-basic-btn")}
                                               pattern={"[0-9]{3}-?[0-9]{3}-?[0-9]{4}"}/>
                                    </div>
                                    <div className={"mt-2 d-flex"}>
                                        <p style={{minWidth: "75px"}}>Email:</p>
                                        <p className={"ms-4"} id={"email-static"}>{email}</p>
                                        <input className={"ms-4 d-none form-control form-control-sm"}
                                               style={{height: "30px", width: "256px"}} id={"email-edit"}
                                               defaultValue={email}
                                               onKeyUp={() => formCheck("basic-form", "save-basic-btn")}
                                               pattern={"[A-Za-z0-9._%+\\-]+@[A-Za-z0-9.\\-]+\\.[A-Za-z]{2,}$"}/>
                                    </div>
                                    <div className={"mt-2 d-flex"}>
                                        <p style={{minWidth: "75px"}}>Area:</p>
                                        <p className={"ms-4"} id={"area-static"}>
                                            <Link to={`/profiles/area/${areaId}`}>{area}</Link>
                                        </p>
                                        <select className={"ms-4 d-none form-select form-select-sm"}
                                                onChange={() => formCheck("basic-form", "save-basic-btn")}
                                                style={{height: "30px", width: "256px"}} id={"area-edit"}
                                                defaultValue={areaId}>
                                            {locationOptions}
                                        </select>
                                    </div>
                                </div>
                                <div className={"mx-5 mt-2 d-inline-block align-top"} id={"basic-col2-div"}
                                     style={{minWidth: "300px"}}>
                                    <div className={"mt-2 d-flex"}>
                                        <ul className={"ps-0 d-inline-block align-top mb-0"}
                                            style={{listStyle: "none"}}>
                                            <li><p style={{minWidth: "75px"}} className={"me-4"}>Groups:</p></li>
                                        </ul>
                                        <ul className={"ps-0 d-inline-block mb-0"} style={{listStyle: "none"}}>
                                            {resGroups?.map((group) => {
                                                return <li>{group?.resident_group_name}</li>
                                            })}
                                        </ul>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "75px"}}>Notes:</p>
                                        <p className={"ms-4"} style={{maxWidth: "250px"}}
                                           id={"notes-static"}>{notes}</p>
                                        <textarea className={"ms-4 d-none form-control form-control-sm"}
                                                  maxLength={2200}
                                                  style={{height: "auto", width: "250px", maxHeight: "300px"}}
                                                  id={"notes-edit"}
                                                  defaultValue={notes}
                                                  onKeyUp={() => formCheck("basic-form", "save-basic-btn")}/>
                                    </div>
                                </div>
                            </form>
                            {pageLoaded && <Button text={"Edit"} class={"btn btn-primary float-end"} id={"edit-basic-btn"}
                                    disabled={!editable} onClick={() => {
                                if (editable) {
                                    basicActionHandling()
                                }
                            }}/>}
                            <Button text={"Save"} class={"btn btn-primary float-end me-3 d-none"} id={"save-basic-btn"}
                                    disabled={!editable} onClick={() => {
                                if (editable) {
                                    basicSubmit()
                                }
                            }}/>
                            <Button type={"button"} class={"btn btn-primary float-end mx-3 d-none"} disabled={true}
                                    spinner={true} text={""} id={"load-basic-btn"}/>
                        </div>
                    </div>
                    <div className={"card"} style={{minWidth: "500px"}}>
                        <div className={"card-header d-flex justify-content-between"}>
                            <div>
                                <h3 className={"card-header-title d-inline-block"}
                                    style={{minWidth: "80px"}}>Devices</h3>
                                <div className={"d-none"} style={{display: "inline-block"}} id={"devices-header-data"}>
                                    <h3 className={"ms-6 my-0 d-inline-block text-success"}>{devices.length} {devices?.length === 1 ? "Device" : "Devices"}</h3>
                                </div>
                            </div>
                            <div>
                                <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"devices-card-btn"}
                                   onClick={() => {
                                       toggleCollapse("devices-card-btn", "devices-card");
                                       document.getElementById("devices-header-data").classList.toggle("d-none");
                                   }}></i>
                            </div>
                        </div>
                        <div className={"card-body collapse collapse-content show"} id={"devices-card"}>
                            <ul className={"d-inline-block"} style={{listStyle: "none"}}>
                                {devices}
                            </ul>
                            <Button text={"Attach"} class={"btn btn-primary float-end me-3"} id={"device-modal-btn"}
                                    disabled={!editable}
                                    onClick={() => {
                                        if (editable) {
                                            setManufactureId(0);
                                            setDeviceTypeId(0);
                                            setDeviceCatId(0);
                                            document.getElementById("listen-container")?.classList?.remove("d-none");
                                            formCheck("pendantForm", "pendantSubmit")
                                            showModal("pendantmodal");
                                        }
                                    }}/>
                        </div>
                    </div>
                    <div className={"card"} style={{minWidth: "500px"}}>
                        <div className={"card-header d-flex justify-content-between"}>
                            <div>
                                <h3 className={"card-header-title d-inline-block"}
                                    style={{minWidth: "80px"}}>Reporting</h3>
                                <div className={"d-none"} style={{display: "inline-block"}}
                                     id={"reporting-header-data"}>
                                    <h3 className={"ms-6 my-0 d-inline-block text-success"}>{!isNaN(hourData?.avgValue) ? hourData.avgValue : "?"} Alert/Week</h3>
                                    <h3 className={"mx-4 my-0 d-inline-block text-success"}>{!isNaN(weekData?.avgValue) ? weekData.avgValue : "?"} Avg/Resp</h3>
                                </div>
                            </div>
                            <div>
                                <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"reporting-card-btn"}
                                   onClick={() => {
                                       toggleCollapse("reporting-card-btn", "reporting-card")
                                       document.getElementById("reporting-header-data").classList.toggle("d-none");
                                   }}></i>
                            </div>
                        </div>
                        <div className={"card-body collapse collapse-content show"} id={"reporting-card"}>
                            <div className={"row"}>
                                <Link to={"/reports/alerts/run"} onClick={() => {
                                    setResFilters(dData, residentId, `${firstName} ${lastName}`);
                                }}>
                                    <Button text={"Run Detailed Report"} class={"btn btn-primary mb-4 float-end"}
                                            icon={true} iconClass={"fe fe-arrow-right-circle float-end ms-3"}/>
                                </Link>
                            </div>
                            <div className={"row"}>
                                <div className={"chart-container w-50 text-end align-top d-inline-block"}>
                                    {hourDataLoaded
                                        ?
                                        (<div className={"chart-container"}>
                                            <canvas className={"chart-canvas p-1"} style={{maxHeight: "425px"}}
                                                    id={"perhour"}></canvas>
                                        </div>)
                                        :
                                        (<div className={"text-center"}>
                                            <Button text={""} class={"border-0 bg-transparent btn"}
                                                    spinner={true}></Button>
                                        </div>)
                                    }
                                </div>
                                <div className={"chart-container w-50 text-center align-top d-inline-block"}>
                                    {weekDataLoaded
                                        ?
                                        (<div className={"chart-container"}>
                                            <canvas className={"chart-canvas p-1"} style={{maxHeight: "425px"}}
                                                    id={"perweek"}></canvas>
                                        </div>)
                                        :
                                        (<div className={"text-center"}>
                                            <Button text={""} class={"border-0 bg-transparent btn"}
                                                    spinner={true}></Button>
                                        </div>)
                                    }
                                </div>
                            </div>
                            <div className={"row mt-5"}>
                                <div className={"w-50 px-4"}>
                                    <table className={"table table-sm table-white table-hover"}>
                                        <thead>
                                        <tr>
                                            <th className={"table table-header w-auto py-1"} colSpan={"4"}>
                                                Event Reason Breakdown
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className={"table table-header w-auto py-1"}>
                                                Reason
                                            </th>
                                            <th className={"table table-header w-auto py-1"}>
                                                Total
                                            </th>
                                            <th className={"table table-header w-auto py-1"}>
                                                % Total Volume
                                            </th>
                                            <th className={"table table-header w-auto py-1"}>
                                                Average Response (min)
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className={"ps-1 fw-bold"}>Total Events</td>
                                            <td className={"ps-1 fw-bold text-center"}>{reasonData?.count ? reasonData.count : 0}</td>
                                            <td className={"ps-1 text-right"}></td>
                                            <td className={"ps-1 fw-bold text-center"}>{reasonData?.avg_response_time ? reasonData.avg_response_time : 0.0}</td>
                                        </tr>
                                        {reasonRows}
                                        </tbody>
                                    </table>
                                </div>
                                <div className={"w-50"} style={{maxHeight: "400px", overflowY: "auto"}}>
                                    <table className={"table table-sm table-white table-hover d-sm-table"}>
                                        <thead className={"sticky-table-head"} style={{top: 0}}>
                                        {eventTableHeaders}
                                        </thead>
                                        <tbody>
                                        {eventTableRows}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"card"} style={{minWidth: "500px"}}>
                        <div className={"card-header d-flex justify-content-between"}>
                            <div>
                                <h3 className={"card-header-title d-inline-block"}
                                    style={{minWidth: "80px"}}>Wellness</h3>
                            </div>
                            <div>
                                <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"wellness-card-btn"}
                                   onClick={() => toggleCollapse("wellness-card-btn", "wellness-card")}></i>
                            </div>
                        </div>
                        <div className={"card-body collapse collapse-content show"} id={"wellness-card"}>
                            <ul className={"d-inline-block"} style={{listStyle: "none"}}>
                                <li className={"mt-2"}>Check-ins:</li>
                                <li className={"mt-2"}>Rounds:</li>
                                <li className={"mt-2"}>Careflow Plan:</li>
                                <li className={"mt-2"}>EHR:</li>
                                <li className={"mt-2"}>EHR Id:</li>
                            </ul>
                            <ul className={"d-inline-block"} style={{listStyle: "none"}}>
                                <li className={"mt-2"}>{checkin === 1 ? "Yes" : "No"}</li>
                                <li className={"mt-2"}>{rounds === 1 ? "Yes" : "No"}</li>
                                <li className={"mt-2"}>{careflow ? careflow : "Default"}</li>
                                <li className={"mt-2"}>
                                    {ehs ? <a href={ehsurl} rel={"noreferrer"} target="_blank">
                                        {ehs}<i className={"fe fe-external-link ms-2"}></i>
                                    </a> : "None"}
                                </li>
                                <li className={"mt-2"}>
                                    <p id={"ehr-id-static"} style={{minWidth: "100px"}}>
                                        {resBasicData?.ehr_external_id != null ? resBasicData.ehr_external_id : "N/A"}
                                    </p>
                                    <input className={"ms-4 d-none form-control form-control-sm"}
                                           style={{height: "30px", width: "256px"}} id={"ehr-id-edit"} readOnly={true}
                                           value={resBasicData?.ehr_external_id != null ? resBasicData.ehr_external_id : ""}
                                           pattern={"^[0-9a-zA-Z_\\- ]+$"} onKeyUp={(e) => {setEhrId(e.target.value)}} />
                                    {ehs && editable
                                        ? <i className={"fe fe-edit as-btn-1x"} onClick={() => {
                                                document.getElementById("ehr-id-static").classList.toggle("d-none");
                                                document.getElementById("ehr-id-edit").classList.toggle("d-none");
                                            }}></i>
                                        : <></>
                                    }
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"}
                                            disabled={true} spinner={true} text={"Loading"} id={"loadEhrSubmit"}/>
                                    <Button text={"Save"} class={"d-none btn btn-primary ms-3"} id={"ehrSubmit"}
                                            onClick={() => performEhrSubmit()} />
                                </li>
                            </ul>
                        </div>
                    </div>
                    {isMobileActive ? <div className={"card"} style={{minWidth: "500px"}}>
                        <div className={"card-header d-flex justify-content-between"}>
                            <div>
                                <h3 className={"card-header-title d-inline-block"}
                                    style={{minWidth: "80px"}}>Mobile</h3>
                                <div className={"d-none"} style={{display: "inline-block"}} id={"mobile-header-data"}>
                                    <h3 className={"ms-6 my-0 d-inline-block text-success"}></h3>
                                </div>
                            </div>
                            <div>
                                <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"mobile-card-btn"}
                                   onClick={() => {
                                       toggleCollapse("mobile-card-btn", "mobile-card");
                                       document.getElementById("mobile-header-data").classList.toggle("d-none");
                                   }}></i>
                            </div>
                        </div>
                        <div className={"card-body collapse collapse-content show"} id={"mobile-card"}>
                            {cognitoId
                                ?
                                <>
                                <h5 className={"d-inline-block mb-3"}>Username: </h5><span
                                    className={"ms-3"}>{username}</span>
                                    <h5 className={"d-inline-block mb-3"}>Phone: </h5><span
                                    className={"ms-3"}>{mobilePhone}</span>
                                    <h5 className={"d-inline-block mb-3"}>Email: </h5><span
                                    className={"ms-3"}>{mobileEmail}</span>
                                    <form id={"addMobileForm"} className={"was-validated d-none"}>
                                        <div>

                                        </div>
                                    </form>
                                    <Button text={"Reset Password"} type={"button"} class={"btn btn-primary d-block"}
                                            id={"passBtn"}
                                            icon={true} iconClass={"fe fe-lock me-2"}
                                            onClick={() => {
                                                formClear("passForm", "passSubmit");
                                                showModal("passmodal");
                                            }}/>
                                    <Button text={"Remove Mobile"} type={"button"} class={"btn btn-danger d-block"}
                                            id={"deleteBtn"} onClick={() => showModal("mobileconfirmmobile")}/>
                                </>
                                :
                                <>
                                    <div className={"mb-3"}>
                                        <Button text={"Set Up Mobile"} class={"btn btn-primary"} id={"add-mobile-btn"}
                                                disabled={!editable}
                                                onClick={() => {
                                                    document.getElementById("addMobileForm").classList.remove("d-none");
                                                    document.getElementById("add-mobile-cancel-btn").classList.remove("d-none");
                                                    document.getElementById("add-mobile-btn").classList.add("d-none");
                                                    document.getElementById("addMobileSubmit").classList.remove("d-none");
                                                    formCheck("addMobileForm", "addMobileSubmit");
                                                }}/>
                                        <Button text={"Cancel"} class={"btn btn-secondary d-none me-3"}
                                                id={"add-mobile-cancel-btn"} onClick={() => {
                                            document.getElementById("add-mobile-btn").classList.remove("d-none");
                                            document.getElementById("add-mobile-cancel-btn").classList.add("d-none");
                                            document.getElementById("addMobileForm").classList.add("d-none");
                                            document.getElementById("addMobileSubmit").classList.add("d-none");
                                            formClear("addMobileForm", "addMobileSubmit");
                                        }}/>
                                        <Button text={"Loading"} class={"btn btn-primary d-none"}
                                                id={"loadAddMobileSubmit"} spinner={true} disabled={true}/>
                                        <Button text={"Submit"} class={"btn btn-primary d-none"} id={"addMobileSubmit"}
                                                onClick={() => {
                                                    addMobileSubmit();
                                                }}/>
                                    </div>
                                    <form id={"addMobileForm"} className={"was-validated d-none"}
                                          style={{maxWidth: "400px"}}>
                                        <div className={"form-floating mb-3"}>
                                            <input type={"text"} className={"form-control"} id={"username"}
                                                   onKeyUp={() => formCheck("addMobileForm", "addMobileSubmit")}
                                                   required={true} pattern={"^[0-9a-zA-Z_@-]+$"}/>
                                            <label htmlFor={"username"}>Username</label>
                                            <div className={"invalid-feedback"}>
                                                Please enter a valid username! No spaces allowed.
                                            </div>
                                        </div>
                                        <div className={"form-floating mb-3"}>
                                            <input type={"password"} className={"form-control"} id={"password"}
                                                   required={true} pattern={"[ -~]{6,}$"}
                                                   onKeyUp={(e) => {
                                                       document.getElementById("password2").pattern = e.target.value;
                                                       formCheck("addMobileForm", "addMobileSubmit");
                                                   }}/>
                                            <label htmlFor={"password"}>Password</label>
                                            <div className={"invalid-feedback"}>
                                                Please enter a valid password! 6 Character minimum.
                                            </div>
                                        </div>
                                        <div className={"form-floating mb-3"}>
                                            <input type={"password"} className={"form-control"} id={"password2"}
                                                   required={true}
                                                   onKeyUp={() => formCheck("addMobileForm", "addMobileSubmit")}/>
                                            <label htmlFor={"password2"}>Confirm Password</label>
                                            <div className={"invalid-feedback"}>
                                                Confirm password must match previous password field.
                                            </div>
                                        </div>
                                        <div className={"my-3"}>
                                            <input type={"checkbox"} className={"form-check-input mx-3"}
                                                   id={"mobile-fillin-toggle"} onClick={(e) => {
                                                let mp, me;
                                                mp = document.getElementById("mobile-phone-add");
                                                me = document.getElementById("mobile-email-add");
                                                if (e.target.checked) {
                                                    mp.value = phone;
                                                    me.value = email.toLowerCase();
                                                    mp.readOnly = true;
                                                    me.readOnly = true;
                                                } else {
                                                    mp.readOnly = false;
                                                    me.readOnly = false;
                                                    mp.value = "";
                                                    me.value = "";
                                                }
                                                formCheck("addMobileForm", "addMobileSubmit");
                                            }}/>
                                            <label htmlFor={"mobile-fillin-toggle"}>Use Profile Contact Info</label>
                                        </div>
                                        <div className={"form-floating mb-3"}>
                                            <input type={"text"} className={"form-control"} id={"mobile-phone-add"}
                                                   onKeyUp={() => {
                                                       formCheck("addMobileForm", "addMobileSubmit")
                                                   }}/>
                                            <label htmlFor={"mobile-phone-add"}>Phone Number</label>
                                            <div className={"invalid-feedback"}>
                                                Enter a valid phone number.
                                            </div>
                                        </div>
                                        <div className={"form-floating mb-3"}>
                                            <input type={"text"} className={"form-control"} id={"mobile-email-add"}
                                                   pattern={"[A-Za-z0-9._%+\\-]+@[A-Za-z0-9.\\-]+\\.[A-Za-z]{2,}$"}
                                                   onKeyUp={() => formCheck("addMobileForm", "addMobileSubmit")}/>
                                            <label htmlFor={"mobile-email-add"}>Email Address</label>
                                            <div className={"invalid-feedback"}>
                                                Enter a valid email address.
                                            </div>
                                        </div>
                                    </form>
                                </>
                            }
                        </div>
                    </div> : <></>}
                </div>
            </div>
            <div className={"modal fade"} id={"imageuploadmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticImageUploadLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticImageUploadLabel"}>Image Upload</h2>
                            <button type={"button"} className={"btn-close"} id={"imageUploadClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <div className={"modal-body"}>
                            <p>Selected file: {newFileName}</p>
                            <input className={"d-none"} id={"image-upload"} type={"file"} accept={"image/*"}
                                   onChange={() => handleFileUpload()}/>
                            <Button type={"button"} text={"Set"} class={"btn btn-outline-primary float-start me-4"}
                                    onClick={() => {
                                        document.getElementById("image-upload").click()
                                    }}/>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} id={"cancelImageUploadBtn"}
                                    data-bs-dismiss={"modal"}>Cancel
                            </button>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true}
                                    text={"Loading"} id={"loadImageUploadSubmit"}/>
                            <Button type={"button"} text={"Save"} class={"btn btn-primary ms-3"}
                                    id={"imageUploadSubmit"} onClick={() => imageSubmit()}
                                    disabled={newFileName === ""}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"pendantmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticDeviceBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticDeviceBackdropLabel"}>Add Device</h2>
                            <button type={"button"} className={"btn-close"} id={"pendantClose"}
                                    data-bs-dismiss={"modal"}
                                    aria-label={"Close"} onClick={() => setListening(false)}/>
                        </div>
                        <form id={"pendantForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"d-flex mb-4"} id={"listen-container"}>
                                    <label className={"form-check-label me-4 mt-1"} htmlFor={"item1"} id={"label1"}>Auto
                                        Register</label>
                                    <div className={"form-check form-switch mt-1"}>
                                        <input className={"form-check-input"} id={"item1"} type={"checkbox"}
                                               checked={listening}
                                               onChange={() => {
                                                   setListening(!listening);
                                               }}/>
                                    </div>
                                    {listenIcon}
                                </div>
                                <div className={"form-floating mb-3 d-inline-block w-75 me-2"}>
                                    <select className={"form-select"} value={undefined}
                                            required={true} id={"device_manufacture"}
                                            onChange={(e) => {
                                                setManufactureId(e.target.value);
                                                if (e.target?.value?.toString() === "6") {
                                                    setSerialPattern("^(?:[1-9]|00?[1-9]|0?[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-4])$");
                                                } else {
                                                    setSerialPattern("^[0-9]+$");
                                                }
                                                if (e.target?.value?.toString() === "1") {
                                                    document.getElementById("listen-container").classList.remove("d-none");
                                                } else {
                                                    document.getElementById("listen-container").classList.add("d-none");
                                                    if (listening) {
                                                        toast.warn("Auto register not available for manufacturer")
                                                        setListening(false);
                                                    }
                                                }
                                                setDeviceCatId(0);
                                                setDeviceTypeId(0);
                                                formCheck("pendantForm", "pendantSubmit");
                                            }}>
                                        <option value={""}></option>
                                        {manufactureOptions}
                                    </select>
                                    <label htmlFor={"device_manufacture"}>Device Manufacture</label>
                                </div>
                                <div className={"form-floating mb-3 d-inline-block w-75 me-2"}>
                                    <select className={"form-select"} value={undefined}
                                            required={true} id={"device_category"}
                                            onChange={(e) => {
                                                setDeviceCatId(e.target.value);
                                                setDeviceTypeId(0);
                                                formCheck("pendantForm", "pendantSubmit");
                                            }}>
                                        <option value={""}></option>
                                        {deviceCatOptions}
                                    </select>
                                    <label htmlFor={"device_category"}>Device Category</label>
                                </div>
                                <div className={"d-flex justify-content-around"}>
                                    <div className={"form-floating mb-3 d-inline-block w-75 me-2"}>
                                        <select className={"form-select"} required={true}
                                                value={undefined} id={"device_type_id"}
                                                onChange={(e) => {
                                                    setDeviceTypeId(e.target.value);
                                                    formCheck("pendantForm", "pendantSubmit");
                                                    setDeviceIds(e.target.value);
                                                    if (manufactureId.toString() === "6"){
                                                        if (e.target.value.toString() === "4"){
                                                            setSerialPattern("^[0-9a-zA-z]+$")
                                                        }else{
                                                            setSerialPattern("^(?:[1-9]|00?[1-9]|0?[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-4])$")
                                                        }
                                                    }
                                                }}>
                                            <option value={""}></option>
                                            {deviceTypeOptions}
                                        </select>
                                        <label htmlFor={"device_type_id"}>Device Type</label>
                                    </div>
                                    <div className={"form-floating mb-3 d-inline-block"} style={{width: "23%"}}>
                                        <input type={"text"} className={"form-control"} id={"productCode"}
                                               readOnly={true} required={true}/>
                                        <label htmlFor={"productCode"}>Product Code</label>
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"description"}
                                           pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                    <label htmlFor={"description"}>Description (optional)</label>
                                </div>
                                <input type={"hidden"} id={"deviceModelId"}/>
                                <input type={"hidden"} id={"device_name"}/>
                                <input type={"hidden"} id={"deviceMsgClass"}/>
                                <input type={"hidden"} id={"deviceProductType"}/>
                                <div className={"form-floating mb-3"} id={"serialToggle"}>
                                    <input type={"text"} className={"form-control"} id={"serialNum"}
                                           onKeyUp={(e) => formCheckWithElement("pendantForm", "pendantSubmit", e, deviceData)}
                                           required={true} pattern={serialPattern}/>
                                    <label htmlFor={"serialNum"}>Serial Number</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter the serial number of device
                                    </div>
                                </div>
                                <div className={"form-floating mb-3 d-none"} id={"serial2Toggle"}>
                                    <select className={"form-select"} id={"serialNum2"} required={selectReq}
                                            value={listenDevice}
                                            onChange={(e) => {
                                                setListenDevice(e.target.value);
                                            }}>
                                        {listenList}
                                    </select>
                                    <label htmlFor={"serialNum2"}>Serial Numbers</label>
                                </div>
                                {[].includes(deviceTypeId.toString()) &&
                                    <>
                                        <h3 className={"mt-4"}>Behavioral Settings</h3>
                                        <hr/>
                                    </>
                                }
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelPendantBtn"}
                                            data-bs-dismiss={"modal"}
                                            onClick={() => {
                                                formClear("pendantForm", "pendantSubmit")
                                            }}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadPendantSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"pendantSubmit"}
                                            onClick={() => pendantSubmit(true)}>Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"pendantconfirmmodal"} data-bs-backdrop={"static"}
                 data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticPendantConfirmLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticPendantConfirmLabel"}>Confirm Add</h2>
                            <button type={"button"} className={"btn-close"} id={"pendantConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <div className={"modal-body"}>
                            <p>Device is already linked to {assignedRes} Do you wish to re-assign this device?</p>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                    id={"pendantConfirmCancelBtn"}
                                    onClick={() => swapModal("pendantConfirmClose", "pendantmodal")}>No
                            </button>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true}
                                    text={"Loading"} id={"loadPendantConfirmSubmit"}/>
                            <button type={"button"} className={"btn btn-primary ms-3"} id={"pendantConfirmSubmit"}
                                    onClick={() => pendantSubmit(false)}>YES
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"mobileconfirmmodal"} data-bs-backdrop={"static"}
                 data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticMobileConfirmLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticMobileConfirmLabel"}>Confirm Removal</h2>
                            <button type={"button"} className={"btn-close"} id={"mobileConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <div className={"modal-body"}>
                            <p>Are you sure you wish to remove this resident from access to the resident mobile app?</p>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                    id={"mobileConfirmCancelBtn"} data-bs-dismiss={"modal"}>No
                            </button>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true}
                                    text={"Loading"} id={"loadMobileConfirmSubmit"}/>
                            <button type={"button"} className={"btn btn-primary ms-3"} id={"mobileConfirmSubmit"}
                                    onClick={() => mobileDeleteSubmit()}>YES
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"deviceconfirmmodal"} data-bs-backdrop={"static"}
                 data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticDeviceConfirmLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticDeviceConfirmLabel"}>Confirm Add</h2>
                            <button type={"button"} className={"btn-close"} id={"deviceConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <div className={"modal-body"}>
                            <p>Are you sure you wish to permanently detach this device from {firstName} {lastName}?</p>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                    id={"deviceCancelBtn"} data-bs-dismiss={"modal"}>No
                            </button>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true}
                                    text={"Loading"} id={"loadDeviceConfirmSubmit"}/>
                            <button type={"button"} className={"btn btn-primary ms-3"} id={"deviceConfirmSubmit"}
                                    onClick={() => removeDevice(deviceToRemove)}>YES
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"passmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Reset Password</h2>
                            <button type={"button"} className={"btn-close"} id={"passClose"} data-bs-dismiss={"modal"}
                                    aria-label={"Close"}/>
                        </div>
                        <form id={"passForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"passwordR"}
                                           onKeyUp={() => formCheck("passForm", "passSubmit")}
                                           required={true} pattern={"[ -~]{6,}"}/>
                                    <label htmlFor={"passwordR"}>New Password</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a valid password! 6 Character minimum.
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"passwordCR"}
                                           onKeyUp={() => formCheck("passForm", "passSubmit")}
                                           required={true} pattern={"[ -~]{6,}"}/>
                                    <label htmlFor={"passwordCR"}>Confirm Password</label>
                                    <div className={"invalid-feedback"}>
                                        Password does not match.
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelPassBtn"}
                                            data-bs-dismiss={"modal"}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadPassSubmit"}/>
                                    <button type={"button"} className={"btn btn-danger ms-3"} id={"passSubmit"}
                                            onClick={() => passSubmit()}>Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"deleteconfirmmodal"} data-bs-backdrop={"static"}
                 data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Confirm Delete</h2>
                            <button type={"button"} className={"btn-close"} id={"deleteConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <div className={"modal-body"}>
                            <p>Are you sure you wish to permanently delete this resident? The resident will be removed
                                from their assigned location and all
                                devices will no longer be attached to this resident. The resident will no longer show in
                                the system or on any dashboards/reports.</p>
                            <p>If you wish to retain the residents current and historical data, move out the resident
                                instead.</p>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                    id={"deleteConfirmCancelBtn"} data-bs-dismiss={"modal"}>No
                            </button>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true}
                                    text={"Loading"} id={"loadDeleteConfirmSubmit"}/>
                            <button type={"button"} className={"btn btn-primary ms-3"} id={"deleteConfirmSubmit"}
                                    onClick={() => deletePerform()}>YES
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"moveoutconfirmmodal"} data-bs-backdrop={"static"}
                 data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Confirm Moving Resident Out</h2>
                            <button type={"button"} className={"btn-close"} id={"moveOutConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <div className={"modal-body"}>
                            <p>Are you sure you wish to move out this resident? The resident will no longer be assigned
                                to their current location and all
                                devices will no longer be attached. The resident's detailed historical activity will
                                still show in reporting (excluding the main dashboards).</p>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                    id={"moveOutConfirmCancelBtn"} data-bs-dismiss={"modal"}>No
                            </button>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true}
                                    text={"Loading"} id={"loadMoveOutConfirmSubmit"}/>
                            <button type={"button"} className={"btn btn-primary ms-3"} id={"moveOutConfirmSubmit"}
                                    onClick={() => moveOutPerform()}>YES
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"moveinconfirmmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Move Resident Back In?</h2>
                            <button type={"button"} className={"btn-close"} id={"moveInConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <div className={"modal-body"}>
                            <form id={"moveInForm"} className={"was-validated"}>
                                <p>To move this resident back in and make them active, please assign to an area.</p>
                                <div className={"form-floating mb-3"}>
                                    <select className={"form-select"} defaultValue={undefined} required={true} id={"locationMoveIn"}
                                            onChange={() => formCheck("moveInForm", "moveInConfirmSubmit")}>
                                        <option value={""}></option>
                                        {locationOptions}
                                    </select>
                                    <label htmlFor={"locationId"}>Area</label>
                                </div>
                            </form>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                    id={"moveInConfirmCancelBtn"} data-bs-dismiss={"modal"}>No
                            </button>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true}
                                    text={"Loading"} id={"loadMoveInConfirmSubmit"}/>
                            <button type={"button"} className={"btn btn-primary ms-3"} id={"moveInConfirmSubmit"}
                                    onClick={() => moveInPerform()}>YES
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true}
                                autoClose={10000}/>
            </Suspense>
        </>
    ) : <Navigate to={"/"}/>;
};

export default ResidentProfile;