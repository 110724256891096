import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import TableHeaders from "../component-assets/TableHeaders";
import TableBodySimple from "../component-assets/TableBodySimple";

const CheckInTrans = (props) => {
    let dData, tableBody, headerData;

    headerData = [
        {label: "Checked In", key: "local_check_in_time", align: "center", type: "datetime"},
        {label: "Resident", key: "resident_name"},
        {label: "Device", key: "device_name"},
        {label: "Area", key: "area_name"}
    ]

    if (ReactSession.get("PAL") != null) {
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const tableHeaders = <TableHeaders checkbox={false} headerData={headerData} sortOrder={props?.sorter?.sortOrder}
                                       sortColumn={props?.sorter?.sortColumn} sorter={props?.sorter} setSorter={props?.setSorter}/>
    if (props?.tableRows !== undefined){
        tableBody = (<TableBodySimple tableData={props.tableRows} checkbox={false} tableHeaders={headerData} />)
    }else{
        tableBody = ""
    }

    return (
        <>
            <div>
                <h6 className={"my-1 fs-3 px-4"}>
                    {dData.campus} {props.repType} Report:
                    <span className={"fs-3 d-inline ms-2"} style={{color: "#0081c7"}}>{props.start}</span>
                    -
                    <span className={"fs-3 d-inline"} style={{color: "#0081c7"}}>{props.end}</span>
                </h6>
                <div className={"pt-1 pb-4 px-4 my-2"}>
                    <table className={"table table-sm table-white table-hover"}>
                        <thead className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString() ? "sticky-table-headX" : "sticky-table-head"}>
                            {tableHeaders}
                        </thead>
                        <tbody>
                            {tableBody}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default CheckInTrans;