const NullAccnt = () => {

    return (
        <div className={"main-content"}>
            <div className={"header"}>
                <div className={"container-fluid"}>
                    <div className={"header-body"}>
                        <div className={"row align-items-end"}>
                            <div className={"col"}>
                                {/* heading content left */}
                            </div>
                            <div className={"col-auto"}>
                                {/* heading content right */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"container-fluid"}>
                <div className={"row"}>
                    <div>
                        <h4>No content to display, user is not assigned to any facility.
                    Please contact your administrator or customer support for assistance.</h4>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NullAccnt;