import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import {useEffect, useState} from "react";
import Button from "../component-assets/Button";
import ApiRequester from "../ApiRequester";
import {formSuccess, handleSubmitActions, formFail, formCheck} from "../../libs";
import alexa_logo from "../../img/alexa_logo.png"

const Alexa = (props) => {

    let dData;
    if (ReactSession.get("PAL") != null) {
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const originData = props?.originData?.length > 0 ? props.originData[0] : {}

    const [isActive, setIsActive] = useState(originData?.is_active ? originData.is_active : false);
    const [foodOn, setFoodOn] = useState(originData?.food_on ? originData.food_on : false);
    const [alertOn, setAlertOn] = useState(originData?.alert_on ? originData.alert_on : false);
    const [mailOn, setMailOn] = useState(originData?.mail_on ? originData.mail_on : false);
    const [checkinOn, setCheckinOn] = useState(originData?.checkin_on ? originData.checkin_on : false);

    useEffect(() => {
        formCheck("mainForm", "editSubmit");
    }, [isActive, foodOn, alertOn, checkinOn, mailOn]);

    function performSave(){
        let payload, food, alert, mail, checkin, menu, arrived;
        handleSubmitActions("editSubmit", "loadEditSubmit");
        food = document.getElementById("food-on")?.checked;
        alert = document.getElementById("alert-on")?.checked;
        mail = document.getElementById("mail-on")?.checked;
        checkin = document.getElementById("checkin-on")?.checked;
        arrived = document.getElementById("mail-arrived")?.checked;
        menu = document.getElementById("food-menu")?.value;
        payload = [{
            alexa_config_id: originData?.alexa_config_id ? originData.alexa_config_id : 0,
            food_on: food ? food : false,
            checkin_on: checkin ? checkin : false,
            alert_on: alert ? alert : false,
            mail_on: mail ? mail : false,
            mail_arrived: arrived ? arrived : false,
            food_menu: menu,
            campus_id: dData.acnt,
            is_active: isActive
        }];
        ApiRequester({reqEndpoint: "mysave", Tbl: "core_alexa_config", Payload: payload,
                            urlType: originData?.alexa_config_id ? "POST" : "PUT", User: dData.user,
                            PKey: "alexa_config_id", Condition: "primary", CampusId: dData.acnt, ClientId: dData.sessionId}).then(data => {
            if (data[0] === true){
                formSuccess("Alexa settings updated", "editSubmit", "loadEditSubmit");
                props?.setCount(0);
            }
            else{
                formFail(`Error during save, error code: ${data[1]}`, "editSubmit", "loadEditSubmit");
            }
        });
    }


    return (
        <>
            <div className={"card"} style={{maxWidth: "700px", minWidth: "515px"}}>
                <div className={"card-header"}>
                    <div>
                        <h4 className={"card-header-title d-inline-block"}>Alexa Settings</h4>
                        <div className={"form-check form-switch ms-4 d-inline-block"}>
                            <input className={"form-check-input"} type={"checkbox"} role={"switch"} id={"alexa-on"}
                                   defaultChecked={isActive} onChange={(e) => {
                                document.getElementById("editSubmit").classList.remove("d-none");
                                setIsActive(e.target.checked);
                                if (e.target.checked) {
                                    e.target.nextElementSibling.children[0].classList.remove("text-danger");
                                    e.target.nextElementSibling.children[0].classList.remove("fw-bold");
                                    e.target.nextElementSibling.children[1].classList.add("fw-bold");
                                    e.target.nextElementSibling.children[1].classList.add("text-success-bright");
                                } else {
                                    e.target.nextElementSibling.children[0].classList.add("text-danger");
                                    e.target.nextElementSibling.children[0].classList.add("fw-bold");
                                    e.target.nextElementSibling.children[1].classList.remove("fw-bold");
                                    e.target.nextElementSibling.children[1].classList.remove("text-success-bright");
                                }
                            }}/>
                            <label className={"form-check-label"} htmlFor={"tels-on"}>
                                <span id={"in-label-off"} className={!isActive ? "text-danger fw-bold" : ""}>Off</span>/<span
                                id={"in-label-on"} className={isActive ? "text-success-bright fw-bold" : ""}>On</span>
                            </label>
                        </div>
                        <a href={"https://www.alexa.com/"} target={"_blank"}>
                            <img width={"110px"} height={"75px"} className={"ms-2"} src={alexa_logo} alt={"Amazon Alexa Smile Logo"} />
                        </a>
                    </div>
                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true}
                            text={"Loading"} id={"loadEditSubmit"}/>
                    <Button id={"editSubmit"} style={{width: "75px"}} text={"Save"} class={"btn btn-primary d-none"} onClick={() => performSave()} />
                </div>
                <div className={"card-body"}>
                    <div className={isActive ? "list-group list-group-flush my-n3" : "list-group list-group-flush my-n3 d-none"}>
                        <form id={"mainForm"} className={"was-validated"}>
                            <h3>Services</h3>
                            <div className={"form-check form-switch ms-3 my-3"}>
                                <input className={"form-check-input"} type={"checkbox"} id={"alert-on"}
                                       defaultChecked={originData?.alert_on} onChange={(e) => {
                                           setAlertOn(e.target.checked);
                                           document.getElementById("editSubmit").classList.remove("d-none");
                                       }} />
                                <label htmlFor={"alert-on"}>Alert Service</label>
                            </div>
                            <div className={"form-check form-switch ms-3 my-3"}>
                                <input className={"form-check-input"} type={"checkbox"} id={"checkin-on"}
                                       defaultChecked={originData?.checkin_on} onChange={(e) => {
                                           setCheckinOn(e.target.checked);
                                           document.getElementById("editSubmit").classList.remove("d-none");
                                       }} />
                                <label htmlFor={"checkin-on"}>Wellness Check-in Service</label>
                            </div>
                            <div className={"form-check form-switch ms-3 my-3"}>
                                <input className={"form-check-input"} type={"checkbox"} id={"mail-on"}
                                       defaultChecked={originData?.mail_on} onChange={(e) => {
                                           setMailOn(e.target.checked);
                                           document.getElementById("editSubmit").classList.remove("d-none");
                                       }} />
                                <label htmlFor={"mail-on"}>Mail Service</label>
                            </div>
                            {mailOn
                                ?   <div className={"form-check form-switch ms-5 mb-3"}>
                                        <input className={"form-check-input"} type={"checkbox"} id={"mail-arrived"}
                                               defaultChecked={originData?.mail_arrived} onChange={() => {
                                                   document.getElementById("editSubmit").classList.remove("d-none");
                                               }} />
                                        <label htmlFor={"mail-arrived"}>Mail Arrived</label>
                                    </div>
                                :   <></>
                            }
                            <div className={"form-check form-switch ms-3 my-3"}>
                                <input className={"form-check-input"} type={"checkbox"} id={"food-on"}
                                       defaultChecked={originData?.food_on} onChange={(e) => {
                                           setFoodOn(e.target.checked ? 1 : 0);
                                           document.getElementById("editSubmit").classList.remove("d-none");
                                       }} />
                                <label htmlFor={"food-on"}>Food Service</label>
                            </div>
                            {foodOn
                                ?   <>
                                        <span className={"d-inline-block ms-5"}>On today's menu is...
                                        </span>
                                        <div className={"form-check d-inline-block"}>
                                            <textarea className={"form-control ms-2"} placeholder={""} required={foodOn}
                                                  style={{minHeight: "42px", maxHeight: "175px", minWidth: "180px",
                                                      maxWidth: "350px", height: "auto", width: "300px", resize: "both"}}
                                                  id={"food-menu"} onChange={() => {
                                                      formCheck("mainForm", "editSubmit");
                                                      document.getElementById("editSubmit").classList.remove("d-none");
                                            }}>{originData?.food_menu}</textarea>
                                        </div>
                                    </>
                                :   <></>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Alexa;