import { Link, Navigate } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import Button from "./component-assets/Button";
import {
    actionAppearance, bulkDeleteModal, checkAll, deleteModalSingle, showModal, formatPhone, formFail,
    handleSubmitActions, paginateData, sortFunc, swapModal, formCheck, formCheckWithElement, formSuccess,
    formClear, closeModal, hideModal, determineUseId, determineDevName
} from "../libs";
import ApiRequester from "./ApiRequester";
import TableBody from "./component-assets/TableBody";
import TableHeaders from "./component-assets/TableHeaders";
import {CSVLink} from "react-csv";
import {Pagination} from "react-bootstrap";
import PaginationItems from "./component-assets/PaginationItems";
import Header from "./Header";
import Footer from "./Footer";
import SingleDeleteModal from "./component-assets/SingleDeleteModal";
import BulkDeleteModal from "./component-assets/BulkDeleteModal";
import PerPageModal from "./component-assets/PerPageModal";
import {toast, ToastContainer} from "react-toastify";
import CampusDropDown from "./component-assets/CampusDropDown";

const Residents = (props) => {
    let dData, bData, headerData, csvHeaders, headerData2;

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    headerData = [
        {label: "First Name", key: "resident_first_name", align: "left",
            link: {enabled: true, type: "standard", linkto: "/profiles/resident", linkkey: "resident_id"}},
        {label: "Last Name", key: "resident_last_name", align: "left",
            link: {enabled: true, type: "standard", linkto: "/profiles/resident", linkkey: "resident_id"}},
        {label: "Area", key: "area_name", align: "left",
            link: {enabled: true, type: "standard", linkto: "/profiles/area", linkkey: "area_id"}},
        {label: "Groups", key: "resident_group_name", align: "left", type: "ellipsis"},
        {label: "Phone", key: "resident_phone", align: "center", type: "tel"},
        {label: "Email", key: "resident_email", align: "left"},
        {label: "Status", key: "is_active", align: "center", type: "bool", f: "Moved Out", t: "Active"}
    ];

    csvHeaders = [
        {label: "First Name", key: "resident_first_name"},
        {label: "Last Name", key: "resident_last_name"},
        {label: "Nickname", key: "resident_nickname"},
        {label: "Area", key: "area_name"},
        {label: "Room Phone", key: "resident_phone"},
        {label: "Email", key: "resident_email"}
    ];

    if (dData.checkin){
        headerData.splice(-1, 0, {label: "Last Check-in", key: "check_in_time", align: "center", type: "datetime"});
        csvHeaders.splice(-1, 0, {label: "Last Check-in", key: "check_in_time"});
    }

    headerData2 = [
        {label: "Group", key: "resident_group_name", align: "left"},
        {label: "Description", key: "resident_description", align: "left"},
    ];

    // Main Table Variables
    const [dataOrigin, setDataOrigin] = useState([]);
    const [activeData, setActiveData] = useState([]);
    const [loadState, setLoadState] = useState("individuals");
    const [tableRows, setTableRows] = useState([]);
    const [count, setCount] = useState(0);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [editable, setEditable] = useState(dData?.access?.Community?.Residents?.Write);
    const [storePage, setStorePage] = useState(1);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(dData.perPage);
    const [tmpPerPage, setTmpPerPage] = useState(dData.perPage);
    const [dContent, setDContent] = useState([]);
    const [search, setSearch] = useState("");
    const [showInactive, setShowInactive] = useState(false);
    const [selectReq, setSelectReq] = useState(false);
    const [resIdList, setResIdList] = useState([]);
    const [editLocationId, setEditLocationId] = useState(0);
    const [sorter, setSorter] = useState({
        sortColumn: "resident_last_name",
        sortOrder: "desc"
    });
    const [ppRules, setPRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    });
    // Group picker table Variables
    const [groupData, setGroupData] = useState([]);
    const [resGroupIdList, setResGroupIdList] = useState([]);
    const [groupDataCnt, setGroupDataCnt] = useState(0);
    const [groupTableRows, setGroupTableRows] = useState([]);
    const [search2, setSearch2] = useState("");
    const [sorter2, setSorter2] = useState({
        sortColumn: "resident_group_name",
        sortOrder: "desc"
    });
    // Device Section Learn In & Variables
    const [deviceData, setDeviceData] = useState([]);
    const [assignedRes, setAssignedRes] = useState("");
    const [listening, setListening] = useState(false);
    const [listenIcon, setListenIcon] = useState(<i className={"fe fe-rss pointer-no fs-2"}></i>);
    const [listenCount, setListenCount] = useState(60);
    const [listenData, setListenData] = useState([]);
    const [listenList, setListenList] = useState([]);
    const [editDeviceList, setEditDeviceList] = useState([]);
    const [storedIndex, setStoredIndex] = useState(0);
    const [listenDevice, setListenDevice] = useState("");
    const [manufactureData, setManufactureData] = useState([]);
    const [manufactureOptions, setManufactureOptions] = useState([]);
    const [manufactureId, setManufactureId] = useState(0);
    const [deviceCatId, setDeviceCatId] = useState(0);
    const [deviceCatData, setDeviceCatData] = useState([]);
    const [deviceCatOptions, setDeviceCatOptions] = useState([]);
    const [deviceTypeData, setDeviceTypeData] = useState([]);
    const [deviceTypeId, setDeviceTypeId] = useState([]);
    const [deviceTypeOptions, setDeviceTypeOptions] = useState([]);
    const [accutechDeviceData, setAccutechDeviceData] = useState([]);
    // Location Section Variables
    const [locationData, setLocationData] = useState([]);
    const [locationOptions, setLocationOptions] = useState("");
    const [locationSorter, setLocationSorter] = useState({
        sortColumn: "area_name",
        sortOrder: "desc"
    });
    // Resident Mobile Variables
    const [mobileAppDisplay, setMobileAppDisplay] = useState(<></>);
    const [onLoad, setOnLoad] = useState(true);
    const [isMobileActive, setIsMobileActive] = useState(dData.resMobile);
    // Other Enact Once Variables
    const [serialPattern, setSerialPattern] = useState("^[0-9]+$");
    const [csvLink, setLink] = useState({
        filename: "Residents.csv",
        headers: [],
        data: []
    });
    const [csvButton, setCSVButton] = useState(
        <CSVLink {...csvLink} uFEFF={false}>
            <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary ms-2 px-4 mt-1"}/>
        </CSVLink>);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "sp_dev_getallresidentsanddevicesbycampusid", reqType: "stored"}).then(data => {
                setDataOrigin(data);
            });
            ApiRequester({reqEndpoint: "myfetch", payload: {device_filter: "", campus_id: campusId},
                                procedure: "sp_dev_getInovonicsDevices", reqType: "stored"}).then(data => {
                setDeviceData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "sp_dev_getareasbycampusid", reqType: "stored"}).then(data => {
                setLocationData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "core_resident_groups",
                                procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                setGroupData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "getresidentdevicecategories", reqType: "stored"}).then(data => {
                setDeviceCatData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "getinovonicsdevicetypes", reqType: "stored"}).then(data => {
                setDeviceTypeData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "getaccutechresidentdevices", reqType: "stored"}).then(data => {
                setAccutechDeviceData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "att_manufactures",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setManufactureData(data);
            });
            setCount(count => 1200);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        setLink(prevState => {
            return {...prevState,
                headers: csvHeaders,
                data: dataOrigin}
        });
    }, [dataOrigin]);

    useEffect(() => {
        setCSVButton(
            <CSVLink {...csvLink} uFEFF={false}>
                <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"}
                        class={"btn btn-primary ms-2 px-4 mt-1"}/>
            </CSVLink>
        );
    }, [csvLink]);

    useEffect(() => {
        // Performs realtime search of data as location types.
        let tmpData, input, pRules;
        tmpData = [];
        input = document.getElementById("search1").value.toString().toLowerCase();
        for (let i=0; i<dataOrigin.length; i++){
            if (((dataOrigin[i]?.resident_first_name && dataOrigin[i]?.resident_first_name?.toLowerCase()?.search(input) !== -1) ||
                 (dataOrigin[i]?.resident_last_name && dataOrigin[i]?.resident_last_name?.toLowerCase()?.search(input) !== -1) ||
                 (dataOrigin[i]?.resident_phone && dataOrigin[i]?.resident_phone?.toLowerCase()?.search(input) !== -1) ||
                 (dataOrigin[i]?.area_name && dataOrigin[i]?.area_name?.toLowerCase()?.search(input) !== -1) ||
                 (dataOrigin[i]?.resident_email && dataOrigin[i]?.resident_email?.toLowerCase()?.search(input) !== -1)) &&
                 (showInactive || (!showInactive && dataOrigin[i]?.is_active))){
                tmpData.push(dataOrigin[i]);
            }
        }
        pRules = paginateData(tmpData, page, perPage);
        if (input && page > pRules.pageMax){
            setPage(pRules.pageMax);
        }else if (!input){
            setPage(storePage);
        }
        setPRules(pRules);
        setActiveData(tmpData);
    }, [search, dataOrigin, showInactive]);

    useEffect(() => {
        let pRules = paginateData(activeData, page, perPage);
        setPRules(pRules);
        if (activeData.length > 0) {
            activeData.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
        }
        setTableRows(<TableBody start={pRules.start} end={pRules.end} tableData={activeData} checkbox={true}
                                writeAccess={editable} unk={"a-"} addlEditKey={"is_active"} addlEditCheck={true}
                                actionAppearance={() => actionAppearance(pRules.start, pRules.end, ["deleteBtn"])}
                                tableHeaders={headerData} editModal={editModal} parentComponent={"Residents"} dData={dData}
                                modal={true} checkboxlist={resIdList} setCheckboxId={(value) => {setResIdList(value)}}
                                checkidkey={"resident_id"} editModalHeaderData={groupData} />);
    }, [activeData, sorter, page, perPage, resIdList, groupData]);

    useEffect(() => {
        let i, tmp = [], input;
        input = search2?.toString()?.toLowerCase();
        for (i = 0; i < groupData.length; i++){
            if ((groupData[i]?.resident_group_name && groupData[i]?.resident_group_name?.toLowerCase()?.search(input) !== -1) ||
                (groupData[i]?.resident_description && groupData[i]?.resident_description?.toLowerCase()?.search(input) !== -1)){
                tmp.push(groupData[i]);
            }
        }
        setGroupDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, sorter2.sortColumn, sorter2.sortOrder));
        setGroupTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                     actionAppearance={() => {}} tableHeaders={headerData2} checkboxId={"uCheckGroup"}
                                     parentComponent={"Residents"} dData={dData} checkboxlist={resGroupIdList}
                                     modal={false} editModal={() => {}} unk={"x-"}
                                     setCheckboxId={(value) => {setResGroupIdList(value)}} checkidkey={"resident_group_id"} />);
    }, [groupData, resGroupIdList, sorter2, search2]);

    useEffect(() => {
        let i, tmp = [];
        manufactureData.sort((a, b) => sortFunc(a, b, "manufacture_name", "desc"));
        for (i = 0; i < manufactureData.length; i++){
            tmp.push(
                <option key={"manufacture-option-"+manufactureData[i]?.manufacture_id.toString()}
                        value={manufactureData[i]?.manufacture_id}>{manufactureData[i]?.manufacture_name}
                </option>
            );
        }
        setManufactureOptions(() => tmp);
    }, [manufactureData]);

    useEffect(() => {
        let i, tmp = [];
        if (manufactureId.toString() === "1"){
            deviceTypeData.sort((a, b) => sortFunc(a, b, "device_type_name", "desc"));
            for (i = 0; i < deviceTypeData.length; i++){
                if (deviceTypeData[i]?.device_category_id.toString() === deviceCatId.toString()){
                    tmp.push(
                        <option key={"device-use-option-"+deviceTypeData[i]?.inovonics_type_id.toString()}
                                value={deviceTypeData[i]?.inovonics_type_id}>{deviceTypeData[i]?.device_type_name}
                        </option>
                    );
                }
            }
        }else if (manufactureId.toString() === "6"){
            accutechDeviceData.sort((a, b) => sortFunc(a, b, "description", "desc"));
            for (i = 0; i < accutechDeviceData.length; i++){
                tmp.push(
                    <option key={"device-use-option-"+accutechDeviceData[i]?.accutech_device_id.toString()}
                            value={accutechDeviceData[i]?.accutech_device_id}>{accutechDeviceData[i]?.description}
                    </option>
                );
            }
        }
        setDeviceTypeOptions(() => tmp);
    }, [deviceTypeData, deviceCatId, accutechDeviceData, manufactureId]);

    useEffect(() => {
        let tmpOptions = []
        if (manufactureId.toString() === "1"){
            for (let i=0; i < deviceCatData.length; i++){
                tmpOptions.push(
                    <option key={"device-category-option-"+deviceCatData[i].device_category_id.toString()}
                            value={deviceCatData[i].device_category_id}>{deviceCatData[i].device_category_short_name}
                    </option>
                );
            }
        }else if (manufactureId.toString() === "6"){
            tmpOptions.push(
                <option key={"device-category-option-1"}
                        value={0}>{"RF Tags"}
                </option>
            );
        }
        setDeviceCatOptions(() => tmpOptions);
    }, [deviceCatData, accutechDeviceData, manufactureId]);

    useEffect(() => {
        let i, tmpOc = [], tmpVa = [], tmp, pOccupancy;
        locationData.sort((a, b) => sortFunc(a, b, locationSorter.sortColumn, locationSorter.sortOrder));
        for (i = 0; i < locationData.length; i++){
            pOccupancy = locationData[i]?.number_residents;
            if (pOccupancy && pOccupancy >= locationData[i].max_occupancy){
                if (storedIndex !== null && activeData[storedIndex]?.area_id?.toString() === locationData[i]?.area_id?.toString()){
                    tmpOc.push(
                        <option key={"location-option"+i.toString()} value={locationData[i].area_id}>
                            {locationData[i].area_name}
                        </option>
                    );
                }
            }
            else{
                tmpVa.push(
                    <option key={"location-option"+i.toString()} value={locationData[i].area_id}>
                        {locationData[i].area_name}
                    </option>
                );
            }
        }
        tmp = tmpVa.concat(tmpOc);
        setLocationOptions(() => tmp);
    }, [locationData, storedIndex, activeData]);

    useEffect(() => {
        formCheck("editForm", "editSubmit");
    }, [editLocationId, locationOptions]);

    useEffect(() => {
        let tmpList = [];
        for (let i=0; i < listenData.length; i++){
            tmpList.push(
                <option key={"listen-"+listenData[i]?.trx_id} value={listenData[i]?.trx_id}>
                    {listenData[i]?.name} ({listenData[i]?.trx_id})
                </option>
            );
        }
        setListenList(tmpList);
        if (listenDevice === "" && listenData.length > 0){
            setListenDevice(() => listenData[0]?.trx_id);
            toast.success("Devices found.");
        }
    }, [listenData]);

    useEffect(() => {
        buildDeviceList(-1);
    }, [activeData]);

    useEffect(() => {
        formCheck("pendantForm", "pendantSubmit");
    }, [listenDevice]);

    useEffect(() => {
        formCheck("pendantForm", "pendantSubmit");
    }, [selectReq]);

    useEffect(() => {
        let product = document.getElementById("device_name")?.value;
        if (!listening){
            // just toggled off & possible first run
            if (!onLoad){
                setListenIcon(
                    <i className={"fe fe-rss pointer-no fs-2"}></i>
                );
                toast.info("Listening stopped.");
                hideListenList();
            }else{
                setOnLoad(false);
            }
        }else{
            if (product !== undefined && product !== ""){
                setListenIcon(
                    <i className={"fe fe-rss icon-vibrant pointer-no fs-2"}></i>
                );
                publishListen(product);
                showListenList();
            }else{
                toast.warning("Listening toggled on, no product selected.");
            }
        }
    }, [listening]);

    useEffect(() => {
        let interval;
        if (listening){
            interval = setInterval(() => {
                setListenCount(listenCount  => listenCount  + 1);
            }, 1000);
            if (listenCount < 60){
                if (listenCount % 4 === 0){
                    let pType = document.getElementById("deviceProductType").value;
                    ApiRequester({reqEndpoint: "getitems", Table: "LearnIn", PKey: "ClientId",
                        PVal: dData.sessionId, CampusId: campusId, Search: parseInt(pType)}).then(data => {
                            setListenData(data);
                    });
                }
            }
            if (listenCount === 60){
                toast.info("Capture timeout, restart capture if device not in list.");
            }
        }
        return () => clearInterval(interval);
    }, [listenCount]);


    function editModal(editIndex){
        let fname, lname, fullname, dataIndex, resId, delBtn, roomphone, groupIdList = [];
        dataIndex = parseInt(editIndex);
        setSearch2("");
        setStoredIndex(dataIndex);
        buildDeviceList(dataIndex);
        fname = document.getElementById("fNameE");
        lname = document.getElementById("lNameE");
        resId = document.getElementById("resUnqIdE");
        if (activeData[dataIndex]?.resident_phone){
            roomphone = formatPhone(activeData[dataIndex].resident_phone);
        }else{
            roomphone = "";
        }
        document.getElementById("emailE").value = activeData[dataIndex]?.resident_email;
        document.getElementById("roomPhE").value = roomphone;
        document.getElementById("campusIdE").value = activeData[dataIndex]?.campus_id;
        setEditLocationId(activeData[dataIndex]?.area_id)
        fname.value = activeData[dataIndex]?.resident_first_name;
        lname.value = activeData[dataIndex]?.resident_last_name;
        resId.value = activeData[dataIndex]?.resident_id;
        document.getElementById("uCheckHead2").checked = false;
        for (let i2=0; i2<activeData[dataIndex]?.resident_groups?.length; i2++){
            groupIdList.push(activeData[dataIndex]?.resident_groups[i2]?.resident_group_id.toString());
        }
        setResGroupIdList(groupIdList);
        document.getElementById("uCheckHead2").checked = groupIdList && groupIdList.length === groupData.length;
        delBtn = document.getElementById("deleteSingleBtn");
        fullname = fname.value+' '+lname.value;
        if (isMobileActive){
            if (activeData[dataIndex]?.cognito_id){
                setMobileAppDisplay(() => {return (
                    <>
                        <h5 className={"d-inline-block mb-3"}>Username: </h5><span className={"ms-3"}>{activeData[dataIndex]?.username}</span>
                        <Button text={"Password Reset"} type={"button"} class={"btn btn-primary d-block"} id={"passBtn"}
                                icon={true} iconClass={"fe fe-lock me-2"} onClick={() => swapModal("cancelEditBtn", "passmodal")} />
                    </>
                )});
            }else{
                setMobileAppDisplay(() => {return (
                    <>
                        <h5 className={"d-inline-block mb-3"}>App not set up for resident</h5>
                        <Button text={"Sign-up Resident"} class={"btn btn-primary d-block"}
                                onClick={() => swapModal("cancelEditBtn", "addmobilemodal")} />
                    </>
                )});
            }
        }else{
            setMobileAppDisplay(() => {return (<></>)});
        }
        delBtn.onclick = () => {
            setDContent([[resId.value, ""]]);
            deleteModalSingle(fullname, fullname, "Are you sure you want to move out");
        };
        formCheck("editForm", "editSubmit");
        showModal("editmodal");
    }

    function removeDevice(deviceIdList){
        let payload;
        payload = [];
        for (let i=0; i < deviceIdList.length; i++){
            payload.push({device_id: deviceIdList[i], is_active: false, resident_id: null});
        }
        ApiRequester({reqEndpoint: "mysave", Tbl: "core_device", Payload: payload, urlType: "POST", User: dData.user,
                            PKey: "device_id", Condition: "primary", CampusId: campusId, ClientId: dData.sessionId}).then(data => {
            if (data && data[0] === true){
                setCount(0);
                toast.success("Devices have been removed");
            }
            else{
                toast.error("Failed to remove devices");
            }
        });
    }

    function buildDeviceList(dataIndex){
        let tmp = [];
        if (dataIndex === -1){
            dataIndex = storedIndex;
        }
        for (let i=0; i < activeData[dataIndex]?.devices?.length; i++){
            tmp.push(
                <tr key={`device-list-item-${i}`}>
                    <td>
                        <i className={"fe fe-x-square red-hover fs-1 me-3"}
                           onClick={() => removeDevice([activeData[dataIndex]?.devices[i].device_id])}/>
                    </td>
                    {activeData[dataIndex]?.devices[i].manufacture_id === 6
                        ? <td>
                            <Link to={`/profiles/accutech/${activeData[dataIndex]?.devices[i].device_id}`}
                                  onClick={() => closeModal("cancelEditBtn")}>{activeData[dataIndex]?.devices[i].description} - {activeData[dataIndex]?.devices[i].manufacture_uuid}
                            </Link>
                        </td>
                        : <td>
                            <Link to={`/profiles/inovonics/${activeData[dataIndex]?.devices[i].device_id}`}
                                    onClick={() => closeModal("cancelEditBtn")}>{activeData[dataIndex]?.devices[i].description} - {activeData[dataIndex]?.devices[i].manufacture_uuid}
                            </Link>
                        </td>
                    }
                </tr>
            );
        }
        setEditDeviceList(tmp);
    }

    function filterSubmit() {
        handleSubmitActions("filterSubmit", "loadFilterSubmit");
        setResIdList([]);
        setPerPage(tmpPerPage);
        dData.perPage = tmpPerPage;
        setPage(1);
        ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
        formSuccess("Page parameters stored to session.", "filterSubmit",
            "loadFilterSubmit", "cancelFilterBtn");
        setCount(0);
    }

    function formAddSubmit(){
        // User add form submit function
        let fname, lname, roomPh, payload, email, locId;
        handleSubmitActions("addSubmit", "loadAddSubmit");
        fname = document.getElementById("fName").value;
        lname = document.getElementById("lName").value;
        email = document.getElementById("email").value;
        locId = document.getElementById("locationId").value;
        roomPh = document.getElementById("roomPh").value.replaceAll("-", "");
        payload = [{campus_id: campusId, resident_first_name: fname, resident_last_name: lname,
                    resident_phone: roomPh, resident_email: email.toLowerCase(), is_area_assigned: true,
                    area_id: parseInt(locId), resident_group_ids: resGroupIdList}];
        ApiRequester({reqEndpoint: "mytransaction", TransType: "resident_add", Payload: payload,
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
            if (data && data[0] === true){
                setResIdList([]);
                formSuccess(`${fname} ${lname} has been added`, "addSubmit",
                    "loadAddSubmit", "cancelAddBtn", "addForm");
                setCount(0);
            }
            else{
                formFail(`Error during add, error code: ${data[1]}`, "addSubmit", "loadAddSubmit");
            }
        });
    }

    function formAddMobileSubmit(){
        // User add form submit function
        let fname, lname, roomPh, payload, email, msg, username, passwd, resId;
        handleSubmitActions("addMobileSubmit", "loadAddMobileSubmit");
        fname = document.getElementById("fNameE").value;
        lname = document.getElementById("lNameE").value;
        email = document.getElementById("emailE").value;
        roomPh = document.getElementById("roomPhE").value.replaceAll("-", "");
        username = document.getElementById("username").value;
        passwd = document.getElementById("password").value;
        resId = document.getElementById("resUnqIdE").value;
        payload = [{Action: "new", UserEmail: email.toLowerCase(), Username: username, UserPass: passwd, CampusId: campusId,
                    ClientId: dData.sessionId, PhoneNumber: roomPh, RegisterType: "resident", resident_id: parseInt(resId)}];
        ApiRequester({reqEndpoint: "user-manage", Payload: payload}).then(data => {
            if (data[0] === true){
                setMobileAppDisplay(() => {return (
                    <>
                        <h5 className={"d-inline-block mb-3"}>Username: </h5><span className={"ms-3"}>{username}</span>
                        <Button text={"Password Reset"} type={"button"} class={"btn btn-primary d-block"} id={"passBtn"}
                                    icon={true} iconClass={"fe fe-lock me-2"} onClick={() => swapModal("cancelEditBtn", "passmodal")} />
                    </>
                )});
                formSuccess(`${fname} ${lname} has been signed up for the PalCare resident mobile app.`,
                    "addMobileSubmit", "loadAddMobileSubmit", "cancelAddMobileBtn", "addMobileForm");
                setCount(0);
            }
            else{
                if (data[1] === "1"){
                    msg = "Username already exists, please change username.";
                }
                else if (data[1] === "2"){
                    msg = "Error, user created, failed saving user details. Contact your customer success manager for assistance.";
                }
                else if (data[1] === "8"){
                    msg = "Request aborted, missing required data. Contact your customer success manager for assistance.";
                }
                else if (data[1] === "9"){
                    msg = "Request aborted, no matching action. Contact your customer success manager for assistance.";
                }
                else{
                    msg = "Unknown failure, request not processed.";
                }
                formFail(msg, "addMobileSubmit", "loadAddMobileSubmit");
            }
        });
    }

    function formEditSubmit(){
        // Location add form submit function
        let fname, lname, resId, email, roomPh, payload, locId;
        handleSubmitActions("editSubmit", "loadEditSubmit");
        fname = document.getElementById("fNameE").value;
        lname = document.getElementById("lNameE").value;
        locId = document.getElementById("locationIdE").value;
        email = document.getElementById("emailE").value;
        roomPh = document.getElementById("roomPhE").value.replaceAll("-", "");
        resId = document.getElementById("resUnqIdE").value;
        payload = [{resident_id: parseInt(resId), resident_first_name: fname, resident_last_name: lname, is_active: 1,
                    resident_email: email.toLowerCase(), resident_phone: roomPh, area_id: parseInt(locId),
                    resident_group_ids: resGroupIdList, campus_id: campusId}];
        ApiRequester({reqEndpoint: "mytransaction", TransType: "resident_edit", Payload: payload,
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
            if (data && data[0]){
                setResIdList([]);
                formSuccess(`${fname} ${lname} updated.`, "editSubmit",
                    "loadEditSubmit", "cancelEditBtn");
                setCount(0);
            }
            else{
                formFail("Error during save, error code: " + data[1], "editSubmit", "loadEditSubmit");
            }
        });
    }

    function passSubmit(){
        let password, username, msg, payload;
        handleSubmitActions("passSubmit", "loadPassSubmit");
        username = activeData[storedIndex]?.username;
        password = document.getElementById("passwordR").value;
        payload = [{Action: "reset", CampusId: campusId, Username: username, UserPass: password, RegisterType: "resident"}];
        ApiRequester({reqEndpoint: "user-manage", Payload: payload}).then(data => {
            if (data[0] === true){
                setResIdList([]);
                formSuccess("Password reset successfully.", "passSubmit", "loadPassSubmit", "cancelPassBtn", "passForm");
                setCount(0);
            }
            else{
                if (data[1] === "3"){
                    msg = "Current password does not match records.";
                }
                else if (data[1] === "4"){
                    msg = "User not authorized.";
                }
                else if (data[1] === "5"){
                    msg = "Error encountered authenticating user. Please contact support for assistance if error persists.";
                }
                else if (data[1] === "6"){
                    msg = "New password failed to be set, check password and try again. Contact support if failure persists.";
                }
                else{
                    msg = "Unknown failure, request not processed.";
                }
                formFail(msg, "passSubmit", "loadPassSubmit");
            }
        });
    }

    // function removeDevicesFromResidents(residentList){
    //     let deviceList = [];
    //     for (let i=0; i < activeData.length; i++){
    //         if (residentList.includes(activeData[i].resident_id)){
    //             if (activeData[i]?.devices){
    //                 for (let i2=0; i2 < activeData[i]?.devices.length; i2++){
    //                     deviceList.push(activeData[i]?.devices[i2].device_id);
    //                 }
    //             }
    //         }
    //     }
    //     if (deviceList.length > 0){
    //         removeDevice(deviceList);
    //     }
    // }

    function deletePerform(submitBtn, loadBtn, closeId, start, end){
        let payload;
        payload = [];
        handleSubmitActions(submitBtn, loadBtn);
        for (let i=0; i < dContent.length; i++){
            payload.push({resident_id: dContent[i][0]});
        }
        ApiRequester({reqEndpoint: "mytransaction", TransType: "resident_disable", Payload: payload,
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
            if (data[0]){
                formSuccess("Resident moved out.", submitBtn, loadBtn, closeId);
                setCount(0);
                setResIdList([]);
                document.getElementById("uCheckHead").checked = false;
                checkAll(start, end);
                actionAppearance(start, end, ["deleteBtn"]);
            }
            else{
                formFail(`Error performing move out, error code: ${data[1]}`, submitBtn, loadBtn);
            }
        });
    }

    function deviceExists(serialNum){
        for (let i=0; i < deviceData.length; i++){
            if (serialNum.toString() === deviceData[i]?.manufacture_uuid.toString()) {
                return deviceData[i];
            }
        }
        return false;
    }

    function pendantSubmit(initial){
        let description, msg, payload, serialNum, fname, lname, resId, useId, modelId, dRec, devName;
        serialNum = listenDevice;
        if (!serialNum || serialNum === ""){
            serialNum = document.getElementById("serialNum")?.value;
            if (!serialNum || serialNum === ""){
                serialNum = document.getElementById("serialNum2")?.value;
            }
        }
        if (serialNum && !isNaN(serialNum)){
            serialNum = parseInt(serialNum);
        }
        dRec = deviceExists(serialNum);
        if (initial){
            if (dRec && dRec?.is_active){
                setAssignedRes(dRec?.resident_name);
                hideModal("pendantmodal");
                showModal("pendantconfirmmodal");
                return
            }else{
                handleSubmitActions("pendantSubmit", "loadPendantSubmit");
            }
        } else{
            handleSubmitActions("pendantConfirmSubmit", "loadPendantConfirmSubmit");
        }

        if (listening){
            setListening(false);
            setListenCount(60);
        }

        fname = document.getElementById("fNameE").value;
        lname = document.getElementById("lNameE").value;
        resId = document.getElementById("resUnqIdE").value;
        devName = document.getElementById("device_name").value;
        description = document.getElementById("description").value;
        modelId = document.getElementById("deviceModelId").value;

        if (manufactureId.toString() === "1"){
            useId = determineUseId(deviceCatId, deviceTypeId);
        }else{
            useId = 0;
        }

        devName = determineDevName(devName, deviceTypeId);

        if (dRec){
            // Edit device and add to resident
            payload = [{device_id: dRec.device_id, campus_id: campusId, is_active: true, resident_id: parseInt(resId),
                        device_name: devName, manufacture_model_id: parseInt(modelId), device_use_id: parseInt(useId),
                        descriptions: description, is_resident: true, is_area_device: false, area_id: 0}]
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_device", PKey: "device_id", ClientId: dData.sessionId,
                            Condition: "primary", urlType: "POST", Payload: payload, User: dData.user, CampusId: campusId}).then(data => {
                if (data && data[0] === true){
                    msg = `${devName} has been added to ${fname} ${lname}`;
                    if (!initial){
                        handleSubmitActions("loadPendantConfirmSubmit", "pendantConfirmSubmit");
                        handleSubmitActions("loadPendantSubmit", "pendantSubmit");
                        swapModal("pendantConfirmClose", "editmodal");
                        document.getElementById("pendantForm").reset();
                        toast.success(msg);
                        setCount(0);
                    }else{
                        formSuccess(msg, "pendantSubmit", "loadPendantSubmit", "cancelPendantBtn", "pendantForm");
                        setCount(0);
                    }
                }
                else{
                    handleSubmitActions("loadPendantConfirmSubmit", "pendantConfirmSubmit");
                    toast.error(`Failed to add ${devName}`);
                }
            });
        }else{
            // Add device and add to resident
            payload = [{campus_id: campusId, manufacture_id: parseInt(manufactureId), manufacture_uuid: serialNum.toString(),
                        device_name: devName, device_use_id: parseInt(useId), is_active: true, is_alerting_now: false, is_server: false,
                        is_area_device: false, is_resident: true, resident_id: parseInt(resId), manufacture_model_id: parseInt(modelId),
                        escalation_enabled: true, careflow_id: 1, descriptions: description}]
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_device", Payload: payload, urlType: "PUT",
                                CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
                if (data && data[0] === true){
                    msg = `${devName} has been added to ${fname} ${lname}`;
                    formSuccess(msg, "pendantSubmit", "loadPendantSubmit", "cancelPendantBtn", "pendantForm");
                    setCount(0);
                }
                else{
                    toast.error(`Failed to add ${devName}`);
                    handleSubmitActions("loadPendantSubmit", "pendantSubmit");
                }
            });
        }
    }

    function setDeviceIds(devTypeId){
        let productCode, modelId, msgClass, pType, devName;
        productCode = document.getElementById("productCode");
        modelId = document.getElementById("deviceModelId");
        devName = document.getElementById("device_name");
        msgClass = document.getElementById("deviceMsgClass");
        pType = document.getElementById("deviceProductType");
        if (manufactureId?.toString() === "1"){
            for (let i = 0; i < deviceTypeData.length; i++){
                if (deviceTypeData[i]?.inovonics_type_id.toString() === devTypeId.toString()){
                    productCode.value = deviceTypeData[i]?.model_name;
                    modelId.value = deviceTypeData[i]?.inovonics_device_id;
                    devName.value = deviceTypeData[i]?.device_type_name;
                    msgClass.value = deviceTypeData[i]?.message_class;
                    pType.value = deviceTypeData[i]?.product_type_id;
                    checkListening(deviceTypeData[i]?.device_type_name);
                    break
                }
            }
        }else if (manufactureId?.toString() === "6"){
            for (let i = 0; i < accutechDeviceData.length; i++){
                if (accutechDeviceData[i]?.accutech_device_id.toString() === devTypeId.toString()){
                    productCode.value = accutechDeviceData[i]?.Accutech_model_id;
                    modelId.value = accutechDeviceData[i]?.accutech_device_id;
                    devName.value = accutechDeviceData[i]?.description;
                    msgClass.value = 0;
                    pType.value = 0;
                    break
                }
            }
        }
    }

    function checkListening(device){
        if (listening && device){
            // Toggle already on, and just set device
            setListenIcon(
                <i className={"fe fe-rss icon-vibrant pointer-no fs-2"}></i>
            );
            showListenList();
            publishListen(device);
        }else{
            if (!device && listening){
                // Listening but just cleared the device
                setListenIcon(
                    <i className={"fe fe-rss pointer-no fs-2"}></i>
                );
                setListening(false);
            }else{
                // Not listening
            }
        }
    }

    function showListenList(){
        setSelectReq(true);
        setListenDevice(() => "");
        setListenList(() => []);
        document.getElementById("serialNum").required = false;
        document.getElementById("serialToggle").classList.add("d-none");
        document.getElementById("serial2Toggle").classList.remove("d-none");
        document.getElementById("serialNum").value = "";
    }

    function hideListenList(){
        setSelectReq(false);
        setListenDevice(() => "");
        setListenList(() => []);
        document.getElementById("serialNum").required = true;
        document.getElementById("serialToggle").classList.remove("d-none");
        document.getElementById("serial2Toggle").classList.add("d-none");
    }


    function publishListen(device){
        let msgClass, pType;
        msgClass = document.getElementById("deviceMsgClass").value;
        pType = document.getElementById("deviceProductType").value;
        ApiRequester({reqEndpoint: "mqtt", Operation: "learnin", ClientId: dData.sessionId, ProductType: pType,
                            CampusId: dData?.acnt, MsgClass: msgClass}).then(data => {

            if (data[0]){
                toast.info(`Listening for ${device}`);
                setListenCount(0);
            }
            else{
                toast.error("Error with auto listening, try again, " +
                    "if persist contact your customer success for assistance.");
                setListening(false);
            }
        });
    }


    const tableHeaders = <TableHeaders checkbox={true} headerData={headerData} checkAll={() => checkAll(ppRules.start, ppRules.end, resIdList, setResIdList)}
                                       writeAccess={editable} sortOrder={sorter.sortOrder} modal_name={"Edit"}
                                       sortColumn={sorter.sortColumn} sorter={sorter} setSorter={setSorter} modal={true} checkboxlist={resIdList}
                                       actionAppearance={() => actionAppearance(ppRules.start, ppRules.end, ["deleteBtn"])}/>

    const groupTableHead = <TableHeaders checkbox={true} headerData={headerData2} checkboxlist={resGroupIdList}
                                         checkAll={() => checkAll(0, groupDataCnt ? groupDataCnt : 0, resGroupIdList, setResGroupIdList, "uCheckHead2", "uCheckGroup")}
                                         writeAccess={true} sortOrder={sorter2.sortOrder} checkboxHeaderId={"uCheckHead2"}
                                         sortColumn={sorter2.sortColumn} sorter={sorter2} setSorter={setSorter2}
                                         actionAppearance={() => {}} />
    // Must have separate header component per table so rendered header ID's do not conflict
    const groupAddTableHead = <TableHeaders checkbox={true} headerData={headerData2} checkboxlist={resGroupIdList}
                                         checkAll={() => checkAll(0, groupDataCnt ? groupDataCnt : 0, resGroupIdList, setResGroupIdList, "uCheckHead3", "uCheckGroup")}
                                         writeAccess={true} sortOrder={sorter2.sortOrder} checkboxHeaderId={"uCheckHead3"}
                                         sortColumn={sorter2.sortColumn} sorter={sorter2} setSorter={setSorter2}
                                         actionAppearance={() => {}} />

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div style={{minWidth: "360px"}}>
                <div className={"d-block"}>
                    {editable && <Button text={"Move Out"} type={"button"} iconClass={"fe fe-user-x me-2"}
                                         class={"btn btn-danger ms-2 hide mt-1"} id={"deleteBtn"} icon={true}
                                         onClick={() => bulkDeleteModal(ppRules.start, ppRules.end, activeData,
                            ["resident_first_name", "resident_last_name"], ["resident_id", ""],
                                             "", setDContent, "Are you sure you want to move out the following residents? \n")} />}
                    <div className={"form-outline d-inline-flex align-bottom ms-2"}>
                        <input type={"search"} id={"search1"} className={"form-control search-input mt-1"}
                             onKeyUp={(e) => setSearch(e.target.value)} />
                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left mt-1"}
                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"} id={"search-btn-1"} />
                    </div>
                    {csvButton}
                    {editable && <Button text={"New Resident"} type={"button"} class={"btn btn-primary ms-2 mt-1"}
                                         icon={true} iconClass={"fe fe-plus me-2"}
                                         onClick={() => {
                                             setSearch2("");
                                             document.getElementById("locationId").value = "";
                                             document.getElementById("uCheckHead3").checked = false;
                                             setStoredIndex(undefined);
                                             setResGroupIdList([]);
                                             formCheck("addForm", "addSubmit");
                                             showModal("addmodal");}} />
                    }
                </div>
                <div className={"d-block"}>
                    <div className={"form-check d-inline-block mt-3 float-end"}>
                        <input className={"form-check-input form-check-med"} id={"active-toggle"} name={"active-toggle"}
                               type={"checkbox"} checked={showInactive} onChange={(e) => {
                                   setShowInactive(e.target.checked);
                        }} />
                        <label className={"form-check-label"} htmlFor={"active-toggle"}>Show moved out</label>
                    </div>
                </div>
            </div>
            <CampusDropDown campusId={campusId} setCampusId={(item) => setCampusId(item)} setCount={(item) => setCount(item)} pageAction={() => {}}/>
        </div>
    );

    const footer = (
        <div>
            <Pagination>
                <PaginationItems setPage={setPage} page={page} beginDot={ppRules.beginDot}
                                 endDot={ppRules.endDot} pageMin={ppRules.pageMin} pageMax={ppRules.pageMax}
                                 max={ppRules.max}/>
            </Pagination>
            <Button class={"btn btn-secondary fs-5 ms-4"} text={perPage} onClick={() => showModal("filtermodal")}/>
        </div>
    );

    return (dData?.slauth === true && dData?.tKey != null && dData?.tKey !== "" && dData?.acnt != null && dData?.access?.Community?.Residents?.Read) ? (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Community"} Title={"Residents"} content={headerContent}/>
                <div className={"container-fluid"}>
                    <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                        <li className={"nav-item"}>
                            <Link to={"/community/residents"}>
                                <Button text={"Individuals"} class={loadState === "individuals" ? "nav-link active" : "nav-link"}
                                    onClick={() => setLoadState("individuals")} />
                            </Link>
                        </li>
                        <li className={"nav-item"}>
                            <Link to={"/community/residents/groups"}>
                                <Button text={"Groups"} class={loadState === "groups" ? "nav-link active" : "nav-link"}
                                    onClick={() => setLoadState("groups")} />
                            </Link>
                        </li>
                    </ul>
                    <table className={"table table-sm table-white table-hover"}>
                        <thead className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString() ? "sticky-table-headX" : "sticky-table-head"}>
                            {tableHeaders}
                        </thead>
                        <tbody id={"tBody"}>
                            {tableRows}
                        </tbody>
                    </table>
                    <div className={"footer-spacer"}>

                    </div>
                </div>
                <Footer center={footer}/>
            </div>
            <div className={"modal fade"} id={"addmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Add Resident</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"addForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3"}>
                                    <select className={"form-select"} defaultValue={undefined} required={true} id={"locationId"}
                                        onChange={() => formCheck("addForm", "addSubmit")}>
                                        <option value={""}></option>
                                        {locationOptions}
                                    </select>
                                    <label htmlFor={"locationId"}>Area</label>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"fName"} onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           required={true} pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                    <label htmlFor={"fName"}>First Name</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a first name
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"lName"} onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           required={true} pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                    <label htmlFor={"lName"}>Last Name</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a last name
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"roomPh"} pattern={"[0-9]{3}-?[0-9]{3}-?[0-9]{4}"}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")} />
                                    <label htmlFor={"roomPh"}>Room Phone #</label>
                                    <div className={"invalid-feedback"}>
                                        Enter valid phone number xxx-xxx-xxxx
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"email"}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")}
                                            pattern={"[A-Za-z0-9._%+\\-]+@[A-Za-z0-9.\\-]+\\.[A-Za-z]{2,}$"} />
                                    <label htmlFor={"email"}>Email Address</label>
                                    <div className={"invalid-feedback"}>
                                        Enter valid email address
                                    </div>
                                </div>
                                <hr />
                                <div className={"mt-1"}>
                                    <h3>Groups</h3>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                        <input type={"search"} id={"search2"} className={"form-control search-input"}
                                               value={search2}
                                               onChange={(e) => setSearch2(e.target.value)} />
                                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"} id={"search-btn-2"}/>
                                    </div>
                                    <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {groupAddTableHead}
                                            </thead>
                                            <tbody>
                                                {groupTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <button type={"button"} className={"btn btn-secondary"} id={"cancelAddBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                <Button type={"button"} class={"btn btn-primary d-none"} disabled={true} spinner={true} text={"Loading"} id={"loadAddSubmit"}/>
                                <button type={"button"} className={"btn btn-primary"} onClick={() => formAddSubmit()} id={"addSubmit"}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"editmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticEditBackdropLabel"} aria-hidden={"true"} style={{overflowY: "auto"}}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticEditBackdropLabel"}>Edit Resident</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"editForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"w-50 d-inline-block"}>
                                    <input type={"hidden"} id={"resUnqIdE"} />
                                    <input type={"hidden"} id={"campusIdE"} />
                                    <div className={"form-floating mb-3"}>
                                        <input type={"text"} className={"form-control"} id={"fNameE"}
                                               onKeyUp={() => formCheck("editForm", "editSubmit")} required={true}
                                               pattern={"[0-9a-zA-Z_\\- ]+"} />
                                        <label htmlFor={"fNameE"}>First Name</label>
                                        <div className={"invalid-feedback"}>
                                            Please enter a first name
                                        </div>
                                    </div>
                                    <div className={"form-floating mb-3"}>
                                        <input type={"text"} className={"form-control"} id={"lNameE"}
                                               onKeyUp={() => formCheck("editForm", "editSubmit")} required={true}
                                               pattern={"[0-9a-zA-Z_\\- ]+"}/>
                                        <label htmlFor={"lNameE"}>Name</label>
                                        <div className={"invalid-feedback"}>
                                            Please enter a last name
                                        </div>
                                    </div>
                                    <div className={"form-floating mb-3"}>
                                        <input type={"hidden"} id={"origLocationIdE"} />
                                        <select className={"form-select"} required={true}
                                                id={"locationIdE"} value={editLocationId}
                                            onChange={(e) => {
                                                setEditLocationId(parseInt(e.target.value));
                                                formCheck("editForm", "editSubmit");
                                            }}>
                                            <option value={""}></option>
                                            {locationOptions}
                                        </select>
                                        <label htmlFor={"locationIdE"}>Area</label>
                                    </div>
                                    <div className={"form-floating mb-3"}>
                                        <input type={"text"} className={"form-control"} id={"roomPhE"}
                                               pattern={"[0-9]{3}-?[0-9]{3}-?[0-9]{4}"}
                                               onKeyUp={() => formCheck("editForm", "editSubmit")} />
                                        <label htmlFor={"roomPhE"}>Room Phone #</label>
                                        <div className={"invalid-feedback"}>
                                            Enter valid phone number xxx-xxx-xxxx
                                        </div>
                                    </div>
                                    <div className={"form-floating mb-3"}>
                                        <input type={"text"} className={"form-control"} id={"emailE"}
                                               pattern={"[A-Za-z0-9._%+\\-]+@[A-Za-z0-9.\\-]+\\.[A-Za-z]{2,}$"}
                                               onKeyUp={() => formCheck("editForm", "editSubmit")} />
                                        <label htmlFor={"emailE"}>Email</label>
                                        <div className={"invalid-feedback"}>
                                            Enter valid email address
                                        </div>
                                    </div>
                                </div>
                                <div className={"w-50 d-inline-block align-top"} style={{paddingLeft: ".5em"}}>
                                    <div className={"modal-header px-1 pt-0"}>
                                        <h2 className={"d-inline my-0 ms-2"}>Devices</h2>
                                        {editable && <Button text={"Device"} type={"button"} class={"btn btn-primary ms-3"}
                                                             id={"pendantBtn"} icon={true} iconClass={"fe fe-plus me-2"}
                                                             onClick={() => {
                                                                 setManufactureId(0);
                                                                 setDeviceTypeId(0);
                                                                 setDeviceCatId(0);
                                                                 document.getElementById("listen-container")?.classList?.remove("d-none");
                                                                 swapModal("cancelEditBtn", "pendantmodal")
                                                             }} />}
                                    </div>
                                    <div className={"modal-body px-1"}>
                                        <table>
                                            <tbody>
                                                {editDeviceList}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {isMobileActive
                                    ? <>
                                        <hr />
                                        <div>
                                            <div>
                                                <h2>Mobile App</h2>
                                            </div>
                                            <div>
                                                {mobileAppDisplay}
                                            </div>
                                        </div>
                                    </>
                                    : <></>}
                                <hr />
                                <div className={"mt-1"}>
                                    <h3>Groups</h3>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                        <input type={"search"} id={"search2E"} className={"form-control search-input"}
                                               value={search2}
                                               onChange={(e) => setSearch2(e.target.value)} />
                                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"} id={"search-btn-3"} />
                                    </div>
                                    <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {groupTableHead}
                                            </thead>
                                            <tbody>
                                                {groupTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"} style={{justifyContent: "space-between"}}>
                                <div>
                                    {editable && <Button text={"Delete"} type={"button"} class={"btn btn-danger me-3"}
                                                         id={"deleteSingleBtn"}/>}
                                </div>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelEditBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadEditSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} onClick={() => formEditSubmit()} id={"editSubmit"}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"pendantmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticDeviceBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticDeviceBackdropLabel"}>Add Device</h2>
                            <button type={"button"} className={"btn-close"} id={"pendantClose"} data-bs-dismiss={"modal"} aria-label={"Close"}
                                    onClick={() => setListening(false)}/>
                        </div>
                        <form id={"pendantForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"d-flex mb-4"} id={"listen-container"}>
                                    <label className={"form-check-label me-4 mt-1"} htmlFor={"item1"} id={"label1"}>Auto
                                        Register</label>
                                    <div className={"form-check form-switch mt-1"}>
                                        <input className={"form-check-input"} id={"item1"} type={"checkbox"}
                                               checked={listening}
                                               onChange={() => {
                                                   setListening(!listening);
                                               }}/>
                                    </div>
                                    {listenIcon}
                                </div>
                                <div className={"form-floating mb-3 d-inline-block w-75 me-2"}>
                                    <select className={"form-select"} value={undefined}
                                            required={true} id={"device_manufacture"}
                                            onChange={(e) => {
                                                setManufactureId(e.target.value);
                                                if (e.target?.value?.toString() === "6"){
                                                    setSerialPattern("^(?:[1-9]|00?[1-9]|0?[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-4])$");
                                                }else{
                                                    setSerialPattern("^[0-9]+$");
                                                }
                                                if (e.target?.value?.toString() === "1"){
                                                    document.getElementById("listen-container").classList.remove("d-none");
                                                }else{
                                                    document.getElementById("listen-container").classList.add("d-none");
                                                    if (listening){
                                                        toast.warn("Auto register not available for manufacturer")
                                                        setListening(false);
                                                    }
                                                }
                                                setDeviceCatId(0);
                                                setDeviceTypeId(0);
                                                formCheck("pendantForm", "pendantSubmit");
                                            }}>
                                        <option value={""}></option>
                                        {manufactureOptions}
                                    </select>
                                    <label htmlFor={"device_manufacture"}>Device Manufacture</label>
                                </div>
                                <div className={"form-floating mb-3 d-inline-block w-75 me-2"}>
                                    <select className={"form-select"} value={undefined}
                                            required={true} id={"device_category"}
                                            onChange={(e) => {
                                                setDeviceCatId(e.target.value);
                                                setDeviceTypeId(0);
                                                formCheck("pendantForm", "pendantSubmit");
                                            }}>
                                        <option value={""}></option>
                                        {deviceCatOptions}
                                    </select>
                                    <label htmlFor={"device_category"}>Device Category</label>
                                </div>
                                <div className={"d-flex justify-content-around"}>
                                    <div className={"form-floating mb-3 d-inline-block w-75 me-2"}>
                                        <select className={"form-select"} required={true}
                                                value={undefined} id={"device_type_id"}
                                                onChange={(e) => {
                                                    setDeviceTypeId(e.target.value);
                                                    formCheck("pendantForm", "pendantSubmit");
                                                    setDeviceIds(e.target.value);
                                                    if (manufactureId.toString() === "6"){
                                                        if (e.target.value.toString() === "4"){
                                                            setSerialPattern("^[0-9a-zA-z]+$")
                                                        }else{
                                                            setSerialPattern("^(?:[1-9]|00?[1-9]|0?[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-4])$")
                                                        }
                                                    }
                                                }}>
                                            <option value={""}></option>
                                            {deviceTypeOptions}
                                        </select>
                                        <label htmlFor={"device_type_id"}>Device Type</label>
                                    </div>
                                    <div className={"form-floating mb-3 d-inline-block"} style={{width: "23%"}}>
                                        <input type={"text"} className={"form-control"} id={"productCode"}
                                               readOnly={true} required={true}/>
                                        <label htmlFor={"productCode"}>Product Code</label>
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"description"}
                                           pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                    <label htmlFor={"description"}>Description (optional)</label>
                                </div>
                                <input type={"hidden"} id={"deviceModelId"}/>
                                <input type={"hidden"} id={"device_name"}/>
                                <input type={"hidden"} id={"deviceMsgClass"}/>
                                <input type={"hidden"} id={"deviceProductType"}/>
                                <div className={"form-floating mb-3"} id={"serialToggle"}>
                                    <input type={"text"} className={"form-control"} id={"serialNum"}
                                           onKeyUp={(e) => formCheckWithElement("pendantForm", "pendantSubmit", e, deviceData)}
                                           required={true} pattern={serialPattern}/>
                                    <label htmlFor={"serialNum"}>Serial Number</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter the serial number of device
                                    </div>
                                </div>
                                <div className={"form-floating mb-3 d-none"} id={"serial2Toggle"}>
                                    <select className={"form-select"} id={"serialNum2"} required={selectReq}
                                            value={listenDevice}
                                            onChange={(e) => {
                                                setListenDevice(e.target.value);
                                            }}>
                                        {listenList}
                                    </select>
                                    <label htmlFor={"serialNum2"}>Serial Numbers</label>
                                </div>
                                {[].includes(deviceTypeId.toString()) &&
                                    <>
                                        <h3 className={"mt-4"}>Behavioral Settings</h3>
                                        <hr/>
                                    </>
                                }
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelPendantBtn"}
                                            onClick={() => {
                                                swapModal("pendantClose", "editmodal");
                                                formClear("pendantForm", "pendantSubmit");
                                                setListening(false);
                                            }}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadPendantSubmit"}/>
                                    <button type={"button"} className={"btn btn-danger ms-3"} id={"pendantSubmit"}
                                            onClick={() => pendantSubmit(true)}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"addmobilemodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticDeviceBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticDeviceBackdropLabel"}>Add Mobile Account</h2>
                            <button type={"button"} className={"btn-close"} id={"addMobileClose"} data-bs-dismiss={"modal"} aria-label={"Close"}
                                    onClick={() => setListening(false)}/>
                        </div>
                        <form id={"addMobileForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"username"}
                                           onKeyUp={() => formCheck("addMobileForm", "addMobileSubmit")}
                                           required={true} pattern={"^[0-9a-zA-Z_@-]+$"}/>
                                    <label htmlFor={"username"}>Username</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a valid username! No spaces allowed.
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"password"} className={"form-control"} id={"password"} required={true} pattern={"[ -~]{6,}$"}
                                            onKeyUp={(e) => {
                                                document.getElementById("password2").pattern = e.target.value;
                                                formCheck("addMobileForm", "addMobileSubmit");
                                            }} />
                                    <label htmlFor={"password"}>Password</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a valid password! 6 Character minimum.
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"password"} className={"form-control"} id={"password2"} required={true}
                                           onKeyUp={() => formCheck("addMobileForm", "addMobileSubmit")} />
                                    <label htmlFor={"password2"}>Confirm Password</label>
                                    <div className={"invalid-feedback"}>
                                        Confirm password must match previous password field.
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelAddMobileBtn"}
                                            onClick={() => {
                                                swapModal("addMobileClose", "editmodal");
                                                formClear("addMobileForm", "addMobileSubmit");
                                            }}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadAddMobileSubmit"}/>
                                    <button type={"button"} className={"btn btn-danger ms-3"} id={"addMobileSubmit"} onClick={() => formAddMobileSubmit()}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"pendantconfirmmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticPendantConfirmLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticPendantConfirmLabel"}>Confirm Add</h2>
                            <button type={"button"} className={"btn-close"} id={"pendantConfirmClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <div className={"modal-body"}>
                            <p>Device is already linked to {assignedRes} Do you wish to re-assign this device?</p>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}} id={"pendantCancelBtn"}
                                    onClick={() => swapModal("pendantConfirmClose", "editmodal")}>No</button>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadPendantConfirmSubmit"}/>
                            <button type={"button"} className={"btn btn-primary ms-3"} id={"pendantConfirmSubmit"} onClick={() => pendantSubmit(false)}>YES</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"passmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Reset Password</h2>
                            <button type={"button"} className={"btn-close"} id={"passClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"passForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"passwordR"}
                                           onKeyUp={() => formCheck("passForm", "passSubmit")}
                                           required={true} pattern={"[ -~]{6,}"} />
                                    <label htmlFor={"passwordR"}>New Password</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a valid password! 6 Character minimum.
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"passwordCR"}
                                            onKeyUp={() => formCheck("passForm", "passSubmit")} required={true}  pattern={"[ -~]{6,}"} />
                                    <label htmlFor={"passwordCR"}>Confirm Password</label>
                                    <div className={"invalid-feedback"}>
                                        Password does not match.
                                    </div>
                            </div>
                                </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelPassBtn"}
                                            onClick={() => {
                                                swapModal("passClose", "editmodal");
                                                formClear("passForm", "passSubmit");
                                            }}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadPassSubmit"}/>
                                    <button type={"button"} className={"btn btn-danger ms-3"} id={"passSubmit"} onClick={() => passSubmit()}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <SingleDeleteModal deletePerform={() => deletePerform("deleteSubmit", "loadDeleteSubmit", "confirmClose", ppRules.start, ppRules.end)}
                                header={"Delete Resident"}/>
            <BulkDeleteModal deletePerform={() => deletePerform("bulkDeleteSubmit", "loadBulkDeleteSubmit", "bulkConfirmClose", ppRules.start, ppRules.end)}
                             start={ppRules.start} end={ppRules.end} header={"Delete Residents"} />
            <PerPageModal filterSubmit={() => filterSubmit()} tmpPerPage={tmpPerPage} setTmpPerPage={(sudocode) => setTmpPerPage(sudocode)} setPage={(sudocode) => setPage(sudocode)}/>
            <input type={"hidden"} id={"dContent"}/>
            <input type={"hidden"} id={"uContent"}/>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </>
    ) : <Navigate to={"/"}/>;
};

export default Residents;