import { Navigate } from 'react-router-dom';
import Navigation from "./Navigation";
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import { useParams } from "react-router";
import {compareRole, iAmHigherAuthority} from "../libs";
import SessionReload from "./component-assets/SessionReload";
import HeaderBanner from "./component-assets/HeaderBanner";
import CampusLogs from "./CampusLogs";

const Profiles = (props) => {
    let pageContent, dData, paramVars, bData;
    paramVars = useParams();

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    if (paramVars.dType === "campus" && (!compareRole("corporate-admin", dData) || iAmHigherAuthority(80, dData?.role_weight))){
        pageContent = <CampusLogs id={paramVars?.eType} />;
    }
    else{
        pageContent = (
            <div>
                <h3>Page not found. 404</h3>
            </div>
        );
    }

    return (dData?.slauth === true && dData?.tKey != null && dData?.tKey !== "" && dData?.acnt != null && dData?.access) ? (
        <>
            <Navigation />
            {dData?.myVersion !== dData?.currentVersion && dData?.cloud ? <HeaderBanner /> : <></>}
            {dData?.migrated && <SessionReload />}
            {pageContent}
        </>
    ) : <Navigate to={"/"}/>;
};

export default Profiles;