import { ReactSession } from 'react-client-session';
import {Navigate} from "react-router-dom";
import {AES, enc} from "crypto-js";
import Navigation from "./Navigation";
import ProfileContent from "./ProfileContent";
import LegacyProfileContent from "./LegacyProfileContent";
import SessionReload from "./component-assets/SessionReload";
import HeaderBanner from "./component-assets/HeaderBanner";

function Profile(props) {
    let dData, bData, profileContent;
    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }
    if (dData?.access?.Profile?.Read){
        if (dData?.migrated){
            profileContent = <ProfileContent />;
        }else{
            profileContent = <LegacyProfileContent />;
        }
    }
    else{
        profileContent = (
            <div>
                <h3>Page not found. 404</h3>
            </div>
        );
    }

    return (dData?.slauth === true && dData?.tKey != null && dData?.tKey !== "" && dData?.acnt != null && dData?.access?.Profile) ? (
        <>
            <Navigation />
            {dData?.myVersion !== dData?.currentVersion && dData?.cloud ? <HeaderBanner /> : <></>}
            {dData?.migrated && <SessionReload />}
            {profileContent}
        </>
    ) : <Navigate to={"/"}/>;
}

export default Profile;