import { Navigate } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import {Suspense, useEffect, useState} from "react";
import {ToastContainer} from "react-toastify";
import {AES, enc} from "crypto-js";
import Header from "./Header";
import Footer from "./Footer";
import PaginationItems from "./component-assets/PaginationItems";
import PerPageModal from "./component-assets/PerPageModal";
import CampusDropDown from "./component-assets/CampusDropDown";
import {
    actionAppearance, bulkDeleteModal, checkAll, showModal, formFail, formSuccess, handleSubmitActions,
    paginateData, sortFunc, formCheck, range, convertSectoHHMM, calculateSendTime, convertHHMMtoSec
} from "../libs";
import BulkDeleteModal from "./component-assets/BulkDeleteModal";
import Button from "./component-assets/Button";
import {Pagination} from "react-bootstrap";
import {CSVLink} from "react-csv";
import ApiRequester from "./ApiRequester";
import TableBody from "./component-assets/TableBody";
import TableHeaders from "./component-assets/TableHeaders";
import {v4 as uuidv4} from "uuid";

const ScheduledReports = (props) => {
    let dData, bData, headerData, csvHeaders;

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }


    headerData = [
        {label: "Report Description", key: "description", align: "left"},
        {label: "Report Type", key: "report_type_name", align: "center", type: "capitalize"},
        // {label: "Status", key: "enabled", align: "center", type: "bool", t: "Active", f: "Off"},
        {label: "Frequency", key: "frequency_name", align: "center"},
        {label: "Send Time", key: "send_report_time", align: "center", type: "timeofday"},
        {label: "Report Duration", key: "report_interval_name", align: "center"},
        {label: "Last Sent", key: "last_sent", align: "center", type: "datetime-utc"},
        {label: "Next Send Time", key: "next_send_time", align: "center", type: "datetime-utc"},
        {label: "Receivers", key: "report_receiver", align: "center"}
    ];
    csvHeaders = [
        {label: "Report", key: "description"},
        {label: "Report", key: "report_type_name"},
        {label: "Active", key: "enabled"},
        {label: "Frequency", key: "frequency_name"},
        {label: "Send Time", key: "send_report_time"},
        {label: "Report Interval", key: "report_interval_name"},
        {label: "Report Filters", key: "report_filter_terms"},
        {label: "Last Sent", key: "last_sent"},
        {label: "Next Scheduled Time", key: "next_send_time"},
        {label: "Created On", key: "create_time"}
    ];

    const [dataOrigin, setDataOrigin] = useState([]);
    const [activeData, setActiveData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [editable, setEditable] = useState(dData?.access?.Community?.Devices?.Write);
    const [count, setCount] = useState(0);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [storePage, setStorePage] = useState(1);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(dData.perPage);
    const [tmpPerPage, setTmpPerPage] = useState(dData.perPage);
    const [dContent, setDContent] = useState([]);
    const [reportIdList, setReportIdList] = useState([]);
    // Edit scheduled report variables
    const [storedIndex, setStoredIndex] = useState(0);
    const [newEmails, setNewEmails] = useState([]);
    const [curEmails, setCurEmails] = useState([]);
    const [freqOptions, setFreqOptions] = useState([]);
    const [freqData, setFreqData] = useState([]);
    // Other Table Control Variables
    const [search, setSearch] = useState("");
    const [sorter, setSorter] = useState({
        sortColumn: "description",
        sortOrder: "desc"
    });
    const [ppRules, setPRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    });
    const [csvLink, setLink] = useState({
        filename: "devices.csv",
        headers: [],
        data: []
    });
    const [csvButton, setCSVButton] = useState(
        <CSVLink {...csvLink} uFEFF={false}>
            <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary ms-2 px-4 mt-1"} />
        </CSVLink>);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "getreportschedules", reqType: "stored"}).then(data => {
                setDataOrigin(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "att_window_frequency",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setFreqData(data);
            });
            setCount(count => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        let pRules = paginateData(activeData, page, perPage);
        setPRules(pRules);
        if (activeData.length > 0) {
            activeData.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
        }
        setTableRows(<TableBody start={pRules.start} end={pRules.end} tableData={activeData} checkbox={true}
                                writeAccess={editable} unk={"a-"} modal={true}
                                actionAppearance={() => actionAppearance(pRules.start, pRules.end, ["deleteBtn"])}
                                tableHeaders={headerData} editModal={editModal} parentComponent={"Pendants"} dData={dData}
                                actionIcon={true} iconAction={(rowId) => sendReport(rowId)} checkboxlist={reportIdList}
                                setCheckboxId={(value) => {setReportIdList(value)}} checkidkey={"report_id"}
                                tzOffset={parseInt(dData?.campusData[campusId]?.tz_offset ? dData?.campusData[campusId]?.tz_offset : 0)} />);
    }, [activeData, sorter, page, perPage, dataOrigin, reportIdList]);

    useEffect(() => {
        setLink(prevState => {
            return {...prevState,
                headers: csvHeaders,
                data: dataOrigin}
        });
    }, [dataOrigin])

    useEffect(() => {
        setCSVButton(
            <CSVLink {...csvLink} uFEFF={false}>
                <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary ms-2 px-4 mt-1"}/>
            </CSVLink>
        )
    }, [csvLink])

    useEffect(() => {
        // Performs realtime search of data as location types.
        let tmpData, input, pRules;
        tmpData = [];
        input = search?.toString()?.toLowerCase();
        for (let i=0; i<dataOrigin.length; i++){
            if ((dataOrigin[i]?.report_type_name && dataOrigin[i]?.report_type_name?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.report_filter_terms && dataOrigin[i]?.report_filter_terms?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.frequency_name && dataOrigin[i]?.frequency_name?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.send_report_time && dataOrigin[i]?.send_report_time?.toString()?.toLowerCase()?.search(input) !== -1)){
                tmpData.push(dataOrigin[i]);
            }
        }
        pRules = paginateData(tmpData, page, perPage);
        if (input && page > pRules.pageMax){
            setPage(pRules.pageMax);
        }else if (!input){
            setPage(storePage);
        }
        setPRules(pRules);
        setActiveData(tmpData);
    }, [search, dataOrigin]);

    useEffect(() => {
        let tmp = [];
        for (let i=0; i < freqData?.length; i++){
            tmp.push(
                <option key={`freq-option-${i}`} value={freqData[i]?.window_frequency_id}>{freqData[i]?.frequency_name}</option>
            );
        }
        setFreqOptions(() => tmp)
    }, [freqData]);

    function clearSelectors(){
        for (let i=0; i < 31; i++){
            document.getElementById(`sch-monthly-meta-${i}`).checked = false;
        }
        for (let i2=0; i2 < 7; i2++){
            document.getElementById(`sch-weekly-meta-${i2}`).checked = false
        }
    }

    function editModal(editIndex){
        let dataIndex, freqId, entityList, tmp = [], selectorArray;
        clearSelectors()
        dataIndex = parseInt(editIndex);
        setStoredIndex(dataIndex);
        setNewEmails([]);
        entityList = activeData[dataIndex]?.report_receiver !== undefined ? activeData[dataIndex]?.report_receiver.split(",") : [];
        for (let i=0; i < entityList.length; i++){
            tmp.push({id: uuidv4(), email: entityList[i]});
        }
        setCurEmails(tmp);
        freqId = activeData[dataIndex]?.issue_freq_id;
        document.getElementById("sch-frequency").value = freqId;
        document.getElementById("sch-description").value = activeData[dataIndex]?.description;
        if (freqId.toString() === "1"){
            document.getElementById("sch-weekly").classList.add("d-none");
            document.getElementById("scheduler-container").classList.add("d-none");
            document.getElementById("sch-monthly").classList.add("d-none");
        }else if (freqId.toString() === "2"){
            document.getElementById("sch-weekly").classList.remove("d-none");
            document.getElementById("scheduler-container").classList.remove("d-none");
            document.getElementById("sch-monthly").classList.add("d-none");
            selectorArray = activeData[dataIndex]?.window_frequency_reference?.split(",");
            for (let i=0; i < selectorArray.length; i++){
                document.getElementById(`sch-weekly-meta-${selectorArray[i]}`).checked = true;
            }
        }else{
            document.getElementById("sch-monthly").classList.remove("d-none");
            document.getElementById("scheduler-container").classList.remove("d-none");
            document.getElementById("sch-weekly").classList.add("d-none");
            selectorArray = activeData[dataIndex]?.window_frequency_reference?.split(",");
            for (let i=0; i < selectorArray.length; i++){
                document.getElementById(`sch-monthly-meta-${selectorArray[i]}`).checked = true;
            }
        }
        document.getElementById("sch-sendTime").value = convertSectoHHMM(activeData[dataIndex].send_report_time);
        document.getElementById("add-pdf").checked = !!activeData[dataIndex].pdf_summary;
        document.getElementById("add-csv").checked = !!activeData[dataIndex].csv_details;
        if (activeData[dataIndex].report_type_id === 5){
            document.getElementById("add-csv-container").classList.add("d-none");
        }else{
            document.getElementById("add-csv-container").classList.remove("d-none");
        }
        formCheck("editForm", "editSubmit");
        showModal("editmodal");
    }

    function filterSubmit(){
        handleSubmitActions("filterSubmit", "loadFilterSubmit");
        setPerPage(tmpPerPage);
        setReportIdList([]);
        dData.perPage = tmpPerPage;
        setPage(1);
        ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
        formSuccess("Page parameters stored to session.", "filterSubmit", "loadFilterSubmit", "cancelFilterBtn");
        setCount(0);
    }

    function deletePerform(submitBtn, loadBtn, closeId, start, end){
        let payload;
        handleSubmitActions(submitBtn, loadBtn);
        payload = [];
        for (let i=0; i < dContent.length; i++){
            payload.push({report_id: dContent[i][0]});
        }
        ApiRequester({reqEndpoint: "mysave", Tbl: "core_report_definition", Payload: payload, urlType: "DELETE",
                            PKey: "report_id", Condition: "primary", CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
            if (data[0]){
                setCount(0);
                setReportIdList([]);
                formSuccess("Reports removed from system.", submitBtn, loadBtn, closeId);
                document.getElementById("uCheckHead").checked = false;
                checkAll(start, end);
                actionAppearance(start, end, ["deleteBtn"]);
            }
            else{
                formFail(`Failed to delete with error: ${data[1]}`, submitBtn, loadBtn);
            }
        });
    }

    function formEditSubmit(){
        // Location add form submit function
        let repId, emailList = [], payload, description, frequencyId, freqContainer, freqRate = [], sendTime, pdf, csv,
            nextSendTime;
        handleSubmitActions("editSubmit", "loadEditSubmit");
        repId = activeData[storedIndex].report_id;
        description = document.getElementById("sch-description").value;
        for (let i=0; i < curEmails.length; i++){
            emailList.push(curEmails[i].email);
        }
        for (let i2=0; i2 < newEmails.length; i2++){
            emailList.push(newEmails[i2].email);
        }
        frequencyId = parseInt(document.getElementById("sch-frequency").value);
        if (frequencyId === 1){
            freqRate.push(1);
        }else if (frequencyId === 2){
            freqContainer = document.getElementsByName("sch-weekly-meta");
            for (let i=0; i < freqContainer?.length; i++){
                if (freqContainer[i].checked){
                    freqRate.push(freqContainer[i].value);
                }
            }
        }else if (frequencyId === 3){
            freqContainer = document.getElementsByName("sch-monthly-meta");
            for (let i=0; i < freqContainer?.length; i++){
                if (freqContainer[i].checked){
                    freqRate.push(freqContainer[i].value);
                }
            }
        }
        sendTime = document.getElementById("sch-sendTime").value;
        nextSendTime = calculateSendTime(frequencyId, freqRate, sendTime,
            parseInt(dData?.campusData[campusId]?.tz_offset ? dData?.campusData[campusId]?.tz_offset : 0));
        pdf = document.getElementById("add-pdf")?.checked;
        csv = document.getElementById("add-csv")?.checked;
        payload = [{report_id: repId, report_receiver: emailList.join(","), issue_freq_id: frequencyId,
                    window_frequency_reference: freqRate.join(","), next_send_time: nextSendTime,
                    description: description, send_report_time: convertHHMMtoSec(sendTime),
                    pdf_summary: pdf ? 1 : 0, csv_details: csv ? 1 : 0}];
        ApiRequester({reqEndpoint: "mytransaction", TransType: "schedule_edit", Payload: payload,
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
                if (data[0]){
                    setReportIdList([]);
                    formSuccess(`report updated.`, "editSubmit", "loadEditSubmit", "cancelEditBtn");
                    setCount(0)
                }
                else{
                    formFail(`Error during save, error code: ${data[1]}`, "editSubmit", "loadEditSubmit");
                }
        });
    }

    function sendReport(rowIndex){
        let payload, description;
        handleSubmitActions(`icon-btn-${rowIndex}`, `load-icon-btn-${rowIndex}`);
        description = activeData[parseInt(rowIndex)]?.description
        payload = [{
            campus_id: activeData[parseInt(rowIndex)]?.campus_id ? activeData[parseInt(rowIndex)]?.campus_id : campusId,
            description: description,
            source: "portal",
            report_id: activeData[parseInt(rowIndex)]?.report_id,
            report_filter_terms: activeData[parseInt(rowIndex)]?.report_filter_terms,
            report_interval_id: activeData[parseInt(rowIndex)]?.report_interval_id,
            report_receiver: activeData[parseInt(rowIndex)]?.report_receiver,
            report_type_id: activeData[parseInt(rowIndex)]?.report_type_id,
            last_sent: activeData[parseInt(rowIndex)]?.last_sent,
            pdf_summary: activeData[parseInt(rowIndex)]?.pdf_summary,
            csv_details: activeData[parseInt(rowIndex)]?.csv_details
        }]
        ApiRequester({reqEndpoint: "send_report", Payload: payload, CampusId: campusId,
                            ClientId: dData.sessionId, nowait: true}).then(data => {
            if (data[0]){
                formSuccess(`Report ${description} sent successfully`, `icon-btn-${rowIndex}`, `load-icon-btn-${rowIndex}`);
            }
            else{
                formFail("Failed to send report, contact support if failure continues.", `icon-btn-${rowIndex}`, `load-icon-btn-${rowIndex}`);
            }
        });
    }

    const tableHeaders = <TableHeaders checkbox={true} headerData={headerData} checkAll={() => checkAll(ppRules.start, ppRules.end, reportIdList, setReportIdList)}
                                       writeAccess={editable} sortOrder={sorter.sortOrder} modal={true} modal_name={"Edit"}
                                       sortColumn={sorter.sortColumn} sorter={sorter} setSorter={setSorter} actionIcon={true}
                                       iconAction={(rowId) => sendReport(rowId)} checkboxlist={reportIdList}
                                       actionAppearance={() => actionAppearance(ppRules.start, ppRules.end, ["deleteBtn"])}/>

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div style={{minWidth: "360px"}}>
                {editable && <Button text={"Disable"} type={"button"} class={"btn btn-danger ms-2 hide mt-1"}
                                     id={"deleteBtn"} icon={true} iconClass={"fe fe-trash-2 me-2"}
                                     onClick={() => bulkDeleteModal(ppRules.start, ppRules.end, activeData, "report_type_name", ["report_id", ""], "", setDContent)} />}
                <div className={"form-outline d-inline-flex align-bottom ms-2"}>
                    <input type={"search"} id={"search1"} className={"form-control search-input mt-1"}
                         onKeyUp={(e) => setSearch(e.target.value)} />
                    <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left mt-1"} style={{height: "42px"}}
                            icon={true} iconClass={"fe fe-search"}/>
                </div>
                {csvButton}
            </div>
            <CampusDropDown campusId={campusId} setCampusId={(item) => setCampusId(item)} setCount={(item) => setCount(item)} pageAction={() => {}}/>
        </div>
    );

    const footer = (
        <div>
            <Pagination>
                <PaginationItems setPage={setPage} page={page} beginDot={ppRules.beginDot}
                                 endDot={ppRules.endDot} pageMin={ppRules.pageMin} pageMax={ppRules.pageMax}
                                 max={ppRules.max}/>
            </Pagination>
            <Button class={"btn btn-secondary fs-5 ms-4"} text={perPage} onClick={() => showModal("filtermodal")}/>
        </div>
    );

    return (dData?.slauth === true && dData?.tKey != null && dData?.tKey !== "" && dData?.acnt != null && dData?.access?.Reports) ? (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Scheduled Reports"} Title={"Schedules"} content={headerContent}/>
                <div className={"container-fluid mt-4"}>
                    <table className={"table table-sm table-white table-hover"}>
                        <thead className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString() ? "sticky-table-headX" : "sticky-table-head"}>
                        {tableHeaders}
                        </thead>
                        <tbody id={"tBody"}>
                        {tableRows}
                        </tbody>
                    </table>
                    <div className={"footer-spacer"}></div>
                </div>
                <Footer center={footer}/>
            </div>
            <div className={"modal fade"} id={"editmodal"} data-bs-backdrop={"static"}
                 data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticBackdropLabel"}>Edit Recipients</h2>
                            <button type={"button"} className={"btn-close"} id={"editClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <form id={"editForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <h5>* To change contents of a scheduled report, delete current report and re-create from
                                    the report page.</h5>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"sch-description"}
                                           onKeyUp={() => formCheck("editForm", "editSubmit")}
                                           required={true} pattern={"^[0-9a-zA-Z_@\\- ]+$"}/>
                                    <label htmlFor={"sch-description"}>Description</label>
                                </div>
                                <div className={"d-flex justify-content-around"}>
                                    <div className={"form-floating mb-3 me-3 w-75"}>
                                        <select className={"form-select"} required={true} id={"sch-frequency"}
                                                defaultValue={""}
                                                onChange={(e) => {
                                                    if (e.target.value.toString() === "1") {
                                                        document.getElementById("sch-weekly").classList.add("d-none");
                                                        document.getElementById("scheduler-container").classList.add("d-none");
                                                        document.getElementById("sch-monthly").classList.add("d-none");
                                                    } else if (e.target.value.toString() === "2") {
                                                        document.getElementById("sch-weekly").classList.remove("d-none");
                                                        document.getElementById("scheduler-container").classList.remove("d-none");
                                                        document.getElementById("sch-monthly").classList.add("d-none");
                                                    } else {
                                                        document.getElementById("sch-monthly").classList.remove("d-none");
                                                        document.getElementById("scheduler-container").classList.remove("d-none");
                                                        document.getElementById("sch-weekly").classList.add("d-none");
                                                    }
                                                    formCheck("editForm", "editSubmit");
                                                }}>
                                            <option value={""} disabled={true}></option>
                                            {freqOptions}
                                        </select>
                                        <label htmlFor={"sch-frequency"}>Delivery Rate</label>
                                    </div>
                                    <div className={"form-floating mb-3"}>
                                        <input type={"time"} className={"form-control"} id={"sch-sendTime"}
                                               required={true} step={"300"} min={"00:00"} max={"23:59"}
                                               onFocus={(e) => {
                                                   e.target.showPicker();
                                               }}
                                               onBlur={(e) => {
                                                   if (!e.target.checkValidity()) {
                                                       e.target.stepUp();
                                                   }
                                                   formCheck("editForm", "editSubmit");
                                               }}/>
                                        <label htmlFor={"sch-sendTime"}>Time of Day</label>
                                    </div>
                                </div>
                                <div className={"d-inline-block"}>
                                    <div className={"form-check"}>
                                        <label htmlFor={"add-pdf"}>Add PDF Attachment (Summary)</label>
                                        <input id={"add-pdf"} defaultChecked={false}
                                               className={"form-check-input"} type={"checkbox"}/>
                                    </div>
                                    <div className={"form-check"} id={"add-csv-container"}>
                                        <label htmlFor={"add-csv"}>Add CSV Attachment (Details)</label>
                                        <input id={"add-csv"} defaultChecked={false}
                                               className={"form-check-input"} type={"checkbox"}/>
                                    </div>
                                </div>
                                <fieldset className={"border py-3 px-3 mt-4 d-none"} style={{borderRadius: "6px"}}
                                          id={"scheduler-container"}>
                                    <legend className={"fs-3 px-3 w-auto mb-0 legend"} style={{
                                        backgroundColor: "#FFFFFF",
                                        position: "relative",
                                        bottom: "26px"
                                    }}>Delivery Schedule
                                    </legend>
                                    <div id={"sch-weekly"} className={"d-none"}>
                                        <ul style={{columns: 3, listStyle: "none", columnGap: "30px"}}
                                            className={"float-start"}>
                                            <li>
                                                <div className={"form-check"}>
                                                    <label htmlFor={"sch-weekly-meta-0"}>Monday</label>
                                                    <input name={"sch-weekly-meta"} id={"sch-weekly-meta-0"} value={0}
                                                           defaultChecked={false} className={"form-check-input"}
                                                           type={"checkbox"}/>
                                                </div>
                                            </li>
                                            <li>
                                                <div className={"form-check"}>
                                                    <label htmlFor={"sch-weekly-meta-1"}>Tuesday</label>
                                                    <input name={"sch-weekly-meta"} id={"sch-weekly-meta-1"} value={1}
                                                           defaultChecked={false} className={"form-check-input"}
                                                           type={"checkbox"}/>
                                                </div>
                                            </li>
                                            <li>
                                                <div className={"form-check"}>
                                                    <label htmlFor={"sch-weekly-meta-2"}>Wednesday</label>
                                                    <input name={"sch-weekly-meta"} id={"sch-weekly-meta-2"} value={2}
                                                           defaultChecked={false} className={"form-check-input"}
                                                           type={"checkbox"}/>
                                                </div>
                                            </li>
                                            <li>
                                                <div className={"form-check"}>
                                                    <label htmlFor={"sch-weekly-meta-3"}>Thursday</label>
                                                    <input name={"sch-weekly-meta"} id={"sch-weekly-meta-3"} value={3}
                                                           defaultChecked={false} className={"form-check-input"}
                                                           type={"checkbox"}/>
                                                </div>
                                            </li>
                                            <li>
                                                <div className={"form-check"}>
                                                    <label htmlFor={"sch-weekly-meta-4"}>Friday</label>
                                                    <input name={"sch-weekly-meta"} id={"sch-weekly-meta-4"} value={4}
                                                           defaultChecked={false} className={"form-check-input"}
                                                           type={"checkbox"}/>
                                                </div>
                                            </li>
                                            <li>
                                                <div className={"form-check"}>
                                                    <label htmlFor={"sch-weekly-meta-5"}>Saturday</label>
                                                    <input name={"sch-weekly-meta"} id={"sch-weekly-meta-5"} value={5}
                                                           defaultChecked={false} className={"form-check-input"}
                                                           type={"checkbox"}/>
                                                </div>
                                            </li>
                                            <li>
                                                <div className={"form-check"}>
                                                    <label htmlFor={"sch-weekly-meta-6"}>Sunday</label>
                                                    <input name={"sch-weekly-meta"} id={"sch-weekly-meta-6"} value={6}
                                                           defaultChecked={false} className={"form-check-input"}
                                                           type={"checkbox"}/>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div id={"sch-monthly"} className={"d-none"}>
                                        <ul style={{columns: 4, listStyle: "none", columnGap: "30px"}}
                                            className={"float-start"}>
                                            {Array.from(range(1, 30)).map((i) => {
                                                return (
                                                    <li key={`monthly-rate-item-${i}`}>
                                                        <div className={"form-check"}>
                                                            <label htmlFor={`sch-monthly-meta-${i}`}>{i}</label>
                                                            <input name={"sch-monthly-meta"}
                                                                   id={`sch-monthly-meta-${i}`} value={i}
                                                                   defaultChecked={false}
                                                                   className={"form-check-input"} type={"checkbox"}/>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                            <li>
                                                <div className={"form-check"}>
                                                    <label htmlFor={"sch-monthly-meta-0"}>Last Day</label>
                                                    <input name={"sch-monthly-meta"} id={"sch-monthly-meta-0"} value={0}
                                                           defaultChecked={false}
                                                           className={"form-check-input"} type={"checkbox"}/>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </fieldset>
                                <hr className={"my-5"}/>
                                <div className={"list-group-item"}>
                                    <Button type={"button"} class={"btn btn-primary mb-3"} text={"New Email"}
                                            onClick={() => {
                                                setNewEmails((prevState) => {
                                                    return [...prevState, {id: uuidv4(), email: ""}]
                                                });
                                            }}/>
                                    {newEmails.map((item) => {
                                        return (
                                            <div key={`new-email-item${item.id}`}>
                                                <i className={"fe fe-trash as-btn d-inline-block red-hover"}
                                                   onClick={() => {
                                                       setNewEmails((prevState) => {
                                                           return prevState.filter(i => i.id !== item.id);
                                                       });
                                                   }}></i>
                                                <div className={"form-floating d-inline-block w-75 mb-3 mx-3"}>
                                                    <input type={"text"} name={"email-input"}
                                                           className={"form-control"}
                                                           id={`new-email-${item.id}`}
                                                           onKeyUp={(e) => {
                                                               setNewEmails((prevState) => {
                                                                   return prevState.map((i) => {
                                                                       if (i.id === item.id) {
                                                                           return {
                                                                               id: i.id,
                                                                               email: e.target.value
                                                                           };
                                                                       }
                                                                       return i;
                                                                   });
                                                               })
                                                               formCheck("outputEmailForm", "outputEmailSubmit");
                                                           }} required={true}
                                                           pattern={"[a-z0-9._%+\\-]+@[a-z0-9.\\-]+\\.[a-z]{2,}$"}
                                                           defaultValue={item.email}/>
                                                    <label htmlFor={`new-email-${item.id}`}>Email
                                                        Address</label>
                                                </div>
                                            </div>);
                                    })}
                                </div>
                                <hr/>
                                <div className={"list-group-item"}>
                                    {curEmails.map((item) => {
                                        return (
                                            <div key={`stored-email-${item.id}`}>
                                                <i className={"fe fe-trash as-btn d-inline-block red-hover"}
                                                   onClick={() => {
                                                       setCurEmails((prevState) => {
                                                           return prevState.filter(i => i?.id !== item.id);
                                                       })
                                                   }}></i>
                                                <div className={"form-floating d-inline-block w-75 mb-3 mx-3"}>
                                                    <input type={"text"} name={"email-input"}
                                                           className={"form-control"}
                                                           id={`tmp-email-${item.id}`} required={true}
                                                           pattern={"[a-z0-9._%+\\-]+@[a-z0-9.\\-]+\\.[a-z]{2,}$"}
                                                           value={item.email} readOnly={true}/>
                                                    <label htmlFor={`tmp-email-${item.id}`}>Email
                                                        Address</label>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelEditBtn"}
                                            data-bs-dismiss={"modal"}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadEditSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"}
                                            onClick={() => formEditSubmit()} id={"editSubmit"}>Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <BulkDeleteModal
                deletePerform={() => deletePerform("bulkDeleteSubmit", "loadBulkDeleteSubmit", "bulkConfirmClose", ppRules.start, ppRules.end)}
                start={ppRules.start} end={ppRules.end} header={"Disable Reports"}/>
            <PerPageModal filterSubmit={() => filterSubmit()} tmpPerPage={tmpPerPage}
                          setTmpPerPage={(sudocode) => setTmpPerPage(sudocode)}
                          setPage={(sudocode) => setPage(sudocode)}/>
            <input type={"hidden"} id={"dContent"}/>
            <input type={"hidden"} id={"uContent"}/>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true}
                                autoClose={10000}/>
            </Suspense>
        </>
    ) : <Navigate to={"/"}/>;
};

export default ScheduledReports;