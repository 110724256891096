import Button from "../component-assets/Button";
import {useState} from "react";
import ApiRequester from "../ApiRequester";
import dklogo from "../../img/PCLC.svg";
import {Link} from 'react-router-dom';

const LoginForm = (props) => {

    const [formObj, setForm] = useState({
        username: "",
        password: "",
        errMsg: "",
        attempts: 0
    })

    function loginSubmit(){
        document.getElementById("loadSubmit").classList.remove("d-none");
        document.getElementById("subBtn").classList.add("d-none");
        ApiRequester({username: formObj.username, password: formObj.password,
                                setMsg: {setForm}, attempts: formObj.attempts, reqEndpoint: "login"});
    }

    return formObj.attempts < 5 ? (
        <div className={"row justify-content-center"}>
            <div className={"col-12 col-md-5 col-xl-4 my-5"}>
                <img src={dklogo} alt={"Palcare Logo Colored Varient"} className={"mb-3 w-100"} />
                <form>
                    <div className={"form-floating mb-4 text-secondary"} style={{textAlign:"left"}}>
                        <input id={"Username"} type={"text"} name={"Username"} className={"form-control"} tabIndex={"0"}
                               onChange={e => {
                                    const userEntered = e.target.value;
                                    setForm(prevState => {
                                       return {...prevState, username: userEntered}
                                    });
                        }}/>
                        <label htmlFor={"Username"}>Username</label>
                    </div>
                    <div className={"form-floating mb-4 text-secondary input-group"}>
                        <input type={"password"} name={"Password"} id={"Password"} className={"form-control"} style={{borderRight: "0px"}}
                               onBlur={() => {
                                   document.getElementById("passwordEye").style.backgroundColor = "#dcf3ff"
                                   document.getElementById("passwordEye").style.borderTopColor = "#7a7a7a"
                                   document.getElementById("passwordEye").style.borderRightColor = "#7a7a7a"
                                   document.getElementById("passwordEye").style.borderBottomColor = "#7a7a7a"
                               }}
                               onFocus={() => {
                                   document.getElementById("passwordEye").style.backgroundColor = "#ffffff"
                                   document.getElementById("passwordEye").style.borderTopColor = "#2C7BE5FF"
                                   document.getElementById("passwordEye").style.borderRightColor = "#2C7BE5FF"
                                   document.getElementById("passwordEye").style.borderBottomColor = "#2C7BE5FF"
                               }}
                               onChange={e => {
                            const passEntered = e.target.value;
                            setForm(prevState => {
                               return {...prevState, password: passEntered}
                            })
                        }} onKeyUp={e => {
                            if (e.key === "Enter"){
                                document.getElementById("subBtn").click();
                            }
                        }}/>
                        <label htmlFor={"Password"} style={{zIndex: 100}}>Password</label>
                        <span className={"fe fe-eye-off toggle-password input-group-text fs-2"} id={"passwordEye"}
                              style={{backgroundColor: "#dcf3ff", borderTop: "1px solid #7a7a7a",
                                        borderRight: "1px solid #7a7a7a", borderBottom: "1px solid #7a7a7a", borderLeft: "0px"}}
                              onClick={() => {
                                let eyeIcon, passfield;
                                eyeIcon = document.getElementById("passwordEye");
                                passfield = document.getElementById("Password");
                                if (eyeIcon.classList.contains("fe-eye-off")){
                                    eyeIcon.classList.remove("fe-eye-off");
                                    eyeIcon.classList.add("fe-eye");
                                    passfield.type = "text";
                                }else{
                                    eyeIcon.classList.add("fe-eye-off");
                                    eyeIcon.classList.remove("fe-eye");
                                    passfield.type = "password";
                                }
                        }}></span>
                    </div>
                    <Button type={"button"} class={"btn btn-lg w-100 btn-primary d-none mb-3"} disabled={true} spinner={true} text={""} id={"loadSubmit"}/>
                    <Button text={"Login"} type={"button"} class={"btn btn-lg w-100 btn-primary mb-3"} id={"subBtn"}
                            onClick={() => loginSubmit()}/>
                    <div className={"text-center"}>
                        <h5 style={{color: "#d11111"}}>{formObj.errMsg}</h5>
                    </div>
                    <Link to={"/recovery"}>Forgot Password?</Link>
                </form>
            </div>
        </div>
    ) : <div className={"row justify-content-center"}>
            <div className={"col-12 col-md-5 col-xl-4 my-5"}>
                <img src={dklogo} alt={"Palcare Logo Colored Varient"} className={"mb-3 w-100"} />
                <h3 style={{color: "#d11111"}}>Maximum login attempts exceeded. Contact your administrator</h3>
            </div>
            <Link to={"/recovery"}>Forgot Password?</Link>
        </div>
};

export default LoginForm;