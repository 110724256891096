import {Link, Navigate} from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import Header from "../Header";
import Button from "../component-assets/Button";
import ApiRequester from "../ApiRequester";
import {
    formCheck, formCheckWithElement, formClear, formFail, formSuccess, determineUseId, areaLearninBehaviorToggle,
    handleSubmitActions, hideModal, showModal, sortFunc, swapModal, toggleCollapse, iAmHigherAuthority
} from "../../libs";

const AreaProfile = (props) => {
    let dData, bData;

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const [areaId, setAreaId] = useState(props?.id);
    const [editable, setEditable] = useState(iAmHigherAuthority(80, dData.role_weight));
    const [count, setCount] = useState(0);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [pageLoaded, setPageLoaded] = useState(false);
    // Basic Information Variables
    const [basicData, setBasicData] = useState([]);
    const [areaName, setAreaName] = useState("");
    const [maxOccupancy, setMaxOccupancy] = useState("");
    const [residents, setResidents] = useState("");
    const [areaType, setAreaType] = useState("");
    const [areaTypeId, setAreaTypeId] = useState("");
    const [areaTypeData, setAreaTypeData] = useState([]);
    const [areaTypeOptions, setAreaTypeOptions] = useState([]);
    const [areaGroups, setAreaGroups] = useState([]);
    const [basicLoaded, setBasicLoaded] = useState(false);
    const [areaTypeLoaded, setAreaTypeLoaded] = useState(false);
    // Device Information Variables
    const [areaDeviceData, setAreaDeviceData] = useState([]);
    const [deviceData, setDeviceData] = useState([])
    const [devices, setDevices] = useState([])
    const [deviceToRemove, setDeviceToRemove] = useState("");
    const [assignedArea, setAssignedArea] = useState("");
    const [deviceCatId, setDeviceCatId] = useState(0);
    const [deviceCatData, setDeviceCatData] = useState([]);
    const [deviceCatOptions, setDeviceCatOptions] = useState([]);
    const [deviceTypeData, setDeviceTypeData] = useState([]);
    const [deviceTypeId, setDeviceTypeId] = useState([]);
    const [deviceTypeOptions, setDeviceTypeOptions] = useState([]);
    // Device Learn in Variables
    const [listening, setListening] = useState(false);
    const [listenIcon, setListenIcon] = useState(<i className={"fe fe-rss pointer-no fs-2"}></i>);
    const [listenCount, setListenCount] = useState(60);
    const [listenData, setListenData] = useState([]);
    const [listenList, setListenList] = useState([]);
    const [onLoad, setOnLoad] = useState(true);
    const [selectReq, setSelectReq] = useState(false);
    const [listenDevice, setListenDevice] = useState("");

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", payload: {area_id: areaId, campus_id: campusId},
                          procedure: "getareaprofile", reqType: "stored"}).then(data => {
                setBasicData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", payload: {area_id: areaId, campus_id: campusId},
                          procedure: "getareadevices", reqType: "stored"}).then(data => {
                setAreaDeviceData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", payload: {device_filter: "", campus_id: campusId},
                                procedure: "sp_dev_getInovonicsDevices", reqType: "stored"}).then(data => {
                setDeviceData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "att_area_type",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setAreaTypeData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "getareadevicecategories", reqType: "stored"}).then(data => {
                setDeviceCatData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "getinovonicsdevicetypes", reqType: "stored"}).then(data => {
                setDeviceTypeData(data);
            });
            setCount(() => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        if (![basicLoaded, areaTypeLoaded].includes(false)){
            setPageLoaded(true);
        }
    }, [basicLoaded, areaTypeLoaded]);

    useEffect(() => {
        let resItems = []
        if (basicData.length > 0){
            setAreaName(() => basicData[0]?.area_name ? basicData[0].area_name : "");
            setMaxOccupancy(() => basicData[0]?.max_occupancy ? basicData[0].max_occupancy : "");
            setAreaType(() => basicData[0]?.area_type_description ? basicData[0].area_type_description : "");
            setAreaTypeId(() => basicData[0]?.area_type_id ? basicData[0].area_type_id : "");
            setAreaGroups(() => basicData[0]?.groups ? basicData[0].groups : []);
            for (let i=0; i < basicData?.length; i++){
                if (i > 0){
                    resItems.push(<br key={`break${i}`}/>)
                    resItems.push(<Link key={`resident${i}`} to={`/profiles/resident/${basicData[i].resident_id}`}>{basicData[i].resident_name}</Link>)
                }else{
                    resItems.push(<Link key={`resident${i}`} to={`/profiles/resident/${basicData[i].resident_id}`}>{basicData[i].resident_name}</Link>)
                }
            }
            setResidents(() => resItems);
            setBasicLoaded(true);
        }
    }, [basicData]);

    useEffect(() => {
        let tmp = [];
        for (let i = 0; i < areaDeviceData?.length; i++){
            tmp.push(
                <li key={"device-name-"+i.toString()} className={"mt-2"}>
                    {iAmHigherAuthority(10, dData.role_weight) ?
                    <i className={"fe fe-x-square red-hover fs-1 me-3"} style={{verticalAlign: "sub"}} title={"Detach device"}
                       onClick={() => {
                           setDeviceToRemove(() => areaDeviceData[i]?.device_id);
                           showModal("deviceconfirmmodal")
                       }}/> : <></>}
                    <Link to={`/profiles/inovonics/${areaDeviceData[i].device_id}`}>{areaDeviceData[i].device_name} ({areaDeviceData[i].manufacture_uuid})</Link>
                </li>
            );
        }
        setDevices(() => tmp);
    }, [areaDeviceData]);

    useEffect(() => {
        if (deviceCatData.length > 0){
            let tmpOptions = []
            for (let i=0; i < deviceCatData.length; i++){
                tmpOptions.push(
                    <option key={"device-category-option-"+deviceCatData[i].device_category_id.toString()}
                            value={deviceCatData[i].device_category_id}>{deviceCatData[i].device_category_short_name}
                    </option>
                );
            }
            setDeviceCatOptions(() => tmpOptions);
        }
    }, [deviceCatData]);

    useEffect(() => {
        let i, tmp = [];
        deviceTypeData.sort((a, b) => sortFunc(a, b, "device_type_name", "desc"));
        for (i = 0; i < deviceTypeData.length; i++){
            if (deviceTypeData[i]?.device_category_id.toString() === deviceCatId.toString()){
                tmp.push(
                    <option key={"device-use-option-"+deviceTypeData[i]?.inovonics_type_id.toString()}
                                 value={deviceTypeData[i]?.inovonics_type_id}>{deviceTypeData[i]?.device_type_name}
                    </option>
                );
            }
        }
        setDeviceTypeOptions(() => tmp);
    }, [deviceTypeData, deviceCatId]);

    useEffect(() => {
        if (areaTypeData.length > 0){
            let tmpOptions = []
            for (let i=0; i < areaTypeData.length; i++){
                tmpOptions.push(
                    <option key={`area-type-option-${areaTypeData[i].area_type_id}`} value={areaTypeData[i].area_type_id}>{areaTypeData[i].area_type_description}</option>
                )
            }
            setAreaTypeOptions(() => tmpOptions);
            setAreaTypeLoaded(true);
        }
    }, [areaTypeData]);

    useEffect(() => {
        formCheck("pendantForm", "pendantSubmit");
    }, [listenDevice]);

    useEffect(() => {
        formCheck("pendantForm", "pendantSubmit");
    }, [selectReq]);

    useEffect(() => {
        let product = document.getElementById("device_name").value;
        if (!listening){
            // just toggled off & possible first run
            if (!onLoad){
                setListenIcon(
                    <i className={"fe fe-rss pointer-no fs-2"}></i>
                );
                toast.info("Listening stopped.");
                hideListenList();
            }else{
                setOnLoad(false);
            }
        }else{
            if (product !== undefined && product !== ""){
                setListenIcon(
                    <i className={"fe fe-rss icon-vibrant pointer-no fs-2"}></i>
                );
                publishListen(product);
                showListenList();
            }else{
                toast.warning("Listening toggled on, no product selected.");
            }
        }
    }, [listening]);

    useEffect(() => {
        let interval;
        if (listening){
            interval = setInterval(() => {
                setListenCount(listenCount  => listenCount  + 1);
            }, 1000);
            if (listenCount < 60){
                if (listenCount % 4 === 0){
                    let pType = document.getElementById("deviceProductType").value;
                    ApiRequester({reqEndpoint: "getitems", Table: "LearnIn", PKey: "ClientId",
                        PVal: dData.sessionId, CampusId: campusId, Search: parseInt(pType)}).then(data => {
                            setListenData(data);
                    });
                }
            }
            if (listenCount === 60){
                toast.info("Capture timeout, restart capture if device not in list.");
            }
        }
        return () => clearInterval(interval);
    }, [listenCount]);

    useEffect(() => {
        let tmpList = [];
        for (let i=0; i < listenData.length; i++){
            tmpList.push(
                <option key={"listen-"+listenData[i]?.trx_id} value={listenData[i]?.trx_id}>{listenData[i]?.name} ({listenData[i]?.trx_id})</option>
            )
        }
        setListenList(tmpList);
        if (listenDevice === "" && listenData.length > 0){
            setListenDevice(() => listenData[0]?.trx_id);
            toast.success("Devices found.");
        }
    }, [listenData])

    function basicActionHandling(){
        document.getElementById("name-static").classList.toggle("d-none");
        document.getElementById("name-edit").classList.toggle("d-none");
        document.getElementById("area-type-static").classList.toggle("d-none");
        document.getElementById("area-type-edit").classList.toggle("d-none");
        document.getElementById("occupancy-static").classList.toggle("d-none");
        document.getElementById("occupancy-edit").classList.toggle("d-none");
        if (document.getElementById("edit-basic-btn").innerText === "Edit"){
            document.getElementById("edit-basic-btn").innerText = "Cancel";
            document.getElementById("save-basic-btn").classList.toggle("d-none");
            document.getElementById("name-edit").value = areaName;
            document.getElementById("area-type-edit").value = areaTypeId;
            document.getElementById("occupancy-edit").value = maxOccupancy;
        }else{
            document.getElementById("edit-basic-btn").innerText = "Edit";
            document.getElementById("save-basic-btn").classList.toggle("d-none");
        }
        formCheck("basic-form", "save-basic-btn");
    }

    function basicSubmit(){
        let name, typeId, occupancy, payload;
        if (editable){
            handleSubmitActions("save-basic-btn", "load-basic-btn")
            name = document.getElementById("name-edit").value;
            typeId = document.getElementById("area-type-edit").value;
            occupancy = document.getElementById("occupancy-edit").value;
            payload = [{area_id: parseInt(areaId), area_name: name, area_type_id: typeId, max_occupancy: occupancy}];
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_areas", PKey: "area_id", ClientId: dData.sessionId,
                                Condition: "primary", urlType: "POST", Payload: payload, User: dData.user, CampusId: campusId}).then(data => {
                if (data && data[0]){
                    setCount(0);
                    formSuccess("Basic information saved.", "save-basic-btn", "load-basic-btn", "edit-basic-btn");
                }
                else{
                    formFail(`Error during save, error code: ${data && data?.length > 1 ? data[1] : ""}`, "save-basic-btn", "load-basic-btn");
                }
            });
        }else{
            toast.warn("You do not have permissions to make changes.");
        }
    }

    function removeDevice(deviceId){
        let payload
        if (iAmHigherAuthority(10, dData.role_weight)){
            handleSubmitActions("deviceConfirmSubmit", "loadDeviceConfirmSubmit");
            payload = [{device_id: deviceId, is_active: false, area_id: null}]
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_device", Payload: payload, urlType: "POST", User: dData.user,
                                PKey: "device_id", Condition: "primary", CampusId: campusId, ClientId: dData.sessionId}).then(data => {
                if (data && data[0] === true){
                    setCount(0);
                    formSuccess("Device has been removed", "deviceConfirmSubmit", "loadDeviceConfirmSubmit", "deviceCancelBtn");
                }
                else{
                    formFail("Failed to remove device", "deviceConfirmSubmit", "loadDeviceConfirmSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to make changes.");
        }
    }

    function deviceExists(serialNum){
        for (let i=0; i < deviceData?.length; i++){
            if (serialNum.toString() === deviceData[i]?.manufacture_uuid.toString()) {
                return deviceData[i];
            }
        }
        return false;
    }

    function setDeviceIds(devTypeId){
        let productCode, modelId, msgClass, pType, devName;
        productCode = document.getElementById("productCode");
        modelId = document.getElementById("deviceModelId");
        devName = document.getElementById("device_name");
        msgClass = document.getElementById("deviceMsgClass");
        pType = document.getElementById("deviceProductType");
        for (let i = 0; i < deviceTypeData?.length; i++){
            if (deviceTypeData[i]?.inovonics_type_id.toString() === devTypeId.toString()){
                productCode.value = deviceTypeData[i]?.model_name;
                modelId.value = deviceTypeData[i]?.inovonics_device_id;
                devName.value = deviceTypeData[i]?.device_type_name;
                msgClass.value = deviceTypeData[i]?.message_class;
                pType.value = deviceTypeData[i]?.product_type_id;
                checkListening(deviceTypeData[i]?.device_type_name);
                break;
            }
        }
    }

    function pendantSubmit(initial){
        let description, msg, payload, serialNum, useId, modelId, dRec, nid, devName;
        if (iAmHigherAuthority(10, dData.role_weight)){
            serialNum = listenDevice;
            if (!serialNum || serialNum === ""){
                serialNum = document.getElementById("serialNum").value;
                if (!serialNum || serialNum === ""){
                    serialNum = document.getElementById("serialNum2")?.value;
                }
            }
            if (serialNum && !isNaN(serialNum)){
                serialNum = parseInt(serialNum);
            }
            dRec = deviceExists(serialNum);
            if (initial){
                if (dRec && dRec?.is_active){
                    setAssignedArea(dRec?.area_name);
                    hideModal("pendantmodal");
                    showModal("pendantconfirmmodal");
                    return;
                }else{
                    handleSubmitActions("pendantSubmit", "loadPendantSubmit");
                }
            }else{
                handleSubmitActions("pendantConfirmSubmit", "loadPendantConfirmSubmit");
            }

            if (listening){
                setListening(false);
                setListenCount(60);
            }

            description = document.getElementById("description").value;
            devName = document.getElementById("device_name").value;
            modelId = document.getElementById("deviceModelId").value;

            useId = determineUseId(deviceCatId, deviceTypeId);

            if (dRec){
                // Edit device and add to area
                payload = [{device_id: dRec.device_id, campus_id: campusId, is_active: true, area_id: parseInt(areaId),
                            device_use_id: parseInt(useId), device_name: devName, manufacture_model_id: parseInt(modelId),
                            descriptions: description, is_area_device: true, is_resident: false, resident_id: 0}]
                if (["135", "131"].includes(useId.toString())){
                    if (["135"].includes(useId.toString())){
                        nid = document.getElementById("nid")?.value;
                        if (!nid){
                            nid = 0;
                        }
                    }else{
                        nid = -1;
                    }
                    payload[0]["network_id"] = parseInt(nid);
                    ApiRequester({reqEndpoint: "mytransaction", TransType: "system_device_edit", Payload: payload,
                                CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
                        if (data[0]) {
                            msg = `${devName} has been added to ${areaName}`;
                            if (!initial){
                                handleSubmitActions("loadPendantConfirmSubmit", "pendantConfirmSubmit");
                                handleSubmitActions("loadPendantSubmit", "pendantSubmit");
                                swapModal("pendantConfirmClose", "editmodal");
                                document.getElementById("pendantForm").reset();
                                toast.success(msg);
                                setCount(0);
                            }else{
                                formSuccess(msg, "pendantSubmit", "loadPendantSubmit",
                                "cancelPendantBtn", "pendantForm");
                                setCount(0);
                            }
                        } else {
                            toast.error(`Failed to add ${devName}`);
                        }
                    });
                }else{
                    ApiRequester({reqEndpoint: "mysave", Tbl: "core_device", PKey: "device_id", ClientId: dData.sessionId,
                                Condition: "primary", urlType: "POST", Payload: payload, User: dData.user, CampusId: campusId}).then(data => {
                        if (data && data[0] === true){
                            msg = `${devName} has been added to ${areaName}`;
                            if (!initial){
                                handleSubmitActions("loadPendantConfirmSubmit", "pendantConfirmSubmit");
                                hideModal("pendantconfirmmodal");
                                document.getElementById("pendantForm").reset();
                                toast.success(msg);
                                setCount(0);
                            }else{
                                formSuccess(msg, "pendantSubmit", "loadPendantSubmit", "cancelPendantBtn", "pendantForm");
                                setCount(0);
                            }
                        }
                        else{
                            toast.error(`Failed to add ${devName}`);
                        }
                    });
                }
            }else{
                // Add device and add to area
                payload = [{campus_id: campusId, manufacture_id: 1, manufacture_uuid: serialNum.toString(), device_name: devName,
                            device_use_id: parseInt(useId), is_active: true, is_alerting_now: false, is_server: false,
                            is_area_device: true, is_resident: false, area_id: parseInt(areaId), manufacture_model_id: parseInt(modelId),
                            escalation_enabled: true, descriptions: description}];
                if (["135", "131"].includes(useId.toString())){
                    if (["135"].includes(useId.toString())){
                        nid = document.getElementById("nid")?.value;
                        if (!nid){
                            nid = 0;
                        }
                    }else{
                        nid = -1;
                    }
                    payload[0]["network_id"] = parseInt(nid);
                    ApiRequester({reqEndpoint: "mytransaction", TransType: "system_device_add", Payload: payload,
                                CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
                        if (data[0]) {
                            formSuccess(`${devName} has been added to ${areaName}`, "pendantSubmit", "loadPendantSubmit",
                                "cancelPendantBtn", "pendantForm");
                            setCount(0);
                        } else {
                            toast.error(`Failed to add ${devName}`);
                        }
                    });
                }else{
                    ApiRequester({reqEndpoint: "mysave", Tbl: "core_device", Payload: payload, urlType: "PUT",
                                    CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
                        if (data && data[0] === true){
                            formSuccess(`${devName} has been added to ${areaName}`, "pendantSubmit", "loadPendantSubmit",
                                 "cancelPendantBtn", "pendantForm");
                            setCount(0);
                        }
                        else{
                            toast.error(`Failed to add ${devName}`);
                        }
                    });
                }
            }
        }else{
            toast.warn("You do not have permissions to make changes.");
        }
    }

    function checkListening(device){
        if (listening && device){
            // Toggle already on, and just set device
            setListenIcon(
                <i className={"fe fe-rss icon-vibrant pointer-no fs-2"}></i>
            );
            showListenList();
            publishListen(device);
        }else{
            if (!device && listening){
                // Listening but just cleared the device
                setListenIcon(
                    <i className={"fe fe-rss pointer-no fs-2"}></i>
                );
                setListening(false);
            }else{
                // Not listening
            }
        }
    }

    function showListenList(){
        setSelectReq(true);
        setListenDevice(() => "");
        setListenList(() => []);
        document.getElementById("serialNum").required = false;
        document.getElementById("serialToggle").classList.add("d-none");
        document.getElementById("serial2Toggle").classList.remove("d-none");
        document.getElementById("serialNum").value = "";
    }

    function hideListenList(){
        setSelectReq(false);
        setListenDevice(() => "");
        setListenList(() => []);
        document.getElementById("serialNum").required = true;
        document.getElementById("serialToggle").classList.remove("d-none");
        document.getElementById("serial2Toggle").classList.add("d-none");
    }


    function publishListen(device){
        let msgClass, pType;
        msgClass = document.getElementById("deviceMsgClass").value;
        pType = document.getElementById("deviceProductType").value;
        ApiRequester({reqEndpoint: "mqtt", Operation: "learnin", ClientId: dData.sessionId, ProductType: pType,
                            CampusId: dData?.acnt, MsgClass: msgClass}).then(data => {

            if (data[0]){
                toast.info("Listening for "+device);
                setListenCount(0);
            }
            else{
                toast.error("Error with auto listening, try again, " +
                    "if persist contact your customer success for assistance.");
                setListening(false);
            }
        });
    }


    const headerContent = (
        <div className={"d-flex justify-content-between"}>
        </div>
    );

    return (dData?.slauth === true && dData?.tKey != null && dData?.tKey !== "" && dData?.acnt != null && dData?.access?.Community?.Areas?.Read) ? (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Area Profile"} Title={areaName} content={headerContent}/>
                <div className={"container-fluid mt-4"}>
                    <div className={"card"} style={{minWidth: "550px"}}>
                        <div className={"card-header d-flex justify-content-between"}>
                            <div>
                                <h3 className={"card-header-title d-inline-block"}>Basic Information</h3>
                            </div>
                            <div>
                                <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"basic-card-btn"}
                                   onClick={() => toggleCollapse("basic-card-btn", "basic-card")}></i>
                            </div>
                        </div>
                        <div className={"card-body collapse collapse-content show"} id={"basic-card"}>
                            <form className={"d-inline align-top form-control-plaintext was-validated"} id={"basic-form"}>
                                <div className={"mx-5 mt-2 align-top d-inline-block"} id={"basic-col1-div"}>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "75px"}}>Name:</p>
                                        <p className={"ms-4"} id={"name-static"}>{areaName}</p>
                                        <input className={"ms-4 d-none form-control form-control-sm"} required={true}
                                               style={{height: "30px", width: "125px"}} id={"name-edit"}
                                               defaultValue={areaName} pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                    </div>
                                    <div className={"mt-2 d-flex"}>
                                        <p style={{minWidth: "75px"}}>Type:</p>
                                        <p className={"ms-4"} id={"area-type-static"}>{areaType}</p>
                                        <select className={"ms-4 d-none form-select form-select-sm"}
                                                onChange={() => formCheck("basic-form", "save-basic-btn")}
                                                style={{height: "30px", width: "256px"}} id={"area-type-edit"}
                                                defaultValue={areaTypeId}>
                                            {areaTypeOptions}
                                        </select>
                                    </div>
                                    <div className={"mt-2 d-flex"}>
                                        <p style={{minWidth: "75px"}}>Capacity:</p>
                                        <p className={"ms-4"} id={"occupancy-static"}>{maxOccupancy}</p>
                                        <input className={"ms-4 d-none form-control form-control-sm"} type={"number"}
                                               style={{height: "30px", width: "256px"}} id={"occupancy-edit"}
                                               defaultValue={maxOccupancy} step={1} max={100} min={0}
                                               onChange={() => formCheck("basic-form", "save-basic-btn")}/>
                                    </div>
                                    <div className={"mt-2 d-flex"}>
                                        <ul className={"ps-0 d-inline-block align-top mb-0"} style={{listStyle: "none"}}>
                                            <li><p style={{minWidth: "75px"}} className={"me-4"}>Residents:</p></li>
                                        </ul>
                                        <ul className={"ps-0 d-inline-block mb-0"} style={{listStyle: "none"}}>
                                            <li>{residents}</li>
                                        </ul>
                                    </div>
                                    <div className={"mt-2 d-flex"}>
                                        <ul className={"ps-0 d-inline-block align-top mb-0"} style={{listStyle: "none"}}>
                                            <li><p style={{minWidth: "75px"}} className={"me-4"}>Groups:</p></li>
                                        </ul>
                                        <ul className={"ps-0 d-inline-block mb-0"} style={{listStyle: "none"}}>
                                            {areaGroups.map((group) => {
                                                return <li>{group?.area_group_name}</li>
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </form>
                            {pageLoaded && <Button text={"Edit"} class={"btn btn-primary float-end"} id={"edit-basic-btn"}
                                    disabled={!editable} onClick={() => {
                                if (editable) {
                                    basicActionHandling();
                                }
                            }}/>}
                            <Button text={"Save"} class={"btn btn-primary float-end me-3 d-none"} id={"save-basic-btn"}
                                    disabled={!editable} onClick={() => {
                                if (editable) {
                                    basicSubmit();
                                }
                            }} />
                            <Button type={"button"} class={"btn btn-primary float-end mx-3 d-none"} disabled={true} spinner={true} text={""} id={"load-basic-btn"} />
                        </div>
                    </div>
                    <div className={"card"} style={{minWidth: "500px"}}>
                        <div className={"card-header d-flex justify-content-between"}>
                            <div>
                                <h3 className={"card-header-title d-inline-block"} style={{minWidth: "80px"}}>Devices</h3>
                                <div className={"d-none"} style={{display: "inline-block"}} id={"devices-header-data"}>
                                    <h3 className={"ms-6 my-0 d-inline-block text-success"}>{devices.length} {devices?.length === 1 ? "Device" : "Devices"}</h3>
                                </div>
                            </div>
                            <div>
                                <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"devices-card-btn"}
                                   onClick={() => {
                                       toggleCollapse("devices-card-btn", "devices-card");
                                       document.getElementById("devices-header-data").classList.toggle("d-none");
                                   }}></i>
                            </div>
                        </div>
                        <div className={"card-body collapse collapse-content show"} id={"devices-card"}>
                            <ul className={"d-inline-block"} style={{listStyle: "none"}}>
                                {devices}
                            </ul>
                            <Button text={"Attach"} class={"btn btn-primary float-end me-3"} id={"device-modal-btn"}
                                    disabled={!iAmHigherAuthority(10, dData.role_weight)}
                                onClick={() => {
                                    if (iAmHigherAuthority(10, dData.role_weight)){
                                        document.getElementById("behavior-heading-container").classList.add("d-none");
                                        formCheck("pendantForm", "pendantSubmit");
                                        showModal("pendantmodal");
                                    }
                                }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"pendantmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticDeviceBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticDeviceBackdropLabel"}>Add Device</h2>
                            <button type={"button"} className={"btn-close"} id={"pendantClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"pendantForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"d-flex mb-4"}>
                                    <label className={"form-check-label me-4 mt-1"} htmlFor={"item1"} id={"label1"}>Auto
                                        Register</label>
                                    <div className={"form-check form-switch mt-1"}>
                                        <input className={"form-check-input"} id={"item1"} type={"checkbox"}
                                               checked={listening}
                                               onChange={() => {
                                                   if (["10"].includes(document.getElementById("device_type_id")?.value)) {
                                                       toast.warn("Network coordinator can't use listening feature.");
                                                       setListening(false);
                                                   } else {
                                                       setListening(!listening);
                                                   }
                                               }}/>
                                    </div>
                                    {listenIcon}
                                </div>
                                <div className={"form-floating mb-3 d-inline-block w-75 me-2"}>
                                    <select className={"form-select"} value={undefined}
                                            required={true} id={"device_category"}
                                            onChange={(e) => {
                                                document.getElementById("behavior-heading-container")?.classList?.add("d-none");
                                                setDeviceCatId(e.target.value);
                                                setDeviceTypeId(0);
                                                formCheck("pendantForm", "pendantSubmit");
                                            }}>
                                        <option value={""}></option>
                                        {deviceCatOptions}
                                    </select>
                                    <label htmlFor={"device_category"}>Device Category</label>
                                </div>
                                <div className={"d-flex justify-content-around"}>
                                    <div className={"form-floating mb-3 d-inline-block w-75 me-2"}>
                                        <select className={"form-select"} value={undefined}
                                                required={true} id={"device_type_id"}
                                                onChange={(e) => {
                                                    setDeviceTypeId(e.target.value);
                                                    areaLearninBehaviorToggle(e.target.value);
                                                    if (["10"].includes(e.target.value.toString())) {
                                                        if (listening) {
                                                            toast.warn("Network coordinator can't use listening feature, stopping listener.");
                                                            setListening(false);
                                                        } else {
                                                            formCheck("pendantForm", "pendantSubmit");
                                                            setDeviceIds(e.target.value);
                                                        }
                                                    } else {
                                                        formCheck("pendantForm", "pendantSubmit");
                                                        setDeviceIds(e.target.value);
                                                    }
                                                }}>
                                            <option value={""}></option>
                                            {deviceTypeOptions}
                                        </select>
                                        <label htmlFor={"device_type_id"}>Device Type</label>
                                    </div>
                                    <div className={"form-floating mb-3 d-inline-block"} style={{width: "23%"}}>
                                        <input type={"text"} className={"form-control"} id={"productCode"}
                                               readOnly={true} required={true}/>
                                        <label htmlFor={"productCode"}>Product Code</label>
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"description"}
                                           pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                    <label htmlFor={"description"}>Description (optional)</label>
                                </div>
                                <input type={"hidden"} id={"deviceModelId"}/>
                                <input type={"hidden"} id={"device_name"}/>
                                <input type={"hidden"} id={"deviceMsgClass"}/>
                                <input type={"hidden"} id={"deviceProductType"}/>
                                <div className={"form-floating mb-3"} id={"serialToggle"}>
                                    <input type={"text"} className={"form-control"} id={"serialNum"}
                                           onKeyUp={(e) => formCheckWithElement("pendantForm", "pendantSubmit", e, areaDeviceData)}
                                           required={true} pattern={"^[0-9]+$"}/>
                                    <label htmlFor={"serialNum"}>Serial Number</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter the serial number of device
                                    </div>
                                </div>
                                <div className={"form-floating mb-3 d-none"} id={"serial2Toggle"}>
                                    <select className={"form-select"} id={"serialNum2"} required={selectReq}
                                            value={listenDevice}
                                            onChange={(e) => {
                                                setListenDevice(e.target.value);
                                            }}>
                                        {listenList}
                                    </select>
                                    <label htmlFor={"serialNum2"}>Serial Numbers</label>
                                </div>
                                <div id={"ncsettings"} className={"d-none"}>
                                    <div className={"form-floating mb-3"}>
                                        <input type={"number"} className={"form-control"} id={"nid"}
                                               required={false} min={0} max={31} step={1} onChange={() => {
                                            formCheck("pendantForm", "pendantSubmit");
                                        }}/>
                                        <label htmlFor={"serialNum"}>Network ID</label>
                                        <div className={"invalid-feedback"}>
                                            Please enter a valid network id for coordinator. Valid 0 - 31
                                        </div>
                                    </div>
                                </div>
                                <div className={"list-group-item d-none"} id={"behavior-heading-container"}>
                                    <h4 className={"mb-4 text-center"}>Alarm Behavior</h4>
                                    <div className={"form-check form-switch mt-1"} id={"alarmProppedClear-switch"}>
                                        <input className={"form-check-input"} id={"alarmProppedClear"} type={"radio"}
                                               name={"alarmBehavior"} defaultChecked={false}/>
                                        <label className={"form-check-label me-4 mt-1 text-dark"}
                                               htmlFor={"alarmProppedClear"} id={"alarmProppedClearlabel"}>
                                            Alarm on Propped Open, clears when contact closed and
                                            reset is pressed.
                                        </label>
                                    </div>
                                    <div className={"form-check form-switch mt-1"} id={"alarmPropped-switch"}>
                                        <input className={"form-check-input"} id={"alarmPropped"} type={"radio"}
                                               name={"alarmBehavior"} defaultChecked={false}/>
                                        <label className={"form-check-label me-4 mt-1 text-dark"}
                                               htmlFor={"alarmPropped"} id={"alarmProppedlabel"}>
                                            Alarm on Propped Open, clears when contact closed.
                                        </label>
                                    </div>
                                    <div className={"form-check form-switch mt-1"} id={"onreset-switch"}>
                                        <input className={"form-check-input"} id={"onreset"} type={"radio"}
                                               name={"alarmBehavior"} defaultChecked={false}/>
                                        <label className={"form-check-label me-4 mt-1 text-dark"}
                                               htmlFor={"onreset"} id={"onresetLabel"}>
                                            Alarm clears when alarm condition is no longer true and reset switch
                                            pressed.
                                        </label>
                                    </div>
                                    <div className={"form-check form-switch mt-1"} id={"onclear-switch"}>
                                        <input className={"form-check-input"} id={"withouttimeout"} type={"radio"}
                                               name={"alarmBehavior"} defaultChecked={false}/>
                                        <label className={"form-check-label me-4 mt-1 text-dark"}
                                               htmlFor={"withouttimeout"} id={"withouttimeoutLabel"}>
                                            Alarm clears when alarm condition is no longer true.
                                        </label>
                                    </div>
                                    <div className={"form-check form-switch mt-1"}>
                                        <input className={"form-check-input"} id={"timeout"} type={"radio"}
                                               name={"alarmBehavior"} defaultChecked={false}/>
                                        <label className={"form-check-label me-4 mt-1 text-dark"}
                                               htmlFor={"timeout"} id={"timeoutLabel"}>
                                            Alarms time out automatically.
                                        </label>
                                    </div>
                                    <div className={"form-check form-switch mt-1"}>
                                        <input className={"form-check-input"} id={"realarm"} type={"radio"}
                                               name={"alarmBehavior"} defaultChecked={false}/>
                                        <label className={"form-check-label me-4 mt-1 text-dark"}
                                               htmlFor={"realarm"} id={"realarmLabel"}>
                                            Alarms time out automatically, re-create alarms on consecutive events.
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelPendantBtn"}
                                            data-bs-dismiss={"modal"}
                                            onClick={() => {
                                                formClear("pendantForm", "pendantSubmit")
                                            }}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadPendantSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"pendantSubmit"}
                                            onClick={() => pendantSubmit(true)}>Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"pendantconfirmmodal"} data-bs-backdrop={"static"}
                 data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticPendantConfirmLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticPendantConfirmLabel"}>Confirm Add</h2>
                            <button type={"button"} className={"btn-close"} id={"pendantConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <div className={"modal-body"}>
                            <p>Device is already linked to {assignedArea} Do you wish to re-assign this device?</p>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                    id={"pendantConfirmCancelBtn"}
                                    onClick={() => swapModal("pendantConfirmClose", "pendantmodal")}>No
                            </button>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true}
                                    text={"Loading"} id={"loadPendantConfirmSubmit"}/>
                            <button type={"button"} className={"btn btn-primary ms-3"} id={"pendantConfirmSubmit"}
                                    onClick={() => pendantSubmit(false)}>YES
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"deviceconfirmmodal"} data-bs-backdrop={"static"}
                 data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticDeviceConfirmLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                    <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticDeviceConfirmLabel"}>Confirm Add</h2>
                            <button type={"button"} className={"btn-close"} id={"deviceConfirmClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <div className={"modal-body"}>
                            <p>Are you sure you wish to permanently detach this device from {areaName}?</p>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}} id={"deviceCancelBtn"} data-bs-dismiss={"modal"}>No</button>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadDeviceConfirmSubmit"}/>
                            <button type={"button"} className={"btn btn-primary ms-3"} id={"deviceConfirmSubmit"} onClick={() => removeDevice(deviceToRemove)}>YES</button>
                        </div>
                    </div>
                </div>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </>
    ) : <Navigate to={"/"}/>;
};

export default AreaProfile;