import { Navigate } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import { Suspense } from "react";
import {AES, enc} from "crypto-js";
import Header from "./Header";
import {useEffect, useState} from "react";
import ApiRequester from "./ApiRequester";
import Button from "./component-assets/Button";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {Modal} from 'bootstrap';
import {CSVLink} from "react-csv";
import {getUTCTimestamp, getAccessSettings, paginateData, compareRole, showModal, sortFunc, boolSort,
        formFail, handleSuccess, handleSubmitActions, actionAppearance, deleteModalSingle, swapModal,
        bulkDeleteModal, checkUserAll} from "../libs"
import {Pagination} from "react-bootstrap";
import PaginationItems from "./component-assets/PaginationItems";
import Footer from "./Footer";
import TableHeaders from "./component-assets/TableHeaders";
import TableBody from "./component-assets/TableBody";
import BulkDeleteModal from "./component-assets/BulkDeleteModal";
import SingleDeleteModal from "./component-assets/SingleDeleteModal";
import PerPageModal from "./component-assets/PerPageModal";
import CampusDropDown from "./component-assets/CampusDropDown";

const LegacyUserManagement = (props) => {

    // * * * * * * * * * * * * * * * * *
    // Establish All Page Elements Below
    // * * * * * * * * * * * * * * * * *
    let dData, bData, selectOptions, addButton, searchButton, deleteButton, groupsButton,
        soloDelButton, passButton, headerData, csvHeaders;

    headerData = [
        {label: "Username", key: "UserName", align: "left"},
        {label: "Display Name", key: "DisplayName", align: "left"},
        {label: "Email", key: "Email", align: "left"},
        {label: "Role", key: "UserRole", align: "left"},
        {label: "Device", key: "DeviceName", align: "left"},
        {label: "Status", key: "LoggedIn", type: "status", true: "Active", false: "Offline", align: "center"},
        {label: "Last Login", key: "FormattedLoginTime", align: "left"}
    ]
    csvHeaders = [
        {label: "Username", key: "UserName"},
        {label: "Display Name", key: "DisplayName"},
        {label: "Email", key: "Email"},
        {label: "User Role", key: "UserRole"},
        {label: "Device", key: "DeviceName"},
        {label: "Last Login", key: "LastLoginTime"},
        {label: "Group Ids", key: "GroupIds"}
    ]

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const [dataOrigin, setDataOrigin] = useState([]);
    const [activeData, setActiveData] = useState([]);
    const [groupData, setGroupData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [count, setCount] = useState(0);
    const [storePage, setStorePage] = useState(1);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(dData.perPage);
    const [tmpPerPage, setTmpPerPage] = useState(dData.perPage);
    const [dContent, setDContent] = useState([]);
    const [search, setSearch] = useState("");
    const [sorter, setSorter] = useState({
        sortColumn: "UserName",
        sortOrder: "desc"
    });
    const [ppRules, setPRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    });
    const [csvLink, setLink] = useState({
        filename: "UserManagement.csv",
        headers: [],
        data: []
    });
    const [csvButton, setCSVButton] = useState(
        <CSVLink {...csvLink} uFEFF={false}>
            <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary ms-2 px-4 mt-1"}/>
        </CSVLink>)
    const [groupList, setGroupList] = useState([]);
    const [groupListE, setGroupListE] = useState([]);
    const [groupListG, setGroupListG] = useState([]);
    const [campusId, setCampusId] = useState(dData.acnt);

    if (dData?.access?.Users?.UserManagement?.Write){
        addButton = (<Button text={"New User"} type={"button"} class={"btn btn-primary ms-2 mt-1"}
                             icon={true} iconClass={"fe fe-plus me-2"} onClick={() => showModal("addmodal")} />);
        deleteButton = (<Button text={"Delete"} type={"button"} class={"btn btn-danger ms-2 hide mt-1"} id={"deleteBtn"}
                            icon={true} iconClass={"fe fe-trash-2 me-2"} onClick={() => bulkDeleteModal(ppRules.start, ppRules.end, activeData, "DisplayName", ["UserId", ""], "UserName", setDContent)} />);
        groupsButton = (<Button text={"Groups"} type={"button"} class={"btn btn-primary ms-2 hide mt-1"} id={"groupsBtn"}
                                icon={true} iconClass={"fe fe-edit me-2"} onClick={() => showModal("groupmodal")} />);
        soloDelButton = (<Button text={"Delete"} type={"button"} class={"btn btn-danger me-3"} id={"deleteSingleBtn"}
                                icon={true} iconClass={"fe fe-trash-2 me-2"} />);
        passButton = (<Button text={"Password Reset"} type={"button"} class={"btn btn-primary ms-3"} id={"passBtn"}
                                icon={true} iconClass={"fe fe-lock me-2"} onClick={() => swapModal("cancelEditBtn", "passmodal")} />);
    }else{
        addButton = (<Button text={"New User"} type={"button"} class={"btn btn-primary d-none"}
                             icon={true} iconClass={"fe fe-plus me-2"} />);
        deleteButton = (<Button text={"Delete"} type={"button"} class={"btn btn-danger me-2 hide d-none"} id={"deleteBtn"}
                            icon={true} iconClass={"fe fe-trash-2 me-2"} />);
        groupsButton = (<Button text={"Groups"} type={"button"} class={"btn btn-primary me-2 hide d-none"} id={"groupsBtn"}
                                icon={true} iconClass={"fe fe-edit me-2"} />);
        soloDelButton = (<Button text={"Delete"} type={"button"} class={"btn btn-danger me-3 d-none"} id={"deleteSingleBtn"}
                                icon={true} iconClass={"fe fe-trash-2 me-2"} />);
        passButton = (<Button text={"Password Reset"} type={"button"} class={"btn btn-primary ms-3 d-none"} id={"passBtn"}
                                icon={true} iconClass={"fe fe-lock me-2"} />);
    }

    searchButton = (<Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left mt-1"} style={{height: "42px"}}
                            icon={true} iconClass={"fe fe-search"} />);

    if (dData?.userrole === "palcare-admin"){
        selectOptions = [<option value={"caregiver"} key={"caregiver-Option"}>Caregiver</option>,
                        <option value={"admin"} key={"admin-Option"}>Administrator</option>,
                        <option value={"corporate"} key={"corporate-Option"}>Corporate</option>,
                        <option value={"corporate-admin"} key={"corporate-admin-Option"}>Corporate Admin</option>,
                        <option value={"palcare"} key={"palcare-Option"}>Palcare</option>,
                        <option value={"palcare-admin"} key={"palcare-admin-Option"}>Palcare Admin</option>];
    }
    else if (dData?.userrole === "palcare"){
        selectOptions = [<option value={"caregiver"} key={"caregiver-Option"}>Caregiver</option>,
                        <option value={"admin"} key={"admin-Option"}>Administrator</option>,
                        <option value={"corporate"} key={"corporate-Option"}>Corporate</option>,
                        <option value={"corporate-admin"} key={"corporate-admin-Option"}>Corporate Admin</option>];
    }
    else if (dData?.userrole === "corporate-admin"){
        selectOptions = [<option value={"caregiver"} key={"caregiver-Option"}>Caregiver</option>,
                        <option value={"admin"} key={"admin-Option"}>Administrator</option>,
                        <option value={"corporate"} key={"corporate-Option"}>Corporate</option>];
    }
    else if (dData?.userrole === "corporate"){
        selectOptions = [<option value={"caregiver"} key={"caregiver-Option"}>Caregiver</option>,
                        <option value={"admin"} key={"admin-Option"}>Administrator</option>];
    }
    else{
        selectOptions = [<option value={"caregiver"} key={"caregiver-Option"}>Caregiver</option>];
    }

    useEffect(() => {
        let interval;
        interval = setInterval(() => {
             setCount((count) => {return count - 1});
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "getitems", Table: "User", PKey: "CampusId", PVal: campusId, SecondIndex: "CampusId-index", CampusId: campusId,
                        Projection: "UserId,DeviceName,DisplayName,Email,LastLoginTime,LoggedIn,UserName,UserRole,GroupIds"}).then(data => {
                setDataOrigin(data);
            });
            ApiRequester({reqEndpoint: "getitems", Table: "Group", PKey: "CampusId", PVal: campusId,
                        Projection: "GroupId,GroupName,CapCode", CampusId: campusId}).then(data => setGroupData(data));
            setCount(count => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        let pRules = paginateData(activeData, page, perPage);
        setPRules(pRules);
        if (activeData.length > 0) {
            if (sorter.sortColumn === "LoggedIn"){
                activeData.sort((a, b) => boolSort(a, b, sorter.sortColumn, sorter.sortOrder));
            }else{
                activeData.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
            }
        }
        setTableRows(<TableBody start={pRules.start} end={pRules.end} tableData={activeData} checkbox={true}
                                writeAccess={dData?.access?.Users?.UserManagement?.Write} unk={"a-"}
                                actionAppearance={() => actionAppearance(pRules.start, pRules.end, ["deleteBtn", "groupsBtn"])}
                                tableHeaders={headerData} editModal={editModal} parentComponent={"UserManagement"} dData={dData} />)
    }, [activeData, sorter, page, perPage, dataOrigin]);

    useEffect(() => {
        setLink(prevState => {
            return {...prevState,
                headers: csvHeaders,
                data: dataOrigin}
        });
    }, [dataOrigin])

    useEffect(() => {
        setCSVButton(
            <CSVLink {...csvLink} uFEFF={false}>
                <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary ms-2 px-4 mt-1"}/>
            </CSVLink>
        )
    }, [csvLink])

    useEffect(() => {
        let index2, tmp3, tmp3E, tmp3G;
        index2 = 0;
        tmp3 = [];
        tmp3E = [];
        tmp3G = [];
        if (groupData.length > 0){
            groupData.sort((a, b) => sortFunc(a, b, sorter.sortColumn, "desc"));
            for (index2; index2 < groupData.length; index2++){
                tmp3.push(
                    <label className={"list-group-item"} key={"listItem"+index2}>
                        <input id={"box"+index2} name={"groups"} className={"form-check-input mx-3"}
                               type={"checkbox"} value={groupData[index2]?.GroupId} />
                        {groupData[index2]?.GroupName} {groupData[index2]?.CapCode}
                    </label>
                );
                tmp3E.push(<label className={"list-group-item"} key={"listItem"+index2}>
                        <input id={"box"+index2} name={"groupsE"} className={"form-check-input mx-3"}
                               type={"checkbox"} value={groupData[index2]?.GroupId} />
                        {groupData[index2]?.GroupName} {groupData[index2]?.CapCode}
                    </label>);
                tmp3G.push(<label className={"list-group-item"} key={"listItem"+index2}>
                        <input id={"box"+index2} name={"groupsG"} className={"form-check-input mx-3"}
                               type={"checkbox"} value={groupData[index2]?.GroupId} />
                        {groupData[index2]?.GroupName} {groupData[index2]?.CapCode}
                    </label>);
            }
            setGroupList(tmp3);
            setGroupListE(tmp3E);
            setGroupListG(tmp3G);
        }
    }, [groupData]);

    function editModal(userIndex){
        let display, email, role, groups, dataIndex, user, i, modal, delBtn, username, passBtn, submitBtn;
        dataIndex = parseInt(userIndex);
        username = document.getElementById("usernameE");
        display = document.getElementById("displayE");
        email = document.getElementById("emailE");
        role = document.getElementById("userroleE");
        user = document.getElementById("userIdE");
        groups = document.getElementsByName("groupsE");
        delBtn = document.getElementById("deleteSingleBtn");
        passBtn = document.getElementById("passBtn");
        submitBtn = document.getElementById("editSubmit");
        email.required = ["palcare-admin", "palcare", "corporate", "corporate-admin", "admin"].includes(activeData[dataIndex]?.UserRole);
        display.value = activeData[dataIndex]?.DisplayName;
        email.value = activeData[dataIndex]?.Email;
        role.value = activeData[dataIndex]?.UserRole;
        user.value = activeData[dataIndex]?.UserId;
        username.value = activeData[dataIndex]?.UserName;
        for (i=0; i<groups.length; i++){
            if (activeData[dataIndex]?.GroupIds.includes(groups[i].value)){
                groups[i].checked = true;
            }
            else{
                groups[i].checked = false;
            }
        }
        delBtn.onclick = () => {
            setDContent([[user.value, ""]])
            deleteModalSingle(display.value, username.value);
        };
        if (compareRole(role.value, dData) || dData?.user === username.value){
            delBtn.classList.add("d-none");
            passBtn.classList.add("d-none");
            submitBtn.classList.add("d-none");
            display.readOnly = true;
            email.readOnly = true;
            role.disabled = true;
            for (i=0; i < groups.length; i++){
                groups[i].disabled = true;
            }
        }else{
            delBtn.classList.remove("d-none");
            passBtn.classList.remove("d-none");
            submitBtn.classList.remove("d-none");
            display.readOnly = false;
            email.readOnly = false;
            role.disabled = false;
            for (i=0; i < groups.length; i++){
                groups[i].disabled = false;
            }
        }
        formCheck("editForm", "editSubmit");
        modal = new Modal(document.getElementById("editmodal"), {});
        modal.show();
    }

    function formSuccess(msg, formId, buttonId, loadBtnId, cancelBtnId){
        handleSuccess(msg, buttonId, loadBtnId, cancelBtnId)
        if (formId){
            formClear(formId, buttonId);
        }
        setCount(0);
    }

    function formClear(formId, buttonId, addFormField, formAddSubmit, formEditSubmit){
        document.getElementById(formId).reset();
        formCheck(formId, buttonId, addFormField, formAddSubmit, formEditSubmit);
    }

    function formCheck(formId, buttonId){
        // Checks form for validation, toggles save button based on validation being met.
        let form = document.getElementById(formId).querySelectorAll("input");
        let formArray, selectItem;
        let btn = document.getElementById(buttonId);
        if (formId === "passForm"){
            let pass1 = document.getElementById("passwordR");
            document.getElementById("passwordCR").pattern = pass1.value;
        }
        if (formId === "addForm"){
            selectItem = document.getElementById("userrole");
        }
        else{
            selectItem = document.getElementById("userroleE");
        }
        formArray = Array.from(form);
        if (formArray.every((formItem) => {return formItem.checkValidity()}) && selectItem.checkValidity()){
            btn.disabled = false;
            if (formId === "addForm"){
                btn.onclick = formAddSubmit;
            }
            else if (formId === "editForm"){
                btn.onclick = formEditSubmit;
            }
            btn.classList.remove("btn-danger");
            btn.classList.add("btn-primary");
        }
        else{
            btn.disabled = true;
            btn.classList.remove("btn-primary");
            btn.classList.add("btn-danger");
        }
    }

    useEffect(() => {
        // Performs realtime search of data as user types.
        let tmpUsers, i, input, pRules;
        tmpUsers = [];
        input = search.toString().toLowerCase();
        for (i=0; i<dataOrigin.length; i++){
            if ((dataOrigin[i]?.UserName && dataOrigin[i]?.UserName?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.DisplayName && dataOrigin[i]?.DisplayName?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.Email && dataOrigin[i]?.Email?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.UserRole && dataOrigin[i]?.UserRole?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.DeviceName && dataOrigin[i]?.DeviceName?.toLowerCase()?.search(input) !== -1)){
                tmpUsers.push(dataOrigin[i]);
            }
        }
        pRules = paginateData(tmpUsers, page, perPage);
        if (input && page > pRules.pageMax){
            setPage(pRules.pageMax)
        }else if (!input){
            setPage(storePage);
        }
        setPRules(pRules);
        setActiveData(tmpUsers);
    }, [search, dataOrigin])

    function filterSubmit(){
        handleSubmitActions("filterSubmit", "loadFilterSubmit");
        setPerPage(tmpPerPage);
        dData.perPage = tmpPerPage;
        setPage(1);
        ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
        formSuccess("Page parameters stored to session.", "", "filterSubmit", "loadFilterSubmit", "cancelFilterBtn", setCount);
    }

    function formAddSubmit(){
        let msg, username, password, email, display, role, groupList, groups, i, payload;
        handleSubmitActions("addSubmit", "loadAddSubmit");
        groupList = [];
        username = document.getElementById("username").value.toLowerCase();
        display = document.getElementById("display").value;
        email = document.getElementById("email").value;
        password = document.getElementById("password").value;
        role = document.getElementById("userrole").value;
        groups = document.getElementsByName("groups");
        for (i=0; i<groups.length; i++){
            if (groups[i].checked === true){
                groupList.push(groups[i].value);
            }
        }
        payload = [{Action: "new", DisplayName: display, UserEmail: email.toLowerCase(), UserRole: role,
                    RequestedBy: dData?.username, GroupIds: groupList, Username: username, UserPass: password, CampusId: campusId}];
        ApiRequester({reqEndpoint: "user-manage", Payload: payload}).then(data => {
                if (data[0] === true){
                    formSuccess("User has been added.", "addForm", "addSubmit", "loadAddSubmit", "cancelAddBtn");
                }
                else{
                    if (data[1] === "1"){
                        msg = "Username already exists, please change username.";
                    }
                    else if (data[1] === "2"){
                        msg = "Error, user created, failed saving user details. Contact your customer success manager for assistance.";
                    }
                    else if (data[1] === "8"){
                        msg = "Request aborted, missing required data. Contact your customer success manager for assistance.";
                    }
                    else if (data[1] === "9"){
                        msg = "Request aborted, no matching action. Contact your customer success manager for assistance.";
                    }
                    else{
                        msg = "Unknown failure, request not processed.";
                    }
                    formFail(msg, "addSubmit", "loadAddSubmit");
                }
        });
    }

    function formEditSubmit(){
        // User add form submit function
        let userId, email, pAccess, display, username, role, groupList, groups, i, payload;
        handleSubmitActions("editSubmit", "loadEditSubmit")
        groupList = [];
        username = document.getElementById("usernameE").value;
        userId = document.getElementById("userIdE").value;
        display = document.getElementById("displayE").value;
        email = document.getElementById("emailE").value;
        role = document.getElementById("userroleE").value;
        groups = document.getElementsByName("groupsE");
        for (i=0; i<groups.length; i++){
            if (groups[i].checked === true){
                groupList.push(groups[i].value);
            }
        }
        pAccess = getAccessSettings(role);
        payload = [{Action: "update", UserId: userId, DisplayName: display, UserEmail: email.toLowerCase(), UserRole: role, PortalAccess: pAccess,
                    RequestedBy: dData?.username, GroupIds: groupList, Username: username, CampusId: campusId}];
        ApiRequester({reqEndpoint: "user-manage", Payload: payload}).then(data => {
                if (data[0]){
                    formSuccess("User updated.", "", "editSubmit", "loadEditSubmit", "cancelEditBtn");
                    if (username === dData?.username){
                        dData.email = email.toLowerCase();
                        dData.display = display;
                        ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
                    }
                }
                else{
                    formFail(data[1], "editSubmit", "loadEditSubmit");
                }
        });
    }

    function deletePerform(submitBtn, loadBtn, closeId, start, end){
        let usernames, payload, i;
        handleSubmitActions(submitBtn, loadBtn);
        usernames = document.getElementById("uContent").value.split(",");
        payload = [];
        for (i=0; i < dContent.length; i++){
            payload.push({Action: "remove", UserId: dContent[i][0], Username: usernames[i], CampusId: campusId,
                            RequestedBy: dData?.username});
        }
        ApiRequester({reqEndpoint: "user-manage", Payload: payload}).then(data => {
            if (data[0]){
                formSuccess("Users have been deleted.", "", submitBtn, loadBtn, closeId);
                document.getElementById("uCheckHead").checked = false;
                checkUserAll(start, end);
                actionAppearance(start, end, ["deleteBtn", "groupsBtn"]);
            }
            else{
                formFail(data[1], submitBtn, loadBtn);
            }
        });
    }

    function groupSubmit(start, end){
        let i, body, rows, i2, i3, timestamp, recIdList, payload, groups, groupList;
        body = document.getElementById("tBody");
        rows = body.querySelectorAll("tr");
        groups = document.getElementsByName("groupsG");
        groupList = [];
        recIdList = [];
        payload = [];
        timestamp = getUTCTimestamp();
        for (i3=0; i3<groups.length; i3++){
            if (groups[i3].checked === true){
                groupList.push(groups[i3].value);
            }
        }
        for (i=0; i < rows.length; i++){
            let nxtCheck = document.getElementById("uCheck"+i.toString());
            if (nxtCheck.checked){
                recIdList.push(activeData[i]?.UserId);
            }
        }
        for (i2=0; i2 < recIdList.length; i2++){
            payload.push({UserId: recIdList[i2], GroupIds: groupList, ModifiedBy: dData?.username,
                            DateModified: timestamp});
        }
        ApiRequester({reqEndpoint: "save", Tbl: "User", PKey: "UserId", Payload: payload, CampusId: campusId}).then(data => {
            if (data[0]){
                formSuccess("User groups updated.", "groupForm", "groupSubmit", "loadGroupSubmit", "cancelGroupBtn");
                document.getElementById("uCheckHead").checked = false;
                checkUserAll(start, end);
            }
            else{
                formFail(data[1], "groupSubmit", "loadGroupSubmit");
            }
        });
    }

    function passSubmit(){
        let password, username, msg, payload;
        handleSubmitActions("passSubmit", "loadPassSubmit");
        username = document.getElementById("usernameE").value;
        password = document.getElementById("passwordR").value;
        payload = [{Action: "reset", CampusId: campusId, Username: username, UserPass: password,
                    RequestedBy: dData.username}];
        ApiRequester({reqEndpoint: "user-manage", Payload: payload}).then(data => {
                if (data[0] === true){
                    formSuccess("Password reset successfully.", "passForm", "passSubmit", "loadPassSubmit", "cancelPassBtn");
                }
                else{
                    if (data[1] === "3"){
                        msg = "Current password does not match records.";
                    }
                    else if (data[1] === "4"){
                        msg = "User not authorized.";
                    }
                    else if (data[1] === "5"){
                        msg = "Error encountered authenticating user. Please contact support for assistance if error persists.";
                    }
                    else if (data[1] === "6"){
                        msg = "New password failed to be set, check password and try again. Contact support if failure persists.";
                    }
                    else{
                        msg = "Unknown failure, request not processed.";
                    }
                    formFail(msg, "passSubmit", "loadPassSubmit");
                }
        });
    }

    const tableHeaders = <TableHeaders checkbox={true} headerData={headerData} checkAll={() => checkUserAll(ppRules.start, ppRules.end, dData)}
                                       writeAccess={dData?.access?.Users?.UserManagement?.Write} sortOrder={sorter.sortOrder}
                                       sortColumn={sorter.sortColumn} sorter={sorter} setSorter={setSorter}
                                       actionAppearance={() => actionAppearance(ppRules.start, ppRules.end, ["deleteBtn", "groupsBtn"])}/>

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div style={{minWidth: "360px"}}>
                {groupsButton}
                {deleteButton}
                <div className={"form-outline d-inline-flex align-bottom ms-2"}>
                    <input type={"search"} id={"search1"} className={"form-control search-input mt-1"}
                         onKeyUp={(e) => setSearch(e.target.value)} />
                    {searchButton}
                </div>
                {csvButton}
                {addButton}
            </div>
            <CampusDropDown campusId={campusId} setCampusId={(item) => setCampusId(item)} setCount={(item) => setCount(item)} pageAction={() => {}}/>
        </div>
    )

    const footer = (
        <div>
            <Pagination>
                <PaginationItems setPage={setPage} page={page} beginDot={ppRules.beginDot}
                                 endDot={ppRules.endDot} pageMin={ppRules.pageMin} pageMax={ppRules.pageMax}
                                 max={ppRules.max}/>
            </Pagination>
            <Button class={"btn btn-secondary fs-5 ms-4"} text={perPage} onClick={() => showModal("filtermodal")}/>
        </div>
    )

    return (dData?.slauth === true && dData?.tKey != null && dData?.tKey !== "" && dData?.acnt != null) ? (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Users"} Title={"User Management"} content={headerContent}/>
                <div className={"container-fluid mt-4"}>
                    <table className={"table table-sm table-white table-hover"}>
                        <thead className={"sticky-table-head"}>
                            {tableHeaders}
                        </thead>
                        <tbody id={"tBody"}>
                            {tableRows}
                        </tbody>
                    </table>
                    <div className={"footer-spacer"}>

                    </div>
                </div>
                <Footer center={footer}/>
            </div>
            <div className={"modal fade"} id={"addmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Add User</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"addForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"username"} onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           required={true} pattern={"^[0-9a-zA-Z_@\\-]+"}/>
                                    <label htmlFor={"username"}>Username</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a valid username! No spaces allowed.
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"display"}
                                            onKeyUp={() => formCheck("addForm", "addSubmit")} required={true} pattern="[a-zA-Z ]+"/>
                                    <label htmlFor={"display"}>Display Name</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a display name!
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"email"}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")} pattern={"[A-Za-z0-9._%+\\-]+@[A-Za-z0-9.\\-]+\\.[A-Za-z]{2,}$"} />
                                    <label htmlFor={"email"}>Email</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a valid email!
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"password"}
                                            onKeyUp={() => formCheck("addForm", "addSubmit")} required={true} pattern="[ -~]{6,}$" />
                                    <label htmlFor={"password"}>Password</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a valid password! 6 Character minimum.
                                    </div>
                                </div>
                                <label htmlFor={"userrole"}>User Role</label>
                                <select className={"form-select"} required={true} id={"userrole"}
                                        onChange={() => formCheck("addForm", "addSubmit")}>
                                    {selectOptions}
                                </select>
                            </div>
                            <h3 className={"modal-header modal-title"}>Groups</h3>
                            <div className={"list-group"} style={{maxHeight: "400px", overflowY: "auto"}}>
                                {groupList}
                            </div>
                            <div className={"modal-footer"}>
                                <button type={"button"} className={"btn btn-secondary"} id={"cancelAddBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                <Button type={"button"} class={"btn btn-primary d-none"} disabled={true} spinner={true} text={"Loading"} id={"loadAddSubmit"}/>
                                <button type={"button"} className={"btn btn-danger"} disabled={true} id={"addSubmit"}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"editmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Edit User</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"editForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <input type={"hidden"} id={"userIdE"} />
                                <input type={"hidden"} id={"usernameE"} />
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"displayE"}
                                            onKeyUp={() => formCheck("editForm", "editSubmit")} required={true} pattern="[a-zA-Z ]+"/>
                                    <label htmlFor={"displayE"}>Display Name</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a display name!
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"emailE"}
                                           onKeyUp={() => formCheck("editForm", "editSubmit")} pattern={"[A-Za-z0-9._%+\\-]+@[A-Za-z0-9.\\-]+\\.[A-Za-z]{2,}$"} />
                                    <label htmlFor={"emailE"}>Email</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a valid email!
                                    </div>
                                </div>
                                <label htmlFor={"userroleE"}>User Role</label>
                                <select className={"form-select"} required={true} id={"userroleE"}
                                        onChange={() => formCheck("editForm", "editSubmit")}>
                                    {selectOptions}
                                </select>
                            </div>
                            <h3 className={"modal-header modal-title"}>Groups</h3>
                            <div className={"list-group"} style={{maxHeight: "400px", overflowY: "auto"}}>
                                {groupListE}
                            </div>
                            <div className={"modal-footer"} style={{justifyContent: "space-between"}}>
                                <div>
                                    {soloDelButton}
                                    {passButton}
                                </div>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelEditBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadEditSubmit"}/>
                                    <button type={"button"} className={"btn btn-danger ms-3"} disabled={true} id={"editSubmit"}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <SingleDeleteModal deletePerform={() => deletePerform("deleteSubmit", "loadDeleteSubmit", "confirmClose", ppRules.start, ppRules.end)}
                                header={"Delete User"}/>
            <BulkDeleteModal deletePerform={() => deletePerform("bulkDeleteSubmit", "loadBulkDeleteSubmit", "bulkConfirmClose", ppRules.start, ppRules.end)}
                             start={ppRules.start} end={ppRules.end} header={"Delete Users"} />
            <PerPageModal filterSubmit={() => filterSubmit()} tmpPerPage={tmpPerPage} setTmpPerPage={(sudocode) => setTmpPerPage(sudocode)} setPage={(sudocode) => setPage(sudocode)}/>
            <div className={"modal fade"} id={"groupmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Edit User Groups</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"groupForm"} className={"was-validated"}>
                            <h3 className={"modal-header modal-title"}>Groups</h3>
                            <div className={"list-group"} style={{maxHeight: "400px", overflowY: "auto"}}>
                                {groupListG}
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelGroupBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadGroupSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"groupSubmit"} onClick={() => groupSubmit(ppRules.start, ppRules.end)}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"passmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Reset Password</h2>
                            <button type={"button"} className={"btn-close"} id={"passClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"passForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"passwordR"}
                                            onKeyUp={() => formCheck("passForm", "passSubmit")}
                                           required={true} pattern={"[ -~]{6,}"} />
                                    <label htmlFor={"passwordR"}>New Password</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a valid password! 6 Character minimum.
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"passwordCR"}
                                            onKeyUp={() => formCheck("passForm", "passSubmit")} required={true}  pattern={"[ -~]{6,}"} />
                                    <label htmlFor={"passwordCR"}>Confirm Password</label>
                                    <div className={"invalid-feedback"}>
                                        Password does not match.
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelPassBtn"}
                                            onClick={() => {
                                                swapModal("passClose", "editmodal");
                                                formClear("passForm", "passSubmit");
                                            }}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadPassSubmit"}/>
                                    <button type={"button"} className={"btn btn-danger ms-3"} id={"passSubmit"} onClick={() => passSubmit()}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <input type={"hidden"} id={"uContent"}/>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </>
    ) : <Navigate to={"/"}/>;
};

export default LegacyUserManagement;