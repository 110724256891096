const Footer = (props) => {
    let leftContent, centerContent, rightContent;
    if (props?.left){
        leftContent = props.left;
    }else{
        leftContent = (
            <h6 className={"fs-5 pt-2"}>
            </h6>
        );
    }
    if (props?.right){
        rightContent = props.right;
    }else{
        rightContent = (
            <h6 className={"fs-5 pt-2"}>
            </h6>
        );
    }
    if (props?.center){
        centerContent = props.center;
    }else{
        centerContent = (
            <h6 className={"fs-5 pt-2"}>
            </h6>
        );
    }

    return (
        <footer>
            <div className={"footer position-fixed bottom-0"} style={{minHeight: "90px"}}>
                <div className={"container-fluid"}>
                    <div className={"d-flex justify-content-around text-center pb-4 pt-3"} style={{borderTop: "solid 1px #e3ebf6"}}>
                        {leftContent}
                        {centerContent}
                        {rightContent}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;