import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import Header from "./Header";
import {Suspense, useEffect, useState} from "react";
import ApiRequester from "./ApiRequester";
import Button from "./component-assets/Button";
import {
    getToday,
    calculateSendTime,
    formatDate,
    sortFunc,
    handleSubmitActions,
    getCurrentYear,
    formSuccess,
    showModal,
    formatDateIso,
    formCheck,
    convertHHMMtoSec,
    range,
    swapModal,
    checkAll,
    formFail, iAmHigherAuthority
} from "../libs";
import {toast, ToastContainer} from "react-toastify";
import {Chart, registerables} from "chart.js";
import "react-datepicker/dist/react-datepicker.css";
import Footer from "./Footer";
import PerPageModal from "./component-assets/PerPageModal";
import CampusDropDown from "./component-assets/CampusDropDown";
import TableHeaders from "./component-assets/TableHeaders";
import {v4 as uuidv4} from "uuid"
import TableBody from "./component-assets/TableBody";
import MaterialIcon from "material-icons-react";


const ExecutiveReport = (props) => {

    let dData, resHeaders, careHeaders, areaTypeHeaders, deviceHeaders, residentHeaders, eventTypeHeaders, userHeaders,
        reasonHeaders, areaGroupHeaders, residentGroupHeaders, areaHeaders, userGroupHeaders, topAreasHeaders,
        excessAlarmsHeaders, areaTypeDailyHeaders, areaTypeResponseHeaders, areaTypeResponseChartHeaders;
    let colorlist = ["#2C7BE5", "#392CE5", "#05B2FF", "#A6C5F7", "#8989FF", "#D2DDEC", "#E52C7B", "#962CE5", "#E52CD8",
        "#E52C7B", "#E5392C", "#09BA00", "#0CFF00", "#8AFF85", "#AEFF00", "#F2FF00", "#A7B000"];

    Chart.register(...registerables);

    // resHeaders = [
    //     {label: "Resident Name", key: "resident_name", align: "left", sorts: false,
    //         link: {enabled: true, type: "standard", linkto: "/profiles/resident", linkkey: "resident_id", sorts: false}},
    //     {label: "Total Alarms", key: "total_events", align: "center", sorts: false},
    //     {label: "Alarms 0-7 Days", key: "week1", align: "center", sorts: false,
    //         highlight: {enabled: !!iAmHigherAuthority(10, dData?.role_weight),
    //                     useIcon: true, weighted: false, compareKey: "total_events",
    //                     warnValue: 30, dangerValue: 50, extremeValue: 70, overunder: "over"}},
    //     {label: "Alarms 7-14 Days", key: "week2", align: "center", sorts: false,
    //         highlight: {enabled: !!iAmHigherAuthority(10, dData?.role_weight),
    //                     useIcon: true, weighted: false, compareKey: "total_events",
    //                     warnValue: 30, dangerValue: 50, extremeValue: 70, overunder: "over"}},
    //     {label: "Alarms 14-21 Days", key: "week3", align: "center", sorts: false,
    //         highlight: {enabled: !!iAmHigherAuthority(10, dData?.role_weight),
    //                     useIcon: true, weighted: false, compareKey: "total_events",
    //                     warnValue: 30, dangerValue: 50, extremeValue: 70, overunder: "over"}},
    //     {label: "Alarms 21-28 Days", key: "week4", align: "center", sorts: false,
    //         highlight: {enabled: !!iAmHigherAuthority(10, dData?.role_weight),
    //                     useIcon: true, weighted: false, compareKey: "total_events",
    //                     warnValue: 30, dangerValue: 50, extremeValue: 70, overunder: "over"}},
    //     {label: "Response Time (min)", key: "response_time", align: "center", sorts: false}
    // ];
    //
    // careHeaders = [
    //     {label: "User Name", key: "display_name", align: "left", sorts: false},
    //     {label: "Total Alarms", key: "total_events", align: "center", sorts: false},
    //     {label: "Alarms 0-7 Days", key: "week1", align: "center", sorts: false,
    //         highlight: {enabled: false,
    //                     useIcon: true, weighted: false, compareKey: "total_events",
    //                     warnValue: 30, dangerValue: 50, extremeValue: 70, overunder: "over"}},
    //     {label: "Alarms 7-14 Days", key: "week2", align: "center", sorts: false,
    //         highlight: {enabled: false,
    //                     useIcon: true, weighted: false, compareKey: "total_events",
    //                     warnValue: 30, dangerValue: 50, extremeValue: 70, overunder: "over"}},
    //     {label: "Alarms 14-21 Days", key: "week3", align: "center", sorts: false,
    //         highlight: {enabled: false,
    //                     useIcon: true, weighted: false, compareKey: "total_events",
    //                     warnValue: 30, dangerValue: 50, extremeValue: 70, overunder: "over"}},
    //     {label: "Alarms 21-28 Days", key: "week4", align: "center", sorts: false,
    //         highlight: {enabled: false,
    //                     useIcon: true, weighted: false, compareKey: "total_events",
    //                     warnValue: 30, dangerValue: 50, extremeValue: 70, overunder: "over"}},
    //     {label: "Response Time (min)", key: "response_time", align: "center", sorts: false}
    // ];

    topAreasHeaders = [
        {label: "Locations", key: "area_name", align: "center", sorts: false},
        {label: "Residents", key: "resident_name", align: "center", sorts: false},
        {label: formatDate(getToday(-7)), key: "day0", align: "center", sorts: false},
        {label: formatDate(getToday(-6)), key: "day1", align: "center", sorts: false},
        {label: formatDate(getToday(-5)), key: "day2", align: "center", sorts: false},
        {label: formatDate(getToday(-4)), key: "day3", align: "center", sorts: false},
        {label: formatDate(getToday(-3)), key: "day4", align: "center", sorts: false},
        {label: formatDate(getToday(-2)), key: "day5", align: "center", sorts: false},
        {label: formatDate(getToday(-1)), key: "day6", align: "center", sorts: false},
        {label: "Totals", key: "total", align: "center", sorts: false}
    ];

    excessAlarmsHeaders = [
        {label: "Event Date Time", key: "event_datetime_local", align: "center", sorts: false, type: "datetime"},
        {label: "Acknowledge Date Time", key: "ack_datetime_local", align: "center", sorts: false, type: "datetime"},
        {label: "Location", key: "area_name", align: "center", sorts: false},
        {label: "Resident Name", key: "resident_name", align: "center", sorts: false},
        {label: "Device Type", key: "device_category_short_name", align: "center", sorts: false},
        {label: "Response Time (min)", key: "response_time", align: "center", sorts: false},
        {label: "Reason", key: "reason_code_description", align: "center", sorts: false}
    ];

    // areaTypeDailyHeaders = [
    //     {label: "Care Setting", key: "area_type_name", align: "center", sorts: false},
    //     {label: formatDate(getToday(-7)), key: "day0", align: "center", sorts: false},
    //     {label: formatDate(getToday(-6)), key: "day1", align: "center", sorts: false},
    //     {label: formatDate(getToday(-5)), key: "day2", align: "center", sorts: false},
    //     {label: formatDate(getToday(-4)), key: "day3", align: "center", sorts: false},
    //     {label: formatDate(getToday(-3)), key: "day4", align: "center", sorts: false},
    //     {label: formatDate(getToday(-2)), key: "day5", align: "center", sorts: false},
    //     {label: formatDate(getToday(-1)), key: "day6", align: "center", sorts: false},
    //     {label: "Totals", key: "total", align: "center", sorts: false}
    // ];

    areaTypeResponseHeaders = [
        {label: "Care Setting", key: "area_type_name", align: "center", sorts: false},
        {label: "Avg Response Time (min)", key: "avg_response_time", align: "center", sorts: false},
        {label: "Total Alarms", key: "total_events", align: "center", sorts: false}
    ];

    areaTypeResponseChartHeaders = [
        {label: "Care Setting", key: "area_type_name", align: "center", sorts: false},
        {label: "0-5", key: "bucket0", align: "center", sorts: false},
        {label: "5-10", key: "bucket1", align: "center", sorts: false},
        {label: "10-20", key: "bucket2", align: "center", sorts: false},
        {label: "20-60", key: "bucket3", align: "center", sorts: false},
        {label: "60+", key: "bucket4", align: "center", sorts: false},
        {label: "Totals", key: "total", align: "center", sorts: false}
    ];

    // areaHeaders = [
    //     {label: "Group Name", key: "area_name", align: "left",
    //         link: {enabled: true, type: "standard", linkto: "/profiles/area", linkkey: "area_id",
    //             inmodal: true, modalKey: "cancelFilterBtn2"}},
    // ];
    // areaGroupHeaders = [
    //     {label: "Group Name", key: "area_group_name", align: "left"},
    //     {label: "Description", key: "area_group_description", align: "left"}
    // ];
    areaTypeHeaders = [
        {label: "Area Type", key: "area_type_name", align: "left"},
        {label: "Description", key: "area_type_description", align: "left"},
    ];
    deviceHeaders = [
        {label: "Category", key: "device_category_short_name", align: "left"},
    ];
    residentHeaders = [
        {label: "First Name", key: "resident_first_name", align: "left",
            link: {enabled: true, type: "standard", linkto: "/profiles/resident", linkkey: "resident_id",
                inmodal: true, modalKey: "cancelFilterBtn2"}},
        {label: "Last Name", key: "resident_last_name", align: "left",
            link: {enabled: true, type: "standard", linkto: "/profiles/resident", linkkey: "resident_id",
                inmodal: true, modalKey: "cancelFilterBtn2"}},
        {label: "Nickname", key: "resident_nickname", align: "left"},
        {label: "Status", key: "is_active", align: "center", type: "bool", f: "Moved Out", t: "Active"}
    ];
    residentGroupHeaders = [
        {label: "Group Name", key: "resident_group_name", align: "left"},
        {label: "Description", key: "resident_description", align: "left"}
    ];
    userHeaders = [
        {label: "Username", key: "username", align: "left"},
        {label: "Display", key: "display_name", align: "left"},
        {label: "Role", key: "user_role", align: "left"}
    ];
    userGroupHeaders = [
        {label: "Group Name", key: "group_name", align: "left"}
    ];
    eventTypeHeaders = [
        {label: "Event Type", key: "event_type_name", align: "left"},
    ];
    reasonHeaders = [
        {label: "Reason", key: "reason_code_description", align: "left"},
    ];

    if (ReactSession.get("PAL") != null) {
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    // Data Variables
    const [run, setRun] = useState(true);
    const [topAreaDailyData, setTopAreaDailyData] = useState([]);
    const [excessAlarmsData, setExcessAlarmsData] = useState([]);
    // const [areaTypeDailyData, setAreaTypeDailyData] = useState([]);
    // const [areaTypeDailyChartData, setAreaTypeDailyChartData] = useState({});
    const [areaTypeResponseData, setAreaTypeResponseData] = useState([]);
    const [areaTypeResponseChartData, setAreaTypeResponseChartData] = useState([]);
    const [areaTypeResponseChart, setAreaTypeResponseChart] = useState({});
    const [resData, setResData] = useState([]);
    const [careData, setCareData] = useState([]);
    const [reasonData, setReasonData] = useState({});
    const [areaDailyDsp, setAreaDailyDsp] = useState(<></>);
    const [excessAlarmsDsp, setExcessAlarmsDsp] = useState(<></>);
    const [areaTypeDsp, setAreaTypeDsp] = useState(<></>);
    const [areaTypeChartDsp, setAreaTypeChartDsp] = useState(<></>);
    const [areaTypeResponseDsp, setAreaTypeResponseDsp] = useState(<></>);
    const [areaTypeResponseChartDsp, setAreaTypeResponseChartDsp] = useState(<></>)
    // const [resDsp, setResDsp] = useState(<></>);
    // const [careDsp, setCareDsp] = useState(<></>);
    const [reasonDsp, setReasonDsp] = useState(<></>)
    const [start, setStart] = useState(getToday(-1));
    const [end, setEnd] = useState(getToday(-1));
    const [freqData, setFreqData] = useState([]);
    const [loaded, setLoaded] = useState(false);
    // Table Control Variables
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(dData.perPage);
    const [ppRules, setPRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    });
    const [sorter, setSorter] = useState({
        sortColumn: "description",
        sortOrder: "desc"
    });
    // Page Variables
    const [campusId, setCampusId] = useState(dData.acnt);
    const [reportDisplay, setReportDisplay] = useState("");
    const [count, setCount] = useState(0);
    const [filterCount, setFilterCount] = useState(0);
    const [footer, setFooter] = useState(<div></div>);
    const [repType, setRepType] = useState("Executive");
    const [scheduleable, setScheduleable] = useState(true);
    const [overLimit, setOverLimit] = useState(false);
    // Helper Control Variables
    const [status, setStatus] = useState("init");
    const [btnAvail, setBtnAvail] = useState(false);
    const [startDisplay, setStartDisplay] = useState("");
    const [endDisplay, setEndDisplay] = useState("");
    const [tabItem, setTabItem] = useState("areaType");
    const [tabCat, setTabCat] = useState("areas");
    const [tmpPerPage, setTmpPerPage] = useState(dData.perPage);
    const [freqOptions, setFreqOptions] = useState([]);
    const [rangeName, setRangeName] = useState("Yesterday");
    const [rangeId, setRangeId] = useState(2);

    // Area Filter Table Variables
    // const [areaOriginData, setAreaOriginData] = useState([]);
    // const [areaTableRows, setAreaTableRows] = useState([]);
    // const [areaDataCnt, setAreaDataCnt] = useState(0);
    // const [areaIdList, setAreaIdList] = useState([]);
    // const [areaSearch, setAreaSearch] = useState("");
    // const [areaSorter, setAreaSorter] = useState({
    //     sortColumn: "area_name",
    //     sortOrder: "desc"
    // });
    // Area Group Filter Table Variables
    // const [areaGroupOriginData, setAreaGroupOriginData] = useState([]);
    // const [areaGroupTableRows, setAreaGroupTableRows] = useState([]);
    // const [areaGroupDataCnt, setAreaGroupDataCnt] = useState(0);
    // const [areaGroupIdList, setAreaGroupIdList] = useState([]);
    // const [areaGroupSearch, setAreaGroupSearch] = useState("");
    // const [areaGroupSorter, setAreaGroupSorter] = useState({
    //     sortColumn: "area_group_name",
    //     sortOrder: "desc"
    // });
    // Area Type Filter Table Variables
    const [areaTypeOriginData, setAreaTypeOriginData] = useState([]);
    const [areaTypeTableRows, setAreaTypeTableRows] = useState([]);
    const [areaTypeDataCnt, setAreaTypeDataCnt] = useState(0);
    const [areaTypeIdList, setAreaTypeIdList] = useState([]);
    const [areaTypeSearch, setAreaTypeSearch] = useState("");
    const [areaTypeSorter, setAreaTypeSorter] = useState({
        sortColumn: "area_type_name",
        sortOrder: "desc"
    });
    // Device Category Filter Table Variables
    const [devOriginData, setDevOriginData] = useState([]);
    const [devTableRows, setDevTableRows] = useState([]);
    const [devDataCnt, setDevDataCnt] = useState(0);
    const [devIdList, setDevIdList] = useState([]);
    const [devSearch, setDevSearch] = useState("");
    const [devSorter, setDevSorter] = useState({
        sortColumn: "device_category_short_name",
        sortOrder: "desc"
    });
    // Resident Filter Table Variables
    const [resOriginData, setResOriginData] = useState([]);
    const [resTableRows, setResTableRows] = useState([]);
    const [resDataCnt, setResDataCnt] = useState(0);
    const [resIdList, setResIdList] = useState([]);
    const [resSearch, setResSearch] = useState("");
    const [resSorter, setResSorter] = useState({
        sortColumn: "resident_first_name",
        sortOrder: "desc"
    });
    // Resident Group Filter Table Variables
    const [residentGroupOriginData, setResidentGroupOriginData] = useState([]);
    const [residentGroupTableRows, setResidentGroupTableRows] = useState([]);
    const [residentGroupDataCnt, setResidentGroupDataCnt] = useState(0);
    const [residentGroupIdList, setResidentGroupIdList] = useState([]);
    const [residentGroupSearch, setResidentGroupSearch] = useState("");
    const [residentGroupSorter, setResidentGroupSorter] = useState({
        sortColumn: "resident_group_name",
        sortOrder: "desc"
    });
    // Event Type Filter Table Variables
    const [eventTypeOriginData, setEventTypeOriginData] = useState([]);
    const [eventTypeTableRows, setEventTypeTableRows] = useState([]);
    const [eventTypeDataCnt, setEventTypeDataCnt] = useState(0);
    const [eventTypeIdList, setEventTypeIdList] = useState([]);
    const [eventTypeSearch, setEventTypeSearch] = useState("");
    const [eventTypeSorter, setEventTypeSorter] = useState({
        sortColumn: "event_type_name",
        sortOrder: "desc"
    });
    // User Filter Table Variables
    const [userOriginData, setUserOriginData] = useState([]);
    const [userTableRows, setUserTableRows] = useState([]);
    const [userDataCnt, setUserDataCnt] = useState(0);
    const [userIdList, setUserIdList] = useState([]);
    const [userSearch, setUserSearch] = useState("");
    const [userSorter, setUserSorter] = useState({
        sortColumn: "username",
        sortOrder: "desc"
    });
    // User Group Filter Table Variables
    const [userGroupOriginData, setUserGroupOriginData] = useState([]);
    const [userGroupTableRows, setUserGroupTableRows] = useState([]);
    const [userGroupDataCnt, setUserGroupDataCnt] = useState(0);
    const [userGroupIdList, setUserGroupIdList] = useState([]);
    const [userGroupSearch, setUserGroupSearch] = useState("");
    const [userGroupSorter, setUserGroupSorter] = useState({
        sortColumn: "group_name",
        sortOrder: "desc"
    });
    // Reason Filter Table Variables
    const [reasonOriginData, setReasonOriginData] = useState([]);
    const [reasonTableRows, setReasonTableRows] = useState([]);
    const [reasonDataCnt, setReasonDataCnt] = useState(0);
    const [reasonIdList, setReasonIdList] = useState([]);
    const [reasonSearch, setReasonSearch] = useState("");
    const [reasonSorter, setReasonSorter] = useState({
        sortColumn: "reason_code_description",
        sortOrder: "desc"
    });
    // Backend Filter Holders For Selected Filters IDs
    const [areaFilterList, setAreaFilterList] = useState(dData?.areaFilter ? dData.areaFilter : []);
    const [areaGroupFilterList, setAreaGroupFilterList] = useState(dData?.areaGroupFilter ? dData.areaGroupFilter : []);
    const [areaTypeFilterList, setAreaTypeFilterList] = useState(dData?.areaTypeFilter ? dData.areaTypeFilter : []);
    const [devFilterList, setDevFilterList] = useState(dData?.devFilter ? dData.devFilter : []);
    const [resFilterList, setResFilterList] = useState(dData?.resFilter ? dData.resFilter : []);
    const [residentGroupFilterList, setResidentGroupFilterList] = useState(dData?.residentGroupFilter ? dData.residentGroupFilter : []);
    const [typeFilterList, setTypeFilterList] = useState(dData?.typeFilter ? dData.typeFilter : []);
    const [userFilterList, setUserFilterList] = useState(dData?.userFilter ? dData.userFilter : []);
    const [userGroupFilterList, setUserGroupFilterList] = useState(dData?.userFilter ? dData.userFilter : []);
    const [reasonFilterList, setReasonFilterList] = useState(dData?.reasonFilterLabel ? dData.reasonFilterLabel : []);
    // Backend Filter Holders For Selected Filters Labels
    const [areaFilterLabels, setAreaFilterLabels] = useState(dData?.areaFilterLabel ? dData.areaFilterLabel : []);
    const [areaGroupFilterLabels, setAreaGroupFilterLabels] = useState(dData?.areaGroupFilterLabel ? dData.areaGroupFilterLabel : []);
    const [areaTypeFilterLabels, setAreaTypeFilterLabels] = useState(dData?.areaTypeFilterLabel ? dData.areaTypeFilterLabel : []);
    const [devFilterLabels, setDevFilterLabels] = useState(dData?.devFilterLabel ? dData.devFilterLabel : []);
    const [resFilterLabels, setResFilterLabels] = useState(dData?.resFilterLabel ? dData.resFilterLabel : []);
    const [residentGroupFilterLabels, setResidentGroupFilterLabels] = useState(dData?.residentGroupFilterLabel ? dData.residentGroupFilterLabel : []);
    const [typeFilterLabels, setTypeFilterLabels] = useState(dData?.typeFilterLabel ? dData.typeFilterLabel : []);
    const [userFilterLabels, setUserFilterLabels] = useState(dData?.userFilterLabel ? dData.userFilterLabel : []);
    const [userGroupFilterLabels, setUserGroupFilterLabels] = useState(dData?.userGroupFilterLabel ? dData.userGroupFilterLabel : []);
    const [reasonFilterLabels, setReasonFilterLabels] = useState(dData?.reasonFilterLabel ? dData.reasonFilterLabel : []);
    // Modal for emails on schedules
    const [addCurEmails, setAddCurEmails] = useState([]);
    const [addNewEmails, setAddNewEmails] = useState([]);
    const [emailEntities, setEmailEntities] = useState([]);
    // Overlimit send email report storage variables, updated on run report.
    const [reportTypeIdx, setReportTypeIdx] = useState(0);
    const [reportFilterTermsx, setReportFilterTermsx] = useState({});
    const [startDatex, setStartDatex] = useState("");
    const [endDatex, setEndDatex] = useState("");

    // This effect monitors fetching data on count expire, implemented for campus drop down
    // change to rerun report using count to rerun.
    useEffect(() => {
        let interval;
        interval = setInterval(() => {
             setCount(count => count + 1);
        }, 1000);
        if (status !== "init" && count <= 0){
            getReportData();
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        if (run === true){
            setRun(false);
            getReportData();
        }
    }, [run]);

    useEffect(() => {
        let interval;
        interval = setInterval(() => {
             setFilterCount(filterCount => filterCount + 1);
        }, 1000);
        if (filterCount <= 0){
            // ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "core_areas",
            //                     procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
            //     setAreaOriginData(data);
            // });
            // ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "core_area_groups",
            //                     procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
            //     setAreaGroupOriginData(data);
            // });
            // ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "att_area_type",
            //                     procedure: "fetchall", reqType: "direct"}).then(data => {
            //     setAreaTypeOriginData(data);
            // });
            // ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "att_device_categories",
            //                     procedure: "fetchall", reqType: "direct"}).then(data => {
            //     setDevOriginData(data);
            // });
            // ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "core_resident",
            //                     procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
            //     setResOriginData(data);
            // });
            // ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "core_resident_groups",
            //                     procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
            //     setResidentGroupOriginData(data);
            // });
            // ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "core_user",
            //                     procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
            //     setUserOriginData(data);
            // });
            // ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "core_groups",
            //                     procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
            //     setUserGroupOriginData(data);
            // });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "att_event_types",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setEventTypeOriginData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "core_reason_codes",
                                procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                setReasonOriginData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "att_window_frequency",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setFreqData(data);
            });
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "getportalfilters",
                                campus_id: campusId, device_category_filter: "1", area_type_filter: "1",
                                user_filter: "1", resident_group_filter: "1", area_filter: "0",
                                user_group_filter: "1", resident_filter: "1"}).then(data => {
                setDevOriginData(() => data?.device_categories?.length > 0 ? data.device_categories : []);
                setAreaTypeOriginData(() => data?.area_types?.length > 0 ? data.area_types : []);
                setResOriginData(() => data?.residents?.length > 0 ? data.residents : []);
                setUserOriginData(() => data?.users?.length > 0 ? data.users : []);
                // setAreaOriginData(() => data?.areas?.length > 0 ? data.areas : []);
                setResidentGroupOriginData(() => data?.resident_groups?.length > 0 ? data.resident_groups : []);
                setUserGroupOriginData(() => data?.user_groups?.length > 0 ? data.user_groups : []);
            });
            setFilterCount(filterCount => 600);
        }
        return () => clearInterval(interval);
    }, [filterCount]);

    useEffect(() => {
        let tmp = [];
        for (let i=0; i < freqData?.length; i++){
            tmp.push(
                <option key={`freq-option-${i}`} value={freqData[i]?.window_frequency_id}>{freqData[i]?.frequency_name}</option>
            );
        }
        setFreqOptions(() => tmp);
    }, [freqData]);

    // useEffect(() => {
    //     let i, tmp = [], input;
    //     input = areaSearch?.toString()?.toLowerCase();
    //     for (i = 0; i < areaOriginData?.length; i++){
    //         if ((areaOriginData[i]?.area_name && areaOriginData[i]?.area_name?.toLowerCase()?.search(input) !== -1)){
    //             tmp.push(areaOriginData[i]);
    //         }
    //     }
    //     setAreaDataCnt(tmp.length);
    //     tmp.sort((a, b) => sortFunc(a, b, areaSorter.sortColumn, areaSorter.sortOrder));
    //     setAreaTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
    //                                 actionAppearance={() => {}} tableHeaders={areaHeaders} checkboxId={"uCheckArea"}
    //                                 parentComponent={"EventReports"} dData={dData} checkboxlist={areaIdList}
    //                                 modal={false} editModal={() => {}} unk={"area-x-"}
    //                                 setCheckboxId={(value) => {setAreaIdList(value)}} checkidkey={"area_id"} />);
    // }, [areaOriginData, areaSorter, areaIdList, areaSearch]);

    // useEffect(() => {
    //     let i, tmp = [], input;
    //     input = areaGroupSearch?.toString()?.toLowerCase();
    //     for (i = 0; i < areaGroupOriginData?.length; i++){
    //         if ((areaGroupOriginData[i]?.area_group_name && areaGroupOriginData[i]?.area_group_name?.toLowerCase()?.search(input) !== -1) ||
    //             (areaGroupOriginData[i]?.area_group_description && areaGroupOriginData[i]?.area_group_description?.toLowerCase()?.search(input) !== -1)){
    //             tmp.push(areaGroupOriginData[i]);
    //         }
    //     }
    //     setAreaGroupDataCnt(tmp.length);
    //     tmp.sort((a, b) => sortFunc(a, b, areaGroupSorter.sortColumn, areaGroupSorter.sortOrder));
    //     setAreaGroupTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
    //                                     actionAppearance={() => {}} tableHeaders={areaGroupHeaders} checkboxId={"uCheckAreaGroup"}
    //                                     parentComponent={"EventReports"} dData={dData} checkboxlist={areaGroupIdList}
    //                                     modal={false} editModal={() => {}} unk={"area-group-x-"}
    //                                     setCheckboxId={(value) => {setAreaGroupIdList(value)}} checkidkey={"area_group_id"} />);
    // }, [areaGroupOriginData, areaGroupSorter, areaGroupIdList, areaGroupSearch]);

    useEffect(() => {
        let i, tmp = [], input;
        input = areaTypeSearch?.toString()?.toLowerCase();
        for (i = 0; i < areaTypeOriginData?.length; i++){
            if ((areaTypeOriginData[i]?.area_type_name && areaTypeOriginData[i]?.area_type_name?.toLowerCase()?.search(input) !== -1) ||
                (areaTypeOriginData[i]?.area_type_description && areaTypeOriginData[i]?.area_type_description?.toLowerCase()?.search(input) !== -1)){
                tmp.push(areaTypeOriginData[i]);
            }
        }
        setAreaTypeDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, areaTypeSorter.sortColumn, areaTypeSorter.sortOrder));
        setAreaTypeTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                        actionAppearance={() => {}} tableHeaders={areaTypeHeaders} checkboxId={"uCheckAreaType"}
                                        parentComponent={"EventReports"} dData={dData} checkboxlist={areaTypeIdList}
                                        modal={false} editModal={() => {}} unk={"area-type-x-"}
                                        setCheckboxId={(value) => {setAreaTypeIdList(value)}} checkidkey={"area_type_id"} />);
    }, [areaTypeOriginData, areaTypeSorter, areaTypeIdList, areaTypeSearch]);

    useEffect(() => {
        let i, tmp = [], input;
        input = devSearch?.toString()?.toLowerCase();
        for (i = 0; i < devOriginData?.length; i++){
            if ((devOriginData[i]?.device_category_short_name && devOriginData[i]?.device_category_short_name?.toLowerCase()?.search(input) !== -1)){
                tmp.push(devOriginData[i]);
            }
        }
        setDevDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, devSorter.sortColumn, devSorter.sortOrder));
        setDevTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                   actionAppearance={() => {}} tableHeaders={deviceHeaders} checkboxId={"uCheckDev"}
                                   parentComponent={"EventReports"} dData={dData} checkboxlist={devIdList}
                                   modal={false} editModal={() => {}} unk={"device-x-"}
                                   setCheckboxId={(value) => {setDevIdList(value)}} checkidkey={"device_category_id"} />);
    }, [devOriginData, devSorter, devIdList, devSearch]);

    useEffect(() => {
        let i, tmp = [], input;
        input = resSearch?.toString()?.toLowerCase();
        for (i = 0; i < resOriginData?.length; i++){
            if ((resOriginData[i]?.resident_first_name && resOriginData[i]?.resident_first_name?.toLowerCase()?.search(input) !== -1) ||
                (resOriginData[i]?.resident_last_name && resOriginData[i]?.resident_last_name?.toLowerCase()?.search(input) !== -1) ||
                (resOriginData[i]?.resident_nickname && resOriginData[i]?.resident_nickname?.toLowerCase()?.search(input) !== -1)){
                tmp.push(resOriginData[i]);
            }
        }
        setResDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, resSorter.sortColumn, resSorter.sortOrder));
        setResTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                   actionAppearance={() => {}} tableHeaders={residentHeaders} checkboxId={"uCheckRes"}
                                   parentComponent={"EventReports"} dData={dData} checkboxlist={resIdList}
                                   modal={false} editModal={() => {}} unk={"resident-x-"} addlEditCheck={true}
                                   addlEditKey={"is_active"}
                                   setCheckboxId={(value) => {setResIdList(value)}} checkidkey={"resident_id"} />);
    }, [resOriginData, resSorter, resIdList, resSearch]);

    useEffect(() => {
        let i, tmp = [], input;
        input = residentGroupSearch?.toString()?.toLowerCase();
        for (i = 0; i < residentGroupOriginData?.length; i++){
            if ((residentGroupOriginData[i]?.resident_group_name && residentGroupOriginData[i]?.resident_group_name?.toLowerCase()?.search(input) !== -1) ||
                (residentGroupOriginData[i]?.resident_description && residentGroupOriginData[i]?.resident_description?.toLowerCase()?.search(input) !== -1)){
                tmp.push(residentGroupOriginData[i]);
            }
        }
        setResidentGroupDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, residentGroupSorter.sortColumn, residentGroupSorter.sortOrder));
        setResidentGroupTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                   actionAppearance={() => {}} tableHeaders={residentGroupHeaders} checkboxId={"uCheckResidentGroup"}
                                   parentComponent={"EventReports"} dData={dData} checkboxlist={residentGroupIdList}
                                   modal={false} editModal={() => {}} unk={"resident-group-x-"}
                                   setCheckboxId={(value) => {setResidentGroupIdList(value)}} checkidkey={"resident_group_id"} />);
    }, [residentGroupOriginData, residentGroupSorter, residentGroupIdList, residentGroupSearch]);

    useEffect(() => {
        let i, tmp = [], input;
        input = eventTypeSearch?.toString()?.toLowerCase();
        for (i = 0; i < eventTypeOriginData?.length; i++){
            if ((eventTypeOriginData[i]?.event_type_name && eventTypeOriginData[i]?.event_type_name?.toLowerCase()?.search(input) !== -1)){
                tmp.push(eventTypeOriginData[i]);
            }
        }
        setEventTypeDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, eventTypeSorter.sortColumn, eventTypeSorter.sortOrder));
        setEventTypeTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                   actionAppearance={() => {}} tableHeaders={eventTypeHeaders} checkboxId={"uCheckType"}
                                   parentComponent={"EventReports"} dData={dData} checkboxlist={eventTypeIdList}
                                   modal={false} editModal={() => {}} unk={"event-type-x-"}
                                   setCheckboxId={(value) => {setEventTypeIdList(value)}} checkidkey={"event_type_id"} />);
    }, [eventTypeOriginData, eventTypeSorter, eventTypeIdList, eventTypeSearch]);

    useEffect(() => {
        let i, tmp = [], input;
        input = userSearch?.toString()?.toLowerCase();
        for (i = 0; i < userOriginData?.length; i++){
            if ((userOriginData[i]?.username && userOriginData[i]?.username?.toLowerCase()?.search(input) !== -1) ||
                (userOriginData[i]?.display_name && userOriginData[i]?.display_name?.toLowerCase()?.search(input) !== -1) ||
                (userOriginData[i]?.user_role && userOriginData[i]?.user_role?.toLowerCase()?.search(input) !== -1)){
                tmp.push(userOriginData[i]);
            }
        }
        setUserDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, userSorter.sortColumn, userSorter.sortOrder));
        setUserTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                   actionAppearance={() => {}} tableHeaders={userHeaders} checkboxId={"uCheckUser"}
                                   parentComponent={"EventReports"} dData={dData} checkboxlist={userIdList}
                                   modal={false} editModal={() => {}} unk={"user-x-"}
                                   setCheckboxId={(value) => {setUserIdList(value)}} checkidkey={"user_id"} />);
    }, [userOriginData, userSorter, userIdList, userSearch]);

    useEffect(() => {
        let i, tmp = [], input;
        input = userGroupSearch?.toString()?.toLowerCase();
        for (i = 0; i < userGroupOriginData?.length; i++){
            if ((userGroupOriginData[i]?.group_name && userGroupOriginData[i]?.group_name?.toLowerCase()?.search(input) !== -1)){
                tmp.push(userGroupOriginData[i]);
            }
        }
        setUserGroupDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, userGroupSorter.sortColumn, userGroupSorter.sortOrder));
        setUserGroupTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                   actionAppearance={() => {}} tableHeaders={userGroupHeaders} checkboxId={"uCheckUserGroup"}
                                   parentComponent={"EventReports"} dData={dData} checkboxlist={userGroupIdList}
                                   modal={false} editModal={() => {}} unk={"user-Group-x-"}
                                   setCheckboxId={(value) => {setUserGroupIdList(value)}} checkidkey={"group_id"} />)
    }, [userGroupOriginData, userGroupSorter, userGroupIdList, userGroupSearch]);

    useEffect(() => {
        let i, tmp = [], input;
        input = reasonSearch?.toString()?.toLowerCase();
        for (i = 0; i < reasonOriginData?.length; i++){
            if ((reasonOriginData[i]?.reason_code_description && reasonOriginData[i]?.reason_code_description?.toLowerCase()?.search(input) !== -1)){
                tmp.push(reasonOriginData[i]);
            }
        }
        setReasonDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, reasonSorter.sortColumn, reasonSorter.sortOrder));
        setReasonTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                   actionAppearance={() => {}} tableHeaders={reasonHeaders} checkboxId={"uCheckReason"}
                                   parentComponent={"EventReports"} dData={dData} checkboxlist={reasonIdList}
                                   modal={false} editModal={() => {}} unk={"reason-x-"}
                                   setCheckboxId={(value) => {setReasonIdList(value)}} checkidkey={"reason_code_id"} />)
    }, [reasonOriginData, reasonSorter, reasonIdList, reasonSearch]);

    useEffect(() => {
        if (topAreaDailyData && loaded){
            setAreaDailyDsp(
                <div key={"my-topareas-display"}>
                    <h4 className={"px-4"}>Top 10 Locations by Total Alerts, Last 7 Days</h4>
                    <div className={"pt-1 pb-4 px-4 my-2"}>
                        <table className={"table table-sm table-white table-hover"}>
                            <thead className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString() ? "sticky-table-headX" : "sticky-table-head"}>
                                {<TableHeaders checkbox={false} headerData={topAreasHeaders} sorts={false} sortOrder={{}}
                                               sortColumn={{}} sorter={{}} setSorter={() => {}} />}
                            </thead>
                            <tbody id={"tBody-topareas"}>
                                {<TableBody start={0} end={topAreaDailyData?.length ? topAreaDailyData.length : 0}
                                            tableData={topAreaDailyData?.length > 0 ? topAreaDailyData : []}
                                            checkbox={false} writeAccess={false}
                                            actionAppearance={() => {}} tableHeaders={topAreasHeaders} checkboxId={""}
                                            parentComponent={"Executive Report"} dData={dData} checkboxlist={[]}
                                            modal={false} editModal={() => {}} unk={"topareas"} setCheckboxId={() => {}}
                                            checkidkey={""}/>}
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }
    }, [topAreaDailyData, loaded]);

    // useEffect(() => {
    //     if (areaTypeDailyData && loaded){
    //         setAreaTypeDsp(
    //             <div key={"my-areatype-display"}>
    //                 <h4 className={"px-4"}>Alarms by Date & Care Setting</h4>
    //                 <div className={"pt-1 pb-4 px-4 my-2"}>
    //                     <table className={"table table-sm table-white table-hover"}>
    //                         <thead className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString() ? "sticky-table-headX" : "sticky-table-head"}>
    //                             {<TableHeaders checkbox={false} headerData={areaTypeDailyHeaders} sorts={false} sortOrder={{}}
    //                                            sortColumn={{}} sorter={{}} setSorter={() => {}} />}
    //                         </thead>
    //                         <tbody id={"tBody-areatype"}>
    //                             {<TableBody start={0} end={areaTypeDailyData?.length ? areaTypeDailyData.length : 0}
    //                                         tableData={areaTypeDailyData?.length > 0 ? areaTypeDailyData : []}
    //                                         checkbox={false} writeAccess={false}
    //                                         actionAppearance={() => {}} tableHeaders={areaTypeDailyHeaders} checkboxId={""}
    //                                         parentComponent={"Executive Report"} dData={dData} checkboxlist={[]}
    //                                         modal={false} editModal={() => {}} unk={"areatype"} setCheckboxId={() => {}}
    //                                         checkidkey={""}/>}
    //                         </tbody>
    //                     </table>
    //                 </div>
    //             </div>
    //         );
    //     }
    // }, [areaTypeDailyData, loaded]);

    useEffect(() => {
        if (areaTypeResponseData && loaded){
            setAreaTypeResponseDsp(
                <div key={"my-areatyperesponse-display"}>
                    <h4 className={"px-4"}>Average Response Time by Care Setting</h4>
                    <div className={"pt-1 pb-4 px-4 my-2"}>
                        <table className={"table table-sm table-white table-hover"}>
                            <thead className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString() ? "sticky-table-headX" : "sticky-table-head"}>
                                {<TableHeaders checkbox={false} headerData={areaTypeResponseHeaders} sorts={false} sortOrder={{}}
                                               sortColumn={{}} sorter={{}} setSorter={() => {}} />}
                            </thead>
                            <tbody id={"tBody-areatyperesponse"}>
                                {<TableBody start={0} end={areaTypeResponseData?.length ? areaTypeResponseData.length : 0}
                                            tableData={areaTypeResponseData?.length > 0 ? areaTypeResponseData : []}
                                            checkbox={false} writeAccess={false}
                                            actionAppearance={() => {}} tableHeaders={areaTypeResponseHeaders} checkboxId={""}
                                            parentComponent={"Executive Report"} dData={dData} checkboxlist={[]}
                                            modal={false} editModal={() => {}} unk={"areatyperesponse"} setCheckboxId={() => {}}
                                            checkidkey={""}/>}
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }
    }, [areaTypeResponseData, loaded]);

    useEffect(() => {
        if (areaTypeResponseChartData && loaded){
            setAreaTypeResponseChartDsp(
                <div key={"my-areatyperesponsechart-display"}>
                    <div className={"pt-0 pb-4 px-4 mb-2 mt-0"}>
                        <table className={"table table-sm table-white table-hover"}>
                            <thead className={"table-header"}>
                                {<TableHeaders checkbox={false} headerData={areaTypeResponseChartHeaders} sorts={false} sortOrder={{}}
                                               sortColumn={{}} sorter={{}} setSorter={() => {}} />}
                            </thead>
                            <tbody id={"tBody-areatyperesponsechart"}>
                                {<TableBody start={0} end={areaTypeResponseChartData?.length ? areaTypeResponseChartData.length : 0}
                                            tableData={areaTypeResponseChartData?.length > 0 ? areaTypeResponseChartData : []}
                                            checkbox={false} writeAccess={false}
                                            actionAppearance={() => {}} tableHeaders={areaTypeResponseChartHeaders} checkboxId={""}
                                            parentComponent={"Executive Report"} dData={dData} checkboxlist={[]}
                                            modal={false} editModal={() => {}} unk={"areatyperesponsechart"} setCheckboxId={() => {}}
                                            checkidkey={""}/>}
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }
    }, [areaTypeResponseChartData, loaded]);

    useEffect(() => {
        if (excessAlarmsData && loaded){
            setExcessAlarmsDsp(
                <div key={"my-excess-display"}>
                    <h4 className={"px-4"}>Yesterday's Alarms Over 20 Minutes</h4>
                    <div className={"pt-1 pb-4 px-4 my-2"}>
                        <table className={"table table-sm table-white table-hover"}>
                            <thead className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString() ? "sticky-table-headX" : "sticky-table-head"}>
                                {<TableHeaders checkbox={false} headerData={excessAlarmsHeaders} sorts={false} sortOrder={{}}
                                               sortColumn={{}} sorter={{}} setSorter={() => {}} />}
                            </thead>
                            <tbody id={"tBody-topareas"}>
                                {<TableBody start={0} end={excessAlarmsData?.length ? excessAlarmsData.length : 0}
                                            tableData={excessAlarmsData?.length > 0 ? excessAlarmsData : []}
                                            checkbox={false} writeAccess={false}
                                            actionAppearance={() => {}} tableHeaders={excessAlarmsHeaders} checkboxId={""}
                                            parentComponent={"Executive Report"} dData={dData} checkboxlist={[]}
                                            modal={false} editModal={() => {}} unk={"excess"} setCheckboxId={() => {}}
                                            checkidkey={""}/>}
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }
    }, [excessAlarmsData, loaded]);

    // useEffect(() => {
    //     if (resData && loaded){
    //         setResDsp(
    //             <div key={"my-res-display"}>
    //                 <h4 className={"px-4"}>Top 10 Resident Review</h4>
    //                 <div className={"pt-1 pb-4 px-4 my-2"}>
    //                     <table className={"table table-sm table-white table-hover"}>
    //                         <thead className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString() ? "sticky-table-headX" : "sticky-table-head"}>
    //                             {<TableHeaders checkbox={false} headerData={resHeaders} sorts={false} sortOrder={{}}
    //                                            sortColumn={{}} sorter={{}} setSorter={() => {}} />}
    //                         </thead>
    //                         <tbody id={"tBody-res"}>
    //                             {<TableBody start={0} end={resData?.length ? resData.length : 0}
    //                                         tableData={resData?.length > 0 ? resData : []}
    //                                         checkbox={false} writeAccess={false}
    //                                         actionAppearance={() => {}} tableHeaders={resHeaders} checkboxId={""}
    //                                         parentComponent={"Executive Report"} dData={dData} checkboxlist={[]}
    //                                         modal={false} editModal={() => {}} unk={"res"} setCheckboxId={() => {}}
    //                                         checkidkey={""}/>}
    //                         </tbody>
    //                     </table>
    //                 </div>
    //             </div>
    //         );
    //     }
    // }, [resData, loaded]);
    //
    // useEffect(() => {
    //     if (careData && loaded){
    //         setCareDsp(
    //             <div key={"my-care-display"}>
    //                 <h4 className={"px-4"}>Top 10 Caregiver Review</h4>
    //                 <div className={"pt-1 pb-4 px-4 my-2"}>
    //                     <table className={"table table-sm table-white table-hover"}>
    //                         <thead className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString() ? "sticky-table-headX" : "sticky-table-head"}>
    //                             {<TableHeaders checkbox={false} headerData={careHeaders} sorts={false} sortOrder={{}}
    //                                            sortColumn={{}} sorter={{}} setSorter={() => {}} />}
    //                         </thead>
    //                         <tbody id={"tBody-care"}>
    //                             {<TableBody start={0} end={careData?.length ? careData.length : 0}
    //                                         tableData={careData?.length > 0 ? careData : []}
    //                                         checkbox={false} writeAccess={false}
    //                                         actionAppearance={() => {}} tableHeaders={careHeaders} checkboxId={""}
    //                                         parentComponent={"Executive Report"} dData={dData} checkboxlist={[]}
    //                                         modal={false} editModal={() => {}} unk={"care"} setCheckboxId={() => {}}
    //                                         checkidkey={""}/>}
    //                         </tbody>
    //                     </table>
    //                 </div>
    //             </div>
    //         );
    //     }
    // }, [careData, loaded]);

    // useEffect(() => {
    //     if (areaTypeDailyChartData !== undefined && areaTypeDailyChartData?.headers?.length > 0 && loaded){
    //         let dataset = [], cnt = 0;
    //         if (!areaTypeDailyChartData?.al?.every(item => item === 0)){
    //             cnt++;
    //             dataset.push({
    //                         type: "bar",
    //                         label: 'AL',
    //                         data: areaTypeDailyChartData?.al ? areaTypeDailyChartData.al : [],
    //                         backgroundColor: ["#0081C7"],
    //                         hoverBackgroundColor: ["#0081C778"],
    //                         yAxisID: "a",
    //                         order: cnt
    //                     });
    //         }
    //         if (!areaTypeDailyChartData?.il?.every(item => item === 0)){
    //             cnt++;
    //             dataset.push({
    //                         type: "bar",
    //                         label: "IL",
    //                         data: areaTypeDailyChartData?.il ? areaTypeDailyChartData.il : [],
    //                         backgroundColor: ["#BE4D25"],
    //                         hoverBackgroundColor: ["#BE4D2578"],
    //                         borderColor: ["#BE4D2578"],
    //                         fill: false,
    //                         yAxisID: "a",
    //                         order: cnt
    //                     });
    //         }
    //         if (!areaTypeDailyChartData?.mc?.every(item => item === 0)){
    //             cnt++;
    //             dataset.push({type: "bar",
    //                         label: "MC",
    //                         data: areaTypeDailyChartData?.mc ? areaTypeDailyChartData.mc : [],
    //                         backgroundColor: ["#2ce598"],
    //                         hoverBackgroundColor: ["#2CE59878"],
    //                         borderColor: ["#2CE59878"],
    //                         fill: false,
    //                         yAxisID: "a",
    //                         order: cnt
    //                     });
    //         }
    //         if (!areaTypeDailyChartData?.snf?.every(item => item === 0)){
    //             cnt++;
    //             dataset.push({type: "bar",
    //                         label: "SNF",
    //                         data: areaTypeDailyChartData?.snf ? areaTypeDailyChartData.snf : [],
    //                         backgroundColor: ["#392CE5"],
    //                         hoverBackgroundColor: ["#392CE578"],
    //                         borderColor: ["#392CE578"],
    //                         fill: false,
    //                         yAxisID: "a",
    //                         order: cnt
    //                     });
    //         }
    //         if (!areaTypeDailyChartData?.common?.every(item => item === 0)){
    //             cnt++;
    //             dataset.push({type: "bar",
    //                         label: "Common",
    //                         data: areaTypeDailyChartData?.common ? areaTypeDailyChartData.common : [],
    //                         backgroundColor: ["#21c4ff"],
    //                         hoverBackgroundColor: ["#21C4FF78"],
    //                         borderColor: ["#21C4FF78"],
    //                         fill: false,
    //                         yAxisID: "a",
    //                         order: cnt
    //                     });
    //         }
    //         if (!areaTypeDailyChartData?.access?.every(item => item === 0)){
    //             cnt++;
    //             dataset.push({type: "bar",
    //                         label: "Access",
    //                         data: areaTypeDailyChartData?.access ? areaTypeDailyChartData.access : [],
    //                         backgroundColor: ["#839fd5"],
    //                         hoverBackgroundColor: ["#839FD578"],
    //                         borderColor: ["#839FD578"],
    //                         fill: false,
    //                         yAxisID: "a",
    //                         order: cnt
    //                     });
    //         }
    //         if (!areaTypeDailyChartData?.other?.every(item => item === 0)){
    //             cnt++;
    //             dataset.push({type: "bar",
    //                         label: "Other",
    //                         data: areaTypeDailyChartData?.other ? areaTypeDailyChartData.other : [],
    //                         backgroundColor: ["#8989FF"],
    //                         hoverBackgroundColor: ["#8989FF78"],
    //                         borderColor: ["#8989FF78"],
    //                         fill: false,
    //                         yAxisID: "a",
    //                         order: cnt
    //                     });
    //         }
    //         if (Chart.getChart("areatypedaily") !== undefined){
    //             const curChart = Chart.getChart("areatypedaily");
    //             curChart.data.datasets = []
    //             for (let i=0; i < dataset.length; i++){
    //                 curChart.data.datasets.push(dataset[i]);
    //             }
    //             curChart.resize();
    //             curChart.update();
    //         }
    //         else{
    //             new Chart('areatypedaily', {
    //                 options: {
    //                     responsive: true,
    //                     plugins: {
    //                         legend: {
    //                             display: true
    //                         },
    //                     },
    //                     scales: {
    //                         x: {
    //                             stacked: true,
    //                             title: {
    //                                 display: true,
    //                                 text: "Date",
    //                             },
    //                             grid: {
    //                                 display: false
    //                             },
    //                         },
    //                         a: {
    //                             id: "a",
    //                             stacked: true,
    //                             position: "left",
    //                             title: {
    //                                 display: true,
    //                                 text: "Total Alarms"
    //                             },
    //                             grid: {
    //                                 display: false
    //                             },
    //                             suggestedMax: 10,
    //                             suggestedMin: 0,
    //                             ticks: {
    //                                 callback: function (value) {
    //                                     return value
    //                                 }
    //                             }
    //                         },
    //                     }
    //                 },
    //                 data: {
    //                     labels: areaTypeDailyChartData?.headers?.length > 0
    //                         ? areaTypeDailyChartData.headers
    //                         : [formatDate(getToday(-7)), formatDate(getToday(-6)),
    //                             formatDate(getToday(-5)), formatDate(getToday(-4)),
    //                             formatDate(getToday(-3)), formatDate(getToday(-2)),
    //                             formatDate(getToday(-1))],
    //                     datasets: dataset
    //                 }
    //             });
    //         }
    //     }
    // }, [areaTypeDailyChartData, loaded]);

    useEffect(() => {
        if (areaTypeResponseChart !== undefined && loaded){
            // ETL DATA HERE
            let bcolor = [], dataset = [], cnt = 0, labels = [], background = [], al = [];
            bcolor = [colorlist[0], colorlist[1], colorlist[2], colorlist[3], colorlist[4]];
            labels = ["0-5", "5-10", "10-20", "20-60", "60+"];
            if (Chart.getChart("areatyperespstack_al") !== undefined){
                // Chart Already Drawn, need to update here
                const curChart = Chart.getChart("areatyperespstack_al");
                curChart.data.labels = labels;
                curChart.data.datasets.data = areaTypeResponseChart?.al ? areaTypeResponseChart.al : [];
                curChart.resize();
                curChart.update();
            }
            else{
                // First time drawing chart
                new Chart('areatyperespstack_al', {
                    type: 'pie',
                    options: {
                        responsive: true,
                        plugins: {
                            title: {
                                display: true,
                                text: 'Assisted Living'
                            },
                            tooltip: {
                                callbacks: {
                                    label: function(tooltipItem){
                                        return ` ${tooltipItem?.label}: ${tooltipItem.formattedValue} Events`
                                    }
                                }
                            },
                            legend: {
                                display: true,
                                position: "left"
                            },

                        }
                    },
                    data: {
                        labels: labels,
                        datasets: [{
                            data: areaTypeResponseChart?.al ? areaTypeResponseChart.al : [],
                            backgroundColor: bcolor
                        }]
                    }
                });
            }
            if (Chart.getChart("areatyperespstack_il") !== undefined){
                // Chart Already Drawn, need to update here
                const curChart = Chart.getChart("areatyperespstack_il");
                curChart.data.labels = labels;
                curChart.data.datasets.data = areaTypeResponseChart?.il ? areaTypeResponseChart.il : [];
                curChart.resize();
                curChart.update();
            }
            else{
                // First time drawing chart
                new Chart('areatyperespstack_il', {
                    type: 'pie',
                    options: {
                        responsive: true,
                        plugins: {
                            title: {
                                display: true,
                                text: 'Independent Living'
                            },
                            tooltip: {
                                callbacks: {
                                    label: function(tooltipItem){
                                        return ` ${tooltipItem?.label}: ${tooltipItem.formattedValue} Events`
                                    }
                                }
                            },
                            legend: {
                                display: true,
                                position: "left"
                            },

                        }
                    },
                    data: {
                        labels: labels,
                        datasets: [{
                            data: areaTypeResponseChart?.il ? areaTypeResponseChart.il : [],
                            backgroundColor: bcolor
                        }]
                    }
                });
            }
            if (Chart.getChart("areatyperespstack_mc") !== undefined){
                // Chart Already Drawn, need to update here
                const curChart = Chart.getChart("areatyperespstack_mc");
                curChart.data.labels = labels;
                curChart.data.datasets.data = areaTypeResponseChart?.mc ? areaTypeResponseChart.mc : [];
                curChart.resize();
                curChart.update();
            }
            else{
                // First time drawing chart
                new Chart('areatyperespstack_mc', {
                    type: 'pie',
                    options: {
                        responsive: true,
                        plugins: {
                            title: {
                                display: true,
                                text: 'Memory Care'
                            },
                            tooltip: {
                                callbacks: {
                                    label: function(tooltipItem){
                                        return ` ${tooltipItem?.label}: ${tooltipItem.formattedValue} Events`
                                    }
                                }
                            },
                            legend: {
                                display: true,
                                position: "left"
                            },

                        }
                    },
                    data: {
                        labels: labels,
                        datasets: [{
                            data: areaTypeResponseChart?.mc ? areaTypeResponseChart.mc : [],
                            backgroundColor: bcolor
                        }]
                    }
                });
            }
            if (Chart.getChart("areatyperespstack_snf") !== undefined){
                // Chart Already Drawn, need to update here
                const curChart = Chart.getChart("areatyperespstack_snf");
                curChart.data.labels = labels;
                curChart.data.datasets.data = areaTypeResponseChart?.snf ? areaTypeResponseChart.snf : [];
                curChart.resize();
                curChart.update();
            }
            else{
                // First time drawing chart
                new Chart('areatyperespstack_snf', {
                    type: 'pie',
                    options: {
                        responsive: true,
                        plugins: {
                            title: {
                                display: true,
                                text: 'Skilled Care'
                            },
                            tooltip: {
                                callbacks: {
                                    label: function(tooltipItem){
                                        return ` ${tooltipItem?.label}: ${tooltipItem.formattedValue} Events`
                                    }
                                }
                            },
                            legend: {
                                display: true,
                                position: "left"
                            },

                        }
                    },
                    data: {
                        labels: labels,
                        datasets: [{
                            data: areaTypeResponseChart?.snf ? areaTypeResponseChart.snf : [],
                            backgroundColor: bcolor
                        }]
                    }
                });
            }
            if (Chart.getChart("areatyperespstack_common") !== undefined){
                // Chart Already Drawn, need to update here
                const curChart = Chart.getChart("areatyperespstack_common");
                curChart.data.labels = labels;
                curChart.data.datasets.data = areaTypeResponseChart?.common ? areaTypeResponseChart.common : [];
                curChart.resize();
                curChart.update();
            }
            else{
                // First time drawing chart
                new Chart('areatyperespstack_common', {
                    type: 'pie',
                    options: {
                        responsive: true,
                        plugins: {
                            title: {
                                display: true,
                                text: 'Common'
                            },
                            tooltip: {
                                callbacks: {
                                    label: function(tooltipItem){
                                        return ` ${tooltipItem?.label}: ${tooltipItem.formattedValue} Events`
                                    }
                                }
                            },
                            legend: {
                                display: true,
                                position: "left"
                            },

                        }
                    },
                    data: {
                        labels: labels,
                        datasets: [{
                            data: areaTypeResponseChart?.common ? areaTypeResponseChart.common : [],
                            backgroundColor: bcolor
                        }]
                    }
                });
            }
            if (Chart.getChart("areatyperespstack_access") !== undefined){
                // Chart Already Drawn, need to update here
                const curChart = Chart.getChart("areatyperespstack_access");
                curChart.data.labels = labels;
                curChart.data.datasets.data = areaTypeResponseChart?.access ? areaTypeResponseChart.access : [];
                curChart.resize();
                curChart.update();
            }
            else{
                // First time drawing chart
                new Chart('areatyperespstack_access', {
                    type: 'pie',
                    options: {
                        responsive: true,
                        plugins: {
                            title: {
                                display: true,
                                text: 'Access'
                            },
                            tooltip: {
                                callbacks: {
                                    label: function(tooltipItem){
                                        return ` ${tooltipItem?.label}: ${tooltipItem.formattedValue} Events`
                                    }
                                }
                            },
                            legend: {
                                display: true,
                                position: "left"
                            },

                        }
                    },
                    data: {
                        labels: labels,
                        datasets: [{
                            data: areaTypeResponseChart?.access ? areaTypeResponseChart.access : [],
                            backgroundColor: bcolor
                        }]
                    }
                });
            }
            if (Chart.getChart("areatyperespstack_other") !== undefined){
                // Chart Already Drawn, need to update here
                const curChart = Chart.getChart("areatyperespstack_other");
                curChart.data.labels = labels;
                curChart.data.datasets.data = areaTypeResponseChart?.other ? areaTypeResponseChart.other : [];
                curChart.resize();
                curChart.update();
            }
            else{
                // First time drawing chart
                new Chart('areatyperespstack_other', {
                    type: 'pie',
                    options: {
                        responsive: true,
                        plugins: {
                            title: {
                                display: true,
                                text: 'Other'
                            },
                            tooltip: {
                                callbacks: {
                                    label: function(tooltipItem){
                                        return ` ${tooltipItem?.label}: ${tooltipItem.formattedValue} Events`
                                    }
                                }
                            },
                            legend: {
                                display: true,
                                position: "left"
                            },

                        }
                    },
                    data: {
                        labels: labels,
                        datasets: [{
                            data: areaTypeResponseChart?.other ? areaTypeResponseChart.other : [],
                            backgroundColor: bcolor
                        }]
                    }
                });
            }
        }
        // if (areaTypeResponseChart !== undefined && loaded){
        //     let dataset = [], cnt = 0;
        //     if (!areaTypeResponseChart?.al?.every(item => item === 0)){
        //         cnt++;
        //         dataset.push({
        //                     type: "bar",
        //                     label: 'AL',
        //                     data: areaTypeResponseChart?.al ? areaTypeResponseChart.al : [],
        //                     backgroundColor: ["#0081C7"],
        //                     hoverBackgroundColor: ["#0081C778"],
        //                     yAxisID: "a",
        //                     order: cnt
        //                 });
        //     }
        //     if (!areaTypeResponseChart?.il?.every(item => item === 0)){
        //         cnt++;
        //         dataset.push({
        //                     type: "bar",
        //                     label: "IL",
        //                     data: areaTypeResponseChart?.il ? areaTypeResponseChart.il : [],
        //                     backgroundColor: ["#BE4D25"],
        //                     hoverBackgroundColor: ["#BE4D2578"],
        //                     borderColor: ["#BE4D2578"],
        //                     fill: false,
        //                     yAxisID: "a",
        //                     order: cnt
        //                 });
        //     }
        //     if (!areaTypeResponseChart?.mc?.every(item => item === 0)){
        //         cnt++;
        //         dataset.push({type: "bar",
        //                     label: "MC",
        //                     data: areaTypeResponseChart?.mc ? areaTypeResponseChart.mc : [],
        //                     backgroundColor: ["#2ce598"],
        //                     hoverBackgroundColor: ["#2CE59878"],
        //                     borderColor: ["#2CE59878"],
        //                     fill: false,
        //                     yAxisID: "a",
        //                     order: cnt
        //                 });
        //     }
        //     if (!areaTypeResponseChart?.snf?.every(item => item === 0)){
        //         cnt++;
        //         dataset.push({type: "bar",
        //                     label: "SNF",
        //                     data: areaTypeResponseChart?.snf ? areaTypeResponseChart.snf : [],
        //                     backgroundColor: ["#392CE5"],
        //                     hoverBackgroundColor: ["#392CE578"],
        //                     borderColor: ["#392CE578"],
        //                     fill: false,
        //                     yAxisID: "a",
        //                     order: cnt
        //                 });
        //     }
        //     if (!areaTypeResponseChart?.common?.every(item => item === 0)){
        //         cnt++;
        //         dataset.push({type: "bar",
        //                     label: "Common",
        //                     data: areaTypeResponseChart?.common ? areaTypeResponseChart.common : [],
        //                     backgroundColor: ["#21c4ff"],
        //                     hoverBackgroundColor: ["#21C4FF78"],
        //                     borderColor: ["#21C4FF78"],
        //                     fill: false,
        //                     yAxisID: "a",
        //                     order: cnt
        //                 });
        //     }
        //     if (!areaTypeDailyChartData?.access?.every(item => item === 0)){
        //         cnt++;
        //         dataset.push({type: "bar",
        //                     label: "Access",
        //                     data: areaTypeDailyChartData?.access ? areaTypeDailyChartData.access : [],
        //                     backgroundColor: ["#839fd5"],
        //                     hoverBackgroundColor: ["#839FD578"],
        //                     borderColor: ["#839FD578"],
        //                     fill: false,
        //                     yAxisID: "a",
        //                     order: cnt
        //                 });
        //     }
        //     if (!areaTypeResponseChart?.other?.every(item => item === 0)){
        //         cnt++;
        //         dataset.push({type: "bar",
        //                     label: "Other",
        //                     data: areaTypeResponseChart?.other ? areaTypeResponseChart.other : [],
        //                     backgroundColor: ["#8989FF"],
        //                     hoverBackgroundColor: ["#8989FF78"],
        //                     borderColor: ["#8989FF78"],
        //                     fill: false,
        //                     yAxisID: "a",
        //                     order: cnt
        //                 });
        //     }
        //     if (Chart.getChart("areatyperespstack") !== undefined){
        //         const curChart = Chart.getChart("areatyperespstack");
        //         curChart.data.datasets = [];
        //         for (let i=0; i < dataset.length; i++){
        //             curChart.data.datasets.push(dataset[i]);
        //         }
        //         curChart.resize();
        //         curChart.update();
        //     }
        //     else{
        //         new Chart('areatyperespstack', {
        //             options: {
        //                 responsive: true,
        //                 plugins: {
        //                     legend: {
        //                         display: true
        //                     },
        //                 },
        //                 scales: {
        //                     x: {
        //                         stacked: true,
        //                         title: {
        //                             display: true,
        //                             text: "Average Response Time (min)",
        //                         },
        //                         grid: {
        //                             display: false
        //                         },
        //                     },
        //                     a: {
        //                         id: "a",
        //                         stacked: true,
        //                         position: "left",
        //                         title: {
        //                             display: true,
        //                             text: "Total Alarms"
        //                         },
        //                         grid: {
        //                             display: false
        //                         },
        //                         suggestedMax: 10,
        //                         suggestedMin: 0,
        //                         ticks: {
        //                             callback: function (value) {
        //                                 return value
        //                             }
        //                         }
        //                     },
        //                 }
        //             },
        //             data: {
        //                 labels: ["0-5", "5-10", "10-20", "20-60", "60+"],
        //                 datasets: dataset
        //             }
        //         });
        //     }
        // }
    }, [areaTypeResponseChart, loaded]);

    useEffect(() => {
        if (reasonData !== undefined && reasonData?.values?.length > 0 && loaded){
            const bcolor = [];
            for (let ix=0; ix <= reasonData?.values?.length; ix++) {
                bcolor.push(colorlist[ix])
            }
            if (Chart.getChart("perreason") !== undefined){
                let curChart3 = Chart.getChart("perreason");
                curChart3.data.datasets[0].data = reasonData?.totals ? reasonData.totals : [];
                curChart3.data.datasets[0].backgroundColor = bcolor;
                curChart3.data.labels = reasonData?.labels ? reasonData.labels : [];
                curChart3.resize();
                curChart3.update();
            }
            else{
                new Chart('perreason', {
                    type: "bar",
                    options: {
                        responsive: true,
                        plugins: {
                            callbacks: {
                                label: function(context) {
                                    return `${context.dataset.value} Events`;
                                }
                            },
                            legend: {
                                display: false
                            },
                        },
                        interaction: {
                            intersect: false,
                            mode: "nearest"
                        },
                        scales: {
                            x: {
                                stacked: false,
                                title: {
                                    display: true,
                                    text: "Reasons",
                                },
                                grid: {
                                    display: false
                                },
                            },
                            a: {
                                id: "a",
                                stacked: true,
                                position: "left",
                                title: {
                                    display: true,
                                    text: "Total Alarms"
                                },
                                grid: {
                                    display: false
                                },
                                suggestedMax: 10,
                                suggestedMin: 0,
                                ticks: {
                                    callback: function (value) {
                                        return value
                                    }
                                }
                            },
                        }
                    },
                    data: {
                        labels: reasonData?.labels ? reasonData.labels : [],
                        datasets: [{
                            label: "",
                            data: reasonData?.totals ? reasonData.totals : [],
                            backgroundColor: bcolor
                        }]
                    }
                });
            }
        }
    }, [reasonData, loaded]);

    useEffect(() => {
        if (status === "init") {
            setReportDisplay(
                <div>
                    <h4 className={"my-1 fs-3 px-4"}>No Report Loaded.</h4>
                </div>
            );
        } else if (status.toLowerCase() === "loading") {
            setReportDisplay(<div>Loading...</div>);
        } else {
            setReportDisplay(
                <div>

                </div>
            )
        }
    }, [status, repType]);

    useEffect(() => {
        let checkVar;
        checkVar = document.getElementById("sendForm");
        if (checkVar) {
            formCheck("sendForm", "downloadSubmit");
        }
    }, [reportDisplay])

    useEffect(() => {
        formCheck("addEmailForm", "addEmailSubmit");
    }, [addNewEmails, addCurEmails]);

    useEffect(() => {
        scheduleFormCheck("scheduleForm", "scheduleSubmit");
    }, [emailEntities]);

    function getReportData() {
        let sDisplay, eDisplay;
        setLoaded(false);
        handleSubmitActions("submitBtn", "loadSubmitBtn");

        // Sets status to loading and clears initial text display.
        setStatus(() => "Loading");
        sDisplay = formatDate(start);
        eDisplay = formatDate(end);
        setStartDisplay(() => sDisplay);
        setEndDisplay(() => eDisplay);

        setStartDatex(formatDateIso(start));
        setEndDatex(formatDateIso(end));
        setReportFilterTermsx(generateFilterTerms());


        // Conditional opportunity for different endpoints and reports.
        ApiRequester({
            reqEndpoint: "get-mysql-report",
            rep_type: "executiveReport",
            username: dData.username,
            campus_id: campusId,
            start_date: formatDateIso(start),
            end_date: formatDateIso(end),
            event_category_filter: "1",
            device_category_filter: devFilterList.join(","),
            area_type_filter: areaTypeFilterList.join(","),
            reason_filter: reasonFilterList.join(","),
            user_filter: userFilterList.join(","),
            event_type_filter: typeFilterList.join(","),
            resident_filter: resFilterList.join(","),
            resident_group_filter: residentGroupFilterList.join(","),
            area_filter: areaFilterList.join(","),
            area_group_filter: areaGroupFilterList.join(","),
            user_group_filter: userGroupFilterList.join(","),
            items: {"weekly_residents": 0, "weekly_caregivers": 0, "excessive_alerts": 1, "daily_areas": 1,
                    "daily_area_types": 0, "reason_data": 1, "area_types_response": 1, "area_types_response_chart": 1}
        }).then(data => {
            handleSubmitActions("loadSubmitBtn", "submitBtn");
            if (data?.ErrorCode === 11) {
                toast.error("Report timed out, report too big, contact your customer success manager for assistance with large reports.");
            } else if (data?.ErrorCode === 10) {
                toast.error("An unknown error was encountered requesting report, please contact customer success if problem persists.");
            } else if (data?.ErrorCode === 12) {
                toast.error("Request unauthorized, service may be unavailable, please contact customer success if problem persists.");
            }
            setTopAreaDailyData(() => data?.daily_areas?.length > 0 ? data.daily_areas : []);
            setExcessAlarmsData(() => data?.excessive_alerts?.length > 0 ? data.excessive_alerts : []);
            // setAreaTypeDailyData(() => data?.daily_area_types?.records?.length > 0 ? data.daily_area_types.records : []);
            // setAreaTypeDailyChartData(() => data?.daily_area_types?.chart ? data.daily_area_types.chart : {});
            setAreaTypeResponseData(() => data?.area_types_response?.length > 0 ? data.area_types_response : []);
            setAreaTypeResponseChartData(() => data?.area_types_response_chart?.records?.length > 0 ? data.area_types_response_chart.records : []);
            setAreaTypeResponseChart(() => data?.area_types_response_chart?.chart ? data.area_types_response_chart.chart : {});
            // setResData(() => data?.weekly_residents?.length > 0 ? data.weekly_residents : []);
            // setCareData(() => data?.weekly_caregivers?.length > 0 ? data.weekly_caregivers : []);
            setReasonData(() => data?.reason_data !== undefined ? data.reason_data : {});
            setCount(() => 600);
            setStatus(() => "");
            setLoaded(true);
        });
    }

    function setListActive(ulItem, liItem){
        let ul, li, liItems;
        ul = document.getElementById(ulItem);
        liItems = ul.getElementsByTagName("li");
        for (let i=0; i < liItems?.length; i++){
            li = liItems[i];
            if (li.id === liItem){
                li.classList.add("active");
                li.setAttribute("aria-current", true);
            }else{
                li.setAttribute("aria-current", false);
                li.classList.remove("active");
            }
        }
    }

    function toggleDateWindow(toggle){
        let dateDiv;
        dateDiv = document.getElementById("dateWindow");
        if (toggle){
            dateDiv.classList.remove("d-none");
            dateDiv.classList.add("d-inline-block");
        }else{
            dateDiv.classList.add("d-none");
            dateDiv.classList.remove("d-inline-block");
        }
    }

    function filterSubmit(){
        // Submit function submitted by the pagination number of items per page modal.
        handleSubmitActions("filterSubmit", "loadFilterSubmit");
        setPerPage(tmpPerPage);
        dData.perPage = tmpPerPage;
        setPage(1);
        ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
        formSuccess("Page parameters stored to session.", "filterSubmit",
            "loadFilterSubmit", "cancelFilterBtn");
    }

    function clearFilters(){
        // Function used in filter modal to clear all toggled checkboxes across all tabs.
        // setAreaSearch("");
        setAreaTypeSearch("");
        setDevSearch("");
        setResSearch("");
        setResidentGroupSearch("");
        setEventTypeSearch("");
        setUserSearch("");
        setUserGroupSearch("");
        setReasonSearch("");
        // setAreaIdList([]);
        // setAreaGroupIdList([]);
        setAreaTypeIdList([]);
        setDevIdList([]);
        setResIdList([]);
        setResidentGroupIdList([]);
        setEventTypeIdList([]);
        setUserIdList([]);
        setUserGroupIdList([]);
        setReasonIdList([]);
        document.getElementById("uCheckHead2").checked = false;
        document.getElementById("uCheckHead3").checked = false;
        document.getElementById("uCheckHead4").checked = false;
        document.getElementById("uCheckHead5").checked = false;
        document.getElementById("uCheckHead6").checked = false;
        document.getElementById("uCheckHead7").checked = false;
        document.getElementById("uCheckHead8").checked = false;
        // document.getElementById("uCheckHead9").checked = false;
        document.getElementById("uCheckHead10").checked = false;
        document.getElementById("uCheckHead11").checked = false;
    }

    function showFilterModal(){
        // Function that preps and populates the filter modal with the checks when called based on stored session.
        // setAreaSearch("");
        // setAreaIdList(areaFilterList);
        // document.getElementById("uCheckHead8").checked = areaFilterList.length === areaOriginData.length;
        // setAreaGroupSearch("");
        // setAreaGroupIdList(areaGroupFilterList);
        // document.getElementById("uCheckHead9").checked = areaGroupFilterList.length === areaGroupOriginData.length;
        setAreaTypeSearch("");
        setAreaTypeIdList(areaTypeFilterList);
        document.getElementById("uCheckHead2").checked = areaTypeFilterList.length === areaTypeOriginData.length;
        setDevSearch("");
        setDevIdList(devFilterList);
        document.getElementById("uCheckHead3").checked = devFilterList.length === devOriginData.length;
        setResSearch("");
        setResIdList(resFilterList);
        document.getElementById("uCheckHead4").checked = resFilterList.length === resOriginData.length;
        setResidentGroupSearch("");
        setResidentGroupIdList(residentGroupFilterList);
        document.getElementById("uCheckHead10").checked = residentGroupFilterList.length === residentGroupOriginData.length;
        setEventTypeSearch("");
        setEventTypeIdList(typeFilterList);
        document.getElementById("uCheckHead5").checked = typeFilterList.length === eventTypeOriginData.length;
        setUserSearch("");
        setUserIdList(userFilterList);
        document.getElementById("uCheckHead6").checked = userFilterList.length === userOriginData.length;
        setUserGroupSearch("");
        setUserGroupIdList(userGroupFilterList);
        document.getElementById("uCheckHead11").checked = userGroupFilterList.length === userGroupOriginData.length;
        setReasonSearch("");
        setReasonIdList(reasonFilterList);
        document.getElementById("uCheckHead7").checked = reasonFilterList.length === reasonOriginData.length;
        showModal("filtermodal2");
    }

    function filterSubmit2(){
        // Function used to set filters to vars on submit from filter modal.
        let areaTypeLabels = [], devLabels = [], resLabels = [], typeLabels = [], userLabels = [], reasonLabels = [],
            areaLabels = [], areaGroupLabels = [], residentGroupLabels = [], userGroupLabels = [];
        handleSubmitActions("filterSubmit2", "loadFilterSubmit2");
        // for (let i=0; i < areaOriginData?.length; i++){
        //     if (areaIdList.includes(areaOriginData[i]?.area_id?.toString())){
        //         areaLabels.push(areaOriginData[i]?.area_name);
        //     }
        // }
        // for (let i=0; i < areaGroupOriginData?.length; i++){
        //     if (areaGroupIdList.includes(areaGroupOriginData[i]?.area_group_id?.toString())){
        //         areaGroupLabels.push(areaGroupOriginData[i]?.area_group_name);
        //     }
        // }
        for (let i=0; i < areaTypeOriginData?.length; i++){
            if (areaTypeIdList.includes(areaTypeOriginData[i]?.area_type_id?.toString())){
                areaTypeLabels.push(areaTypeOriginData[i]?.area_type_name);
            }
        }
        for (let i=0; i < devOriginData?.length; i++){
            if (devIdList.includes(devOriginData[i]?.device_category_id?.toString())){
                devLabels.push(devOriginData[i]?.device_category_short_name);
            }
        }
        for (let i=0; i < resOriginData?.length; i++){
            if (resIdList.includes(resOriginData[i]?.resident_id?.toString())){
                resLabels.push(`${resOriginData[i]?.resident_first_name} ${resOriginData[i]?.resident_last_name}`);
            }
        }
        for (let i=0; i < residentGroupOriginData?.length; i++){
            if (resIdList.includes(residentGroupOriginData[i]?.resident_group_id?.toString())){
                residentGroupLabels.push(`${residentGroupOriginData[i]?.resident_group_name}`);
            }
        }
        for (let i=0; i < eventTypeOriginData?.length; i++){
            if (eventTypeIdList.includes(eventTypeOriginData[i]?.event_type_id?.toString())){
                typeLabels.push(eventTypeOriginData[i]?.event_type_name);
            }
        }
        for (let i=0; i < userOriginData?.length; i++){
            if (userIdList.includes(userOriginData[i]?.user_id?.toString())){
                userLabels.push(userOriginData[i]?.username);
            }
        }
        for (let i=0; i < userGroupOriginData?.length; i++){
            if (userGroupIdList.includes(userGroupOriginData[i]?.group_id?.toString())){
                userGroupLabels.push(userGroupOriginData[i]?.group_name);
            }
        }
        for (let i=0; i < reasonOriginData?.length; i++){
            if (reasonIdList.includes(reasonOriginData[i]?.reason_code_id?.toString())){
                reasonLabels.push(reasonOriginData[i]?.reason_code_description);
            }
        }
        // setAreaFilterList(areaIdList);
        setAreaFilterLabels(areaLabels);
        // setAreaGroupFilterList(areaGroupIdList);
        setAreaGroupFilterLabels(areaGroupLabels);
        setAreaTypeFilterList(areaTypeIdList);
        setAreaTypeFilterLabels(areaTypeLabels);
        setDevFilterList(devIdList);
        setDevFilterLabels(devLabels);
        setResFilterList(resIdList);
        setResFilterLabels(resLabels);
        setResidentGroupFilterList(residentGroupIdList);
        setResidentGroupFilterLabels(residentGroupLabels);
        setTypeFilterList(eventTypeIdList);
        setTypeFilterLabels(typeLabels);
        setUserFilterList(userIdList);
        setUserFilterLabels(userLabels);
        setUserGroupFilterList(userGroupIdList);
        setUserGroupFilterLabels(userGroupLabels);
        setReasonFilterList(reasonIdList);
        setReasonFilterLabels(reasonLabels);
        dData.devFilter = devIdList;
        dData.devFilterLabel = devLabels;
        // dData.areaFilter = areaIdList;
        dData.areaFilterLabel = areaLabels
        // dData.areaGroupFilter = areaGroupIdList;
        dData.areaGroupFilterLabel = areaGroupLabels;
        dData.areaTypeFilter = areaTypeIdList;
        dData.areaTypeFilterLabel = areaTypeLabels;
        dData.resFilter = resIdList;
        dData.resFilterLabel = resLabels;
        dData.residentGroupFilter = residentGroupIdList;
        dData.residentGroupFilterLabel = residentGroupLabels;
        dData.typeFilter = eventTypeIdList;
        dData.typeFilterLabel = typeLabels;
        dData.reasonFilter = reasonIdList;
        dData.reasonFilterLabel = reasonLabels;
        dData.userFilter = userIdList;
        dData.userFilterLabel = userLabels;
        dData.userGroupFilter = userGroupIdList;
        dData.userGroupFilterLabel = userGroupLabels;
        ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
        formSuccess("Filters saved", "filterSubmit2", "loadFilterSubmit2", "cancelFilterBtn2");
        if (status !== "init"){
            setCount(() => 0);
        }
    }

    function complexDelimDisplay(originText, newText){
        // Function used to build filter display inside the schedule modal pop up.
        // This function is generic takes lists recursively and builds appending comma delim strings.
        let ret;
        if (originText){
            if (newText){
                ret = `${originText}, ${newText}`;
            }else{
                ret = originText;
            }
        }else {
            ret = newText;
        }
        return ret;
    }

    function showScheduleModal(){
        // Function that preps and displays the pop up of the schedule modal.
        let filterText;
        document.getElementById("scheduleForm").reset();
        filterText = resFilterLabels.join(", ");
        filterText = complexDelimDisplay(filterText, areaFilterLabels.join(", "));
        filterText = complexDelimDisplay(filterText, areaGroupFilterLabels.join(", "));
        filterText = complexDelimDisplay(filterText, areaTypeFilterLabels.join(", "));
        filterText = complexDelimDisplay(filterText, residentGroupFilterLabels.join(", "));
        filterText = complexDelimDisplay(filterText, devFilterLabels.join(", "));
        filterText = complexDelimDisplay(filterText, typeFilterLabels.join(", "));
        filterText = complexDelimDisplay(filterText, userFilterLabels.join(", "));
        filterText = complexDelimDisplay(filterText, userGroupFilterLabels.join(", "));
        filterText = complexDelimDisplay(filterText, reasonFilterLabels.join(", "));
        setEmailEntities(() => []);
        document.getElementById("filter-display").innerText = filterText?.length > 0 ? filterText : "None";
        formCheck("scheduleForm", "scheduleSubmit");
        showModal("schedulemodal");
    }

    function generateFilterTerms(){
        return {
            device_category_filter: {values: devFilterList.join(","), labels: devFilterLabels.join(",")},
            area_filter: {values: areaFilterList.join(","), labels: areaFilterLabels.join(",")},
            area_group_filter: {values: areaGroupFilterList.join(","), labels: areaGroupFilterLabels.join(",")},
            area_type_filter: {values: areaTypeFilterList.join(","), labels: areaTypeFilterLabels.join(",")},
            resident_filter: {values: resFilterList.join(","), labels: resFilterLabels.join(",")},
            resident_group_filter: {values: residentGroupFilterList.join(","), labels: residentGroupFilterLabels.join(",")},
            event_type_filter: {values: typeFilterList.join(","), labels: typeFilterLabels.join(",")},
            reason_filter: {values: reasonFilterList.join(","), labels: reasonFilterLabels.join(",")},
            user_filter: {values: userFilterList.join(","), labels: userFilterLabels.join(",")},
            user_group_filter: {values: userGroupFilterList.join(","), labels: userGroupFilterLabels.join(",")}
        }
    }

    function scheduleSubmit(){
        // Function called when submitting the scheduled report from schedule modal pop up.
        let payload, filterTerms, frequencyId, freqContainer, freqRate = [], nextSendTime, sendTime, pdf;
        handleSubmitActions("scheduleSubmit", "loadScheduleSubmit");
        filterTerms = generateFilterTerms()
        frequencyId = parseInt(document.getElementById("sch-frequency").value);
        if (frequencyId === 1){
            freqRate.push(1);
        }else if (frequencyId === 2){
            freqContainer = document.getElementsByName("sch-weekly-meta");
            for (let i=0; i < freqContainer?.length; i++){
                if (freqContainer[i].checked){
                    freqRate.push(freqContainer[i].value);
                }
            }
        }else if (frequencyId === 3){
            freqContainer = document.getElementsByName("sch-monthly-meta");
            for (let i=0; i < freqContainer?.length; i++){
                if (freqContainer[i].checked){
                    freqRate.push(freqContainer[i].value);
                }
            }
        }
        sendTime = document.getElementById("sch-sendTime").value;
        nextSendTime = calculateSendTime(frequencyId, freqRate, sendTime, parseInt(dData?.campusData[campusId]?.tz_offset ? dData?.campusData[campusId]?.tz_offset : 0));
        pdf = document.getElementById("add-pdf")?.checked;
        payload = [{
            campus_id: campusId,
            description: document.getElementById("sch-description").value,
            issue_freq_id: frequencyId,
            window_frequency_reference: freqRate.join(","),
            send_report_time: convertHHMMtoSec(sendTime),
            enabled: 1,
            report_type_id: 5,
            report_interval_id: rangeId,
            report_filter_terms: filterTerms,
            report_receiver: emailEntities.join(","),
            next_send_time: nextSendTime,
            pdf_summary: pdf ? 1 : 0,
            csv_details: 0
        }];
        ApiRequester({reqEndpoint: "mytransaction", TransType: "schedule_add", Payload: payload,
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
            if (data[0]) {
                formSuccess("Report schedule saved", "scheduleSubmit", "loadScheduleSubmit", "scheduleCancelBtn");
            } else {
                toast.error("Failed to schedule report.", "scheduleSubmit", "loadScheduleSubmit");
            }
        });
    }

    // The following section handles the email modal for schedule modal.

    function showEmailAddModal(output){
        // This function is called from the add modal when clicking on text or voice calling to set a list of phone
        // numbers to be saved with record add. This function triggers the modal to populate and builds the entity
        // list of phone numbers to add to the system.
        let tmp = [];
        for (let i=0; i < emailEntities?.length; i++){
            tmp.push(
                {id: uuidv4(), email: emailEntities[i]}
            );
        }
        setAddNewEmails(() => []);
        setAddCurEmails(() => tmp);
        swapModal("scheduleCancelBtn", "addemailmodal");
    }

    function setAddEmailItems(){
        // This function is triggered from the phone number add modal save which takes the current phone numbers
        // display items and then new numbers display items and sets those values into their appropriate output
        // entity holder text or voice.
        let tmp = [];
        handleSubmitActions("addEmailSubmit", "loadAddEmailSubmit");
        for (let i=0; i < addCurEmails?.length; i++){
            tmp.push(addCurEmails[i].email);
        }
        for (let i2=0; i2 < addNewEmails?.length; i2++){
            tmp.push(addNewEmails[i2].email);
        }
        setEmailEntities(() => tmp);
        document.getElementById("cancelAddEmailBtn").click();
        handleSubmitActions("loadAddEmailSubmit", "addEmailSubmit");
    }

    function scheduleFormCheck(formId, submitId){
        formCheck(formId, submitId);
        if (emailEntities?.length <= 0){
            let btn = document.getElementById(submitId)
            btn.classList.add("btn-danger");
            btn.classList.remove("btn-primary");
            btn.disabled = true;
        }
    }

    function sendReport(){
        let payload, description;
        handleSubmitActions("downloadSubmit", "loadDownloadSubmit");
        document.getElementById("sendMsg").classList.remove("d-none");
        description = `Event Report`
        payload = [{
            campus_id: campusId,
            description: description,
            source: "portal",
            report_id: 999,
            report_filter_terms: JSON.stringify(reportFilterTermsx),
            report_interval_id: 0,
            startDate: startDatex,
            endDate: endDatex,
            report_receiver: document.getElementById("emailx").value,
            report_type_id: 1,
            last_sent: ""
        }]
        ApiRequester({reqEndpoint: "send_report", Payload: payload, CampusId: campusId,
                            ClientId: dData.sessionId, nowait: true}).then(data => {
            if (data[0]){
                formSuccess(`Report request successful, larger reports may take several minutes.`,
                    "downloadSubmit", "loadDownloadSubmit");
            }
            else{
                formFail("Failed to send report, contact support if failure continues.",
                    "downloadSubmit", "loadDownloadSubmit");
            }
        });
    }

    function eventFiltersOn(){
        return [typeFilterList, reasonFilterList].some(filterArray => filterArray.length > 0);
    }

    function areaFiltersOn(){
        return [areaFilterList, areaGroupFilterList, areaTypeFilterList].some(filterArray => filterArray.length > 0);
    }

    function resFiltersOn(){
        return [resFilterList, residentGroupFilterList].some(filterArray => filterArray.length > 0);
    }

    function userFiltersOn(){
        return [userFilterList, userGroupIdList].some(filterArray => filterArray.length > 0);
    }

    function deviceFiltersOn(){
        return [devFilterList].some(filterArray => filterArray.length > 0);
    }

    function filtersOn(){
        return [areaTypeFilterList, resFilterList, residentGroupFilterList, userFilterList, userGroupIdList,
                devFilterList, typeFilterList, reasonFilterList].some(filterArray => filterArray.length > 0);
    }

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div>
                {scheduleable && <Button text={"Schedule"} style={{height: "46px"}} class={"btn btn-primary me-4 mb-2"}
                                         iconClass={"material-icons md-18 md-dark me-2 align-text-bottom"} icon={true}
                                         iconSize={"tiny"} iconColor={"#FFFFFF"} iconType={"google"}
                                         iconName={"edit_calendar"} onClick={() => showScheduleModal()} />}
                <button type={"button"} className={"btn btn-secondary mx-2 mb-2"} style={{height: "46.5px"}}
                        onClick={() => {
                            showFilterModal();
                        }}>
                    <i className={"fe fe-filter fs-3"}/>
                    {filtersOn() && <div className={"filter-caret"}></div>}
                </button>
                <Button id={"loadSubmitBtn"} text={""}
                        class={"btn px-4 pt-2 btn-toolbar btn-primary d-inline-block fs-3 mb-2 mx-2 d-none"}
                        spinner={true}/>
                <Button text={"Run"} icon={true} iconClass={"fe fe-refresh-cw pe-2"} id={"submitBtn"}
                        class={"btn px-3 btn-toolbar btn-primary d-inline-block fs-3 mb-2 mx-2"}
                        onClick={() => {getReportData()}}/>
            </div>
            <CampusDropDown campusId={campusId} setCount={(item) => {
                setCount(item);
                setFilterCount(item);
            }} setCampusId={(item) => setCampusId(item)} pageAction={() => {window.location.reload(true)}}/>
        </div>
    );

    // const areaTableHeaders = <TableHeaders checkbox={true} headerData={areaHeaders} checkboxlist={areaIdList}
    //                                            checkAll={() => checkAll(0, areaDataCnt ? areaDataCnt : 0, areaIdList, setAreaIdList, "uCheckHead8", "uCheckArea")}
    //                                            writeAccess={true} sortOrder={areaSorter.sortOrder} checkboxHeaderId={"uCheckHead8"}
    //                                            sortColumn={areaSorter.sortColumn} sorter={areaSorter} setSorter={setAreaSorter}
    //                                            actionAppearance={() => {}} />
    // const areaGroupTableHeaders = <TableHeaders checkbox={true} headerData={areaGroupHeaders} checkboxlist={areaGroupIdList}
    //                                            checkAll={() => checkAll(0, areaGroupDataCnt ? areaGroupDataCnt : 0, areaGroupIdList, setAreaGroupIdList, "uCheckHead9", "uCheckAreaGroup")}
    //                                            writeAccess={true} sortOrder={areaGroupSorter.sortOrder} checkboxHeaderId={"uCheckHead9"}
    //                                            sortColumn={areaGroupSorter.sortColumn} sorter={areaGroupSorter} setSorter={setAreaGroupSorter}
    //                                            actionAppearance={() => {}} />
    const areaTypeTableHeaders = <TableHeaders checkbox={true} headerData={areaTypeHeaders} checkboxlist={areaTypeIdList}
                                               checkAll={() => checkAll(0, areaTypeDataCnt ? areaTypeDataCnt : 0, areaTypeIdList, setAreaTypeIdList, "uCheckHead2", "uCheckAreaType")}
                                               writeAccess={true} sortOrder={areaTypeSorter.sortOrder} checkboxHeaderId={"uCheckHead2"}
                                               sortColumn={areaTypeSorter.sortColumn} sorter={areaTypeSorter} setSorter={setAreaTypeSorter}
                                               actionAppearance={() => {}} />
    const deviceTableHeaders = <TableHeaders checkbox={true} headerData={deviceHeaders} checkboxlist={devIdList}
                                             checkAll={() => checkAll(0, devDataCnt ? devDataCnt : 0, devIdList, setDevIdList, "uCheckHead3", "uCheckDev")}
                                             writeAccess={true} sortOrder={devSorter.sortOrder} checkboxHeaderId={"uCheckHead3"}
                                             sortColumn={devSorter.sortColumn} sorter={devSorter} setSorter={setDevSorter}
                                             actionAppearance={() => {}} />
    const residentTableHeaders = <TableHeaders checkbox={true} headerData={residentHeaders} checkboxlist={resIdList}
                                             checkAll={() => checkAll(0, resDataCnt ? resDataCnt : 0, resIdList, setResIdList, "uCheckHead4", "uCheckRes")}
                                             writeAccess={true} sortOrder={resSorter.sortOrder} checkboxHeaderId={"uCheckHead4"}
                                             sortColumn={resSorter.sortColumn} sorter={resSorter} setSorter={setResSorter}
                                             actionAppearance={() => {}} />
    const residentGroupTableHeaders = <TableHeaders checkbox={true} headerData={residentGroupHeaders} checkboxlist={residentGroupIdList}
                                             checkAll={() => checkAll(0, residentGroupDataCnt ? residentGroupDataCnt : 0, residentGroupIdList, setResidentGroupIdList, "uCheckHead10", "uCheckResidentGroup")}
                                             writeAccess={true} sortOrder={residentGroupSorter.sortOrder} checkboxHeaderId={"uCheckHead10"}
                                             sortColumn={residentGroupSorter.sortColumn} sorter={residentGroupSorter} setSorter={setResidentGroupSorter}
                                             actionAppearance={() => {}} />
    const eventTypeTableHeaders = <TableHeaders checkbox={true} headerData={eventTypeHeaders} checkboxlist={eventTypeIdList}
                                             checkAll={() => checkAll(0, eventTypeDataCnt ? eventTypeDataCnt : 0, eventTypeIdList, setEventTypeIdList, "uCheckHead5", "uCheckType")}
                                             writeAccess={true} sortOrder={eventTypeSorter.sortOrder} checkboxHeaderId={"uCheckHead5"}
                                             sortColumn={eventTypeSorter.sortColumn} sorter={eventTypeSorter} setSorter={setEventTypeSorter}
                                             actionAppearance={() => {}} />
    const userTableHeaders = <TableHeaders checkbox={true} headerData={userHeaders} checkboxlist={userIdList}
                                             checkAll={() => checkAll(0, userDataCnt ? userDataCnt : 0, userIdList, setUserIdList, "uCheckHead6", "uCheckUser")}
                                             writeAccess={true} sortOrder={userSorter.sortOrder} checkboxHeaderId={"uCheckHead6"}
                                             sortColumn={userSorter.sortColumn} sorter={userSorter} setSorter={setUserSorter}
                                             actionAppearance={() => {}} />
    const userGroupTableHeaders = <TableHeaders checkbox={true} headerData={userGroupHeaders} checkboxlist={userGroupIdList}
                                                checkAll={() => checkAll(0, userGroupDataCnt ? userGroupDataCnt : 0, userGroupIdList, setUserGroupIdList, "uCheckHead11", "uCheckUserGroup")}
                                                writeAccess={true} sortOrder={userGroupSorter.sortOrder} checkboxHeaderId={"uCheckHead11"}
                                                sortColumn={userGroupSorter.sortColumn} sorter={userGroupSorter} setSorter={setUserGroupSorter}
                                                actionAppearance={() => {}} />
    const reasonTableHeaders = <TableHeaders checkbox={true} headerData={reasonHeaders} checkboxlist={reasonIdList}
                                             checkAll={() => checkAll(0, reasonDataCnt ? reasonDataCnt : 0, reasonIdList, setReasonIdList, "uCheckHead7", "uCheckReason")}
                                             writeAccess={true} sortOrder={reasonSorter.sortOrder} checkboxHeaderId={"uCheckHead7"}
                                             sortColumn={reasonSorter.sortColumn} sorter={reasonSorter} setSorter={setReasonSorter}
                                             actionAppearance={() => {}} />

    const copyright = (
        <h6 className={"fs-5 pt-2"}>
            Copyright &copy; {getCurrentYear()}
        </h6>
    );
    const version = (
        <h6 className={"fs-5 pt-2"}>
            {process.env.REACT_APP_VERSION}
        </h6>
    );

    return (
        <div className={"main-content"}>
            <Header preTitle={"Executive Report"} Title={"Executive Daily"} content={headerContent}/>
            <div className={"container-fluid mt-4"}>
                <div className={"row"}>
                    {excessAlarmsDsp}
                </div>
                {/*<div className={"row d-none"}>*/}
                {/*    {areaTypeDailyChartData?.headers?.length > 0 && loaded*/}
                {/*        ?   <h4 className={"px-4"}>Alarms by Date & Care Settings Charted</h4>*/}
                {/*        :   <></>*/}
                {/*    }*/}
                {/*    <div className={"chart-container"}>*/}
                {/*        <canvas className={"chart-canvas"} id={"areatypedaily"}*/}
                {/*                style={{maxHeight: "400px", maxWidth: "inherit", minWidth: "600px"}}></canvas>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className={"row d-none"}>*/}
                {/*    {areaTypeDsp}*/}
                {/*</div>*/}
                <div className={"row"} style={{width: "calc(100%)"}}>
                    <div className={"px-4"}>
                        {reasonData !== undefined && loaded
                            ?   <h4 className={"px-3"}>Total Alarms by Reason</h4>
                            :   <></>
                        }
                        <div className={"ms-3 card"}>
                            <div className={"chart-container"}>
                                {reasonData !== undefined && reasonData?.values?.length > 0 && loaded
                                    ? <></> : <h4 className={"mt-4 mb-5 ms-5"}>Reasons not being used</h4>}
                                <canvas
                                    className={`chart-canvas ${reasonData !== undefined && reasonData?.values?.length > 0 && loaded ? "d-flex" : "d-none"}`}
                                    id={"perreason"}
                                    style={{maxHeight: "400px", maxWidth: "inherit", minWidth: "600px"}}></canvas>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div>
                        {areaTypeResponseDsp}
                    </div>
                </div>
                <div className={"row"}>
                    {areaTypeResponseChart !== undefined && loaded
                        ? <>
                            <h4 className={"px-4 ms-1"}>
                                Alarms by Response Time & Care Setting Charted
                                <MaterialIcon size={"small"}
                                              className={"ms-2 as-btn collapse-toggle material-icons align-middle"}
                                              icon={"table_view"} data-bs-toggle={"collapse"} title={"Show/hide chart table data"}
                                              data-bs-target={"#area_type_response_chart_display"}/>
                            </h4>
                        </>
                        : <></>
                    }
                    <div className={"d-inline-block px-4"}>
                        <div className={`card px-3 mx-1 d-inline-block ${!areaTypeResponseChart?.al?.every(item => item === 0) ? "" : "d-none"}`}
                             style={{maxWidth: "350px"}}>
                            <div className={`chart-container`}>
                                <canvas className={"chart-canvas"} id={"areatyperespstack_al"}
                                        style={{maxHeight: "400px", maxWidth: "inherit"}}></canvas>
                            </div>
                        </div>
                        <div className={`card px-3 mx-1 d-inline-block ${!areaTypeResponseChart?.il?.every(item => item === 0) ? "" : "d-none"}`}
                             style={{maxWidth: "350px"}}>
                            <div className={`chart-container`}>
                                <canvas className={"chart-canvas"} id={"areatyperespstack_il"}
                                        style={{maxHeight: "400px", maxWidth: "inherit"}}></canvas>
                            </div>
                        </div>
                        <div className={`card px-3 mx-1 d-inline-block ${!areaTypeResponseChart?.mc?.every(item => item === 0) ? "" : "d-none"}`}
                             style={{maxWidth: "350px"}}>
                            <div className={`chart-container`}>
                                <canvas className={"chart-canvas"} id={"areatyperespstack_mc"}
                                        style={{maxHeight: "400px", maxWidth: "inherit"}}></canvas>
                            </div>
                        </div>
                        <div className={`card px-3 mx-1 d-inline-block ${!areaTypeResponseChart?.snf?.every(item => item === 0) ? "" : "d-none"}`}
                             style={{maxWidth: "350px"}}>
                            <div className={`chart-container`}>
                                <canvas className={"chart-canvas"} id={"areatyperespstack_snf"}
                                        style={{maxHeight: "400px", maxWidth: "inherit"}}></canvas>
                            </div>
                        </div>
                        <div className={`card px-3 mx-1 d-inline-block ${!areaTypeResponseChart?.common?.every(item => item === 0) ? "" : "d-none"}`}
                             style={{maxWidth: "350px"}}>
                            <div className={`chart-container`}>
                                <canvas className={"chart-canvas"} id={"areatyperespstack_common"}
                                        style={{maxHeight: "400px", maxWidth: "inherit"}}></canvas>
                            </div>
                        </div>
                        <div className={`card px-3 mx-1 d-inline-block ${!areaTypeResponseChart?.access?.every(item => item === 0) ? "" : "d-none"}`}
                             style={{maxWidth: "350px"}}>
                            <div className={`chart-container`}>
                                <canvas className={"chart-canvas"} id={"areatyperespstack_access"}
                                        style={{maxHeight: "400px", maxWidth: "inherit"}}></canvas>
                            </div>
                        </div>
                        <div className={`card px-3 mx-1 d-inline-block ${!areaTypeResponseChart?.other?.every(item => item === 0) ? "" : "d-none"}`}
                             style={{maxWidth: "350px"}}>
                            <div className={`chart-container`}>
                                <canvas className={"chart-canvas"} id={"areatyperespstack_other"}
                                        style={{maxHeight: "400px", maxWidth: "inherit"}}></canvas>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"row collapse"} id={"area_type_response_chart_display"}>
                    {areaTypeResponseChartDsp}
                </div>
                <div className={"row"}>
                    {areaDailyDsp}
                </div>
                {/*<div className={"row d-none"}>*/}
                {/*    <div className={"d-flex justify-content-start"}>*/}
                {/*        <div className={"w-50"}>*/}
                {/*            {resDsp}*/}
                {/*        </div>*/}
                {/*        <div className={"w-50"}>*/}
                {/*            {careDsp}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className={"footer-spacer"}>

                </div>
            </div>
            <div className={"modal fade"} id={"filtermodal2"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Filter Settings</h2>
                            <Button text={"Clear All"} class={"btn ms-3 pb-0 hover-text-white"} onClick={() => clearFilters()} />
                            <button type={"button"} className={"btn-close"} id={"filterClose2"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"filterForm2"}>
                            <div className={"modal-body"}>
                                <div className={"row align-items-center"} style={{borderBottom: "dotted 1px #b7b7b7"}}>
                                    <div className={"col"}>
                                        <ul className={"nav nav-tabs nav-overflow header-tabs mb-0"}>
                                            <li className={"mx-1 my-2 d-flex"}>
                                                <Button text={"Areas"} class={tabCat === "areas" ? "btn btn-toggle btn-active-primary" : "btn btn-toggle"}
                                                        style={{width: "95px"}}
                                                        onClick={() => {
                                                            setTabCat("areas");
                                                            setTabItem("areaType");
                                                        }} />
                                                {areaFiltersOn() && <div className={"filter-caret"}></div>}
                                            </li>
                                            <li className={"mx-1 my-2 d-flex"}>
                                                <Button text={"Residents"} class={tabCat === "residents" ? "btn btn-toggle btn-active-primary" : "btn btn-toggle"}
                                                        style={{width: "95px"}}
                                                        onClick={() => {
                                                            setTabCat("residents");
                                                            setTabItem("resident");
                                                        }} />
                                                {resFiltersOn() && <div className={"filter-caret"}></div>}
                                            </li>
                                            <li className={"mx-1 my-2 d-flex"}>
                                                <Button text={"Users"} class={tabCat === "users" ? "btn btn-toggle btn-active-primary" : "btn btn-toggle"}
                                                        style={{width: "95px"}}
                                                        onClick={() => {
                                                            setTabCat("users");
                                                            setTabItem("user");
                                                        }} />
                                                {userFiltersOn() && <div className={"filter-caret"}></div>}
                                            </li>
                                            <li className={"mx-1 my-2 d-flex"}>
                                                <Button text={"Devices"} class={tabCat === "devices" ? "btn btn-toggle btn-active-primary" : "btn btn-toggle"}
                                                        style={{width: "95px"}}
                                                        onClick={() => {
                                                            setTabCat("devices");
                                                            setTabItem("device");
                                                        }} />
                                                {deviceFiltersOn() && <div className={"filter-caret"}></div>}
                                            </li>
                                            <li className={"mx-1 my-2 d-flex"}>
                                                <Button text={"Events"} class={tabCat === "events" ? "btn btn-toggle btn-active-primary" : "btn btn-toggle"}
                                                        style={{width: "95px"}}
                                                        onClick={() => {
                                                            setTabCat("events");
                                                            setTabItem("event");
                                                        }} />
                                                {eventFiltersOn() && <div className={"filter-caret"}></div>}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={"mt-2"}>
                                    <div>
                                        {tabCat === "areas"
                                            ?   <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                                                    {/*<li className={"nav-item"}>*/}
                                                    {/*    <Button text={"Area"} class={tabItem === "area" ? "nav-link active" : "nav-link"}*/}
                                                    {/*            onClick={() => setTabItem("area")} />*/}
                                                    {/*</li>*/}
                                                    {/*<li className={"nav-item"}>*/}
                                                    {/*    <Button text={"Area Groups"} class={tabItem === "areaGroup" ? "nav-link active" : "nav-link"}*/}
                                                    {/*            onClick={() => setTabItem("areaGroup")} />*/}
                                                    {/*</li>*/}
                                                    <li className={"nav-item"}>
                                                        <Button text={"Area Types"} class={tabItem === "areaType" ? "nav-link active" : "nav-link"}
                                                                onClick={() => setTabItem("areaType")} />
                                                    </li>
                                                </ul>
                                            :   <></>
                                        }
                                        {tabCat === "residents"
                                            ?   <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                                                    <li className={"nav-item"}>
                                                        <Button text={"Resident"} class={tabItem === "resident" ? "nav-link active" : "nav-link"}
                                                                onClick={() => setTabItem("resident")} />
                                                    </li>
                                                    <li className={"nav-item"}>
                                                        <Button text={"Resident Groups"} class={tabItem === "residentGroup" ? "nav-link active" : "nav-link"}
                                                                onClick={() => setTabItem("residentGroup")} />
                                                    </li>
                                                </ul>
                                            :   <></>
                                        }
                                        {tabCat === "users"
                                            ?   <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                                                    <li className={"nav-item"}>
                                                        <Button text={"User"} class={tabItem === "user" ? "nav-link active" : "nav-link"}
                                                                onClick={() => setTabItem("user")} />
                                                    </li>
                                                    <li className={"nav-item"}>
                                                        <Button text={"User Groups"} class={tabItem === "userGroup" ? "nav-link active" : "nav-link"}
                                                                onClick={() => setTabItem("userGroup")} />
                                                    </li>
                                                </ul>
                                            :   <></>
                                        }
                                        {tabCat === "devices"
                                            ?   <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                                                    <li className={"nav-item"}>
                                                        <Button text={"Device Categories"} class={tabItem === "deviceCat" ? "nav-link active" : "nav-link"}
                                                                onClick={() => setTabItem("deviceCat")} />
                                                    </li>
                                                </ul>
                                            :   <></>
                                        }
                                        {tabCat === "events"
                                            ?   <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                                                    <li className={"nav-item"}>
                                                        <Button text={"Event Types"} class={tabItem === "event" ? "nav-link active" : "nav-link"}
                                                                onClick={() => setTabItem("event")} />
                                                    </li>
                                                    <li className={"nav-item"}>
                                                        <Button text={"Reasons"} class={tabItem === "reason" ? "nav-link active" : "nav-link"}
                                                                onClick={() => setTabItem("reason")} />
                                                    </li>
                                                </ul>
                                            :   <></>
                                        }
                                    </div>
                                    {/*<div className={tabItem === "area" ? "d-block" : "d-none"}>*/}
                                    {/*    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>*/}
                                    {/*        <input type={"search"} id={"areaSearch"} className={"form-control search-input"}*/}
                                    {/*               value={areaSearch}*/}
                                    {/*               onChange={(e) => setAreaSearch(e.target.value)} />*/}
                                    {/*        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}*/}
                                    {/*                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>*/}
                                    {/*    </div>*/}
                                    {/*    <div style={{maxHeight: "400px", overflowY: "auto"}}>*/}
                                    {/*        <table className={"table table-sm table-white table-hover"}>*/}
                                    {/*            <thead className={"sticky-table-head"} style={{top: "0px"}}>*/}
                                    {/*                {areaTableHeaders}*/}
                                    {/*            </thead>*/}
                                    {/*            <tbody>*/}
                                    {/*                {areaTableRows}*/}
                                    {/*            </tbody>*/}
                                    {/*        </table>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className={tabItem === "areaGroup" ? "d-block" : "d-none"}>*/}
                                    {/*    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>*/}
                                    {/*        <input type={"search"} id={"areaGroupSearch"} className={"form-control search-input"}*/}
                                    {/*               onKeyUp={(e) => setAreaGroupSearch(e.target.value)} />*/}
                                    {/*        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}*/}
                                    {/*                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>*/}
                                    {/*    </div>*/}
                                    {/*    <table className={"table table-sm table-white table-hover"}*/}
                                    {/*           style={{maxHeight: "400px", overflowY: "auto", display: "block"}}>*/}
                                    {/*        <thead className={"sticky-table-head"} style={{top: "0px"}}>*/}
                                    {/*            {areaGroupTableHeaders}*/}
                                    {/*        </thead>*/}
                                    {/*        <tbody>*/}
                                    {/*            {areaGroupTableRows}*/}
                                    {/*        </tbody>*/}
                                    {/*    </table>*/}
                                    {/*</div>*/}
                                    <div className={tabItem === "areaType" ? "d-block" : "d-none"}>
                                        <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                            <input type={"search"} id={"areaTypeSearch"} className={"form-control search-input"}
                                                   value={areaTypeSearch}
                                                   onChange={(e) => setAreaTypeSearch(e.target.value)} />
                                            <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                    style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                        </div>
                                        <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                            <table className={"table table-sm table-white table-hover"}>
                                                <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                    {areaTypeTableHeaders}
                                                </thead>
                                                <tbody>
                                                    {areaTypeTableRows}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className={tabItem === "resident" ? "d-block" : "d-none"}>
                                        <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                            <input type={"search"} id={"resSearch"}
                                                   className={"form-control search-input"}
                                                   value={resSearch}
                                                   onChange={(e) => setResSearch(e.target.value)}/>
                                            <Button text={""} type={"button"}
                                                    class={"btn btn-primary smooth-radius-left"}
                                                    style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                        </div>
                                        <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                            <table className={"table table-sm table-white table-hover"}>
                                                <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                    {residentTableHeaders}
                                                </thead>
                                                <tbody>
                                                    {resTableRows}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className={tabItem === "residentGroup" ? "d-block" : "d-none"}>
                                        <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                            <input type={"search"} id={"residentGroupSearch"}
                                                   className={"form-control search-input"}
                                                   value={residentGroupSearch}
                                                   onChange={(e) => setResidentGroupSearch(e.target.value)}/>
                                            <Button text={""} type={"button"}
                                                    class={"btn btn-primary smooth-radius-left"}
                                                    style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                        </div>
                                        <div style={{maxHeight: "400px", overflowY: "auto", display: "block"}}>
                                            <table className={"table table-sm table-white table-hover"}>
                                                <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                    {residentGroupTableHeaders}
                                                </thead>
                                                <tbody>
                                                    {residentGroupTableRows}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className={tabItem === "user" ? "d-block" : "d-none"}>
                                        <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                            <input type={"search"} id={"userSearch"}
                                                   className={"form-control search-input"}
                                                   value={userSearch}
                                                   onChange={(e) => setUserSearch(e.target.value)}/>
                                            <Button text={""} type={"button"}
                                                    class={"btn btn-primary smooth-radius-left"}
                                                    style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                        </div>
                                        <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                            <table className={"table table-sm table-white table-hover"}>
                                                <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                    {userTableHeaders}
                                                </thead>
                                                <tbody>
                                                    {userTableRows}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className={tabItem === "userGroup" ? "d-block" : "d-none"}>
                                        <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                            <input type={"search"} id={"userGroupSearch"}
                                                   className={"form-control search-input"}
                                                   value={userGroupSearch}
                                                   onChange={(e) => setUserGroupSearch(e.target.value)}/>
                                            <Button text={""} type={"button"}
                                                    class={"btn btn-primary smooth-radius-left"}
                                                    style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                        </div>
                                        <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                            <table className={"table table-sm table-white table-hover"}>
                                                <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                    {userGroupTableHeaders}
                                                </thead>
                                                <tbody>
                                                    {userGroupTableRows}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className={tabItem === "device" ? "d-block" : "d-none"}>
                                        <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                            <input type={"search"} id={"devSearch"}
                                                   className={"form-control search-input"}
                                                   value={devSearch}
                                                   onChange={(e) => setDevSearch(e.target.value)}/>
                                            <Button text={""} type={"button"}
                                                    class={"btn btn-primary smooth-radius-left"}
                                                    style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                        </div>
                                        <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                            <table className={"table table-sm table-white table-hover"}>
                                                <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                    {deviceTableHeaders}
                                                </thead>
                                                <tbody>
                                                    {devTableRows}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className={tabItem === "event" ? "d-block" : "d-none"}>
                                        <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                            <input type={"search"} id={"typeSearch"}
                                                   className={"form-control search-input"}
                                                   value={eventTypeSearch}
                                                   onChange={(e) => setEventTypeSearch(e.target.value)}/>
                                            <Button text={""} type={"button"}
                                                    class={"btn btn-primary smooth-radius-left"}
                                                    style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                        </div>
                                        <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                            <table className={"table table-sm table-white table-hover"}>
                                                <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                    {eventTypeTableHeaders}
                                                </thead>
                                                <tbody>
                                                    {eventTypeTableRows}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className={tabItem === "reason" ? "d-block" : "d-none"}>
                                        <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                            <input type={"search"} id={"reasonSearch"}
                                                   className={"form-control search-input"}
                                                   value={reasonSearch}
                                                   onChange={(e) => setReasonSearch(e.target.value)}/>
                                            <Button text={""} type={"button"}
                                                    class={"btn btn-primary smooth-radius-left"}
                                                    style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                        </div>
                                        <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                            <table className={"table table-sm table-white table-hover"}>
                                                <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                    {reasonTableHeaders}
                                                </thead>
                                                <tbody>
                                                    {reasonTableRows}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelFilterBtn2"}
                                            data-bs-dismiss={"modal"}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadFilterSubmit2"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"filterSubmit2"}
                                            onClick={() => filterSubmit2()}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"schedulemodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={"-1"} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticBackdropLabel"}>Schedule Report</h2>
                            <button type={"button"} className={"btn-close"} id={"scheduleClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"scheduleForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div>
                                    <h4 className={"d-inline-block align-top"} style={{minWidth: "125px"}}>Report
                                        Type:</h4>
                                    <h4 className={"d-inline-block text-capitalize"} id={"report-display"}
                                        style={{
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            maxWidth: "350px"
                                        }}>Executive</h4>
                                </div>
                                <div className={"mb-3"}>
                                    <h4 className={"d-inline-block"} style={{minWidth: "125px"}}>Report Range:</h4>
                                    <h4 className={"d-inline-block"}>{rangeName}</h4>
                                </div>
                                <div className={"mb-3"}>
                                    <h4 className={"d-inline-block align-top"} style={{minWidth: "125px"}}>Filter
                                        Params:</h4>
                                    <h4 className={"d-inline-block"} id={"filter-display"}
                                        style={{
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            maxWidth: "350px"
                                        }}> </h4>
                                </div>
                                <div className={"d-inline-block"}>
                                    <div className={"form-check"}>
                                        <label htmlFor={"add-pdf"}>Add PDF Attachment (Summary)</label>
                                        <input id={"add-pdf"} defaultChecked={false}
                                               className={"form-check-input"} type={"checkbox"}/>
                                    </div>
                                </div>
                                <hr/>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"sch-description"}
                                           onKeyUp={() => scheduleFormCheck("scheduleForm", "scheduleSubmit")}
                                           required={true} pattern={"^[0-9a-zA-Z_@\\- ]+$"}/>
                                    <label htmlFor={"sch-description"}>Description</label>
                                </div>
                                <div className={"d-flex justify-content-around"}>
                                    <div className={"form-floating mb-3 me-3 w-75"}>
                                        <select className={"form-select"} required={true} id={"sch-frequency"}
                                                defaultValue={""}
                                                onChange={(e) => {
                                                    if (e.target.value.toString() === "1") {
                                                        document.getElementById("sch-weekly").classList.add("d-none");
                                                        document.getElementById("scheduler-container").classList.add("d-none");
                                                        document.getElementById("sch-monthly").classList.add("d-none");
                                                    } else if (e.target.value.toString() === "2") {
                                                        document.getElementById("sch-weekly").classList.remove("d-none");
                                                        document.getElementById("scheduler-container").classList.remove("d-none");
                                                        document.getElementById("sch-monthly").classList.add("d-none");
                                                    } else {
                                                        document.getElementById("sch-monthly").classList.remove("d-none");
                                                        document.getElementById("scheduler-container").classList.remove("d-none");
                                                        document.getElementById("sch-weekly").classList.add("d-none");
                                                    }
                                                    scheduleFormCheck("scheduleForm", "scheduleSubmit");
                                                }}>
                                            <option value={""} disabled={true}></option>
                                            {freqOptions}
                                        </select>
                                        <label htmlFor={"sch-frequency"}>Delivery Rate</label>
                                    </div>
                                    <div className={"form-floating mb-3"}>
                                        <input type={"time"} className={"form-control"} id={"sch-sendTime"}
                                               required={true} step={"300"} min={"00:00"} max={"23:59"}
                                               onFocus={(e) => {
                                                   e.target.showPicker();
                                               }}
                                               onBlur={(e) => {
                                                   if (!e.target.checkValidity()) {
                                                       e.target.stepUp();
                                                   }
                                                   scheduleFormCheck("scheduleForm", "scheduleSubmit");
                                               }}/>
                                        <label htmlFor={"sch-sendTime"}>Time of Day</label>
                                    </div>
                                </div>
                                <fieldset className={"border py-3 px-3 mt-4 d-none"} style={{borderRadius: "6px"}}
                                          id={"scheduler-container"}>
                                    <legend className={"fs-3 px-3 w-auto mb-0 legend"} style={{
                                        backgroundColor: "#FFFFFF",
                                        position: "relative",
                                        bottom: "26px"
                                    }}>Delivery Schedule
                                    </legend>
                                    <div id={"sch-weekly"} className={"d-none"}>
                                        <ul style={{columns: 3, listStyle: "none", columnGap: "30px"}}
                                            className={"float-start"}>
                                            <li>
                                                <div className={"form-check"}>
                                                    <label htmlFor={"sch-weekly-meta-0"}>Monday</label>
                                                    <input name={"sch-weekly-meta"} id={"sch-weekly-meta-0"} value={0}
                                                           defaultChecked={false}
                                                           className={"form-check-input"} type={"checkbox"}/>
                                                </div>
                                            </li>
                                            <li>
                                                <div className={"form-check"}>
                                                    <label htmlFor={"sch-weekly-meta-1"}>Tuesday</label>
                                                    <input name={"sch-weekly-meta"} id={"sch-weekly-meta-1"} value={1}
                                                           defaultChecked={false}
                                                           className={"form-check-input"} type={"checkbox"}/>
                                                </div>
                                            </li>
                                            <li>
                                                <div className={"form-check"}>
                                                    <label htmlFor={"sch-weekly-meta-2"}>Wednesday</label>
                                                    <input name={"sch-weekly-meta"} id={"sch-weekly-meta-2"} value={2}
                                                           defaultChecked={false}
                                                           className={"form-check-input"} type={"checkbox"}/>
                                                </div>
                                            </li>
                                            <li>
                                                <div className={"form-check"}>
                                                    <label htmlFor={"sch-weekly-meta-3"}>Thursday</label>
                                                    <input name={"sch-weekly-meta"} id={"sch-weekly-meta-3"} value={3}
                                                           defaultChecked={false}
                                                           className={"form-check-input"} type={"checkbox"}/>
                                                </div>
                                            </li>
                                            <li>
                                                <div className={"form-check"}>
                                                    <label htmlFor={"sch-weekly-meta-4"}>Friday</label>
                                                    <input name={"sch-weekly-meta"} id={"sch-weekly-meta-4"} value={4}
                                                           defaultChecked={false}
                                                           className={"form-check-input"} type={"checkbox"}/>
                                                </div>
                                            </li>
                                            <li>
                                                <div className={"form-check"}>
                                                    <label htmlFor={"sch-weekly-meta-5"}>Saturday</label>
                                                    <input name={"sch-weekly-meta"} id={"sch-weekly-meta-5"} value={5}
                                                           defaultChecked={false}
                                                           className={"form-check-input"} type={"checkbox"}/>
                                                </div>
                                            </li>
                                            <li>
                                                <div className={"form-check"}>
                                                    <label htmlFor={"sch-weekly-meta-6"}>Sunday</label>
                                                    <input name={"sch-weekly-meta"} id={"sch-weekly-meta-6"} value={6}
                                                           defaultChecked={false}
                                                           className={"form-check-input"} type={"checkbox"}/>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div id={"sch-monthly"} className={"d-none"}>
                                        <ul style={{columns: 4, listStyle: "none", columnGap: "30px"}}
                                            className={"float-start"}>
                                            {Array.from(range(1, 30)).map((i) => {
                                                return (
                                                    <li key={`monthly-rate-item-${i}`}>
                                                        <div className={"form-check"}>
                                                            <label htmlFor={`sch-monthly-meta-${i}`}>{i}</label>
                                                            <input name={"sch-monthly-meta"}
                                                                   id={`sch-monthly-meta-${i}`} value={i}
                                                                   defaultChecked={false}
                                                                   className={"form-check-input"} type={"checkbox"}/>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                            <li>
                                                <div className={"form-check"}>
                                                    <label htmlFor={"sch-monthly-meta-0"}>Last Day</label>
                                                    <input name={"sch-monthly-meta"} id={"sch-monthly-meta-0"} value={0}
                                                           defaultChecked={false}
                                                           className={"form-check-input"} type={"checkbox"}/>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </fieldset>
                                <hr className={"my-5"}/>
                                <div className={"d-inline-block"} id={"add-email-icon"}>
                                    <Button text={"Set Emails"} class={"btn btn-primary align-top me-4"}
                                            id={"add-email-groups"} onClick={() => {
                                        showEmailAddModal()
                                    }}/>
                                    <fieldset className={"border py-3 px-3 d-inline-block"}
                                              style={{borderRadius: "6px", minWidth: "200px"}}>
                                        <legend className={"legend-3"}>Emails</legend>
                                        <ul className={"ps-3"} style={{listStyle: "none"}}>
                                            {emailEntities?.length > 0
                                                ? emailEntities.map((email) => {
                                                    return (
                                                        <li key={`email-display-${email}`}>{email}</li>
                                                    );
                                                })
                                                : <li>None</li>
                                            }
                                        </ul>
                                    </fieldset>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"scheduleCancelBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadScheduleSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"scheduleSubmit"} onClick={() => scheduleSubmit()}>Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"addemailmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticBackdropLabel"}>Set Emails</h2>
                            <button type={"button"} className={"btn-close"} id={"addEmailClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"addEmailForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"list-group-item"}>
                                    <Button type={"button"} class={"btn btn-primary mb-3"} text={"New Email"} onClick={() => {
                                        setAddNewEmails((prevState) => {return [...prevState, {id: uuidv4(), email: ""}]});
                                        formCheck("addEmailForm", "addEmailSubmit");
                                    }} />
                                    {addNewEmails.map((item) => {
                                        return (
                                            <div key={`add-new-email-item${item.id}`}>
                                                <i className={"fe fe-trash as-btn d-inline-block red-hover"} onClick={() => {
                                                    setAddNewEmails((prevState) => {
                                                        return prevState.filter(i => i.id !== item.id);
                                                    });
                                                }}></i>
                                                <div className={"form-floating d-inline-block w-75 mb-3 mx-3"}>
                                                    <input type={"text"} name={"add-email-input"} className={"form-control"} id={`add-new-email-${item.id}`}
                                                           onKeyUp={(e) => {
                                                               setAddNewEmails((prevState) => {
                                                                   return prevState.map((i) => {
                                                                       if (i.id === item.id){
                                                                           return {id: i.id, email: e.target.value}
                                                                       }
                                                                       return i
                                                                   });
                                                               })
                                                               formCheck("addEmailForm", "addEmailSubmit");
                                                           }} required={true}
                                                            pattern={"[a-z0-9._%+\\-]+@[a-z0-9.\\-]+\\.[a-z]{2,}$"} defaultValue={item.email} />
                                                    <label htmlFor={`tmp-email-${item.id}`}>Email Address</label>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <hr />
                                <div className={"list-group-item"}>
                                    {addCurEmails.map((item) => {
                                        return (
                                            <div key={`add-stored-email-${item.id}`}>
                                                <i className={"fe fe-trash as-btn d-inline-block red-hover"} onClick={() => {
                                                    setAddCurEmails((prevState) => {
                                                        return prevState.filter(i => i?.id !== item.id);
                                                        });
                                                    }}></i>
                                                <div className={"form-floating d-inline-block w-75 mb-3 mx-3"}>
                                                    <input type={"text"} name={"add-email-input"} className={"form-control"} id={`add-tmp-email-${item.id}`} required={true}
                                                           pattern={"[a-z0-9._%+\\-]+@[a-z0-9.\\-]+\\.[a-z]{2,}$"} value={item.email} readOnly={true} />
                                                    <label htmlFor={`add-tmp-email-${item.id}`}>Email Address</label>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelAddEmailBtn"} onClick={() => {swapModal("addEmailClose", "schedulemodal")}}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadAddEmailSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"addEmailSubmit"} onClick={() => setAddEmailItems()}>Set</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer left={copyright} right={version} center={footer}/>
            <PerPageModal filterSubmit={() => filterSubmit()} tmpPerPage={tmpPerPage} setTmpPerPage={(sudocode) => setTmpPerPage(sudocode)} setPage={(sudocode) => setPage(sudocode)}/>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </div>
    );
};

export default ExecutiveReport;