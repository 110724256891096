function Navbar() {

    const authlinks = (
        <ul>
            <li key={"external1"}>
                <a href="https://www.palcare.com/" target={"_self"}>
                    <i className={"fe fe-home"}/> Home
                </a>
            </li>
            <li key={"external2"}>
                <a href={"https://shop.palcare.com/"} target={"_self"}>
                    <i className={"fe fe-shopping-cart"}/> Shop
                </a>
            </li>
            <li key={"external3"}>
                <a href={"https://www.palcare.com/contact-us/"} target={"_self"}>
                    <i className={"fe fe-phone-call"}/> Contact </a>
            </li>
        </ul>
    );

    return (
        <nav className={"landing-navbar bg-dark"}>
            <h1>Portal</h1>
            {authlinks}
        </nav>
    );
}

export default Navbar;