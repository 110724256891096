import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import Header from "./Header";
import {Suspense, useEffect, useState} from "react";
import Button from "./component-assets/Button";
import ApiRequester from "./ApiRequester";
import {ToastContainer} from "react-toastify";
import {Modal} from "bootstrap";
import Footer from "./Footer";
import {campusToGuid, formSuccess, formCheck, formFail, showModal} from "../libs";


const ProfileContent = () => {
    let dData, groupsDisplay;

    if (ReactSession.get("PAL") != null){
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const [proObj, setForm] = useState({
        email: dData?.email,
        display: dData?.display,
        errMsg: ""
    });
    const [usergroups, setUserGroups] = useState(dData?.groups);
    const [count, setCount] = useState(0);
    const [groupData, setGroupData] = useState([]);
    const [groupList, setGroupList] = useState([]);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.myAcnt,
                            procedure: "sp_dev_getgroupsbycampusid", reqType: "stored"}).then(data => {
                                setGroupData(data)
                            });
            setCount(count => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        let tmp;
        tmp = [];
        if (groupData.length > 0 && dData.groupEdit){
            groupData.sort((a, b) => {
                if (a["group_name"] > b["group_name"]) {
                    return 1;
                } else if (a["group_name"] < b["group_name"]) {
                    return -1;
                } else {
                    return 0;
                }
            });
            for (let index2 = 0; index2 < groupData.length; index2++){
                tmp.push(
                    <label className={"list-group-item"} key={"listItem"+index2}>
                        <input id={"box"+index2} name={"groupsE"} className={"form-check-input mx-3"}
                               type={"checkbox"} value={groupData[index2]?.group_id}
                               checked={usergroups.includes(groupData[index2]?.group_id)}
                               onChange={(e) => {
                                   if (!e.target.checked) {
                                       setUserGroups(() => {
                                           return removeItem(usergroups, groupData[index2]?.group_id);
                                       });
                                   }
                                   else{
                                       setUserGroups((prevState) => {
                                           return [...prevState, groupData[index2]?.group_id];
                                       });
                                   }
                               }}/>
                        {groupData[index2]?.group_name}
                    </label>
                );
            }
        setGroupList(tmp);
        }
    }, [groupData, usergroups]);

    // BUILDING THE MODAL CONTENT BELOW


    groupsDisplay = [];
    for (let ig=0; ig < dData?.groups?.length; ig++){
        groupData.forEach(group => {
            if (group?.group_id === dData?.groups[ig]){
                groupsDisplay.push(
                    <li key={"groupdisplay"+ig}>{group?.group_name}</li>
                );
            }
        });
    }

    function editModal(){
        let modal;
        document.getElementById("displayE").value = proObj.display;
        document.getElementById("emailE").value = proObj.email;
        formCheck("editForm", "editSubmit");
        modal = new Modal(document.getElementById("editmodal"), {});
        modal.show();
    }

    function submitProfile() {
        let msg, payload, email, display, groups, groupList, intGroupList, i, loadBtn, submitBtn, cancelBtn, add, load;
        groupList = [];
        loadBtn = "loadEditSubmit";
        submitBtn = "editSubmit";
        cancelBtn = "cancelEditBtn";
        add = document.getElementById(submitBtn);
        load = document.getElementById(loadBtn);
        add.classList.add("d-none");
        load.classList.add("d-inline-display");
        load.classList.remove("d-none");
        email = document.getElementById("emailE").value;
        payload = [{Action: "update", Username: dData?.username, UserId: dData?.user, CampusId: dData.myAcnt,
                    UserEmail: email.toLowerCase(), Migrated: dData?.migrated ? dData.migrated : false, ClientId: dData.sessionId,
                    CampusGuid: campusToGuid(dData, dData?.myAcnt), UserRole: dData.role_id, CognitoId: dData.cognito}];
        if (dData?.displayEdit){
            display = document.getElementById("displayE").value;
            payload[0]["DisplayName"] = display;
        }else{
            payload[0]["DisplayName"] = dData?.display;
        }
        if (dData?.groupEdit){
            groups = document.getElementsByName("groupsE");
            for (i=0; i < groups.length; i++){
                if (groups[i].checked === true){
                    groupList.push(parseInt(groups[i].value));
                }
            }
            payload[0]["GroupIds"] = groupList;
        }else{
            payload[0]["GroupIds"] = dData?.groups ? dData.groups : []
        }
        ApiRequester({reqEndpoint: "user-manage", Payload: payload}).then((data) => {
            if (data[0]){
                dData.email = email.toLowerCase();
                if (dData?.displayEdit){
                    dData.display = display;
                }
                if (dData?.groupEdit){
                    dData.groups = groupList;
                }
                ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
                formSuccess("Profile saved", submitBtn, loadBtn, cancelBtn);
                setCount(0);
            }
            else{
                if (data[1] === "1"){
                    msg = "Save failed, failed to lookup user. Contact your customer success manager for assistance.";
                }else{
                    msg = "Save failed, unknown reason.";
                }
                formFail(msg, submitBtn, loadBtn);
            }
        });
    }

    function removeItem(theArray, theValue){
        theArray = theArray.filter(item => item !== theValue);
        return theArray;
    }

    function passSubmit(){
        let loadBtn, form, submitBtn, cancelBtn, password, add, load, msg, payload, origPass;
        loadBtn = "loadPassSubmit";
        submitBtn = "passSubmit";
        cancelBtn = "cancelPassBtn";
        form = "passForm";
        add = document.getElementById(submitBtn);
        load = document.getElementById(loadBtn);
        add.classList.add("d-none");
        load.classList.add("d-inline-display");
        load.classList.remove("d-none");
        origPass = document.getElementById("ogPassword").value;
        password = document.getElementById("passwordR").value;
        payload = [{Action: "reset-self", CampusId: dData?.myAcnt, Username: dData.username, UserPass: password,
                    OrigPass: origPass, Migrated: dData?.migrated ? dData.migrated : false, ClientId: dData.sessionId}];
        ApiRequester({reqEndpoint: "user-manage", Payload: payload}).then(data => {
                if (data[0] === true){
                    formSuccess("Password reset, inform user of change.", submitBtn, loadBtn, cancelBtn, form);
                    setCount(0);
                }
                else{
                    if (["4", "3"].includes(data[1])){
                        msg = "Current password incorrect.";
                    }
                    else{
                        msg = "Unknown failure, request not processed.";
                    }
                    formFail(msg, submitBtn, loadBtn);
                }
        });
    }

    return (
        <div className={"main-content"}>
            <Header preTitle={"Profile"} Title={dData?.display} />
            <div className={"container-fluid"}>
                <div className={"row justify-content-center"}>
                    <div className={"col-12 col-lg-10 col-xl-8"}>
                        <i className={"fe fe-edit fs-2 p-2 float-end"} style={{cursor: "pointer"}} onClick={() => editModal()}/>
                        <div className={"floating-form"} style={{borderBottom: "1px solid #ccc", marginTop: "10px"}}>
                            <label htmlFor={"user-name"} style={{minWidth: "100px", marginBottom: "10px"}}>Username</label>
                            <input disabled={true} readOnly={true} type={"text"} id={"user-name"} value={dData?.username}
                                   style={{"fontWeight": 500, "background": "none", "border": "none"}}/>
                        </div>
                        <div className={"floating-form"} style={{borderBottom: "1px solid #ccc", marginTop: "10px"}}>
                            <label htmlFor={"user-display"} style={{minWidth: "100px", marginBottom: "10px"}}>Display</label>
                            <input disabled={true} readOnly={true} type={"text"} id={"user-display"} value={proObj?.display}
                                   style={{"fontWeight": 500, "background": "none", "border": "none"}}/>
                        </div>
                        <div className={"floating-form"} style={{borderBottom: "1px solid #ccc", marginTop: "10px"}}>
                            <label htmlFor={"user-email"} style={{minWidth: "100px", marginBottom: "10px"}}>Email</label>
                            <input disabled={true} readOnly={true} type={"text"} id={"user-email"} value={proObj?.email}
                                   required={["palcare-admin", "palcare", "corporate", "corporate-admin", "admin"].includes(dData.userrole)}
                                   style={{"fontWeight": 500, "background": "none", "border": "none"}}/>
                        </div>
                        <div className={"floating-form"} style={{borderBottom: "1px solid #ccc", marginTop: "10px"}}>
                            <label htmlFor={"user-role"} style={{minWidth: "100px", marginBottom: "10px"}}>Role</label>
                            <input disabled={true} readOnly={true} type={"text"} id={"user-role"} value={dData.userrole}
                                   style={{"fontWeight": 500, "background": "none", "border": "none"}}/>
                        </div>
                        <div className={"floating-form"} style={{borderBottom: "1px solid #ccc", marginTop: "10px"}}>
                            <h4 className={"d-inline-block"} style={{minWidth: "100px", marginBottom: "10px"}}>Groups</h4>
                            <ul className={"list-group d-inline-flex"} style={{listStyle: "none"}}>
                                {groupsDisplay}
                            </ul>
                        </div>
                        <div className={"floating-form"} style={{borderBottom: "1px solid #ccc", marginTop: "10px"}}>
                            <label style={{minWidth: "100px", marginBottom: "10px", letterSpacing: "unset"}}>Password</label>
                            <Button text={"Reset Password"} type={"button"} class={"btn btn-primary ms-3 mb-3 mt-2"} id={"passBtn"}
                                icon={true} iconClass={"fe fe-lock me-2"} onClick={() => showModal("passmodal")} />
                        </div>
                    </div>
                </div>
                <div className={"footer-spacer"}>
                </div>
            </div>
            <Footer />
            <div className={"modal fade"} id={"editmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Edit User</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"editForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                {dData.displayEdit
                                    ? <div className={"form-floating mt-3"} key={"display-edit"}>
                                        <input type={"text"} className={"form-control"} required={true}
                                               pattern={"[ -~]+"} id={"displayE"}
                                               onKeyUp={() => formCheck("editForm", "editSubmit")} />
                                        <label htmlFor={"displayE"}>Display Name</label>
                                        <div className={"invalid-feedback"}>
                                            Please enter display name!
                                        </div>
                                    </div>
                                    : <></>}
                                <div className={"form-floating mt-3"}>
                                    <input type={"text"} className={"form-control"}
                                           pattern={"[A-Za-z0-9._%+\\-]+@[A-Za-z0-9.\\-]+\\.[A-Za-z]{2,}$"}
                                           id={"emailE"}
                                           onKeyUp={() => formCheck("editForm", "editSubmit")} />
                                    <label htmlFor={"emailE"}>Email</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a valid email!
                                    </div>
                                </div>
                            </div>
                            {
                                dData?.groupEdit
                                    ? <>
                                        <h3 className={"modal-header modal-title"}>Groups</h3>
                                        <div className={"list-group"} style={{maxHeight: "400px", overflowY: "auto"}}>
                                            {groupList}
                                        </div>
                                      </>
                                    : <></>
                            }
                            <div className={"modal-footer"} style={{justifyContent: "space-between"}}>
                                <div>
                                </div>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelEditBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadEditSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} onClick={() => submitProfile()} id={"editSubmit"}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"passmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Reset Password</h2>
                            <button type={"button"} className={"btn-close"} id={"passClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"passForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3"}>
                                    <input type={"password"} className={"form-control"} id={"ogPassword"}
                                            onKeyUp={() => formCheck("passForm", "passSubmit")}
                                           required={true} pattern={"[ -~]{6,}"} />
                                    <label htmlFor={"passwordR"}>Current Password</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a valid password!
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"password"} className={"form-control"} id={"passwordR"}
                                            onKeyUp={() => formCheck("passForm", "passSubmit")}
                                           required={true} pattern={"[ -~]{6,}"} />
                                    <label htmlFor={"passwordR"}>New Password</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a valid password! 6 Character minimum.
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"password"} className={"form-control"} id={"passwordCR"}
                                            onKeyUp={() => formCheck("passForm", "passSubmit")} required={true}  pattern={"[ -~]{6,}"} />
                                    <label htmlFor={"passwordCR"}>Confirm Password</label>
                                    <div className={"invalid-feedback"}>
                                        Password does not match.
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelPassBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadPassSubmit"}/>
                                    <button type={"button"} className={"btn btn-danger ms-3"} id={"passSubmit"} onClick={() => passSubmit()}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </div>
    );
};

export default ProfileContent;