import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import Button from "../component-assets/Button";
import ApiRequester from "../ApiRequester";
import {ToastContainer} from "react-toastify";
import {
    getYear, setCheckinACNT, formatTimestampIso, luxonParseSQLDate, luxonCompareDateTimes,
    handleSubmitActions, showModal, formFail, sortFunc, convertSectoHHMM,
    convertHHMMtoSec, formCheck, formSuccess, checkAll
} from "../../libs";
import TableBody from "../component-assets/TableBody";
import TableHeaders from "../component-assets/TableHeaders";

const CheckinSettings = (props) => {

    let dData, headerData2;
    if (ReactSession.get("PAL") != null) {
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const [resLinkClose, setResLinkClose] = useState("cancelGroupBtn");

    headerData2 = [
        {label: "Group Name", key: "resident_group_name", align: "left"},
        {label: "Description", key: "resident_description", align: "left"}
    ]

    const checkData = props?.data ? props.data : [];
    const campusId = dData.acnt;

    // Main Table Display Variables
    const [checkin, setCheckin] = useState(dData.checkin);
    const [customDisplay, setCustomDisplay] = useState([]);
    const [editRecords, setEditRecords] = useState([]);
    const [groupSaveId, setGroupSaveId] = useState("");

    // Resident Picker Table Variables
    const [resData, setResData] = useState(props?.resGroupData ? props.resGroupData : []);
    const [resIdList, setResIdList] = useState([]);
    const [resDataCnt, setResDataCnt] = useState(0);
    const [resTableRows, setResTableRows] = useState([]);
    const [search2, setSearch2] = useState("");
    const [sorter2, setSorter2] = useState({
        sortColumn: "resident_group_name",
        sortOrder: "desc"
    });

    useEffect(() => {
        let trs = [];
        for (let i=0; i < checkData?.length; i++){
            trs.push(
                <tr key={`checkin-row-${i}`}>
                    <td className={"text-center"}>
                        <div>
                            <input type={"hidden"} className={"d-none"} value={checkData[i].window_id} id={`rowId${i}`} />
                            <input style={{height: "1.25rem", width: "1.25rem"}} name={`on-checkbox${i}`} id={`on-checkbox${i}`}
                                   defaultChecked={luxonCompareDateTimes(luxonParseSQLDate(checkData[i]?.ignore_until), "lt")}
                                   className={"form-check-input mx-3"} type={"checkbox"} onChange={() => {
                                       document.getElementById("editSubmit").classList.remove("d-none");
                                       if (editRecords.includes(i) === false){
                                           setEditRecords((prev) => {return [...prev, i]});
                                       }
                                   }} />
                        </div>
                    </td>
                    <td className={"text-center"}>
                        <i className={"fe fe-settings as-btn"} onClick={() => {
                            setGroupSaveId(() => checkData[i].window_id);
                            showGroupModal(i);
                        }} />
                    </td>
                    <td className={"text-center"}>
                        <div className={"me-3"} style={{maxWidth: "185px"}}>
                            <input className={"form-control"} type={"time"} id={`startTime${i}`}
                                   defaultValue={convertSectoHHMM(checkData[i].window_start)}
                                   step={"300"} max={convertSectoHHMM(checkData[i].window_end)} required={true}
                                   onFocus={(e) => {
                                       e.target.showPicker();
                                   }}
                                   onBlur={(e) => {
                                       if (!e.target.checkValidity()){
                                           e.target.stepUp();
                                       }
                                       document.getElementById(`endTime${i}`).min = e.target.value;
                                       document.getElementById(`alarmTime${i}`).min = e.target.value;
                                       analyzeAlarmTime(i);
                                       document.getElementById("editSubmit").classList.remove("d-none");
                                       formCheck("mainform", "editSubmit");
                                       if (!editRecords.includes(i)){
                                           setEditRecords((prev) => {return [...prev, i]});
                                       }
                            }} />
                            <div className={"invalid-feedback"}>
                                Start time must be prior to end time and in 5 minute increments.
                            </div>
                        </div>
                    </td>
                    <td className={"text-center"}>
                        <div className={"me-3"} style={{maxWidth: "185px"}}>
                            <input className={"form-control"} type={"time"} id={`endTime${i}`}
                                   defaultValue={convertSectoHHMM(checkData[i].window_end)}
                                   step={checkData[i].window_end !== 86400 ? "300" : "0"} required={true}
                                   min={convertSectoHHMM(checkData[i].window_start)}
                                   onFocus={(e) => {
                                       e.target.showPicker();
                                   }}
                                   onBlur={(e) => {
                                       if (e.target.value === "23:59"){
                                           e.target.step = "0";
                                       }else{
                                           e.target.step = "300";
                                       }
                                       if (!e.target.checkValidity()){
                                           e.target.stepUp();
                                       }
                                       document.getElementById(`startTime${i}`).max = e.target.value;
                                       document.getElementById(`alarmTime${i}`).max = e.target.value;
                                       analyzeAlarmTime(i);
                                       document.getElementById("editSubmit").classList.remove("d-none");
                                       formCheck("mainform", "editSubmit");
                                       if (!editRecords.includes(i)){
                                           setEditRecords((prev) => {return [...prev, i]});
                                       }
                               }} />
                            <div className={"invalid-feedback"}>
                                End time must be after start time and in 5 minute increments.
                            </div>
                        </div>
                    </td>
                    <td className={"text-center"}>
                        <div className={"me-3"}>
                            <input className={"form-control"} pattern={"[a-zA-Z0-9_ \\-\\/]+"} type={"text"}
                                   id={`windowName${i}`} defaultValue={checkData[i]?.description ? checkData[i].description : ""}
                                   onChange={() => {
                                       document.getElementById("editSubmit").classList.remove("d-none");
                                       if (!editRecords.includes(i)){
                                           setEditRecords((prev) => {return [...prev, i]});
                                       }
                               }} />
                            <div className={"invalid-feedback"}>
                                Enter a valid description. Characters, numbers, and - _ / characters allowed.
                            </div>
                        </div>
                    </td>
                    <td className={"text-center"}>
                        <div>
                            <input style={{height: "1.25rem", width: "1.25rem"}} name={`alarm-checkbox${i}`} id={`alarm-checkbox${i}`}
                                   className={"form-check-input mx-3"} type={"checkbox"} defaultChecked={checkData[i]?.generate_alarm === 1}
                                   onChange={() => {
                                        document.getElementById("editSubmit").classList.remove("d-none");
                                        toggleAlarmField(i);
                                        formCheck("mainform", "editSubmit");
                                        if (!editRecords.includes(i)){
                                           setEditRecords((prev) => {return [...prev, i]});
                                       }

                            }} />
                        </div>
                    </td>
                    <td className={"text-center"}>
                        <div className={"ms-5"} style={{maxWidth: "185px"}}>
                            <input className={"form-control text-center"} type={"time"} id={`alarmTime${i}`} step={"300"}
                                   disabled={checkData[i]?.generate_alarm !== 1}
                                   defaultValue={convertSectoHHMM(checkData[i].alarm_at)}
                                   min={convertSectoHHMM(checkData[i].window_start)}
                                   max={convertSectoHHMM(checkData[i].window_end)}
                                   onFocus={(e) => {
                                       e.target.showPicker();
                                   }}
                                   onBlur={(e) => {
                                       if (!e.target.checkValidity()){
                                           e.target.stepUp();
                                       }
                                       document.getElementById("editSubmit").classList.remove("d-none");
                                       analyzeAlarmTime(i);
                                       formCheck("mainform", "editSubmit");
                                       if (!editRecords.includes(i)){
                                           setEditRecords((prev) => {return [...prev, i]});
                                       }
                               }} />
                            <div className={"invalid-feedback"}>
                                Alarm time must be after start time and no later than the end time in 5 minute increments.
                            </div>
                        </div>
                    </td>
                </tr>
            )
        }
        setCustomDisplay(() => trs)
    }, [checkData, editRecords]);

    useEffect(() => {
        let i, tmp = [], input;
        input = search2?.toString()?.toLowerCase();
        for (i = 0; i < resData?.length; i++){
            if ((resData[i]?.resident_group_name && resData[i].resident_first_name?.toLowerCase()?.search(input) !== -1) ||
                (resData[i]?.resident_description && resData[i].resident_last_name?.toLowerCase()?.search(input) !== -1)){
                tmp.push(resData[i]);
            }
        }
        setResDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, sorter2.sortColumn, sorter2.sortOrder));
        setResTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                   actionAppearance={() => {}} tableHeaders={headerData2} checkboxId={"uCheckRes"}
                                   parentComponent={"Checkin"} dData={dData} checkboxlist={resIdList}
                                   modal={false} editModal={() => {}} unk={"x2-"}
                                   setCheckboxId={(value) => {setResIdList(value)}} checkidkey={"resident_group_id"} />);
    }, [resData, sorter2, resIdList, search2]);

    function analyzeAlarmTime(index){
        let alarmField, startField, endField, alarmSec, startSec, endSec;
        if (index === -1){
            alarmField = document.getElementById("add-alarmtime");
            startField = document.getElementById("add-starttime");
            endField = document.getElementById("add-endtime");
        }else{
            alarmField = document.getElementById("alarmTime"+index.toString());
            startField = document.getElementById("startTime"+index.toString());
            endField = document.getElementById("endTime"+index.toString());
        }
        alarmSec = convertHHMMtoSec(alarmField.value);
        startSec = convertHHMMtoSec(startField.value);
        endSec = convertHHMMtoSec(endField.value);
        if (!(startSec < alarmSec && alarmSec <= endSec)){
            alarmField.value = endField.value;
        }
    }

    function toggleAlarmField(index){
        let alarmField, checkbox;
        checkbox = document.getElementById("alarm-checkbox"+index.toString());
        alarmField = document.getElementById("alarmTime"+index.toString());
        alarmField.disabled = !checkbox.checked;
    }

    function showGroupModal(rowId){
        setResLinkClose("cancelGroupBtn");
        setSearch2("");
        setResIdList(checkData[rowId]?.resident_group_id_list !== '' ? checkData[rowId].resident_group_id_list.split(",") : []);
        document.getElementById("uCheckHead").checked = checkData[rowId]?.resident_ids?.split(",")?.length === resData?.length;
        showModal("groupmodal");
    }

    function performAddSave(){
        let payload, start, end, endVal, alarm, description, generate;
        handleSubmitActions("addSubmit", "loadAddSubmit");
        description = document.getElementById("add-description").value;
        start = convertHHMMtoSec(document.getElementById("add-starttime").value);
        endVal = document.getElementById("add-endtime").value;
        end = endVal !== "23:59" ? convertHHMMtoSec(endVal) : 86400;
        generate = document.getElementById("add-alarm-on").checked;
        alarm = convertHHMMtoSec(document.getElementById("add-alarmtime").value);
        payload = [{
            window_start: start, window_end: end, alarm_at: alarm, description: description,
            generate_alarm: generate, campus_id: campusId, window_frequency_id: 1,
            resident_group_ids: resIdList
        }];
        ApiRequester({reqEndpoint: "mytransaction", TransType: "checkin_add", Payload: payload,
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
            if (data && data[0]){
                props.setCount(0);
                formSuccess("Checkin profile added", "addSubmit", "loadAddSubmit",
                    "cancelAddBtn", "addForm");
            }
            else{
                formFail("Error encountered, save failed.", "addSubmit", "loadAddSubmit");
            }
        });
    }

    function performSave(){
        let payload = [], endVal, end;
        handleSubmitActions("editSubmit", "loadEditSubmit");
        for (let i=0; i < editRecords.length; i++){
            endVal = document.getElementById(`endTime${editRecords[i]}`).value;
            end = endVal !== "23:59" ? parseInt(convertHHMMtoSec(endVal)) : 86400;
            payload.push(
                {
                    window_id: checkData[parseInt(editRecords[i])].window_id,
                    window_start: parseInt(convertHHMMtoSec(document.getElementById(`startTime${editRecords[i]}`).value)),
                    window_end: end,
                    alarm_at: parseInt(convertHHMMtoSec(document.getElementById(`alarmTime${editRecords[i]}`).value)),
                    description: document.getElementById(`windowName${editRecords[i]}`).value,
                    generate_alarm: document.getElementById(`alarm-checkbox${editRecords[i]}`).checked ? 1 : 0,
                    ignore_until: document.getElementById(`on-checkbox${editRecords[i]}`).checked
                        ? formatTimestampIso(getYear(0)[0])
                        : formatTimestampIso(getYear(10)[0]),
                    campus_id: campusId,
                    is_resident_checkin: checkin
                }
            );
        }
        ApiRequester({reqEndpoint: "mytransaction", TransType: "checkin_edit", Payload: payload,
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId, Param: checkin}).then(data => {
            if (data && data[0]){
                props.setCount(0);
                formSuccess("Check-in profile saved.", "editSubmit", "loadEditSubmit");
                setCheckinACNT(dData, checkin);
                setEditRecords(() => []);
            }
            else{
                formFail("Error encountered, save failed.", "editSubmit", "loadEditSubmit");
            }
        });
    }

    function performGroupSave(){
        let payload;
        handleSubmitActions("groupSubmit", "loadGroupSubmit");
        payload = [{window_id: groupSaveId, resident_group_ids: resIdList, campus_id: campusId}];
        ApiRequester({reqEndpoint: "mytransaction", TransType: "checkin_edit_groups", Payload: payload,
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
            if (data[0] === true){
                props.setCount(0);
                formSuccess("Residents have been updated.", "groupSubmit", "loadGroupSubmit",
                    "cancelGroupBtn");
            }
            else{
                formFail(`Error during add, error code: ${data[1]}`, "groupSubmit", "loadGroupSubmit");
            }
        });
    }

    const resTableHead = <TableHeaders checkbox={true} headerData={headerData2} checkboxHeaderId={"uCheckHead"}
                                 checkAll={() => checkAll(0, resDataCnt ? resDataCnt : 0, resIdList, setResIdList, "uCheckHead", "uCheckRes")} writeAccess={true}
                                 sortOrder={sorter2.sortOrder} sortColumn={sorter2.sortColumn} sorter={sorter2}
                                 setSorter={setSorter2} actionAppearance={() => {}} />

    const resAddTableHead = <TableHeaders checkbox={true} headerData={headerData2} checkboxHeaderId={"uCheckHead2"}
                                 checkAll={() => checkAll(0, resDataCnt ? resDataCnt : 0, resIdList, setResIdList, "uCheckHead2", "uCheckRes")} writeAccess={true}
                                 sortOrder={sorter2.sortOrder} sortColumn={sorter2.sortColumn} sorter={sorter2}
                                 setSorter={setSorter2} actionAppearance={() => {}} />

    return (
        <>
            <div className={"card"}>
                <div className={"card-header d-flex justify-content-between"}>
                    <div>
                        <h4 className={"card-header-title d-inline-block"}>Check-in Settings</h4>
                        <div className={"form-check form-switch ms-4 d-inline-block"}>
                            <input className={"form-check-input"} type={"checkbox"} role={"switch"} id={"checkinOn"}
                                   checked={checkin} value={checkin} onChange={(e) => {
                                setCheckin(e.target.checked);
                                document.getElementById("editSubmit").classList.remove("d-none");
                                if (e.target.checked) {
                                    e.target.nextElementSibling.children[0].classList.remove("text-danger");
                                    e.target.nextElementSibling.children[0].classList.remove("fw-bold");
                                    e.target.nextElementSibling.children[1].classList.add("fw-bold");
                                    e.target.nextElementSibling.children[1].classList.add("text-success-bright");
                                }else{
                                    e.target.nextElementSibling.children[0].classList.add("text-danger");
                                    e.target.nextElementSibling.children[0].classList.add("fw-bold");
                                    e.target.nextElementSibling.children[1].classList.remove("fw-bold");
                                    e.target.nextElementSibling.children[1].classList.remove("text-success-bright");
                                }
                            }} />
                            <label className={"form-check-label"} htmlFor={"checkinOn"}><span id={"in-label-off"} className={!checkin ? "text-danger fw-bold" : ""}>Off</span>/<span id={"in-label-on"} className={checkin ? "text-success-bright fw-bold" : ""}>On</span></label>
                        </div>
                    </div>
                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadEditSubmit"}/>
                    <Button id={"editSubmit"} style={{width: "75px"}} text={"Save"} class={"btn btn-primary d-none"} onClick={() => performSave()} />
                </div>
                <div className={"card-body"}>
                    <div className={checkin ? "list-group list-group-flush my-n3" : "list-group list-group-flush my-n3 d-none"} id={"checkin-display"}>
                        <div className={"list-group-item"}>
                            <Button text={"Add"} icon={true} class={"btn btn-primary mb-3 float-end"} style={{height: "40.5px"}} iconClass={"fe fe-plus me-2"} onClick={() => {
                                setResLinkClose("cancelAddBtn");
                                setSearch2("");
                                setResIdList([]);
                                document.getElementById("uCheckHead2").checked = false;
                                formCheck("addForm", "addSubmit");
                                showModal("addmodal");
                            }}/>
                            <form id={"mainform"} className={"was-validated"}>
                                <table className={"table"}>
                                    <thead>
                                        <tr>
                                            <th className={"text-center"} scope={"col"}>Enable</th>
                                            <th className={"text-center"} scope={"col"}>Residents</th>
                                            <th className={"text-center"} scope={"col"}>Start Time</th>
                                            <th className={"text-center"} scope={"col"}>End Time</th>
                                            <th className={"text-center"} scope={"col"}>Description</th>
                                            <th className={"text-center"} scope={"col"}>Alarm On?</th>
                                            <th className={"text-center"} scope={"col"}>Alarm Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {customDisplay}
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"groupmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticBackdropLabel"}>Edit Residents Attached</h2>
                            <button type={"button"} className={"btn-close"} id={"groupClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"groupForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div id={"groupsdiv"} className={"mt-1"}>
                                    <h3>Residents</h3>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                        <input type={"search"} id={"search3E"} className={"form-control search-input"}
                                               value={search2}
                                               onChange={(e) => setSearch2(e.target.value)} />
                                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                    </div>
                                    <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {resTableHead}
                                            </thead>
                                            <tbody>
                                                {resTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelGroupBtn"}
                                            data-bs-dismiss={"modal"}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadGroupSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"groupSubmit"} onClick={() => performGroupSave()}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"addmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticBackdropLabel"}>New Check-in Window</h2>
                            <button type={"button"} className={"btn-close"} id={"addClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"addForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"add-description"}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")} required={true}
                                           pattern={"[a-zA-Z0-9 _\\-\\/]+"} />
                                    <label htmlFor={"add-description"}>Description</label>
                                    <div className={"invalid-feedback"}>
                                        Enter a valid description. Characters, numbers, and - _ / characters allowed.
                                    </div>
                                </div>
                                <div className={"d-flex justify-content-around"}>
                                    <div className={"form-floating mb-3 d-inline-block"} style={{width: "49%"}}>
                                        <input type={"time"} className={"form-control"} id={"add-starttime"}
                                               required={true} step={"300"} min={"00:00"} max={"23:59"}
                                               onFocus={(e) => {
                                                   e.target.showPicker();
                                               }}
                                               onBlur={(e) => {
                                                   if (!e.target.checkValidity()){
                                                       e.target.stepUp();
                                                   }
                                                   document.getElementById("add-endtime").min = e.target.value;
                                                   document.getElementById("add-alarmtime").min = e.target.value;
                                                   analyzeAlarmTime(-1);
                                                   formCheck("addForm", "addSubmit");
                                               }} />
                                        <label htmlFor={"add-starttime"}>Start Time</label>
                                        <div className={"invalid-feedback"}>
                                            Start time must be prior to end time and in 5 minute increments.
                                        </div>
                                    </div>
                                    <div className={"form-floating mb-3 d-inline-block"} style={{width: "49%"}}>
                                        <input type={"time"} className={"form-control"} id={"add-endtime"}
                                               required={true} step={"300"} min={"00:00"} max={"23:59"}
                                               onFocus={(e) => {
                                                   e.target.showPicker();
                                               }}
                                               onBlur={(e) => {
                                                   if (e.target.value === "23:59"){
                                                       e.target.step = "0";
                                                   }else{
                                                       e.target.step = "300";
                                                   }
                                                   if (!e.target.checkValidity()){
                                                       e.target.stepUp();
                                                   }
                                                   document.getElementById("add-starttime").max = e.target.value;
                                                   document.getElementById("add-alarmtime").max = e.target.value;
                                                   analyzeAlarmTime(-1);
                                                   formCheck("addForm", "addSubmit");
                                               }} />
                                        <label htmlFor={"add-endtime"}>End Time</label>
                                        <div className={"invalid-feedback"}>
                                            End time must be after start time and in 5 minute increments.
                                        </div>
                                    </div>
                                </div>
                                <div className={"list-group-item py-3"}>
                                    <div>
                                        <h3 className={"d-inline-block"} style={{minWidth: "120px"}}>Generate Alarms</h3>
                                        <div className={"d-inline-block form-check form-switch ms-5 align-middle"}>
                                            <input name={"add-alarm-on"} id={"add-alarm-on"} defaultChecked={true}
                                                   className={"form-check-input"} type={"checkbox"} onChange={(e) => {
                                                       let setting = document.getElementById("add-alarmtime");
                                                       if (!e.target.checked){
                                                           setting.value = "00:00";
                                                       }else {
                                                           setting.value = document.getElementById("add-endtime")?.value ? document.getElementById("add-endtime").value : ""
                                                       }
                                                       setting.disabled = !e.target.checked;
                                                       analyzeAlarmTime(-1);
                                                       formCheck("addForm", "addSubmit");
                                                   }} />
                                        </div>
                                    </div>
                                    <div className={"form-floating mb-3 d-inline-block w-50"}>
                                        <input type={"time"} className={"form-control"} id={"add-alarmtime"} step={"300"}
                                               onFocus={(e) => {
                                                   e.target.showPicker();
                                               }}
                                               onBlur={(e) => {
                                                    if (!e.target.checkValidity()){
                                                        e.target.stepUp();
                                                    }
                                                    analyzeAlarmTime(-1);
                                                    formCheck("addForm", "addSubmit");
                                               }} required={true} />
                                        <label htmlFor={"add-alarmtime"}>Alarm Time</label>
                                        <div className={"invalid-feedback"}>
                                            Alarm time must be after start time and no later than the end time in 5 minute increments.
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div id={"addgroupsdiv"} className={"mt-1"}>
                                    <h3 className={"text-center"}>Residents</h3>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                        <input type={"search"} id={"search2"} className={"form-control search-input"}
                                               value={search2}
                                               onChange={(e) => setSearch2(e.target.value)} />
                                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                    </div>
                                    <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {resAddTableHead}
                                            </thead>
                                            <tbody>
                                                {resTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelAddBtn"}
                                            data-bs-dismiss={"modal"}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadAddSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"addSubmit"} onClick={() => performAddSave()}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </>
    );
};

export default CheckinSettings;