import { Navigate } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import ApiRequester from "./ApiRequester";
import { ToastContainer } from "react-toastify";
import {Modal} from 'bootstrap';
import {CSVLink} from "react-csv";
import Header from "./Header";
import Button from "./component-assets/Button";
import {getUTCTimestamp, paginateData, showModal, sortFunc,
        formFail, handleSuccess, handleSubmitActions, actionAppearance, deleteModalSingle,
        bulkDeleteModal, checkAll} from "../libs"
import {v4 as uuidv4} from "uuid"
import {Pagination} from "react-bootstrap";
import PaginationItems from "./component-assets/PaginationItems";
import Footer from "./Footer";
import TableBody from "./component-assets/TableBody";
import TableHeaders from "./component-assets/TableHeaders";
import BulkDeleteModal from "./component-assets/BulkDeleteModal";
import SingleDeleteModal from "./component-assets/SingleDeleteModal";
import PerPageModal from "./component-assets/PerPageModal";
import CampusDropDown from "./component-assets/CampusDropDown";

const LegacyGroups = (props) => {
    let dData, bData, addButton, deleteButton, soloDelButton, searchButton, headerData, csvHeaders;

    headerData = [
        {label: "Group", key: "GroupName", align: "left"},
        {label: "CapCode", key: "CapCode", align: "left"}
    ]

    csvHeaders = [
        {label: "Group", key: "GroupName"},
        {label: "CapCode", key: "CapCode"}
    ]

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const [dataOrigin, setDataOrigin] = useState([])
    const [activeData, setActiveData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [userData, setUserData] = useState([]);
    const [count, setCount] = useState(0);
    const [storePage, setStorePage] = useState(1);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(dData.perPage);
    const [tmpPerPage, setTmpPerPage] = useState(dData.perPage);
    const [dContent, setDContent] = useState([]);
    const [search, setSearch] = useState("");
    const [campusId, setCampusId] = useState(dData.acnt);
    const [sorter, setSorter] = useState({
        sortColumn: "GroupName",
        sortOrder: "desc"
    });
    const [ppRules, setPRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    })
    const [csvLink, setLink] = useState({
        filename: "Groups.csv",
        headers: [],
        data: []
    })
    const [csvButton, setCSVButton] = useState(
        <CSVLink {...csvLink} uFEFF={false}>
            <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary ms-2 px-4 mt-1"}/>
        </CSVLink>)

    if (dData?.access?.Users?.UserManagement?.Write){
        addButton = (<Button text={"New Group"} type={"button"} class={"btn btn-primary ms-2 mt-1"}
                             icon={true} iconClass={"fe fe-plus me-2"} onClick={() => showModal("addmodal")} />);
        deleteButton = (<Button text={"Delete"} type={"button"} class={"btn btn-danger ms-2 hide mt-1"} id={"deleteBtn"}
                            icon={true} iconClass={"fe fe-trash-2 me-2"} onClick={() => bulkDeleteModal(ppRules.start, ppRules.end, activeData, "GroupName", ["GroupId", ""], "GroupName", setDContent)} />);
        soloDelButton = (<Button text={"Delete"} type={"button"} class={"btn btn-danger me-3"} id={"deleteSingleBtn"}
                                icon={true} iconClass={"fe fe-trash-2 me-2"} />);
    }else{
        addButton = (<Button text={"New Group"} type={"button"} class={"btn btn-primary d-none"}
                             icon={true} iconClass={"fe fe-plus me-2"} />);
        deleteButton = (<Button text={"Delete"} type={"button"} class={"btn btn-danger me-2 hide d-none"} id={"deleteBtn"}
                            icon={true} iconClass={"fe fe-trash-2 me-2"} />);
        soloDelButton = (<Button text={"Delete"} type={"button"} class={"btn btn-danger me-3 d-none"} id={"deleteSingleBtn"}
                                icon={true} iconClass={"fe fe-trash-2 me-2"} />);
    }
    searchButton = (<Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left mt-1"} style={{height: "42px"}}
                            icon={true} iconClass={"fe fe-search"}/>);


    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "getitems", Table: "User", PKey: "CampusId", PVal: campusId, SecondIndex: "CampusId-index", CampusId: campusId,
                        Projection: "UserId,DeviceName,DisplayName,UserRole,GroupIds"}).then(data => {
                setUserData(data);
            });
            ApiRequester({reqEndpoint: "getitems", Table: "Group", PKey: "CampusId", PVal: campusId,
                        Projection: "GroupId,GroupName,CapCode", CampusId: campusId}).then(data => {
                            setDataOrigin(data)
            });
            setCount(count => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        let pRules = paginateData(activeData, page, perPage)
        setPRules(pRules);
        if (activeData.length > 0) {
            activeData.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
        }
        setTableRows(<TableBody start={pRules.start} end={pRules.end} tableData={activeData} checkbox={true}
                                writeAccess={dData?.access?.Users?.UserManagement?.Write} unk={"a-"}
                                actionAppearance={() => actionAppearance(pRules.start, pRules.end, ["deleteBtn"])}
                                tableHeaders={headerData} editModal={editModal} parentComponent={"Groups"} dData={dData} />)
    }, [activeData, sorter, page, perPage, dataOrigin]);

    useEffect(() => {
        setLink(prevState => {
            return {...prevState,
                headers: csvHeaders,
                data: dataOrigin}
        });
    }, [dataOrigin])

    useEffect(() => {
        setCSVButton(
            <CSVLink {...csvLink} uFEFF={false}>
                <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary ms-2 px-4 mt-1"}/>
            </CSVLink>
        )
    }, [csvLink])

    function editModal(groupIndex){
        let groupId, groupname, capcode, dataIndex, modal, delBtn;
        dataIndex = parseInt(groupIndex);
        groupname = document.getElementById("groupNameE");
        capcode = document.getElementById("capCodeE");
        groupId = document.getElementById("groupIdE");
        delBtn = document.getElementById("deleteSingleBtn");
        groupname.value = activeData[dataIndex]?.GroupName;
        capcode.value = activeData[dataIndex]?.CapCode;
        groupId.value = activeData[dataIndex]?.GroupId;
        delBtn.onclick = () => {
            setDContent([[groupId.value, ""]])
            deleteModalSingle(groupname.value, groupname.value);
        };
        formCheck("editForm", "editSubmit");
        modal = new Modal(document.getElementById("editmodal"), {});
        modal.show();
    }

    function formSuccess(msg, formId, buttonId, loadBtnId, cancelBtnId){
        handleSuccess(msg, buttonId, loadBtnId, cancelBtnId)
        if (formId){
            formClear(formId, buttonId);
        }
        setCount(0);
    }

    function formClear(formId, buttonId){
        document.getElementById(formId).reset();
        formCheck(formId, buttonId);
    }

    function formCheck(formId, buttonId){
        let form, formArray, btn;
        form = document.getElementById(formId).querySelectorAll("input");
        btn = document.getElementById(buttonId);
        formArray = Array.from(form);
        if (formArray.every((formItem) => {return formItem.checkValidity()})){
            btn.disabled = false;
            if (formId === "addForm"){
                btn.onclick = formAddSubmit;
            }
            else if (formId === "editForm"){
                btn.onclick = formEditSubmit;
            }
            btn.classList.remove("btn-danger");
            btn.classList.add("btn-primary");
        }
        else{
            btn.disabled = true;
            btn.classList.remove("btn-primary");
            btn.classList.add("btn-danger");
        }
    }

    useEffect(() => {
        let tmpGroups, input, pRules;
        tmpGroups = [];
        input = search.toString().toLowerCase();
        for (let i=0; i < dataOrigin.length; i++){
            if ((dataOrigin[i]?.GroupName && dataOrigin[i]?.GroupName?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.CapCode && dataOrigin[i]?.CapCode?.toLowerCase()?.search(input) !== -1)){
                tmpGroups.push(dataOrigin[i]);
            }
        }
        pRules = paginateData(tmpGroups, page, perPage);
        if (input && page > pRules.pageMax){
            setPage(pRules.pageMax);
        }else if (!input){
            setPage(storePage);
        }
        setPRules(pRules);
        setActiveData(tmpGroups);
    }, [search, dataOrigin])

    function filterSubmit(){
        handleSubmitActions("filterSubmit", "loadFilterSubmit");
        setPerPage(tmpPerPage);
        dData.perPage = tmpPerPage;
        setPage(1);
        ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
        formSuccess("Page parameters stored to session.", "", "filterSubmit", "loadFilterSubmit", "cancelFilterBtn", setCount);
    }

    function formAddSubmit(){
        // User add form submit function
        let msg, groupname, capcode, payload, timestamp;
        handleSubmitActions("addSubmit", "loadAddSubmit");
        groupname = document.getElementById("groupname").value;
        capcode = document.getElementById("capcode").value;
        timestamp = getUTCTimestamp();
        payload = [{CampusId: campusId, GroupId: uuidv4(), GroupName: groupname, CapCode: capcode, Deleted: false,
                    ModifiedBy: dData?.username, DateCreated: timestamp, DateModified: timestamp}];
        ApiRequester({reqEndpoint: "save", CampusId: campusId, Tbl: "Group", PKey: "CampusId",
                        SKey: "GroupId", Payload: payload}).then(data => {
                if (data[0] === true){
                    formSuccess("Group has been added.", "addForm", "addSubmit", "loadAddSubmit", "cancelAddBtn");
                }
                else{
                    formFail(msg, "addSubmit", "loadAddSubmit");
                }
        });
    }

    function formEditSubmit(){
        // User add form submit function
        let groupId, groupname, capcode, payload;
        handleSubmitActions("editSubmit", "loadEditSubmit")
        groupId = document.getElementById("groupIdE").value;
        groupname = document.getElementById("groupNameE").value;
        capcode = document.getElementById("capCodeE").value;
        payload = [{CampusId: campusId, GroupName: groupname, GroupId: groupId, CapCode: capcode,
                    DateModified: getUTCTimestamp(), ModifiedBy: dData?.username}];
        ApiRequester({reqEndpoint: "save", Payload: payload, Tbl: "Group", PKey: "CampusId", SKey: "GroupId",
                            CampusId: campusId}).then(data => {
                if (data[0]){
                    formSuccess("Group updated.", "", "editSubmit", "loadEditSubmit", "cancelEditBtn");
                }
                else{
                    formFail(data[1], "editSubmit", "loadEditSubmit");
                }
        });
    }

    function deletePerform(submitBtn, loadBtn, closeId, start, end){
        let payload, timestamp, userPayload;
        payload = [];
        handleSubmitActions(submitBtn, loadBtn);
        timestamp = getUTCTimestamp();
        for (let i=0; i < dContent.length; i++){
            payload.push({CampusId: campusId, GroupId: dContent[i][0], Deleted: true, ModifiedBy: dData?.username,
                DateModified: timestamp});
        }
        userPayload = findUsers(dContent);
        ApiRequester({reqEndpoint: "save", Tbl: "Group", PKey: "CampusId", SKey: "GroupId", Payload: payload,
                            CampusId: campusId}).then(data => {
            if (data[0]){
                formSuccess("Groups have been deleted.", "", submitBtn, loadBtn, closeId);
                document.getElementById("uCheckHead").checked = false;
                checkAll(start, end)
                actionAppearance(start, end, ["deleteBtn"]);
                ApiRequester({reqEndpoint: "save", Tbl: "User", PKey: "UserId", Payload: userPayload, CampusId: campusId}).then(data => {
                    if (data[0]){
                        console.log("Users updated");
                    }
                    else{
                        console.log("Users not updated.");
                    }
                });
            }
            else{
                formFail(data[1], submitBtn, loadBtn);
            }
        });
    }

    function findUsers(dataset){
        let userList, timestamp, tmpGroup, idList;
        idList = [];
        userList = [];
        timestamp = getUTCTimestamp();
        for (let i2=0; i2 < dataset.length; i2++){
            idList.push(dataset[i2][0])
        }
        for (let i=0; i < userData.length; i++){
            tmpGroup = [];
            for (let i2=0; i2 < userData[i].GroupIds.length; i2++) {
                if (!idList.includes(userData[i].GroupIds[i2])){
                    tmpGroup.push(userData[i].GroupIds[i2]);
                }
            }
            if (tmpGroup?.length !== userData[i]?.GroupIds?.length){
                userList.push({CampusId: campusId, UserId: userData[i].UserId, DateModified: timestamp,
                                    ModifiedBy: dData.username, GroupIds: tmpGroup});
            }
        }
        return userList;
    }

    const tableHeaders = <TableHeaders checkbox={true} headerData={headerData} checkAll={() => checkAll(ppRules.start, ppRules.end)}
                                       writeAccess={dData?.access?.Users?.UserManagement?.Write} sortOrder={sorter.sortOrder}
                                       sortColumn={sorter.sortColumn} sorter={sorter} setSorter={setSorter}
                                       actionAppearance={() => actionAppearance(ppRules.start, ppRules.end, ["deleteBtn"])}/>

    const contentHeader = (
        <div className={"d-flex justify-content-between"}>
            <div style={{minWidth: "360px"}}>
                {deleteButton}
                <div className={"form-outline d-inline-flex align-bottom ms-2"}>
                    <input type={"search"} id={"search1"} className={"form-control search-input mt-1"}
                         onKeyUp={(e) => setSearch(e.target.value)} />
                </div>
                {searchButton}
                {csvButton}
                {addButton}
            </div>
            <CampusDropDown campusId={campusId} setCampusId={(item) => setCampusId(item)} setCount={(item) => setCount(item)} pageAction={() => {}}/>
        </div>
    )

    const footer = (
        <div>
            <Pagination>
                <PaginationItems setPage={setPage} page={page} beginDot={ppRules.beginDot}
                                 endDot={ppRules.endDot} pageMin={ppRules.pageMin} pageMax={ppRules.pageMax}
                                 max={ppRules.max}/>
            </Pagination>
            <Button class={"btn btn-secondary fs-5 ms-4"} text={perPage} onClick={() => showModal("filtermodal")}/>
        </div>
    )

    return (dData?.slauth === true && dData?.tKey != null && dData?.tKey !== "" && dData?.acnt != null) ? (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Users"} Title={"Groups"} content={contentHeader} />
                <div className={"container-fluid mt-4"}>
                    <table className={"table table-sm table-white table-hover"}>
                        <thead className={"sticky-table-head"}>
                            {tableHeaders}
                        </thead>
                        <tbody id={"tBody"}>
                            {tableRows}
                        </tbody>
                    </table>
                    <div className={"footer-spacer"}>

                    </div>
                </div>
                <Footer center={footer} />
            </div>
            <div className={"modal fade"} id={"addmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Add Group</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"addForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"groupname"}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           required={true} pattern={"[a-zA-Z ]+"}/>
                                    <label htmlFor={"groupname"}>Group</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a valid group name.
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"capcode"}
                                            onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           required={true} pattern={"[0-9]+"}/>
                                    <label htmlFor={"capcode"}>CapCode</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a valid CapCode!
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <h3 className={"fw-bold"}>*Remember to build your escalation list on your server to match any groups
                                        created. If you have questions, please contact our Customer Experience Team*</h3>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <button type={"button"} className={"btn btn-secondary"} id={"cancelAddBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                <Button type={"button"} class={"btn btn-primary d-none"} disabled={true} spinner={true} text={"Loading"} id={"loadAddSubmit"}/>
                                <button type={"button"} className={"btn btn-danger"} disabled={true} id={"addSubmit"}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"editmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Edit Group</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"editForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <input type={"hidden"} id={"groupIdE"} />
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"groupNameE"}
                                           onKeyUp={() => formCheck("editForm", "editSubmit")}
                                           required={true} pattern={"[a-zA-Z ]+"}/>
                                    <label htmlFor={"groupNameE"}>Group</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a group name!
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"capCodeE"}
                                           onKeyUp={() => formCheck("editForm", "editSubmit")}
                                           required={true} pattern={"[0-9]+"}/>
                                    <label htmlFor={"capCodeE"}>CapCode</label>
                                    <div className={"invalid-feedback"}>
                                        Enter a valid CapCode!
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"} style={{justifyContent: "space-between"}}>
                                <div>
                                    {soloDelButton}
                                </div>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelEditBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadEditSubmit"}/>
                                    <button type={"button"} className={"btn btn-danger ms-3"} disabled={true} id={"editSubmit"}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <SingleDeleteModal deletePerform={() => deletePerform("deleteSubmit", "loadDeleteSubmit", "confirmClose", ppRules.start, ppRules.end)}
                                header={"Delete Group"}/>
            <BulkDeleteModal deletePerform={() => deletePerform("bulkDeleteSubmit", "loadBulkDeleteSubmit", "bulkConfirmClose", ppRules.start, ppRules.end)}
                             start={ppRules.start} end={ppRules.end} header={"Delete Groups"} />
            <PerPageModal filterSubmit={() => filterSubmit()} tmpPerPage={tmpPerPage} setTmpPerPage={(sudocode) => setTmpPerPage(sudocode)} setPage={(sudocode) => setPage(sudocode)}/>
            <input type={"hidden"} id={"uContent"}/>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </>
    ) : <Navigate to={"/"}/>;
};

export default LegacyGroups;