import {Link, Navigate, useLocation, useNavigate} from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import ApiRequester from "./ApiRequester";
import Button from "./component-assets/Button";
import {
    formFail, formSuccess, handleSubmitActions, paginateData, showModal, sortFunc
} from "../libs";
import {Pagination} from "react-bootstrap";
import PaginationItems from "./component-assets/PaginationItems";
import Header from "./Header";
import Footer from "./Footer";
import {toast, ToastContainer} from "react-toastify";
import PerPageModal from "./component-assets/PerPageModal";
import TableBody from "./component-assets/TableBody";
import TableHeaders from "./component-assets/TableHeaders";


const CampusUploadPreview = (props) => {
    let dData, bData, areaHeaderData, resHeaderData, deviceHeaderData, footer;

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const navigate = useNavigate();
    const useLocationData = useLocation();

    areaHeaderData = [
        {label: "Name", key: "area_name", align: "left"},
        {label: "Area Type", key: "area_type_description", align: "left"},
        {label: "Residents", key: "resident_name", align: "left"},
        {label: "Devices", key: "device_name", align: "left"}
    ];
    resHeaderData = [
        {label: "First Name", key: "resident_first_name", align: "left"},
        {label: "Last Name", key: "resident_last_name", align: "left"},
        {label: "Area", key: "area_name", align: "left"}
    ];
    deviceHeaderData = [
        {label: "Name", key: "device_name", align: "left"},
        {label: "Description", key: "descriptions", align: "left"},
        {label: "Model", key: "model_name", align: "center"},
        {label: "Serial Number", key: "manufacture_uuid", align: "center"},
        {label: "Resident", key: "resident_name", align: "left"},
        {label: "Area", key: "area_name", align: "left"}
    ];

    const [uploadKey, setUploadKey] = useState(props?.id);
    const [keyValid, setKeyValid] = useState(false);
    const [campusId, setCampusId] = useState(parseInt(props?.campus_id));
    const [stateData, setStateData] = useState(useLocationData.state);
    const [count, setCount] = useState(0);
    const [uploadTimer, setUploadTimer] = useState(-1);
    const [search, setSearch] = useState("");
    const [tabItem, setTabItem] = useState("area");
    const [areaData, setAreaData] = useState([]);
    const [activeAreaData, setActiveAreaData] = useState([]);
    const [areaRows, setAreaRows] = useState([]);
    const [areaLoaded, setAreaLoaded] = useState(false);
    const [areaSorter, setAreaSorter] = useState({
        sortColumn: "area_name",
        sortOrder: "desc"
    });
    const [areappRules, setAreaPRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    });
    const [resData, setResData] = useState([]);
    const [activeResData, setActiveResData] = useState([]);
    const [resRows, setResRows] = useState([]);
    const [resLoaded, setResLoaded] = useState(false);
    const [resSorter, setResSorter] = useState({
        sortColumn: "resident_first_name",
        sortOrder: "desc"
    });
    const [resppRules, setResPRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    });
    const [deviceData, setDeviceData] = useState([]);
    const [activeDeviceData, setActiveDeviceData] = useState([]);
    const [deviceRows, setDeviceRows] = useState([]);
    const [deviceLoaded, setDeviceLoaded] = useState(false);
    const [deviceSorter, setDeviceSorter] = useState({
        sortColumn: "device_name",
        sortOrder: "desc"
    });
    const [deviceppRules, setDevicePRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    });
    const [loaded, setLoaded] = useState(false);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(dData.perPage);
    const [tmpPerPage, setTmpPerPage] = useState(dData.perPage);

    useEffect(() => {
        if (!keyValid){
            ApiRequester({reqEndpoint: "myfetch", pKey: "unique_key", pVal: uploadKey, Tbl: "stg_upload_keys",
                                procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                if (data && data.length > 0){
                    setKeyValid(true);
                }else{
                    navigate(`/profiles/campus/${campusId}`,
                        {state: {message: {success: "", error: "Invalid key"}}});
                }
            });
        }
    }, [keyValid]);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0 && keyValid){
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId,
                                procedure: "get_staging_areas", reqType: "stored"}).then(data => {
                setAreaData(data ? data : []);
                setAreaLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId,
                                procedure: "get_staging_residents", reqType: "stored"}).then(data => {
                setResData(data ? data : []);
                setResLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId,
                                procedure: "get_staging_devices", reqType: "stored"}).then(data => {
                setDeviceData(data ? data : []);
                setDeviceLoaded(true);
            });
            setCount(() => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setUploadTimer(count => count - 1);
        }, 1000);
        if (uploadTimer > 0 && uploadTimer%5 === 0 && uploadKey !== ""){
            ApiRequester({reqEndpoint: "myfetch", pKey: "unique_key", pVal: uploadKey, Tbl: "stg_upload_keys",
                                procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                if (data && data.length > 0){
                    if (data[0]?.submit_complete === 1){
                        setUploadTimer(-1);
                        handleSubmitActions("loadUploadSubmit", "uploadSubmit");
                        document.getElementById("cancelUploadSubmitBtn").click();
                        navigate(`/profiles/campus/${campusId}`,
                            {state: {message: {success: "Upload completed successfully", error: ""}}});
                    }else if (data[0]?.submit_error === 1){
                        setUploadTimer(-1);
                        handleSubmitActions("loadUploadSubmit", "uploadSubmit");
                        toast.error("Error encountered on the backend, contact software support.");
                    }
                }
            });
        }
        if (uploadTimer === 0){
            handleSubmitActions("loadUploadSubmit", "uploadSubmit");
            toast.error("Timed out finishing, please try again.");
        }
        return () => clearInterval(interval);
    }, [uploadTimer]);

    useEffect(() => {
        if (stateData && stateData?.message){
            if (stateData.message?.error){
                toast.error(stateData.message.error);
            }else if (stateData.message?.success){
                toast.success(stateData.message.success);
            }
        }
    }, [stateData]);

    useEffect(() => {
        // Performs realtime search of data as location types.
        let tmpData, input, pRules;
        tmpData = [];
        input = document.getElementById("search1").value.toString().toLowerCase();
        for (let i=0; i<areaData.length; i++){
            if (((areaData[i]?.area_type_description && areaData[i]?.area_type_description?.toLowerCase()?.search(input) !== -1) ||
                 (areaData[i]?.resident_name && areaData[i]?.resident_name?.toLowerCase()?.search(input) !== -1) ||
                 (areaData[i]?.area_name && areaData[i]?.area_name?.toLowerCase()?.search(input) !== -1))){
                tmpData.push(areaData[i]);
            }
        }
        pRules = paginateData(tmpData, page, perPage);
        if (input && page > pRules.pageMax){
            setPage(pRules.pageMax);
        }
        setAreaPRules(pRules);
        setActiveAreaData(tmpData);
    }, [search, areaData]);


    useEffect(() => {
        // Performs realtime search of data as location types.
        let tmpData, input, pRules;
        tmpData = [];
        input = document.getElementById("search1").value.toString().toLowerCase();
        for (let i=0; i<resData.length; i++){
            if (((resData[i]?.resident_first_name && resData[i]?.resident_first_name?.toLowerCase()?.search(input) !== -1) ||
                 (resData[i]?.resident_last_name && resData[i]?.resident_last_name?.toLowerCase()?.search(input) !== -1) ||
                 (resData[i]?.area_name && resData[i]?.area_name?.toLowerCase()?.search(input) !== -1))){
                tmpData.push(resData[i]);
            }
        }
        pRules = paginateData(tmpData, page, perPage);
        if (input && page > pRules.pageMax){
            setPage(pRules.pageMax);
        }
        setResPRules(pRules);
        setActiveResData(tmpData);
    }, [search, resData]);


    useEffect(() => {
        // Performs realtime search of data as location types.
        let tmpData, input, pRules;
        tmpData = [];
        input = document.getElementById("search1").value.toString().toLowerCase();
        for (let i=0; i<deviceData.length; i++){
            if (((deviceData[i]?.device_name && deviceData[i]?.device_name?.toLowerCase()?.search(input) !== -1) ||
                 (deviceData[i]?.descriptions && deviceData[i]?.descriptions?.toLowerCase()?.search(input) !== -1) ||
                 (deviceData[i]?.inovonics_model_name && deviceData[i]?.inovonics_model_name?.toLowerCase()?.search(input) !== -1) ||
                 (deviceData[i]?.resident_name && deviceData[i]?.resident_name?.toLowerCase()?.search(input) !== -1) ||
                 (deviceData[i]?.area_name && deviceData[i]?.area_name?.toLowerCase()?.search(input) !== -1) ||
                 (deviceData[i]?.manufacture_uuid && deviceData[i]?.manufacture_uuid?.toLowerCase()?.search(input) !== -1))){
                tmpData.push(deviceData[i]);
            }
        }
        pRules = paginateData(tmpData, page, perPage);
        if (input && page > pRules.pageMax){
            setPage(pRules.pageMax);
        }
        setDevicePRules(pRules);
        setActiveDeviceData(tmpData);
    }, [search, deviceData]);


    useEffect(() => {
        let pRules = paginateData(activeAreaData, page, perPage);
        setAreaPRules(pRules);
        if (activeAreaData.length > 0) {
            activeAreaData.sort((a, b) => sortFunc(a, b, areaSorter.sortColumn, areaSorter.sortOrder));
        }
        setAreaRows(<TableBody start={pRules.start} end={pRules.end} tableData={activeAreaData} checkbox={false}
                                writeAccess={false} unk={"a-"} actionAppearance={() => {}}
                                tableHeaders={areaHeaderData} editModal={() => {}} modal={false}
                                dData={dData} checkboxlist={[]} parentComponent={"upload"}
                                setCheckboxId={() => {}} checkidkey={""} />);
    }, [activeAreaData, areaSorter, page, perPage]);

    useEffect(() => {
        let pRules = paginateData(activeResData, page, perPage);
        setResPRules(pRules);
        if (activeResData.length > 0) {
            activeResData.sort((a, b) => sortFunc(a, b, resSorter.sortColumn, resSorter.sortOrder));
        }
        setResRows(<TableBody start={pRules.start} end={pRules.end} tableData={activeResData} checkbox={false}
                                writeAccess={false} unk={"b-"} actionAppearance={() => {}}
                                tableHeaders={resHeaderData} editModal={() => {}} modal={false}
                                dData={dData} checkboxlist={[]} parentComponent={"upload"}
                                setCheckboxId={() => {}} checkidkey={""} />);
    }, [activeResData, resSorter, page, perPage]);

    useEffect(() => {
        let pRules = paginateData(activeDeviceData, page, perPage);
        setDevicePRules(pRules);
        if (activeDeviceData.length > 0) {
            activeDeviceData.sort((a, b) => sortFunc(a, b, deviceSorter.sortColumn, deviceSorter.sortOrder));
        }
        setDeviceRows(<TableBody start={pRules.start} end={pRules.end} tableData={activeDeviceData} checkbox={false}
                                writeAccess={false} unk={"a-"} actionAppearance={() => {}}
                                tableHeaders={deviceHeaderData} editModal={() => {}} modal={false}
                                dData={dData} checkboxlist={[]} parentComponent={"upload"}
                                setCheckboxId={() => {}} checkidkey={""} />);
    }, [activeDeviceData, deviceSorter, page, perPage]);

    useEffect(() => {
        if (![areaLoaded, deviceLoaded, resLoaded].includes(false)){
            setLoaded(true);
        }
    }, [areaLoaded, resLoaded, deviceLoaded]);

    function filterSubmit(){
        // Submit function submitted by the pagination number of items per page modal.
        handleSubmitActions("filterSubmit", "loadFilterSubmit");
        setPerPage(tmpPerPage);
        dData.perPage = tmpPerPage;
        setPage(1);
        ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
        formSuccess("Page parameters stored to session.", "filterSubmit",
            "loadFilterSubmit", "cancelFilterBtn");
    }

    function uploadSubmit(){
        handleSubmitActions("uploadSubmit", "loadUploadSubmit");
        let payload = {file_key: uploadKey};
        ApiRequester({reqEndpoint: "uploadprocess", Payload: payload, User: dData.user,
                            CampusId: campusId, Process: "finish"}).then(data => {
            if (data){
                toast.info("File upload finalizing, awaiting results");
                setUploadTimer(120);
            }
            else{
                formFail(`Error during file upload: ${data[1]}`, "uploadSubmit", "loadUploadSubmit");
            }
        });
    }

    const areaHeaders = <TableHeaders checkbox={false} headerData={areaHeaderData} checkAll={() => {}}
                                      writeAccess={false} sortOrder={areaSorter.sortOrder} checkboxHeaderId={""}
                                      sortColumn={areaSorter.sortColumn} sorter={areaSorter} setSorter={setAreaSorter}
                                      modal={false} checkboxlist={[]} actionAppearance={() => {}} modal_name={""}/>
    const resHeaders = <TableHeaders checkbox={false} headerData={resHeaderData} checkAll={() => {}}
                                      writeAccess={false} sortOrder={resSorter.sortOrder} checkboxHeaderId={""}
                                      sortColumn={resSorter.sortColumn} sorter={resSorter} setSorter={setResSorter}
                                      modal={false} checkboxlist={[]} actionAppearance={() => {}} modal_name={""}/>
    const deviceHeaders = <TableHeaders checkbox={false} headerData={deviceHeaderData} checkAll={() => {}}
                                      writeAccess={false} sortOrder={deviceSorter.sortOrder} checkboxHeaderId={""}
                                      sortColumn={deviceSorter.sortColumn} sorter={deviceSorter} setSorter={setDeviceSorter}
                                      modal={false} checkboxlist={[]} actionAppearance={() => {}} modal_name={""}/>

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div className={"me-3"}>
                <div className={"form-outline d-inline-flex align-bottom ms-2"}>
                    <input type={"search"} id={"search1"} className={"form-control search-input mt-1"}
                           onKeyUp={(e) => setSearch(e.target.value)} />
                    <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left mt-1"}
                            style={{height: "42px"}} icon={true} iconClass={"fe fe-search"} id={"search-btn-1"}/>
                </div>
            </div>
            <div className={"mt-1"}>
                <Button text={"Submit"} class={"btn btn-primary mx-4"} style={{width: "125px"}} id={"uploadPreSubmit"}
                        type={"button"} onClick={() => showModal("uploadsubmitmodal")}/>
                <Link to={`/profiles/campus/${campusId}`}>
                    <Button text={"Cancel"} class={"btn btn-outline-danger"} type={"button"}/>
                </Link>
            </div>
        </div>
    );

    if (tabItem === "area") {
        footer = (
            <div>
                <Pagination>
                    <PaginationItems setPage={setPage} page={page} beginDot={areappRules.beginDot}
                                     endDot={areappRules.endDot} pageMin={areappRules.pageMin} pageMax={areappRules.pageMax}
                                     max={areappRules.max}/>
                </Pagination>
                <Button class={"btn btn-secondary fs-5 ms-4"} text={perPage} onClick={() => showModal("filtermodal")}/>
            </div>
    )
    }else if (tabItem === "resident"){
        footer = (
            <div>
                <Pagination>
                    <PaginationItems setPage={setPage} page={page} beginDot={resppRules.beginDot}
                                     endDot={resppRules.endDot} pageMin={resppRules.pageMin} pageMax={resppRules.pageMax}
                                     max={resppRules.max}/>
                </Pagination>
                <Button class={"btn btn-secondary fs-5 ms-4"} text={perPage} onClick={() => showModal("filtermodal")}/>
            </div>
    )
    }else{
        footer = (
            <div>
                <Pagination>
                    <PaginationItems setPage={setPage} page={page} beginDot={deviceppRules.beginDot}
                                     endDot={deviceppRules.endDot} pageMin={deviceppRules.pageMin} pageMax={deviceppRules.pageMax}
                                     max={deviceppRules.max}/>
                </Pagination>
                <Button class={"btn btn-secondary fs-5 ms-4"} text={perPage} onClick={() => showModal("filtermodal")}/>
            </div>
        )
    }

    return (dData?.slauth === true && dData?.tKey != null && dData?.tKey !== "" && dData?.acnt != null && dData?.access?.Community?.Areas?.Read) ? (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Community"} Title={"Areas"} content={headerContent}/>
                <div className={"container-fluid"}>
                    <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                        <li className={"nav-item"}>
                            <Button text={"Areas"} class={tabItem === "area" ? "nav-link active" : "nav-link"}
                                    onClick={() => {
                                        setTabItem("area");
                                        setPage(1);
                                    }}/>
                        </li>
                        <li className={"nav-item"}>
                            <Button text={"Residents"} class={tabItem === "resident" ? "nav-link active" : "nav-link"}
                                    onClick={() => {
                                        setTabItem("resident");
                                        setPage(1);
                                    }}/>
                        </li>
                        <li className={"nav-item"}>
                            <Button text={"Devices"} class={tabItem === "device" ? "nav-link active" : "nav-link"}
                                    onClick={() => {
                                        setTabItem("device");
                                        setPage(1);
                                    }}/>
                        </li>
                    </ul>
                    {loaded === true
                        ? <>
                            <div className={tabItem === "area" ? "" : "d-none"}>
                                <table className={"table table-sm table-white table-hover"}>
                                    <thead
                                        className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString() ? "sticky-table-headX" : "sticky-table-head"}>
                                    {areaHeaders}
                                    </thead>
                                    <tbody id={"tBody"}>
                                    {areaRows}
                                    </tbody>
                                </table>
                            </div>
                            <div className={tabItem === "resident" ? "" : "d-none"}>
                                <table className={"table table-sm table-white table-hover"}>
                                    <thead
                                        className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString() ? "sticky-table-headX" : "sticky-table-head"}>
                                    {resHeaders}
                                    </thead>
                                    <tbody id={"tBody"}>
                                    {resRows}
                                    </tbody>
                                </table>
                            </div>
                            <div className={tabItem === "device" ? "" : "d-none"}>
                                <table className={"table table-sm table-white table-hover"}>
                                    <thead
                                        className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString() ? "sticky-table-headX" : "sticky-table-head"}>
                                    {deviceHeaders}
                                    </thead>
                                    <tbody id={"tBody"}>
                                    {deviceRows}
                                    </tbody>
                                </table>
                            </div>
                        </>
                        : <div>{keyValid ? <p>Loading data...</p> : <p>Key invalid</p>}</div>
                    }
                    <div className={"footer-spacer"}>

                    </div>
                </div>
                <Footer center={footer}/>
            </div>
            <div className={"modal fade"} id={"uploadsubmitmodal"} data-bs-backdrop={"static"}
                 data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticUploadSubmitLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticUploadSubmitLabel"}>Confirm Upload</h2>
                            <button type={"button"} className={"btn-close"} id={"uploadSubmitClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <div className={"modal-body"}>
                            <p>Have you reviewed all of the data and confirmed it looks right, are you sure you want to
                                apply this data to the community?
                            </p>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} id={"cancelUploadSubmitBtn"}
                                    data-bs-dismiss={"modal"} style={{width: "125px"}}>No
                            </button>
                            <Button type={"button"} class={"btn btn-primary d-none mx-4"} style={{width: "125px"}}
                                    disabled={true} spinner={true} text={"Uploading"} id={"loadUploadSubmit"}/>
                            <Button text={"Yes"} class={"btn btn-primary mx-4"} id={"uploadSubmit"} style={{width: "125px"}}
                                    type={"button"} onClick={() => uploadSubmit()}/>
                        </div>
                    </div>
                </div>
            </div>
            <PerPageModal filterSubmit={() => filterSubmit()} tmpPerPage={tmpPerPage}
                          setTmpPerPage={(sudocode) => setTmpPerPage(sudocode)}
                          setPage={(sudocode) => setPage(sudocode)}/>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true}
                                autoClose={10000}/>
            </Suspense>
        </>
    ) : <Navigate to={"/"}/>;
};

export default CampusUploadPreview;
