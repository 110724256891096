import { Navigate } from 'react-router-dom';
import Navigation from "./Navigation";
import LegacyCurrentAlerts from "./LegacyCurrentAlerts";
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import { useParams } from "react-router"
import CurrentAlerts from "./CurrentAlerts";
import CurrentCloudAlerts from "./CurrentCloudAlerts";
import HeaderBanner from "./component-assets/HeaderBanner";
import SessionReload from "./component-assets/SessionReload";

const Alerts = (props) => {
    let alertContent, dData, paramVars, bData;
    paramVars = useParams()

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    if (dData.migrated){
        if (dData?.cloud){
            if (paramVars.dType === "all" && dData?.access?.Alerts?.All?.Read){
                alertContent = <CurrentCloudAlerts toFetch={"all"}/>;
            }
            else if (paramVars.dType === "emergency" && dData?.access?.Alerts?.Emergency?.Read){
                alertContent = <CurrentCloudAlerts toFetch={"emergency"}/>;
            }
            else if (paramVars.dType === "maintenance" && dData?.access?.Alerts?.Maintenance?.Read){
                alertContent = <CurrentCloudAlerts toFetch={"maintenance"}/>;
            }
            else{
                alertContent = (
                    <div>
                        <h3>Page not found. 404</h3>
                    </div>
                );
            }
        }else{
            if (paramVars.dType === "all" && dData?.access?.Alerts?.All?.Read){
                alertContent = <CurrentAlerts toFetch={"all"} fullscreen={props?.fullscreen}/>;
            }
            else if (paramVars.dType === "emergency" && dData?.access?.Alerts?.Emergency?.Read){
                alertContent = <CurrentAlerts toFetch={"emergency"} fullscreen={props?.fullscreen}/>;
            }
            else if (paramVars.dType === "maintenance" && dData?.access?.Alerts?.Maintenance?.Read){
                alertContent = <CurrentAlerts toFetch={"maintenance"} fullscreen={props?.fullscreen}/>;
            }
            else{
                alertContent = (
                    <div>
                        <h3>Page not found. 404</h3>
                    </div>
                );
            }
        }
    }else{
        if (paramVars.dType === "all" && dData?.access?.Alerts?.All?.Read){
            alertContent = <LegacyCurrentAlerts toFetch={"all"}/>;
        }
        else if (paramVars.dType === "emergency" && dData?.access?.Alerts?.Emergency?.Read){
            alertContent = <LegacyCurrentAlerts toFetch={"emergency"}/>;
        }
        else if (paramVars.dType === "maintenance" && dData?.access?.Alerts?.Maintenance?.Read){
            alertContent = <LegacyCurrentAlerts toFetch={"maintenance"}/>;
        }
        else if (paramVars.dType === "system" && dData?.access?.Alerts?.System?.Read){
            alertContent = <LegacyCurrentAlerts toFetch={"system"}/>;
        }
        else{
            alertContent = (
                <div>
                    <h3>Page not found. 404</h3>
                </div>
            );
        }
    }

    return (dData?.slauth === true && dData?.tKey != null && dData?.tKey !== "" && dData?.acnt != null && dData?.access?.Alerts) ? (
        <>
            {!props?.fullscreen && <Navigation />}
            {dData?.migrated && <SessionReload />}
            {dData?.myVersion !== dData?.currentVersion && dData?.cloud ? <HeaderBanner /> : <></>}
            {alertContent}
        </>
    ) : <Navigate to={"/"}/>;
};

export default Alerts;