import { ReactSession } from 'react-client-session';
import {Navigate} from "react-router-dom";
import {AES, enc} from "crypto-js";
import Navigation from "./Navigation";
import LegacyDemoPanel from "./LegacyDemoPanel";
import DemoPanel from "./DemoPanel";
import {compareRole, iAmHigherAuthority} from "../libs";
import HeaderBanner from "./component-assets/HeaderBanner";
import SessionReload from "./component-assets/SessionReload";

function Demo(props) {
    let dData, bData, pageContent;

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    if (dData.migrated){
        pageContent = <DemoPanel />;
    }
    else{
        pageContent = <LegacyDemoPanel />;
    }

    return (dData?.slauth === true && dData?.tKey != null && dData?.tKey !== "" && dData?.acnt != null && (!compareRole("corporate-admin", dData) || iAmHigherAuthority(80, dData?.role_weight))) ? (
        <>
            <Navigation />
            {dData?.myVersion !== dData?.currentVersion && dData?.cloud ? <HeaderBanner /> : <></>}
            {dData?.migrated && <SessionReload />}
            {pageContent}
        </>
    ) : <Navigate to={"/"}/>;
}

export default Demo;