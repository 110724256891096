import { Navigate } from 'react-router-dom';
import Navigation from "./Navigation";
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import { useParams } from "react-router";
import SystemSettings from "./SystemSettings";
import WellnessSettings from "./WellnessSettings";
import IntegrationSettings from "./IntegrationSettings";
import SessionReload from "./component-assets/SessionReload";
import HeaderBanner from "./component-assets/HeaderBanner";
import EnvironmentSettings from "./EnvironmentSettings";
import {iAmHigherAuthority} from "../libs";

const Settings = (props) => {
    let settingsContent, dData, paramVars, bData;
    paramVars = useParams();

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    if (paramVars.dType === "system" && dData?.access?.Settings?.Mobile?.Read && iAmHigherAuthority(20, dData.role_weight)){
        settingsContent = <SystemSettings />;
    }else if (paramVars.dType === "wellness" && dData?.access?.Settings?.Mobile?.Read && iAmHigherAuthority(20, dData.role_weight)){
        settingsContent = <WellnessSettings />;
    }else if (paramVars.dType === "integrations" && dData?.access?.Settings?.Mobile?.Read && iAmHigherAuthority(80, dData.role_weight)){
        settingsContent = <IntegrationSettings />;
    }else if (paramVars.dType === "environmental" && dData?.access?.Settings?.Mobile?.Read && iAmHigherAuthority(20, dData.role_weight)){
        settingsContent = <EnvironmentSettings />;
    }
    else{
        settingsContent = (
            <div>
                <h3>Page not found. 404</h3>
            </div>
        );
    }

    return (dData?.slauth === true && dData?.tKey != null && dData?.tKey !== "" && dData?.acnt != null && dData?.access?.Settings) ? (
        <>
            <Navigation />
            {dData?.myVersion !== dData?.currentVersion && dData?.cloud ? <HeaderBanner /> : <></>}
            {dData?.migrated && <SessionReload />}
            {settingsContent}
        </>
    ) : <Navigate to={"/"}/>;
};

export default Settings;