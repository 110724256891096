import TableHeaders from "../component-assets/TableHeaders";
import TableBodySimple from "../component-assets/TableBodySimple";

const Inspection = (props) => {
    let summaries, summaryClass, detailedClass, headerData, tableBody;

    headerData = [
        {label: "Device", key: "DeviceUse"},
        {label: "Serial Number", key: "serialNum"},
        {label: "Location", key: "Location", type: "obj"},
        {label: "Last Active", key: "LastAlarmDateTime"},
        {label: "Status", key: "Status"}
    ]

    if (props.repType === "Detailed"){
        summaryClass = "d-none";
        detailedClass = "";
    }else{
        summaryClass = "";
        detailedClass = "d-none";
    }

    summaries =(
        <>
            <td className={"ps-1"}>Totals</td>
            <td className={"ps-1"}>{props.reportData?.Pass}</td>
            <td className={"ps-1"}>{props.reportData?.Fail}</td>
        </>
    )

    const tableHeaders = <TableHeaders checkbox={false} headerData={headerData} sortOrder={props?.sorter?.sortOrder}
                                       sortColumn={props?.sorter?.sortColumn} sorter={props?.sorter} setSorter={props?.setSorter}/>;
    if (props.tableRows !== undefined){
        tableBody = (<TableBodySimple tableData={props?.tableRows} checkbox={false} tableHeaders={headerData} />);
    }else{
        tableBody = "";
    }

    return (
        <>
            <div className={summaryClass}>
                <h6 className={"my-1 fs-3 px-4"}>
                    {props.repType}: <span className={"fs-3 d-inline ms-2"} style={{color: "#0081c7"}}>{props.start}</span> - <span className={"fs-3 d-inline"} style={{color: "#0081c7"}}>{props.end}</span>
                </h6>
                <div className={"px-4 pt-1 pb-4 my-2"}>
                    <div className={"text-center align-top w-100"}>
                        <table className={"table table-sm table-white table-hover"}>
                            <thead>
                                <tr>
                                    <th className={"table table-header w-auto py-1"}>

                                    </th>
                                    <th className={"table table-header w-auto py-1"}>
                                        Passed
                                    </th>
                                    <th className={"table table-header w-auto py-1"}>
                                        Failed
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {summaries}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className={detailedClass}>
                <h6 className={"my-1 fs-3 px-4"}>
                    {props.repType}: <span className={"fs-3 d-inline ms-2"} style={{color: "#0081c7"}}>{props.start}</span> - <span className={"fs-3 d-inline"} style={{color: "#0081c7"}}>{props.end}</span>
                </h6>
                <div className={"pt-1 pb-4 px-4 my-2"}>
                    <table className={"table table-sm table-white table-hover"}>
                        <thead className={"sticky-table-head"}>
                            {tableHeaders}
                        </thead>
                        <tbody>
                            {tableBody}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default Inspection;