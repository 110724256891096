import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import Header from "./Header";
import {useState, useEffect, Suspense} from "react";
import ApiRequester from "./ApiRequester";
import {durationToNow, getTTL, getUTCTimestamp, sortFunc, campusToGuid, userToGuid} from "../libs";
import Button from "./component-assets/Button";
import {CSVLink} from "react-csv";
import {toast, ToastContainer} from "react-toastify";
import Footer from "./Footer";
import TableHeaders from "./component-assets/TableHeaders";
import CampusDropDown from "./component-assets/CampusDropDown";


const LegacyCurrentAlerts = (props) => {

    let dData, headerData, csvHeaders;

    headerData = [
        {label: "Event Date / Time", key: "EventDateTime"},
        {label: "Duration", key: "Elapsed"},
        {label: "Alert Category", key: "AlertType"}
    ];
    if (props?.toFetch === "all"){
        headerData.push({label: "Alert Type", key: "AlertSubType"});
    }
    else if(props?.toFetch === "maintenance"){
        headerData.push({label: "Alert Type", key: "AlertSubType"});
    }
    headerData.push({label: "Resident Name", key: "ResidentsParsed"});
    headerData.push({label: "Area / Device", key: "DeviceUse"});
    headerData.push({label: "Building / Location", key: "LocationName"});
    headerData.push({label: "Near", key: "NearName"});
    if(props?.toFetch === "all" || props?.toFetch === "emergency") {
        headerData.push({label: "Accepted", key: "AcceptUsername"});
    }
    headerData.push({label: "Status", key: "Status"});


    csvHeaders = [
        {label: "Event Time", key: "EventDateTime"},
        {label: "Duration (seconds)", key: "Elapsed"},
        {label: "Alert Type", key: "AlertType"},
        {label: "Reason", key: "AlertSubType"},
        {label: "Residents", key: "ResidentsParsed"},
        {label: "Device", key: "DeviceUse"},
        {label: "Location", key: "LocationName"},
        {label: "Near", key: "NearName"},
        {label: "Status", key: "Status"}
    ];

    if (ReactSession.get("PAL") != null){
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    if (props?.toFetch === "maintenance" && dData.userrole !== "caregiver"){

    }

    const [gData, setData] = useState({
        alertData: [],
        tableData: [],
        dataLength: 0
    });
    const [tableRows, setTableRows] = useState([]);
    const [sorter, setSorter] = useState({
        sortColumn: "EventDateTime",
        sortOrder: "asc"
    });
    const [count, setCount] = useState(0);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [csvLink, setLink] = useState({
        filename: "maintenance_alarms.csv",
        headers: [],
        data: []
    });
    const [csvButton, setCSVButton] = useState(
        <CSVLink {...csvLink} uFEFF={false}>
                <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary ms-2 px-4 mt-1"}/>
            </CSVLink> ? props?.toFetch === "maintenance" : ""
        )

    useEffect(() => {
        setCount(count => 0);
    }, [props.toFetch]);

    useEffect(() => {
        let interval, toFetch, tmp, ie;
        interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            if (props?.toFetch === "system"){
                toFetch = "sys";
            }
            else if (props?.toFetch === "maintenance"){
                toFetch = "maint";
            }
            else if (props?.toFetch === "emergency"){
                toFetch = "alarm";
            }
            else{
                toFetch = "all";
            }
            ApiRequester({reqEndpoint: "get-alerts", CampusId: campusToGuid(dData, campusId),
                                ToGet: toFetch, UserId: userToGuid(dData, dData.user), Migrated: dData.migrated}).then(data => {
                if (data.length > 0 && data[0][0] !== ""){
                    tmp = data;
                    for (ie=0; ie < tmp.length; ie++){
                        tmp[ie].ElapsedFormatted = durationToNow(tmp[ie].EventDateTime);
                    }
                    setData(prevState => {
                        return {...prevState, tableData: tmp}});
                }else{
                    setData(prevState => {
                        return {...prevState, tableData: []}});
                }
            })
            setCount((count) => 7);
        }
        else if (gData.tableData.length > 0 && gData.tableData[0]){
            let newtmp = gData.tableData;
            for (ie=0; ie < newtmp.length; ie++){
                newtmp[ie].ElapsedFormatted = durationToNow(newtmp[ie].EventDateTime);
            }
            setData(prevState => {
                return {...prevState, tableData: newtmp}});
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        let index = 0;
        let addOns, tmpTD, accepted, actionAdd = (<td className={"d-none"}></td>);
        let tmp = [];
        if (gData.tableData?.length > 0) {
            gData.tableData.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
        }
        for (index = 0; index < gData.tableData?.length; index++){
            let curIndex = index.toString();
            if (props?.toFetch === "all"){
                addOns = [
                    <td className={"text-center"}>{gData.tableData[curIndex]?.AlertSubType}</td>];
            }else if (props?.toFetch === "maintenance"){
                addOns = (<td className={"text-center"}>{gData.tableData[curIndex]?.AlertSubType}</td>);
            }else{
                addOns = (<td className={"d-none"}></td>);
            }
            if (props?.toFetch === "all" || props?.toFetch === "emergency"){
                accepted = (<td style={{whiteSpace: "pre"}}>{gData.tableData[curIndex]?.AcceptUsername}</td>);
            }else{
                accepted = (<td className={"d-none"}></td>);
            }
            if (gData.tableData[curIndex]?.Status === "Waiting"){
                tmpTD = (<td className={"text-warning text-center"}>{gData.tableData[curIndex]?.Status}</td>);
            }
            else if(gData.tableData[curIndex]?.Status === "Overdue"){
                tmpTD = (<td className={"text-danger text-center"}>{gData.tableData[curIndex]?.Status}</td>);
            }
            else{
                tmpTD = (<td className={"text-primary text-center"}>{gData.tableData[curIndex]?.Status}</td>);
            }
            if (props?.toFetch === "maintenance" && dData.userrole !== "caregiver" && gData.tableData[curIndex]?.LocalEventDate){
                actionAdd = (
                    <td className={"text-primary text-center"}>
                        <Button text={"Clear"} class={"btn btn-primary"} id={"clear"+curIndex.toString()}
                                onClick={() => {clearMaint(gData.tableData[curIndex]?.AlarmId, setCount, dData,
                                        "clear"+curIndex.toString(), "loader"+curIndex.toString())}} />
                        <Button type={"button"} class={"btn btn-primary d-none"} disabled={true} spinner={true}
                                text={""} id={"loader"+curIndex.toString()}/>
                    </td>
                );
            }
            tmp.push(
                <tr key={"tableRow"+index} className={["fall", "assist"].includes(gData.tableData[curIndex]?.AlertType?.toLowerCase()) ? "importantTR" : ""}>
                    <td className={"text-center"} style={{whiteSpace: "pre"}}>
                        <p className={"mb-0 fs-6"}>{gData.tableData[curIndex]?.LocalEventDate}</p>
                        <br/>
                        <p className={"mb-0"}>{gData.tableData[curIndex]?.LocalEventTime}</p>
                    </td>
                    <td className={"text-center"} style={{whiteSpace: "pre-line"}}>
                        {gData.tableData[curIndex]?.ElapsedFormatted}
                    </td>
                    <td className={"text-center"} style={{whiteSpace: "pre-line"}}>
                        {gData.tableData[curIndex]?.AlertType}
                    </td>
                    {addOns}
                    <td style={{whiteSpace: "pre-line"}}>
                        {gData.tableData[curIndex]?.ResidentsParsed}
                    </td>
                    <td className={"text-center"}>
                        <p className={"mb-0 fs-6"}>{gData.tableData[curIndex]?.LocationArea}</p>
                        <br/>
                        <p className={"mb-0"}>{gData.tableData[curIndex]?.DeviceUse}</p>
                    </td>
                    <td className={"text-center"}>
                        <p className={"mb-0 fs-6"}>{gData.tableData[curIndex]?.LocationBuilding}</p>
                        <br/>
                        <p className={"mb-0"}>{gData.tableData[curIndex]?.LocationName}</p>
                    </td>
                    <td style={{whiteSpace: "pre"}}>
                        {gData.tableData[curIndex]?.NearName}
                    </td>
                    {accepted}
                    {tmpTD}
                    {actionAdd}
                </tr>
            );
        }
        setTableRows(tmp);
        setLink(prevState => {
        return {...prevState,
            headers: csvHeaders,
            data: gData.tableData}
        });
    }, [gData.tableData, sorter, count]);

    useEffect(() => {
        if (props?.toFetch === "maintenance"){
            setCSVButton(
                <CSVLink {...csvLink} uFEFF={false}>
                    <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary ms-2 px-4 mt-1"}/>
                </CSVLink>
            );
        }else{
            setCSVButton("");
        }
    }, [csvLink]);

    function clearMaint(alarmId, setCount, dData, clearId, loadId){
        let payload, save, load, ackDateTime;
        save = document.getElementById(clearId);
        load = document.getElementById(loadId);
        save.classList.add("d-none");
        load.classList.remove("d-none");
        ackDateTime = getUTCTimestamp();
        payload = [{"CampusId": campusToGuid(dData, campusId), "AlarmId": alarmId, "DateModified": ackDateTime, "Deleted": true,
            "AckDateTime": ackDateTime, "ModifiedBy": dData.username, "TTL": getTTL(.5)}];
        ApiRequester({reqEndpoint: "save", Tbl: "Telemetry", PKey: "CampusId", SKey: "AlarmId",
                                Payload: payload, CampusId: campusToGuid(dData, campusId)}).then(data => {
            if(data[0]){
                toast.success("Maintenance alarm cleared");
                setCount(0);
            }
            else{
                toast.error("Error encountered, clear failed.");
            }
            save.classList.remove("d-none");
            load.classList.add("d-none");
        });
    }

    const tableHeaders = <TableHeaders checkbox={false} headerData={headerData} sortOrder={sorter.sortOrder}
                                       sortColumn={sorter.sortColumn} sorter={sorter} setSorter={setSorter} />

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div style={{minWidth: "360px"}}>
                <div className={"form-outline d-inline-flex align-bottom"}>
                </div>
                {csvButton}
            </div>
            <CampusDropDown campusId={campusId} setCampusId={(item) => setCampusId(item)} setCount={(item) => setCount(item)} pageAction={() => {}}/>
        </div>
    );
    return (
        <div className={"main-content"}>
            <Header preTitle={"Alerts"} Title={props.toFetch} content={headerContent}/>
            <div className={"container-fluid"}>
                <div className={"row"}>
                    <table className={"table table-sm table-white table-hover"}>
                        <thead className={"sticky-table-head"}>
                            {tableHeaders}
                        </thead>
                        <tbody>
                            {tableRows}
                        </tbody>
                    </table>
                </div>
                <div className={"footer-spacer"}>

                </div>
            </div>
            <Footer />
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </div>
    );
};

export default LegacyCurrentAlerts;